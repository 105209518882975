import { RootState } from '../../../../infrastructure';
import { connect, ConnectedProps } from 'react-redux';
import { FeatureFlagKeys, getFeatureFlagItem } from '@maverick/entity';
import { DiscountsPaymentDetails } from './DiscountsPaymentDetails';
import { AppOperations } from '../../../../features/App/redux/App.operations';
import { CheckoutOperations } from '../../../../features/Checkout/redux/Checkout.operations';

const mapStateToProps = (state: RootState) => ({
	basket: state.checkout.basket,
	processing: state.app.processing,
	skeletonPages: getFeatureFlagItem(state.app.featureFlagItems, FeatureFlagKeys.SkeletonPages),
});

const mapDispatchToProps = {
	setProcessing: AppOperations.setProcessing,
	setBasket: CheckoutOperations.setBasket,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const DiscountsPaymentDetailsConnected = connector(DiscountsPaymentDetails);
export { DiscountsPaymentDetailsConnected as DiscountsPaymentDetails };
