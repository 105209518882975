import {
	HomeHeaderData,
	HomeLogo,
	Route,
	SitecoreRouteItem,
	NavigationProps,
	FeatureFlagItem,
	FeatureFlagHighlight,
	Brands,
} from '@maverick/entity';
import { NavigationService } from '../../services/sitecore/Navigation.service';
import { NavigationBonefishGrill, NavigationCarrabbas, NavigationOutback } from '../../shared/constants/Navigation';
import { EdgeConfigService } from '../../services';
import { Config } from '../../Config';
import { BrandsLogo } from '../../shared/constants/BrandsLogo';

export const AppManager = {
	GetRoutes: async (): Promise<NavigationProps> => {
		const homeDataResponse = await NavigationService.GetHomeData<HomeHeaderData>();

		if (homeDataResponse.error) {
			switch (Config.Brand) {
				case Brands.Outback:
					return NavigationOutback;
				case Brands.Carrabbas:
					return NavigationCarrabbas;
				case Brands.Bonefish:
					return NavigationBonefishGrill;
				default:
					return NavigationOutback;
			}
		}

		const routes: NavigationProps = {
			mainItems: await GetRoutesSorted(homeDataResponse.body.item.mainItems.value),
			hamburgerItems: await GetRoutesSorted(homeDataResponse.body.item.hamburgerItems.value),
			featuredItems: await GetRoutesSorted(homeDataResponse.body.item.featuredItems.value),
			unfeaturedItems: await GetRoutesSorted(homeDataResponse.body.item.unfeaturedItems.value),
		};

		return routes;

		async function GetRoutesSorted(fieldValue: string): Promise<Array<Route>> {
			const ids = GetIds(fieldValue);
			const routes = await GetRoutes(ids);
			return RouteSort(ids, routes);
		}

		function GetIds(idFieldValue: string): Array<string> {
			return idFieldValue.includes('|') ? idFieldValue.split('|') : [];
		}

		function RouteSort(ids: string[], routes: Array<Route>): Array<Route> {
			const routeSorted = new Array<Route>();

			ids.forEach((id) => {
				const route = routes.find((r) => r.id === id);
				if (!route) {
					return;
				}

				routeSorted.push(route);
			});

			return routeSorted;
		}
	},

	GetLogo: async (): Promise<HomeLogo> => {
		const homeLogoResponse = await NavigationService.GetHomeLogo<HomeLogo>();

		if (homeLogoResponse.error) {
			switch (Config.Brand) {
				case Brands.Outback:
					return BrandsLogo.outback;
				case Brands.Carrabbas:
					return BrandsLogo.carrabbas;
				case Brands.Bonefish:
					return BrandsLogo.bonefishgrill;
				default:
					return BrandsLogo.outback;
			}
		}

		return homeLogoResponse.body;
	},

	GetFeatureFlag: async (): Promise<Array<FeatureFlagItem>> => {
		const response = await EdgeConfigService.Get();
		if (!response?.featureFlag?.length) return [];
		return response.featureFlag;
	},

	GetFeatureHighlight: async (): Promise<Array<FeatureFlagHighlight>> => {
		const response = await EdgeConfigService.Get();
		if (!response?.featureHighlight?.length) return [];
		return response.featureHighlight;
	},

	GetAllowDelivery: async (): Promise<boolean> => {
		const response = await EdgeConfigService.Get();
		if (!response) return false;
		return response.allowDelivery;
	},

	GetWhitelabelSwitch: async (): Promise<boolean> => {
		const response = await EdgeConfigService.Get();
		if (!response) return false;
		return response.whitelabelSwitch;
	},
};

const GetRoutes = async (ids: string[]): Promise<Array<Route>> => {
	const routes = new Array<Route>();
	const getRouteItem = async (id: string) => {
		const navigationItem = await NavigationService.GetRouteItem<SitecoreRouteItem>(id);
		const route: Route = {
			id: id,
			label: navigationItem.body.item?.label,
			newTab: navigationItem.body.item?.link?.jsonValue.value.target === '_blank' ? true : false ?? false,
			url: navigationItem.body.item.link?.jsonValue.value.href ?? navigationItem.body.item?.url.path ?? '/',
		};
		routes.push(route);
	};
	await Promise.all(ids.map((id) => getRouteItem(id)));
	return routes;
};
