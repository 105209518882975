import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const HowItWorks = styled.button`
	font-family: unset;
	background: none;
	border: none;
	margin: 0;

	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall}
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	padding: 0;
	cursor: pointer;

	&:hover {
		color: ${({ theme }) => theme.orderUi.colors.primary.light};
	}

	margin-top: 16px;
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.button};
	}
`;

export const Title = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h3};
	color: ${({ theme }) => theme.orderUi.specificStyle.titleColor};

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.headline.h2};
	}
`;

export const RedeemContent = styled.div`
	align-items: flex-start;
	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		flex: 1;
	}
`;

export const Items = styled.div<{ isMobile: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: ${({ isMobile }) => (isMobile ? 0 : '16px')};
`;

export const DineRewardsIcon = styled.div<{ isMobile: boolean }>`
	display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
	margin-right: 16px;
	background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/dine-rewards-logo.svg');
	width: 68px;
	height: 41px;
	background-repeat: no-repeat;
`;

export const RedeemColumn = styled.div`
	flex-direction: column;
	display: flex;
	flex: 1;
`;

export const RewardsInfo = styled.div`
	${({ theme }) => theme.orderUi.typography.body.body}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	& > span {
		${({ theme }) => theme.orderUi.typography.inputs.button}
	}
`;
