import styled from 'styled-components';

export const Container = styled.div``;

export const ProgressBar = styled.div`
	width: 100%;
	height: 8px;
	background-color: ${({ theme }) => theme.orderUi.specificStyle.progressBarBackgroundColor};
	border-radius: 10px;
	overflow: hidden;
	margin-top: 14px;
`;

export const Progress = styled.div`
	height: 100%;
	background-color: ${({ theme }) => theme.orderUi.colors.primary.variant};
	transition: width 0.5s ease;
	border-radius: 10px;
`;
