import { FeatureFlagKeys, getFeatureFlagItem } from '@maverick/entity';
import { RootState } from '../../../../../infrastructure';
import { MenuContent } from './MenuContent';
import { connect, ConnectedProps } from 'react-redux';
import { UserOperations } from '../../../../User/redux/User.operations';
import { AppOperations } from '../../../../App/redux/App.operations';
import { FeatureFlagHighlight, getFeatureHighlight } from '@maverick/entity';
import { MenuOperations } from '../../../redux/Menu.operations';

const mapStateToProps = (state: RootState) => ({
	recentOrdersAndFaves: getFeatureFlagItem(state.app.featureFlagItems, FeatureFlagKeys.RecentOrdersAndFaves),
	redeemInPerson: getFeatureFlagItem(state.app.featureFlagItems, FeatureFlagKeys.RedeemInPerson),
	featureFlagItems: state.app.featureFlagItems,
	userInfo: state.user.userInfo,
	userRewards: state.user.userRewards,
	faves: state.user.userFaves?.faves,
	recentOrders: state.user.userRecentOrders?.orders,
	menu: state.menu.menu,
	highlightFeature: getFeatureHighlight(state.app.featureHighlight, FeatureFlagKeys.RedeemInPerson) as FeatureFlagHighlight,
});

const mapDispatchToProps = {
	setUserRewards: UserOperations.setUserRewards,
	setRedirectRoute: AppOperations.setRedirectRoute,
	setSelectedProduct: MenuOperations.setSelectedProduct,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const MenuContentConnected = connector(MenuContent);
export { MenuContentConnected as MenuContent };
