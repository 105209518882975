import { FC } from 'react';
import * as Styled from './RewardsHowItWorksColumn.styled';
import { useTheme } from 'styled-components';
import { Drawer } from '@maverick/ui';
import { DESKTOP_BREAKPOINT } from '../../../../utils/GeneralUtils';
import { useIsMobile } from '@maverick/hooks';
import { HowItWorks } from '../../../../features/Checkout/views/HowItWorks';
import { GaEvent } from '@maverick/utils';
import { ButtonProfile } from '../../../enums/ButtonProfile';

interface RewardsHowItWorksColumnProps {
	userName: string;
	showHowItWorks: boolean;
	setShowHowItWorks: (value: boolean) => void;
}

export const RewardsHowItWorksColumn: FC<RewardsHowItWorksColumnProps> = ({
	userName,
	showHowItWorks,
	setShowHowItWorks,
}) => {
	const { orderUi } = useTheme();
	const isMobile = useIsMobile(DESKTOP_BREAKPOINT);
	const drawerWidth = isMobile ? DESKTOP_BREAKPOINT : 406;

	return (
		<Styled.RedeemColumn>
			<Styled.RedeemContent>
				<Styled.Title tabIndex={0} data-testid='hello-user'>
					{orderUi.texts.DineRewardsSalutation}, {userName}!
				</Styled.Title>
				<Styled.Items isMobile={isMobile}>
					<Styled.DineRewardsIcon isMobile={isMobile} />
					<Styled.RewardsInfo tabIndex={0}>
						You are logged into your <span>Dine Rewards</span> account.
					</Styled.RewardsInfo>
				</Styled.Items>
				<Styled.HowItWorks
					aria-label='Open How it works'
					data-testid='how-it-works'
					onClick={() => {
						GaEvent.ProfileButtonsClick(ButtonProfile.HowItWorks);
						setShowHowItWorks(true);
					}}
				>
					How it works
				</Styled.HowItWorks>
			</Styled.RedeemContent>

			<Drawer
				isOpen={showHowItWorks}
				onClose={() => setShowHowItWorks(false)}
				hideScrollbar
				width={drawerWidth}
				maxWidth={100}
			>
				{showHowItWorks && (
					<HowItWorks
						onClose={() => {
							setShowHowItWorks(false);
						}}
					/>
				)}
			</Drawer>
		</Styled.RedeemColumn>
	);
};
