import { FC, useState, useEffect } from 'react';
import { AppliedReward, RewardWallet } from '@maverick/entity';
import { isDesktop } from '../../../utils/GeneralUtils';
import * as Styled from './RewardsList.styled';
import { RewardsButton, RewardsButtonVariants } from '../Rewards/RewardsButton';
import { DineRewardsBanner } from '../DineRewardsBanner';
import { GaEvent } from '@maverick/utils';
import { Button, SkeletonRewardsList, useToast } from '@maverick/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { ReduxProps } from './index';
import { UserManager } from '../../../features/User/User.manager';
import { rewardAdded, selectGetQualifyingRewardsError, selectRewardError } from '../../constants';
import { CheckoutManager } from '../../../features/Checkout/Checkout.manager';
import { useTheme } from 'styled-components';
import { MenuArrowLeft } from '@maverick/icons/dist/MenuArrowLeft';
import { MenuArrowRight } from '@maverick/icons/dist/MenuArrowRight';

export interface RewardsListProps extends ReduxProps {
	onRedeemReward?: (appliedReward: AppliedReward) => Promise<boolean>;
	location?: string;
	isInView?: boolean;
}

export const RewardsList: FC<RewardsListProps> = ({
	setProcessing,
	setBasket,
	userToken,
	selectedRestaurant,
	basket,
	onRedeemReward,
	userRewards,
	userRedeemableRewards,
	processing,
	skeletonPages,
	isInView,
	location,
}) => {
	const pageTitle = 'RewardsList';
	const { isAuthenticated, user } = useAuth0();
	const { setToast } = useToast();
	const [hasRewardsApplied, setHasRewardsApplied] = useState<boolean>(false);
	const [focused, setFocused] = useState<number>(0);
	const isLastRewardFocused = userRedeemableRewards && focused === userRedeemableRewards?.length - 1;
	const isFirstRewardFocused = focused === 0;
	const hasMultipleRewards = userRedeemableRewards && userRedeemableRewards?.length > 1;
	const [redeemingReward, setRedeemingReward] = useState(false);
	const { orderUi } = useTheme();
	const [visibleRewars, setVisibleRewars] = useState<number>(0);

	useEffect(() => {
		setHasRewardsApplied(!!basket?.appliedrewards.length);
	}, [basket]);

	useEffect(() => {
		let visibleRewardId: number[] = [];
		const elementsWithSuffix = document.querySelectorAll('[id$="-reward"]');
		elementsWithSuffix.forEach((element) => {
			observerFunction(element, visibleRewardId);
		});
		setTimeout(() => {
			setVisibleRewars(Math.max(...visibleRewardId));
		}, 500);
	}, [isInView]);

	const onDineRewardsClick = () => {
		GaEvent.DineRewardsLinkClick('cart');
	};

	const scrollToNextCard = () => {
		let goNextCase = 1;
		if (focused === 0 && visibleRewars > 1) goNextCase = visibleRewars;
		if (userRedeemableRewards && focused >= userRedeemableRewards?.length - 1) {
			return;
		}
		const nextCard = document.getElementById(`${focused + goNextCase}-reward`);
		nextCard?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		setTimeout(() => {
			setFocused(focused + goNextCase);
		}, 300);
	};

	const scrollToPreviousCard = () => {
		let goBackCase = 1;
		if (userRedeemableRewards?.length === focused + 1 && visibleRewars > 1) goBackCase = visibleRewars;

		const previousCard = document.getElementById(`${focused - goBackCase}-reward`);
		previousCard?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		if (focused == 0) {
			return;
		}
		setTimeout(() => {
			setFocused(focused - goBackCase);
		}, 300);
	};

	const redeemReward = async (reward: RewardWallet) => {
		setProcessing(true);
		setRedeemingReward(true);

		const { responseRewards, error } = await UserManager.GetQualifyingRewards(userToken!, selectedRestaurant!.id);

		if (!!error || !responseRewards) {
			const errorMessage = error ?? selectGetQualifyingRewardsError;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage, pageTitle);
			setProcessing(false);
			return;
		}

		const equivalentRewards = userRewards?.rewardsWallets.filter((r) => r.walletCode === reward.walletCode);

		let rewardValue = 0;
		const oloReward = responseRewards.find((r) =>
			equivalentRewards?.find((er) => {
				if (er.id.toString() === r.externalreference.replace(/\D/g, '')) {
					rewardValue = er.rewardValue;
					return er;
				}
			})
		);

		if (!oloReward) {
			setToast({
				variant: 'error',
				text: selectRewardError,
			});
			GaEvent.ErrorMessage(selectRewardError, pageTitle);
			setProcessing(false);
			return;
		}

		const result = await CheckoutManager.AddRewards(basket!.id, {
			membershipid: oloReward.membershipid,
			references: [oloReward.reference],
		});

		if (!result || !result.response || result.error) {
			setToast({
				variant: 'error',
				text: result.error || selectRewardError,
			});
			GaEvent.ErrorMessage(result.error || selectRewardError, pageTitle);
			setProcessing(false);
			return;
		}

		let shouldShowToast = true;
		if (onRedeemReward) {
			shouldShowToast = await onRedeemReward(result.response.appliedrewards[0]);
		} else {
			setBasket(result.response);
		}

		GaEvent.RedeemRewardClick(location === '/checkout' ? 'checkout' : 'order summary', rewardValue);
		if (shouldShowToast) {
			setToast({
				variant: 'success',
				text: rewardAdded,
			});
		}

		setProcessing(false);
		setRedeemingReward(false);
	};

	if (!isAuthenticated || !user || !userRewards || !userRedeemableRewards || userRedeemableRewards?.length === 0) {
		return (
			<Styled.DineRewardsBanner data-testid='dine-rewards-banner'>
				<Styled.Section>
					<DineRewardsBanner
						imagePath={orderUi.images.dineRewardsBanner}
						variant='small'
						onClick={onDineRewardsClick}
					></DineRewardsBanner>
					<Styled.Line />
				</Styled.Section>
			</Styled.DineRewardsBanner>
		);
	}
	const observerFunction = (element: Element, visibleRewardId: number[]) => {
		const observer = new IntersectionObserver((entrie) => {
			entrie.forEach((entry) => {
				if (entry.isIntersecting) {
					const id = entry.target.id.split('-')[0] ? parseInt(entry.target.id.split('-')[0]) : null;
					if (id) visibleRewardId.push(id);
				}
			});
		});
		observer.observe(element);
	};

	if (processing && skeletonPages?.active && redeemingReward) {
		return <SkeletonRewardsList />;
	}
	return (
		<Styled.Section data-testid='rewards-section'>
			<Styled.Section>
				<Styled.Header>
					<Styled.Title id='rewards-section-title'>Rewards</Styled.Title>
				</Styled.Header>
				{userRedeemableRewards?.length === 1 ? (
					<Styled.Subtitle>{`${userRedeemableRewards.length} available reward (Only one reward per order)`}</Styled.Subtitle>
				) : (
					<Styled.Subtitle>{`${userRedeemableRewards?.length} available rewards (Only one reward per order)`}</Styled.Subtitle>
				)}
				<Styled.ListWrapper>
					{isDesktop() && !isFirstRewardFocused && (
						<Styled.ListGradientPrevious>
							<Styled.PreviousRewardButton>
								<Button
									icon={MenuArrowLeft}
									text=''
									variant='terciary'
									onClick={scrollToPreviousCard}
									id={'next-reward'}
								/>
							</Styled.PreviousRewardButton>
						</Styled.ListGradientPrevious>
					)}
					{isDesktop() && !isLastRewardFocused && (
						<Styled.ListGradientNext>
							<Styled.NextRewardButton>
								<Button
									icon={MenuArrowRight}
									disabled={isLastRewardFocused}
									text=''
									variant='terciary'
									onClick={scrollToNextCard}
									id={'next-reward'}
								/>
							</Styled.NextRewardButton>
						</Styled.ListGradientNext>
					)}
					<Styled.ScrollWrapper hasMultipleRewards={hasMultipleRewards !== undefined}>
						{userRedeemableRewards?.map((reward, index) => {
							return (
								<div key={`${index}-reward`} id={`${index}-reward`}>
									<RewardsButton
										variant={RewardsButtonVariants.Secondary}
										disabled={hasRewardsApplied}
										reward={reward}
										key={reward.id}
										id={reward.id}
										selected={false}
										onClick={() => {
											redeemReward(reward);
										}}
									/>
								</div>
							);
						})}
					</Styled.ScrollWrapper>
				</Styled.ListWrapper>
			</Styled.Section>
			<Styled.Line />
		</Styled.Section>
	);
};
