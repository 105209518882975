import { Icon } from '@maverick/ui';
import * as Styled from './ContentCard.styled';
import React, { FC } from 'react';

export interface ContentCardProps {
	icon: JSX.Element;
	title: string;
	content: string;
}

export const ContentCard: FC<ContentCardProps> = ({ icon, title, content }) => {
	return (
		<>
			<Styled.Container>
				<Styled.Icon>
					<Icon icon={icon} />
				</Styled.Icon>
				<Styled.Subject>
					<Styled.CardTitle tabIndex={0}>{title}</Styled.CardTitle>
					<Styled.CardContent tabIndex={0}>{content}</Styled.CardContent>
				</Styled.Subject>
			</Styled.Container>
		</>
	);
};
