import styled from 'styled-components';

export const Image = styled.img<{ src: string }>`
	flex: 1;
	height: 100%;
	object-fit: cover;
	background-position: center center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	flex-shrink: 0;
`;
