import styled from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../utils/GeneralUtils';

export const Container = styled.div`
	background: none;
	border: none;
	padding: 0;
	${({ theme }) => theme.orderUi.typography.caption.captionMedium};
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	cursor: pointer;
	font-weight: ${({ theme }) => theme.orderUi.specificStyle.specificFontWeight};

	&:hover {
		color: ${({ theme }) => theme.orderUi.colors.primary.light};
		text-decoration: underline;
	}

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
	}
`;
