import { FC, useEffect } from 'react';
import { ReduxProps } from '.';
import { Loading } from '@maverick/ui';
import { useLocation } from 'react-router-dom';
import { useRedirect } from '../../../../shared/hooks';
import { HandoffManager } from '../../Handoff.manager';
import { GaEvent } from '@maverick/utils';
import { Helmet } from 'react-helmet';
import { Restaurant } from '@maverick/entity';

import * as Styled from './PreSelectRestaurant.styled';

interface PreSelectRestaurantProps extends ReduxProps {}
export const PreSelectRestaurant: FC<PreSelectRestaurantProps> = ({ setSelectedRestaurant }) => {
	const location = useLocation();
	const redirect = useRedirect();

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const restaurantParam = location.pathname.slice(1);
		if (!restaurantParam) return exit();

		let restaurant: Restaurant | null;
		const restaurantId = Number(restaurantParam.split('-')?.[0]);
		if (isNaN(restaurantId)) {
			restaurant = await HandoffManager.GetRestaurantBySlug(restaurantParam);
		} else {
			restaurant = await HandoffManager.GetRestaurant(restaurantId);
		}

		if (!restaurant) return exit();

		setRestaurantWithCalendar(restaurant);
		
		GaEvent.Global.Set('store_id', restaurant.id);

		redirect(`/menu/${restaurant.slug}`);
	};

	const setRestaurantWithCalendar = async (restaurant: Restaurant) => {
		const { calendar, error } = await HandoffManager.GetRestaurantCalendarById(restaurant.id);
		if (!error && calendar) {
			const selectedRestaurant = {
				...restaurant,
				calendar: calendar || [],
			};
			setSelectedRestaurant(selectedRestaurant);
		}
		else {
			setSelectedRestaurant(restaurant);
		}
	};

	const exit = () => {
		redirect('/');
	};

	return (
		<>
			<Helmet>
				<title>Selecting your location</title>
			</Helmet>

			<Styled.Container>
				<Styled.Loading>
					<Loading />
				</Styled.Loading>
			</Styled.Container>
		</>
	);
};
