import { TABLET_BREAKPOINT } from '@maverick/utils';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isNested: boolean }>`
	margin: 24px 0 0;
	transition: all ease 0.2s;

	${({ isNested }) =>
		isNested &&
		css`
			${Top} {
				margin: 0;
			}
		`}
`;

export const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin: 0 0 16px;
`;

export const Title = styled.h2`
	font-weight: unset;
	${({ theme }) => theme.orderUi.typography.headline.h5};

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.headline.h4Variant};
	}
`;

export const TitleObs = styled.div<{ mandatory: boolean }>`
	${({ theme }) => theme.orderUi.typography.caption.caption};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.medium};

	${({ mandatory }) =>
		mandatory &&
		css`
			color: ${({ theme }) => theme.orderUi.colors.primary.medium};
		`}

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.bodySmall};
	}
`;

export const Subtitle = styled.h3`
	margin: 0 0 8px;
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	font-family: inherit;
`;

export const SelectionSubtitle = styled.h3`
	margin: 0 0 16px;
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	font-family: inherit;
`;

export const Content = styled.div``;
