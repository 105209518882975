import { RestApi, RestResponse } from "@maverick/http";

const bbiResponseMiddleware = (response: RestResponse) => {
	if (!response.error) {
		if (!!response.body.error) {
			const newResponse: RestResponse = {
				...response,
				error: true,
				errorMessage: response.body.error,
			};
			return newResponse;
		}

		return response;
	}
	
	if (response.errorClient) {
		const newResponse: RestResponse = {
			...response,
			errorMessage: response.body.error,
		};
		return newResponse;
	}	

	const newResponse: RestResponse = {
		...response,
		errorMessage: 'Failure in communicate with the server',
	};

	return newResponse;
};

export const BbiClient = new RestApi('/order/api/bbi', undefined, bbiResponseMiddleware);