import { FC } from 'react';
import * as Styled from './DineRewardsBanner.styled';
import { Button, Icon } from '@maverick/ui';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ReduxProps } from '.';
import { useTheme } from 'styled-components';
import { Config } from '../../../Config';
import { IconChevronRight } from '@maverick/icons/dist/IconChevronRight';

type variantOptions = 'large' | 'small';

export interface DineRewardsBannerProps extends ReduxProps {
	imagePath: string;
	dineDescription?: string;
	variant?: variantOptions;
	onClick: () => void;
}

export const DineRewardsBanner: FC<DineRewardsBannerProps> = ({
	imagePath,
	dineDescription,
	variant = 'small',
	setRedirectRoute,
	onClick,
}) => {
	const location = useLocation();
	const { loginWithRedirect, isAuthenticated } = useAuth0();

	const joinDineRewards = () => {
		onClick();
		setRedirectRoute(location.pathname);
		loginWithRedirect({ source: Config.Source });
	};
	const { orderUi } = useTheme();

	return (
		<Styled.Container id='dine-rewards' variant={variant}>
			<Styled.HeyMate variant={variant}>
				<Styled.Header>
					<Styled.Title variant={variant} id='dine-rewards-title' data-testid='dine-rewards-title'>
						{orderUi.texts.DineRewardsBannerTitle}
					</Styled.Title>
					<Styled.Icon>
						<Icon icon={orderUi.icons.iconDollarSign} customSize={variant === 'large'} />
					</Styled.Icon>
				</Styled.Header>
				<Styled.DineDescription id='dine-rewards-description' data-testid='dine-rewards-description'>
					{dineDescription ??
						(isAuthenticated ? (
							<>
								Checkout your order to <strong>earn rewards</strong> on this purchase!
							</>
						) : (
							<>
								You may be missing <strong>rewards</strong> on this purchase!
							</>
						))}
				</Styled.DineDescription>
				{!isAuthenticated && (
					<Styled.JoinDine role='link'>
						<Button
							text='Join Dine Rewards'
							variant='terciary'
							id='join-dine'
							margin='0 0 4px 4px'
							onClick={joinDineRewards}
							icon={IconChevronRight}
							revertIcon
							variantSize='buttonSmall'
						/>
					</Styled.JoinDine>
				)}
			</Styled.HeyMate>
			<Styled.Image
				variant={variant}
				imagePath={imagePath}
				id='dine-rewards-image'
				data-testid='dine-rewards-image'
			/>
		</Styled.Container>
	);
};
