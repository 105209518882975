import { connect, ConnectedProps } from 'react-redux';
import { MenuOperations } from '../../../features/Menu/redux/Menu.operations';
import { RootState } from '../../../infrastructure';
import { OptionsCheckboxList } from './OptionsCheckboxList';

const mapStateToProps = ({ menu }: RootState) => ({
	selectedOptions: menu.selectedOptions,
	rawOptionGroups: menu.rawOptionGroups,
});

const mapDispatchToProps = {
	selectOption: MenuOperations.selectOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type OptionsCheckboxListReduxProps = ConnectedProps<typeof connector>;
const OptionsCheckboxListConnected = connector(OptionsCheckboxList);
export { OptionsCheckboxListConnected as OptionsCheckboxList };
