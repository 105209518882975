import { HttpMethods, RestRequest } from '@maverick/http';
import { RadarAutocompleteAddress } from 'radar-sdk-js/dist/types';
import { RadarClient } from './radarClient';

export const RadarService = {
	GetAddressAutocomplete: async (text: string) => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/search/autocomplete?country=US&layers=address,locality,state,postalCode&query=${text}`,
		});
		const response = await RadarClient.ExecuteRequest(request);
		return response?.body?.addresses as Array<RadarAutocompleteAddress>;
	},
};
