import { FC } from 'react';
import * as Styled from './BlankOrderSummaryBackground.styled';
import { useTheme } from 'styled-components';

interface BlankOrderSummaryBackgroundProps {
	src: string | undefined;
	alt?: string;
}

export const BlankOrderSummaryBackground: FC<BlankOrderSummaryBackgroundProps> = ({ src, alt }) => {
	const { orderUi } = useTheme();
	const image = !!src ? src : orderUi.images.blankOrderSummaryBackground;
	return <Styled.Image data-testid='image' alt={alt} src={image}></Styled.Image>;
};
