import { RootState } from '../../../../infrastructure';
import { connect, ConnectedProps } from 'react-redux';
import { MenuOperations } from '../../redux/Menu.operations';
import { RestaurantMenu } from './RestaurantMenu';
import { AppOperations } from '../../../App/redux/App.operations';
import { CheckoutOperations } from '../../../Checkout/redux/Checkout.operations';

const mapStateToProps = (state: RootState) => ({
	deliveryAddress: state.handoff.deliveryAddress,
	redirectRouteCheckout: state.app.redirectRouteCheckout,
	redirectRouteEdit: state.app.redirectRouteEdit,
	method: state.handoff.selectedMethod,
	menu: state.menu.menu,
	selectedRestaurantAddress: state.handoff.selectedRestaurantAddress,
	selectedRestaurant: state.handoff.selectedRestaurant,
	itemsNotTransferred: state.checkout.itemsNotTransferred,
});

const mapDispatchToProps = {
	setLoading: AppOperations.setLoading,
	setShowSummary: AppOperations.setShowSummary,
	clearRedirectRouteCheckout: AppOperations.clearRedirectRouteCheckout,
	clearRedirectRouteEdit: AppOperations.clearRedirectRouteEdit,
	setSelectedProduct: MenuOperations.setSelectedProduct,
	setRedirectRouteMenu: MenuOperations.setRedirectRouteMenu,
	clearItemsNotTransferred: CheckoutOperations.clearItemsNotTransferred,
	setAddCartOrigin: MenuOperations.setAddCartOrigin,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const RestaurantMenuConnected = connector(RestaurantMenu);
export { RestaurantMenuConnected as RestaurantMenu };
