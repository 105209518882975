import { FC } from 'react';
import * as Styled from './RewardsWelcomeColumn.styled';
import { RewardsCheckColumn } from '../RewardsCheckColumn';

interface RewardsWelcomeColumnProps {
	rewardsCounter: number;
	handleClick: () => void;
	message?: string;
}

export const RewardsWelcomeColumn: FC<RewardsWelcomeColumnProps> = ({ rewardsCounter, handleClick, message }) => {
	return (
		<Styled.Container data-testid='rewards-welcome-column'>
			<Styled.Welcome>{message && <Styled.Title tabIndex={0}>{message}</Styled.Title>}</Styled.Welcome>
			<Styled.CheckRewards>
				<RewardsCheckColumn key='howitworks' rewardsCounter={rewardsCounter} handleClick={handleClick} />
			</Styled.CheckRewards>
		</Styled.Container>
	);
};
