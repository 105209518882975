import styled from 'styled-components';
import { TABLET_BREAKPOINT } from '@maverick/utils';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ContainerAddressIcon = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const AddressContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		width: max-content;
	}
`;

export const MethodIcon = styled.div<{ iconColor: string, iconPadding?: string }>`
	width: 40px;
	margin-right: 16px;
	color: ${({ iconColor }) => iconColor};
	padding: ${({ iconPadding }) => iconPadding};
`;

export const AddressContainerItem = styled.div`
	flex: 1;
`;

export const Label = styled.span`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	display: block;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.buttonSmall}
	}
`;

export const RestaurantName = styled.h1`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium}
	display: inline;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.buttonSmall}
	}
`;

export const Address = styled.span`
	${({ theme }) => theme.orderUi.typography.body.bodySmall}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.body}
	}
`;

export const EditIcon = styled.a`
	all: unset;
	color: ${({ theme }) => theme.orderUi.colors.primary.variant};
	width: 20px;
	margin-left: 16px;
	cursor: pointer;

	&:focus-visible {
		outline: solid 2px ${({ theme }) => theme.orderUi.colors.neutral.dark};
	}

	&:hover {
		color: ${({ theme }) => theme.orderUi.colors.primary.lightVariant};
	}
`;

export const HandoffMethodAlert = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
	padding: 2px 4px;
	width: fit-content;
	border-radius: 2px;
	background: ${({ theme }) => theme.orderUi.colors.secondary.light};
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	margin-top: 16px;
`;

export const HandoffMethodAlertIcon = styled.div`
	margin-right: 4px;
	width: 14px;
`;

export const HandoffMethodAlertText = styled.div`
	@media screen and (max-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.caption.caption};
	}
`;
