import { TABLET_BREAKPOINT, ULTRAWIDE_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.div<{ navbarHeight: number }>`
	position: sticky;
	top: ${({ navbarHeight }) => navbarHeight + 'px'};
	width: 100%;
	background: #fff;
	z-index: 2;
	border-bottom: solid 2px ${({ theme }) => theme.orderUi.colors.neutral.medium};
`;

export const ChangeRestaurantContainer = styled.div`
	padding: 16px 24px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		width: max-content;
		padding: 16px 32px;
	}

	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
	}
`;

export const NavbarContainer = styled.div`
	border-top: solid 2px ${({ theme }) => theme.orderUi.colors.neutral.medium};
	width: 100%;
`;

export const NavbarPosition = styled.div`
	max-width: 1920px;
	margin: 0 auto;
`;
