import { FC, useState } from 'react';
import * as Styled from './CurbsideHandoff.styled';

import { CurbsideContent } from './CurbsideContent';
import { Address, HandoffMethods, ItemAutocomplete, Restaurant, SearchedAddresses } from '@maverick/entity';
import { AutocompleteSearch, Icon, SkeletonCurbsideContent } from '@maverick/ui';
import { IconTarget } from '@maverick/icons/dist/IconTarget';
import { GaEvent, Storage } from '@maverick/utils';
import { ValidationConstants } from '../../../../../shared/constants';
import { fetchAddressSuggestions } from '../../../../../utils/GeneralUtils';

interface CurbsideHandoffProps {
	curbsideRestaurant: Restaurant | null;
	handleHighlightedRestaurant: (restaurant: Restaurant) => void;
	getCurrentLocation: () => void;
	setCurbsideRestaurant: (restaurant: Restaurant | null) => void;
	curbsideSearchedAddress: Address | null | undefined;
	setCurbsideSearchedAddress: (curbsideSearchedAddress: Address | null) => void;
	restaurantsList: Restaurant[];
	setRestaurantList: (restaurants: Restaurant[]) => void;
	setLoading: (loading: boolean) => void;
	setSearchedAddresses: (searchedAddresses: SearchedAddresses) => void;
	searchedAddresses: SearchedAddresses | null;
	loading: boolean;
	skeletonPagesActive: boolean;
}

export const CurbsideHandoff: FC<CurbsideHandoffProps> = ({
	restaurantsList,
	curbsideRestaurant,
	curbsideSearchedAddress,
	setCurbsideSearchedAddress,
	handleHighlightedRestaurant,
	setCurbsideRestaurant,
	getCurrentLocation,
	setRestaurantList,
	setLoading,
	setSearchedAddresses,
	searchedAddresses,
	loading,
	skeletonPagesActive,
}) => {
	const [isCurrentLocation, setIsCurrentLocation] = useState<boolean>(false);

	const clearCurbsideSearch = () => {
		setRestaurantList([]);
		setIsCurrentLocation(false);
		setCurbsideRestaurant(null);
		setCurbsideSearchedAddress(null);
	};

	const handleCurrentLocation = () => {
		setIsCurrentLocation(true);
		getCurrentLocation();
	};

	const handleCurbsideSearch = async (searchedAddress: ItemAutocomplete) => {
		if (!searchedAddress) {
			GaEvent.ErrorMessage(ValidationConstants.curbsideLocation);
			return;
		}

		setLoading(true);
		setIsCurrentLocation(false);

		const curbsideSearch = {
			address: searchedAddress.label,
			latitude: searchedAddress.value?.latitude,
			longitude: searchedAddress.value?.longitude,
		};

		setCurbsideSearchedAddress?.(curbsideSearch);

		setSearchedAddresses({
			...searchedAddresses,
			curbside: curbsideSearch,
			lastMethod: HandoffMethods.Curbside,
		});

		Storage.Session.Set('isUrlSlugFlow', false);

		setLoading(false);
		return;
	};

	return (
		<Styled.Container>
			<Styled.Address>
				<AutocompleteSearch
					id='curbside-address-input'
					label='Search for a Location'
					placeholder='Address, City and State'
					onSearch={fetchAddressSuggestions}
					onChoose={handleCurbsideSearch}
					onClear={clearCurbsideSearch}
					isCurrentLocation={isCurrentLocation}
					initialValue={curbsideSearchedAddress?.address}
				/>

				<Styled.LocationContainer>
					<Styled.Location onClick={handleCurrentLocation} data-testid='use-my-location'>
						Use my location
						<Icon icon={IconTarget} customSize aria-hidden={true} />
					</Styled.Location>
				</Styled.LocationContainer>
			</Styled.Address>
			{loading && skeletonPagesActive ? (
				<SkeletonCurbsideContent />
			) : (
				<CurbsideContent
					restaurantsList={restaurantsList}
					curbsideRestaurant={curbsideRestaurant}
					handleHighlightedRestaurant={handleHighlightedRestaurant}
					searchedAddresses={searchedAddresses}
				/>
			)}
		</Styled.Container>
	);
};
