import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.div``;

export const NotFound = styled.div`
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: flex;
`;

export const NotFoundDelivery = styled.div`
	padding-top: 24px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: flex;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding-top: 34px;
	}
`;

export const FoundImage = styled.div`
	${({ theme }) => theme.orderUi.icons.iconNoRestaurantsFoundSize}
`;

export const FoundIcon = styled.div`
	width: 112px;
	color: ${({ theme }) => theme.orderUi.colors.secondary.dark};
`;

export const MainText = styled.div`
	${({ theme }) => theme.orderUi.typography.inputs.buttonLarge}
	color: ${({ theme }) => theme.orderUi.colors.neutral.dark};
	margin-top: 32px;
	margin-bottom: 16px;
	text-align: center;
`;

export const SubText = styled.div`
	${({ theme }) => theme.orderUi.typography.body.body}
	color: ${({ theme }) => theme.orderUi.colors.neutral.dark};
	text-align: center;
`;

export const Address = styled.div`
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;
