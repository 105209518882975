import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.div``;

export const SectionTitle = styled.div`
	margin-bottom: 8px;
	${({ theme }) => theme.orderUi.typography.headline.h5}

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.headline.h4Variant};
	}
`;

export const Subtitle = styled.div`
	margin-top: 8px;
	${({ theme }) => theme.orderUi.typography.title.orderSummaryDetails}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.title.orderSummaryDetails}
	}
`;

export const TotalValue = styled.div`
	margin-top: 8px;
	${({ theme }) => theme.orderUi.typography.headline.h5};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	display: flex;
	justify-content: end;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.headline.h4Variant};
	}
`;

export const OrderSummary = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 8px;
`;

export const Tax = styled.div`
	${({ theme }) => theme.orderUi.typography.body.body};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.body};
	}
`;

export const Value = styled.div`
	${({ theme }) => theme.orderUi.typography.body.body};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	display: flex;
	justify-content: end;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.body};
	}
`;

export const PromoCode = styled.div`
	div {
		display: flex;
	}
`;

export const Remove = styled.div`
	div {
		display: flex;
	}
`;

export const GiftCard = styled.div`
	display: flex;
`;

export const Title = styled.div`
	margin-bottom: 8px;
    ${({ theme }) => theme.orderUi.typography.title.orderSummaryDetails}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;
