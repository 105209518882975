import { FC, Children, ReactNode } from 'react';

import * as Styled from './RewardsBanner.styled';

import { Button } from '@maverick/ui';
import { useRedirect } from '../../hooks';
import { useIsMobile } from '@maverick/hooks';
import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import { ReturnIcon } from '@maverick/icons/dist/ReturnIcon';

export interface RewardsBannerProps {
	redirectRoute?: string | null;
	hideWhenMobile?: boolean;
	hideReturnButton?: boolean;
	children: ReactNode;
}

export const RewardsBanner: FC<RewardsBannerProps> = ({
	redirectRoute,
	hideWhenMobile = false,
	hideReturnButton = false,
	children,
}) => {
	const redirect = useRedirect();
	const isMobile = useIsMobile(DESKTOP_BREAKPOINT);

	const handleClickReturn = () => {
		redirect(redirectRoute ?? '/');
	};

	return (
		<Styled.MainContainer data-testid='rewards-banner'>
			<Styled.Container hideWhenMobile={isMobile && hideWhenMobile}>
				{!hideReturnButton && (
					<Styled.ButtonReturn id='return-button' data-testid='return-button'>
						<Button
							icon={ReturnIcon}
							text='Return'
							variant='terciary'
							id='Return'
							revertIcon
							onClick={handleClickReturn}
							variantSize='buttonMedium'
							isStrong={true}
						/>
					</Styled.ButtonReturn>
				)}

				<Styled.Columns>
					{Children.map(children, (child, id) => {
						if ((!isMobile || (!hideWhenMobile && id !== 1)) && child) {
							return (
								<Styled.Banner key={id}>
									<Styled.BannerColumn tabIndex={0}>{child}</Styled.BannerColumn>
									{id !== Children.count(children) - 1 && <Styled.Divider data-testid='divider' />}
								</Styled.Banner>
							);
						}
					})}
				</Styled.Columns>
			</Styled.Container>
		</Styled.MainContainer>
	);
};
