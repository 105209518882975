import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop: FC = () => {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});
	}, [location]);

	return null;
};
