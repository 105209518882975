import styled from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../../utils/GeneralUtils';

export const Container = styled.div`
	position: relative;
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	justify-content: space-around;
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const Body = styled.div`
	padding: 0 24px 0;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	height: 100%;
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 0 32px 0;
	}
`;

export const ComponentTitle = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h3};
	text-transform: ${({ theme }) => theme.orderUi.specificStyle.titleLetterCase};
	color: ${({ theme }) => theme.orderUi.specificStyle.titleColor};
	position: sticky;
	top: 0;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	background: ${({ theme }) => theme.orderUi.colors.neutral.light};
	z-index: 1;
	padding: 24px 0 24px;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 24px 0 24px;
	}
`;

export const Close = styled.button`
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	transition: all ease 0.1s;

	&:hover {
		opacity: 0.6;
	}
`;

export const HowItWorksSection = styled.div``;

export const Cards = styled.div`
	padding: 24px 0 12px;
`;

export const BeyondSection = styled.div`
	padding-bottom: 32px;
`;

export const BeyondTitle = styled.div`
	${({ theme }) => theme.orderUi.typography.title.title};
	text-transform: ${({ theme }) => theme.orderUi.specificStyle.titleLetterCase};
	color: ${({ theme }) => theme.orderUi.specificStyle.titleColor};
	margin-bottom: 20px;
`;

export const BeyondSubtitle = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	margin-bottom: 30px;
`;

export const ContactUsSection = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-bottom: 32px;
`;

export const ContactUs = styled.a`
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	gap: 8px;
	cursor: pointer;
`;

export const Link = styled.a`
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
	color: ${({ theme }) => theme.orderUi.colors.primary.medium} !important;
	cursor: pointer;
`;

export const Phone = styled.a`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
`;

export const Logo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
`;

export const Brand = styled.img`
	padding-right: 29px;
`;
