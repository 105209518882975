import { TABLET_BREAKPOINT } from '@maverick/utils';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isExpanded: boolean }>`
	${({ isExpanded }) =>
		isExpanded &&
		css`
			${Options} {
				& > *:nth-child(n + 4) {
					display: flex;
				}

				@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
					& > *:nth-child(n + 7) {
						display: flex;
					}
				}
			}
		`}
`;

export const PillsContainer = styled.div`
	display: flex;
	margin-bottom: 16px;
	width: 100%;
	align-items: center;
	overflow-x: scroll;
	max-width: max-content;
	scroll-behavior: smooth;

	-ms-overflow-style: none;
	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}
`;

export const Pill = styled.div<{ selected: boolean }>`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	cursor: pointer;
	white-space: nowrap;

	&:not(:last-child) {
		border-right: 2px solid ${({ theme }) => theme.orderUi.colors.neutral.medium};
		padding-right: 16px;
		margin-right: 16px;

		@media screen and (max-width: 389px) {
			padding-right: 12px;
			margin-right: 12px;
		}
	}

	${({ selected }) =>
		!selected &&
		css`
			&:hover {
				color: ${({ theme }) => theme.orderUi.colors.primary.lightVariant};
			}
		`}

	${({ selected }) =>
		selected &&
		css`
			${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
			color: ${({ theme }) => theme.orderUi.colors.primary.variant};
		`}
`;

export const Options = styled.div<{ columns: number }>`
	display: grid;
	grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
	gap: 16px 24px;
	grid-auto-rows: 1fr;

	& > *:nth-child(n + ${({ columns }) => columns + 1}) {
		display: none;
	}
`;

export const CallToAction = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
`;

export const OptionGroupWrapper = styled.div`
`

export const PillsNavbar = styled.div`
	max-width: max-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
`;
