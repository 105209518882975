import { HttpStatus, RestApi, RestResponse } from '@maverick/http';
import { OloCodeErrors } from '../../shared/constants/OloCodeErrors';

const InvalidAuthtokenErrorMessage =
	'Your login session has timed out. Please log in again to continue using your account.';
const CommunicationErrorMessage = 'Failure in communicate with the server';

const oloResponseMiddleware = (response: RestResponse) => {
	if (response.status === HttpStatus.BadRequest) {
		const newResponse: RestResponse = {
			...response,
			errorMessage: response.body.message,
		};

		if (response.body.code === OloCodeErrors.InvalidAuthtoken) {
			newResponse.errorMessage = InvalidAuthtokenErrorMessage;
			newResponse.body.message = InvalidAuthtokenErrorMessage;
		}
		return newResponse;
	}

	if (response.status === HttpStatus.Ok) {
		if (!!response.body.message) {
			const newResponse: RestResponse = {
				...response,
				error: true,
				errorMessage: response.body.message,
			};
			return newResponse;
		}

		return response;
	}

	if (response.status === HttpStatus.Teapot) {
		const newResponse: RestResponse = {
			...response,
			error: true,
			errorMessage: 'Invalid CAPTCHA',
		};
		return newResponse;
	}

	const newResponse: RestResponse = {
		...response,
		errorMessage: CommunicationErrorMessage,
	};
	return newResponse;
};

export const OloClient = new RestApi('/order/api/olo', undefined, oloResponseMiddleware);
