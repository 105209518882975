import { FC, useEffect } from 'react';
import { isBasketExpired } from '../../utils/GeneralUtils';
import { ReduxProps } from './index';
import { useLocation } from 'react-router-dom';
import { Log } from '../logger/Logger';

export interface ValidateBasketExpirationProps extends ReduxProps {}

export const ValidateBasketExpiration: FC<ValidateBasketExpirationProps> = ({
	basketCreationDate,
	showSummary,
	setBasket,
	setLoading,
}) => {
	const location = useLocation();

	useEffect(() => {
		const checkCurrentBasket = async () => {
			if (!basketCreationDate) return;

			const staleBasket = isBasketExpired(basketCreationDate);
			if (staleBasket) {
				clearBasketWhenExpires();
				Log.Warn('Basket-expired', {});
			}
			setLoading(false);
		};

		checkCurrentBasket();
	}, [location.pathname, showSummary]);

	const clearBasketWhenExpires = () => {
		setBasket(null);
	};

	return null;
};
