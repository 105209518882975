import { connect, ConnectedProps } from 'react-redux';
import { MenuOperations } from '../../../features/Menu/redux/Menu.operations';
import { RootState } from '../../../infrastructure';
import { OptionsCardList } from './OptionsCardList';

const mapStateToProps = ({ menu }: RootState) => ({
	selectedOptions: menu.selectedOptions,
	rawOptionGroups: menu.rawOptionGroups,
	imagepath: menu.menu?.imagepath,
});

const mapDispatchToProps = {
	selectOption: MenuOperations.selectOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const OptionsCardListConnected = connector(OptionsCardList);
export { OptionsCardListConnected as OptionsCardList };
