import { Icon } from '@maverick/ui';
import * as Styled from './HourDisplay.styled';
import { useTheme } from 'styled-components';
import { RestaurantStatus } from '../../../enums/RestaurantStatus';
import { IconClock } from '@maverick/icons/dist/IconClock';
import { CalendarTypes } from '@maverick/entity';

interface CalendarInfo {
	type: string;
	range: string;
	tag: RestaurantStatus | 'Unavailable' | undefined;
}

const getIconAndLabel = (calendarType: string, orderUi: any) => {
	switch (calendarType) {
		case CalendarTypes.Curbside:
			return { icon: orderUi.icons.curbside, label: 'Curbside' };
		case CalendarTypes.Pickup:
			return { icon: orderUi.icons.pickup, label: 'Pickup' };
		case CalendarTypes.Delivery:
			return { icon: orderUi.icons.delivery, label: 'Delivery' };
		default:
			return { icon: IconClock, label: 'Restaurant' };
	}
};

function getStatusDisplayText(status: RestaurantStatus | 'Unavailable' | undefined): string {
	if (status === 'Unavailable' || status === undefined) return 'Unavailable';

	const statusDisplayMap: Record<RestaurantStatus, string> = {
		[RestaurantStatus.Open]: 'Open',
		[RestaurantStatus.OrderAhead]: 'Order ahead',
		[RestaurantStatus.Closed]: 'Closed',
		[RestaurantStatus.ClosingSoon]: 'Closing soon',
		[RestaurantStatus.CloseForDineIn]: 'Closed for dine-in',
	};

	return statusDisplayMap[status] || 'Unavailable';
}

export const HourDisplay: React.FC<{ calendar: CalendarInfo; index: number }> = ({ calendar, index }) => {
	const { orderUi } = useTheme();
	const { icon, label } = getIconAndLabel(calendar.type, orderUi);

	return (
		<Styled.Container data-testid={`${calendar.type}-hours-${index}`}>
			<Styled.IconContainer isClosed={calendar.tag ?? RestaurantStatus.Closed}>
				<Icon icon={icon} customSize />
			</Styled.IconContainer>
			<Styled.HandoffTitle isClosed={calendar.tag ?? RestaurantStatus.Closed}>
				<Styled.Label data-testid='handoff'>{label}</Styled.Label>
				<Styled.Colon>:</Styled.Colon>
				{calendar.tag !== 'Unavailable' && (
					<>
						<Styled.Hour data-testid='hour'>{calendar.range}</Styled.Hour>
						<Styled.Dot>•</Styled.Dot>
					</>
				)}
				<Styled.Tag data-testid='status' statusTag={calendar.tag ?? 'Unavailable'}>
					{getStatusDisplayText(calendar.tag)}
				</Styled.Tag>
			</Styled.HandoffTitle>
		</Styled.Container>
	);
};

export default HourDisplay;
