const env = String(process?.env?.ENV);

export const Config = {
	Env: {
		Is: env,
		IsLocal: env === 'local',
		IsDev: env === 'dev',
		IsQa: env === 'qa',
		IsStg: env === 'stg',
		IsPreview: env === 'preview',
		IsPrd: env === 'prd',
	},
	SitecoreApiKey: String(process.env.SITECORE_API_KEY),
	SitecoreGraphQLEndpoint: String(process.env.GRAPH_QL_ENDPOINT),
	JssApp: String(process.env.JSS_APP),
	Auth0: {
		domain: String(process.env.AUTH0_DOMAIN),
		clientId: String(process.env.AUTH0_CLIENT_ID),
		redirectUri: String(process.env.AUTH0_LOGIN_REDIRECT_URI),
		audience: String(process.env.AUTH0_AUDIENCE_URI),
	},
	Brand: String(process?.env?.BRAND),
	Gtm: {
		Id: String(process.env.GTM_ID),
		Auth: String(process.env.GTM_AUTH),
		EnvId: String(process.env.GTM_ENV_ID),
	},
	Source: String(process?.env?.SOURCE),
	BrandCorporate: String(process?.env?.BRAND_CORPORATE),
	WhitelabelUrl: String(process?.env?.WHITELABEL_URL),
	OrderUrl: String(process.env.ORDER_URL),
} as const;
