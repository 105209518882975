import {
	BasketDeliveryAddress,
	CustomField,
	Item,
	TimeWanted,
	GiftCard,
	SubmitWithSinglePayment,
	SubmitWithMultiplePayments,
	ApplyRewards,
	CreateFromOrderBody,
	HandoffMethods,
} from '@maverick/entity';
import { HttpMethods, RestRequest, RestResponse } from '@maverick/http';
import { OloClient } from './client';

const apiVersion = 'v1.1';

interface SetBasketParams {
	vendorid: number;
	authtoken: string | null;
}

interface GetBasketParams {
	basketId: string;
}

interface SetBasketDeliveryAddressParams {
	basketId: string;
	basketDeliveryAddress: BasketDeliveryAddress;
}

interface SetBasketHandoffMethodParams {
	basketId: string;
	deliverymode: HandoffMethods;
}

interface SetTransferBasketParams {
	basketId: string;
	vendorid: number;
}

interface GetUpsellsParams {
	basketId: string;
}

interface SetUpsellsParams {
	basketId: string;
	items: Item;
}

interface DeleteProductFromBasketParams {
	basketId: string;
	productId: number;
}

interface SetTipAmountParams {
	basketId: string;
	amount: number;
}

interface SetBasketCustomFieldValueParams {
	basketId: string;
	customField: CustomField;
}
interface SetTimeWantedParams {
	basketId: string;
	timeWanted: TimeWanted;
}

interface SetBasketTimeModetoASAPParams {
	basketId: string;
}

interface GetBillingSchemeParams {
	basketId: string;
}

interface PostRetrieveGiftCardBalance {
	basketId: string;
	billingSchemeId: number;
	giftCard: GiftCard;
}

interface AddPromoCodeParams {
	basketId: string;
	promoCode: string;
}

interface RemovePromoCodeParams {
	basketId: string;
}

interface SubmitOrderWithSinglePaymentParams {
	basketId: string;
	submitSinglePayment: SubmitWithSinglePayment;
}

interface SubmitOrderWithMultiplePaymentsParams {
	basketId: string;
	submitMultiplePayments: SubmitWithMultiplePayments;
}

interface ValidateBasketParams {
	basketId: string;
}

interface ApplyToRewardsToBasketParams {
	basketId: string;
	applyRewardsToBasket: ApplyRewards;
}

interface RemoveRewardsParams {
	basketId: string;
	rewardId: number;
}
interface GetUserBillingAccountParams {
	userToken: string;
}

interface CreateFromOrderParams {
	userToken: string;
	body: CreateFromOrderBody;
}

export const CheckoutService = {
	SetBasket: async (params: SetBasketParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/baskets/create`,
			body: { ...params },
		});

		return await OloClient.ExecuteRequest(request);
	},

	GetBasket: async (params: GetBasketParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `${apiVersion}/baskets/${params.basketId}`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	SetBasketDeliveryAddress: async (params: SetBasketDeliveryAddressParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Put,
			endpoint: `${apiVersion}/baskets/${params.basketId}/deliveryaddress`,
			body: { ...params.basketDeliveryAddress },
		});

		return await OloClient.ExecuteRequest(request);
	},

	SetBasketHandoffMethod: async (params: SetBasketHandoffMethodParams): Promise<RestResponse> => {
		const deliverymode = params.deliverymode;
		const request = new RestRequest({
			method: HttpMethods.Put,
			endpoint: `${apiVersion}/baskets/${params.basketId}/deliverymode`,
			body: { deliverymode },
		});

		return await OloClient.ExecuteRequest(request);
	},

	SetTransferBasket: async (params: SetTransferBasketParams): Promise<RestResponse> => {
		const vendorid = params.vendorid;
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/baskets/${params.basketId}/transfer`,
			body: { vendorid },
		});

		return await OloClient.ExecuteRequest(request);
	},

	GetUpsells: async (params: GetUpsellsParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `${apiVersion}/baskets/${params.basketId}/upsell`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	AddUpsellItems: async (params: SetUpsellsParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/baskets/${params.basketId}/upsell`,
			body: { ...params.items },
		});

		return await OloClient.ExecuteRequest(request);
	},

	DeleteProductFromBasket: async (params: DeleteProductFromBasketParams): Promise<RestResponse> => {
		const { productId } = params;
		const request = new RestRequest({
			method: HttpMethods.Delete,
			endpoint: `${apiVersion}/baskets/${params.basketId}/products/${productId}`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	SetTipAmount: async (params: SetTipAmountParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Put,
			endpoint: `${apiVersion}/baskets/${params.basketId}/tip`,
			body: { amount: params.amount },
		});

		return await OloClient.ExecuteRequest(request);
	},

	SetBasketCustomFieldValue: async (params: SetBasketCustomFieldValueParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Put,
			endpoint: `${apiVersion}/baskets/${params.basketId}/customFields`,
			body: params.customField,
		});

		return await OloClient.ExecuteRequest(request);
	},

	GetBillingScheme: async (params: GetBillingSchemeParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `${apiVersion}/baskets/${params.basketId}/billingSchemes`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	AddPromoCode: async (params: AddPromoCodeParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Put,
			endpoint: `${apiVersion}/baskets/${params.basketId}/coupon`,
			body: { couponcode: params.promoCode },
		});

		return await OloClient.ExecuteRequest(request);
	},

	PostRetrieveGiftCardBalance: async (params: PostRetrieveGiftCardBalance): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/baskets/${params.basketId}/billingschemes/${params.billingSchemeId}/retrievebalance`,
			body: { ...params.giftCard },
		});

		return await OloClient.ExecuteRequest(request);
	},

	RemovePromoCode: async (params: RemovePromoCodeParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Delete,
			endpoint: `${apiVersion}/baskets/${params.basketId}/coupon`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	SetTimeWanted: async (params: SetTimeWantedParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Put,
			endpoint: `${apiVersion}/baskets/${params.basketId}/timewanted`,
			body: params.timeWanted,
		});

		return await OloClient.ExecuteRequest(request);
	},

	SetBasketTimeModetoASAP: async (params: SetBasketTimeModetoASAPParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Delete,
			endpoint: `${apiVersion}/baskets/${params.basketId}/timewanted`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	ValidateBasket: async (params: ValidateBasketParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/baskets/${params.basketId}/validate`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	SubmitOrderWithSinglePayment: async (params: SubmitOrderWithSinglePaymentParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/baskets/${params.basketId}/submit`,
			body: { ...params.submitSinglePayment },
		});

		return await OloClient.ExecuteRequest(request);
	},

	SubmitOrderWithMultiplePayments: async (params: SubmitOrderWithMultiplePaymentsParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/baskets/${params.basketId}/submit/multiplepayments`,
			body: { ...params.submitMultiplePayments },
		});

		return await OloClient.ExecuteRequest(request);
	},

	ApplyToRewardsToBasket: async (params: ApplyToRewardsToBasketParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Put,
			endpoint: `${apiVersion}/baskets/${params.basketId}/loyaltyrewards/byref`,
			body: { ...params.applyRewardsToBasket },
		});

		return await OloClient.ExecuteRequest(request);
	},

	RemoveRewards: async (params: RemoveRewardsParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Delete,
			endpoint: `${apiVersion}/baskets/${params.basketId}/loyaltyrewards/${params.rewardId}`,
		});
		return await OloClient.ExecuteRequest(request);
	},

	GetUserBillingAccount: async (params: GetUserBillingAccountParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `v1.1/users/${params.userToken}/billingaccounts`,
		});
		return await OloClient.ExecuteRequest(request);
	},

	CreateFromOrder: async (params: CreateFromOrderParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/baskets/createfromorder?authtoken=${params.userToken}`,
			body: { ...params.body },
		});

		return await OloClient.ExecuteRequest(request);
	},
} as const;
