import { Icon } from '@maverick/ui';
import { IconBinoculars } from '@maverick/icons/dist/IconBinoculars';
import * as Styled from './DeliveryHandoff.styled';
import { useTheme } from 'styled-components';

interface DeliveryContentProps {
	hasRestaurantAvailable: boolean;
}

export const DeliveryContent = ({ hasRestaurantAvailable }: DeliveryContentProps) => {
	const { orderUi } = useTheme();

	const renderRestaurantList = () => {
		return (
			<Styled.NotFound>
				<Styled.FoundIcon tabIndex={0} aria-label='address found'>
					<Icon icon={IconBinoculars} customSize />
				</Styled.FoundIcon>
				<Styled.MainText tabIndex={0}>Great news! We can deliver to your address!</Styled.MainText>
				<Styled.SubText tabIndex={0}>Check fees and details at checkout.</Styled.SubText>
			</Styled.NotFound>
		);
	};

	const renderNotFound = () => {
		return (
			<Styled.NotFoundDelivery>
				<Styled.FoundImage>
					<Icon icon={orderUi.icons.iconNoRestaurantsFound} customSize />
				</Styled.FoundImage>
				<Styled.MainText tabIndex={0}>Enter your delivery address to get started.</Styled.MainText>
				<Styled.SubText tabIndex={0}>Check fees and details at checkout.</Styled.SubText>
			</Styled.NotFoundDelivery>
		);
	};

	return hasRestaurantAvailable ? renderRestaurantList() : renderNotFound();
};
