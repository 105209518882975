import { FC, useState, useEffect } from 'react';
import { Category, Menu, Product } from '@maverick/entity';
import { useParams } from 'react-router-dom';
import * as Styled from './CategoryPage.styled';
import { MenuMargins } from '../RestaurantMenu';
import { MenuContent } from '../MenuContent';
import { RestaurantHeader, RestaurantProducts } from '../../../../../shared/components';
import { useRedirect } from '../../../../../shared/hooks';

interface CategoryPageProps {
	menu: Menu;
	onSelectProduct: (product: Product, name: string) => void;
	changeRestaurant: JSX.Element;
	margins: MenuMargins;
}

export const CategoryPage: FC<CategoryPageProps> = ({ menu, onSelectProduct, changeRestaurant, margins }) => {
	const { restaurantSlug, categoryId } = useParams();
	const redirect = useRedirect();

	const [category, setCategory] = useState<Array<Category>>([]);

	useEffect(() => {
		const currentCategory = menu.categories.find((category) => category.id.toString() === categoryId);

		if (!currentCategory) {
			redirect(`/menu/${restaurantSlug}`);
			return;
		}

		setCategory([currentCategory]);
	}, [restaurantSlug, categoryId]);

	return (
		<Styled.Container data-testid='category-page'>
			<RestaurantHeader changeRestaurant={changeRestaurant} margins={margins} />

			<MenuContent margins={margins}>
				<RestaurantProducts
					categories={category}
					imagepath={menu.imagepath}
					onSelectProduct={onSelectProduct}
				/>
			</MenuContent>
		</Styled.Container>
	);
};
