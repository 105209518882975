import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../infrastructure';
import { RewardsBanner } from './RewardsBanner';
import { FeatureFlagHighlight, FeatureFlagKeys, getFeatureHighlight } from '@maverick/entity';

const mapStateToProps = ({ app }: RootState) => ({
	highlightFeature: getFeatureHighlight(app.featureHighlight, FeatureFlagKeys.RedeemInPerson) as FeatureFlagHighlight,
});

const connector = connect(mapStateToProps);
export type ReduxProps = ConnectedProps<typeof connector>;

const RewardsBannerConnected = connector(RewardsBanner);
export { RewardsBannerConnected as RewardsBanner };
