import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { Basket, BillingScheme, GiftCardItem, LastOrder, CurbsideInfo } from '@maverick/entity';


export interface CheckoutStore {
	basket: Basket | null;
	giftCard: Array<GiftCardItem>;
	itemsNotTransferred: Array<string> | null;
	billingScheme: Array<BillingScheme> | null;
	basketCreationDate: Date | null;
	lastOrder: LastOrder | null;
	curbsideInfo: CurbsideInfo | null;
	signUpOption: number | null;
}

const initialState: CheckoutStore = {
	basket: null,
	giftCard: [],
	itemsNotTransferred: null,
	billingScheme: null,
	basketCreationDate: null,
	lastOrder: null,
	curbsideInfo: null,
	signUpOption: null,
};

export const CheckoutSlice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		setBasket: (state, { payload }: PayloadAction<CheckoutStore['basket']>) => {
			state.basket = payload;
		},
		setGiftCard: (state, { payload }: PayloadAction<CheckoutStore['giftCard']>) => {
			state.giftCard = payload;
		},
		setItemsNotTransferred: (state, { payload }: PayloadAction<CheckoutStore['itemsNotTransferred']>) => {
			state.itemsNotTransferred = payload;
		},
		clearItemsNotTransferred: (state) => {
			state.itemsNotTransferred = null;
		},
		setBillingScheme: (state, { payload }: PayloadAction<CheckoutStore['billingScheme']>) => {
			state.billingScheme = payload;
		},
		setBasketCreationDate: (state, { payload }: PayloadAction<CheckoutStore['basketCreationDate']>) => {
			state.basketCreationDate = payload;
		},
		setLastOrder: (state, { payload }: PayloadAction<CheckoutStore['lastOrder']>) => {
			state.lastOrder = payload;
		},
		setCurbsideInfo: (state, { payload }: PayloadAction<CheckoutStore['curbsideInfo']>) => {
			state.curbsideInfo = payload;
		},

		setSignUpOption: (state, { payload }: PayloadAction<CheckoutStore['signUpOption']>) => {
			state.signUpOption = payload;
		},
		
		resetCheckout: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => ({
			...state,
			...action,
		}));
	},
});
