import { HttpMethods, RestRequest, RestResponse } from '@maverick/http';
import { store } from '../../infrastructure';
import { BbiClient } from './client';
import { SignupRequest } from '@maverick/entity';

interface GetTransactionsHistoryByEmailParams {
	email: string;
}

interface DeleteLoyaltyAccountParams {
	loyaltyId: string;
}

const conceptId = String(process.env.CONCEPT_ID);
const source = String(process.env.SOURCE);


export const LoyaltyService = {
	GetTransactionsHistoryByEmail: async (params: GetTransactionsHistoryByEmailParams): Promise<RestResponse> => {
		const auth0Token = store.getState().app.auth0Token;
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `loyalty/transactions/${params.email}/history`,
			headers: {},
		});

		if (auth0Token) {
			request.headers = {
				authorization: `Bearer ${auth0Token}`,
			};
		}

		return await BbiClient.ExecuteRequest(request);
	},

	DeleteLoyaltyAccount: async (params: DeleteLoyaltyAccountParams): Promise<RestResponse> => {
		const auth0Token = store.getState().app.auth0Token;
		const request = new RestRequest({
			method: HttpMethods.Delete,
			endpoint: `loyalty/users/${params.loyaltyId}/deactivate`,
			headers: {},
		});

		if (auth0Token) {
			request.headers = {
				authorization: `Bearer ${auth0Token}`,
			};
		}

		return await BbiClient.ExecuteRequest(request);
	},

	DineRewardsSignUp: async (payload: SignupRequest): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `loyalty/register?source=${source}&dinerewardsFilter.conceptCode=${conceptId}`,
			body: payload,
		});

		return await BbiClient.ExecuteRequest(request);
	},
};
