import styled from 'styled-components';

export const Container = styled.div`
	background: url(${({ theme }) => theme.orderUi.images.DRBannerBackgroundImage}) no-repeat center;
	background-size: cover;
	border-radius: 5px;
	border: none;
	display: flex;
	flex-direction: column;
	gap: 31px;
	padding: 16px;
`;

export const Title = styled.div`
	color: ${({ theme }) => theme.orderUi.colors.tertiary.light};
	font-size: 20px;
	line-height: 24px;
	${({ theme }) => theme.orderUi.typography.title.dRBanner};
`;

export const DescriptionContainer = styled.div`
	font-size: 12px;
	font-weight: 400;
`;

export const DescriptionItem = styled.div`
	display: flex;
	justify-content: start;
	padding: 4px 0;
	color: white;
	align-items: center;
`;

export const DescriptionText = styled.div`
	margin-left: 8px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 16px;

	& > div {
		flex: 1;
	}
`;

export const Line = styled.hr`
	background-color: ${({ theme }) => theme.orderUi.colors.neutral.medium};
	border: none;
	height: 1.5px;
	margin: 24px 0;
`;
