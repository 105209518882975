import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { DigitalWallets } from '@olo/pay';
import type { PaymentMethod, DigitalWalletsOptions } from '@olo/pay';
import { Config } from '../../../Config';
import { PaymentType } from '@maverick/utils';

export interface WalletOptionTotal {
	label: string;
	amount: number;
}

export interface DigitalWalletsSubmitBody {
	cardtype: string;
	cardlastfour: string;
	expiryyear: number;
	expirymonth: number;
	zip: string;
	token: string;
	paymentOption?: string;
}

const walletsOptions: DigitalWalletsOptions = {
	options: {
		country: 'US',
		currency: 'usd',
		total: {
			label: 'Place your order',
			amount: 12345,
		},
	},
	style: {
		type: 'default',
		theme: 'dark',
		height: '60px',
	},
};

const paymentEnvironment = Config.Env.IsPrd ? 'production' : 'development';

export const supportsDigitalWallets = async (): Promise<{ supportsApplePay: boolean; supportsGooglePay: boolean }> => {
	const wallets = new DigitalWallets(paymentEnvironment);
	await wallets.initialize(walletsOptions);

	const result = { supportsApplePay: false, supportsGooglePay: false };
	if (!wallets.canMakePayment) return result;

	result.supportsApplePay = wallets.canMakePayment['applePay'];
	result.supportsGooglePay = wallets.canMakePayment['googlePay'];
	return result;
};

export interface DigitalWalletsPaymentHandle {
	initializePaymentDigitalWallets: (paymentType: PaymentType) => void;
}

interface DigitalWalletsPaymentProps {
	onPayment: (method: PaymentMethod) => void;
	onPaymentError: () => void;
	setLoading: (loading: boolean) => void;
	handleDeliveryInstructions: () => Promise<boolean>;
	handleBasketCustomFields: () => Promise<boolean>;
	validateBasket: () => Promise<boolean>;
	paymentValue?: number;
}

export const DigitalWalletsPayment = forwardRef<DigitalWalletsPaymentHandle, DigitalWalletsPaymentProps>(
	function DigitalWalletsPayment(
		{
			onPayment,
			onPaymentError,
			setLoading,
			handleDeliveryInstructions,
			handleBasketCustomFields,
			validateBasket,
			paymentValue,
		},
		ref
	) {
		const [digitalWallets, setDigitalWallets] = useState<DigitalWallets | null>(null);

		const onPaymentSubmit = async (wallet: DigitalWallets, method: PaymentMethod, paymentType: PaymentType) => {
			if (method) {
				try {
					if (paymentType === PaymentType.ApplePay) {
						const [isBasketValid, handleDeliveryInstructionsResponse, handleBasketCustomFieldsResponse] =
							await Promise.all([
								validateBasket(),
								handleDeliveryInstructions(),
								handleBasketCustomFields(),
							]);

						if (
							!isBasketValid ||
							!handleDeliveryInstructionsResponse ||
							!handleBasketCustomFieldsResponse
						) {
							throw new Error('Error during basket validation');
						}
					}
					setLoading(true);
					wallet.completePaymentEvent();
					onPayment(method);
				} catch (error) {
					wallet.failPaymentEvent();
					onPaymentError();
				}
			}
		};

		useImperativeHandle(ref, () => ({
			async initializePaymentDigitalWallets(paymentType: PaymentType) {
				digitalWallets?.mountButton((method) => onPaymentSubmit(digitalWallets, method, paymentType));
				if (digitalWallets) {
					try {
						setLoading(false);
						digitalWallets.initiatePayment();
					} catch (error) {
						onPaymentError();
					}
				}
			},
		}));

		useEffect(() => {
			init();

			return () => {
				digitalWallets?.unmount();
				setLoading(false);
			};
		}, [paymentValue]);

		const init = async () => {
			const wallets = new DigitalWallets(paymentEnvironment);
			const digitalWalletsTotal: WalletOptionTotal = {
				label: 'Place your order',
				amount: parseInt(String(paymentValue?.toFixed(2)).replace('.', '')),
			};
			walletsOptions.options.total = digitalWalletsTotal;
			await wallets.initialize(walletsOptions);
			setDigitalWallets(wallets);
		};

		return null;
	}
);
