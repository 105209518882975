import { FC } from 'react';
import { Checkbox, Icon } from '@maverick/ui';
import * as Styled from './SignUpOption.styled';
import { IconStarRewards } from '@maverick/icons/dist/IconStarRewards';
import { IconCellphone } from '@maverick/icons/dist/IconCellphone';
import { IconQuicklyReorder } from '@maverick/icons/dist/IconQuicklyReorder';

export interface SignUpOptionProps {
	onClick: () => void;
	checked: boolean;
}

export const SignUpOption: FC<SignUpOptionProps> = ({ onClick, checked = false }) => {
	const brand = () => {
		switch (process.env.BRAND) {
			case 'outback':
				return 'Outback';
			case 'carrabbas':
				return 'Carrabba`s';
			case 'bonefishgrill':
				return 'Bonefish';
			default:
				return '';
		}
	};

	return (
		<>
			<Styled.SignUpOptionSection displayStyle='full'>
				<Checkbox checked={checked} onClick={onClick} label='' id='SignUp' />
				<Styled.TextContent>
					<Styled.TitleContent>
						<label tabIndex={0}>Sign up to earn points and perks on your next orders*</label>
					</Styled.TitleContent>
					<Styled.DescriptionContent>
						<Styled.DescriptionItem>
							<Icon icon={IconStarRewards} />
							<Styled.DescriptionText>
								<label tabIndex={0}>Earn points & redeem rewards</label>
							</Styled.DescriptionText>
						</Styled.DescriptionItem>
						<Styled.DescriptionItem>
							<Icon icon={IconCellphone} />
							<Styled.DescriptionText>
								<label tabIndex={0}>Seamless ordering on App and Web</label>
							</Styled.DescriptionText>
						</Styled.DescriptionItem>
						<Styled.DescriptionItem>
							<Icon icon={IconQuicklyReorder} />
							<Styled.DescriptionText>
								<label tabIndex={0}>Quickly reorder recents & favorites</label>
							</Styled.DescriptionText>
						</Styled.DescriptionItem>
					</Styled.DescriptionContent>
				</Styled.TextContent>
			</Styled.SignUpOptionSection>

			<Styled.termsAndConditions>
				{`*In signing up I acknowledge that I am 18 years of age or older, want to receive email offers from
				${brand()} and agree to the `}
				<a href={'https://www.dine-rewards.com/terms-and-conditions'} tabIndex={0}>
					terms and conditions
				</a>
				{` of the Dine Rewards program. I’ll be able to sign in to Dine Rewards after completing this order. Only
				orders placed while signed in will be eligible for rewards points.`}
			</Styled.termsAndConditions>
		</>
	);
};
