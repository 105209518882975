import { HttpMethods, RestRequest, RestResponse } from '@maverick/http';
import { OloClient } from './client';

const apiVersion = 'v1.1';

interface RetrieveUserRecentOrdersParams {
	authtoken: string;
}

interface RetrieveUserFavesParams {
	authtoken: string;
}

interface CreateBasketFromFaveParams {
	authToken: string;
	faveid: number;
	ignoreunavailableproducts: boolean;
}

interface DeleteUserAccountParams {
	authtoken: string;
}

interface RemoveUserBillingAccounttParams {
	authtoken: string;
	billingAccountId: string;
}

interface AuthorizeUserParams {
	provider: string;
	providertoken: string;
	provideruserid: string;
	contactnumber: string | null;
	basketid: string | null;
	firstname: string | null;
	lastname: string | null;
	emailaddress: string | null;
}

interface GetQualifyingRewards {
	authtoken: string | null;
	vendorId: number | null;
}

export const UserService = {
	RetrieveUserRecentOrders: async (params: RetrieveUserRecentOrdersParams): Promise<RestResponse> => {
		const { authtoken } = params;
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `${apiVersion}/users/${authtoken}/recentorders`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	RetrieveUserFaves: async (params: RetrieveUserFavesParams): Promise<RestResponse> => {
		const { authtoken } = params;
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `${apiVersion}/users/${authtoken}/faves`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	CreateBasketFromFave: async (params: CreateBasketFromFaveParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/baskets/createfromfave?authtoken=${params.authToken}`,
			body: { ...params },
		});

		return await OloClient.ExecuteRequest(request);
	},

	DeleteUserAccount: async (params: DeleteUserAccountParams): Promise<RestResponse> => {
		const { authtoken } = params;
		const request = new RestRequest({
			method: HttpMethods.Delete,
			endpoint: `${apiVersion}/users/${authtoken}/account`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	RemoveUserBillingAccount: async (params: RemoveUserBillingAccounttParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Delete,
			endpoint: `${apiVersion}/users/${params.authtoken}/billingaccounts/${params.billingAccountId}`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	AuthorizeUser: async (params: AuthorizeUserParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/users/getorcreate`,
			body: { ...params },
			retry: true,
		});

		return await OloClient.ExecuteRequest(request);
	},

	GetQualifyingRewards: async (params: GetQualifyingRewards): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `${apiVersion}/users/${params.authtoken}/qualifyingrewards?vendorId=${params.vendorId}`,
		});

		return await OloClient.ExecuteRequest(request);
	},
} as const;
