import { FC, useState, useEffect, useRef, ReactNode } from 'react';
import { MenuGateReduxProps } from '.';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useRedirect } from '../../../../shared/hooks';
import { SkeletonMenu, useToast } from '@maverick/ui';
import { HandoffManager } from '../../../HandoffMethod/Handoff.manager';
import { GaEvent, UtilsIsAppOnlyCategory, handleRestaurantInfo, isProductDineInOnly } from '@maverick/utils';
import { Category, HandoffMethods, Product, Restaurant, TransferBasket } from '@maverick/entity';
import { useAuth0 } from '@auth0/auth0-react';
import { MenuManager } from '../../Menu.manager';
import { Storage } from '@maverick/utils';
import { useToken } from '../../../../shared/hooks/useToken';
import { Log } from '../../../../infrastructure/logger/Logger';
import { UserManager } from '../../../User/User.manager';
import { selectGetQualifyingRewardsError } from '../../../../shared/constants';
import { CheckoutManager } from '../../../Checkout/Checkout.manager';

export interface MenuGateProps extends MenuGateReduxProps {
	loadMenu?: boolean;
	fullMenu?: boolean;
	children: ReactNode;
}

export const MenuGate: FC<MenuGateProps> = (props) => {
	const {
		selectedRestaurant,
		method,
		basket,
		userToken,
		children,
		loadMenu = false,
		fullMenu = false,
		menu,
		deliveryAddress,
		auth0Token,
		skeletonPages,
		userRewards,
		setSelectedMethod,
		setBasket,
		setRestaurantMenu,
		setSelectedRestaurantAddress,
		resetOrder,
		setAuth0Token,
		setUserToken,
		setSelectedRestaurant,
		setUserRedeemableRewards,
		setItemsNotTransferred,
	} = props;
	const pageTitle = 'MenuGate';
	const location = useLocation();
	const redirect = useRedirect();
	const { restaurantSlug } = useParams();
	const [searchParams] = useSearchParams();
	const { isAuthenticated } = useAuth0();
	const renewToken = useToken();
	const { setToast } = useToast();

	const [isRestaurantMenuReady, setIsRestaurantMenuReady] = useState(false);

	const restaurantDone = useRef<boolean>(false);
	const methodDone = useRef<boolean>(false);
	const basketDone = useRef<boolean>(false);
	const menuDone = useRef<boolean>(false);

	useEffect(() => {
		if ((!isAuthenticated && auth0Token && basket?.contactnumber) || (auth0Token && !userToken)) {
			//TODO check basket
			renewLogin();
			Log.Info('RenewLogin-MenuGate', {});
		}
	}, [isAuthenticated]);

	const renewLogin = async () => {
		return await renewToken({
			basket,
			setAuth0Token,
			setUserToken,
		}); // TODO check basket
	};

	useEffect(() => {
		initRestaurant();
	}, [location]);

	useEffect(() => {
		const basketReady = basketDone.current;
		const menuReady = loadMenu ? !!menu && menuDone.current : true;
		const methodReady = !!method && methodDone.current;
		const restaurantReady = !!selectedRestaurant && restaurantDone.current;
		setIsRestaurantMenuReady(basketReady && menuReady && methodReady && restaurantReady);
	}, [basket, loadMenu, menu, method, selectedRestaurant]);

	useEffect(() => {
		getRedeemableRewards().then((redeemable) => {
			if (redeemable) {
				setUserRedeemableRewards(redeemable);
			}
		});
	}, [selectedRestaurant?.id]);

	const getRedeemableRewards = async () => {
		if (!userToken || !selectedRestaurant) return;

		const { responseRewards, error } = await UserManager.GetQualifyingRewards(userToken, selectedRestaurant.id);

		if (!!error || !responseRewards) {
			const errorMessage = error ?? selectGetQualifyingRewardsError;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage, pageTitle);
			return;
		}

		if (responseRewards && !error) {
			const redeemable = userRewards?.rewardsWallets.filter((reward) => {
				const isRedeemable = responseRewards.some(
					(r) => r.externalreference.replace(/\D/g, '') === reward.id.toString()
				);
				return isRedeemable;
			});
			return redeemable;
		}
	};

	const retrieveRestaurantBySlug = async (restaurantSlug: string) => {
		let restaurant: Restaurant | null = null;
		if (!selectedRestaurant || selectedRestaurant.slug !== restaurantSlug) {
			Storage.Session.Set('isUrlSlugFlow', true);
			Storage.Session.Remove('isDeliverySelected');
			const _restaurant = await HandoffManager.GetRestaurantBySlug(restaurantSlug);
			restaurant = _restaurant;
		} else {
			restaurant = { ...selectedRestaurant };
		}
		return restaurant;
	};

	const retrieveRestaurantCalendar = async (restaurant: Restaurant) => {
		const { calendar, error } = await HandoffManager.GetRestaurantCalendarById(restaurant.id);
		if (error || !calendar) {
			return goToHome();
		}
		restaurant.calendar = calendar;
		restaurant.calendars = undefined;
	};

	const initRestaurant = async () => {
		if (!restaurantSlug) {
			return goToHome();
		}

		if (Storage.Session.Get('isUrlSlugFlow') === undefined) {
			Storage.Session.Set('isUrlSlugFlow', true);
		}

		const restaurant = await retrieveRestaurantBySlug(restaurantSlug);
		if (!restaurant) {
			return goToHome();
		}

		await retrieveRestaurantCalendar(restaurant);

		const { restaurantAddress } = handleRestaurantInfo(restaurant);
		setSelectedRestaurantAddress(restaurantAddress);
		setSelectedRestaurant(restaurant);
		restaurantDone.current = true;

		initMethod(restaurant);
		initMenu(restaurant);
	};

	const initMethod = async (restaurant: Restaurant) => {
		const targetMethod = restaurant.supportscurbside
			? HandoffMethods.Curbside
			: restaurant.canpickup
			? HandoffMethods.Pickup
			: restaurant.candeliver
			? HandoffMethods.Delivery
			: null;

		if (!targetMethod) {
			return goToHome();
		}

		let setMethod = targetMethod;

		if (method && Storage.Session.Get('isDeliverySelected')) {
			setMethod = method;
		}

		methodDone.current = true;
		setSelectedMethod(setMethod);
		checkCurrentBasket(restaurant);
		return;
	};

	const checkCurrentBasket = async (restaurant: Restaurant) => {
		let updatedBasket = basket;

		const URLDeliveryAddress = searchParams.get('deliveryAddress');
		const redirectFromCMS = searchParams.get('redirectFromCMS');
		const hasCMSParams = URLDeliveryAddress || redirectFromCMS;

		if (!hasCMSParams) {
			if (!!updatedBasket && updatedBasket.vendorid !== restaurant.id) {
				const transferredBasket = await handleBasketTransferring(updatedBasket.id, restaurant.id);
				if (!transferredBasket || !transferredBasket.basket) {
					updatedBasket = null;
				} else {
					updatedBasket = transferredBasket.basket.products.length ? transferredBasket.basket : null;
					if (transferredBasket.itemsnottransferred)
						setItemsNotTransferred(transferredBasket.itemsnottransferred);
				}
			}
			if (!!updatedBasket && updatedBasket.deliverymode !== method) {
				if (method === HandoffMethods.Delivery) {
					const { id, ...deliveryWithoutId } = deliveryAddress!;
					const { settedAddressToBasket, setBasketDeliveryAddressError } =
						await CheckoutManager.SetBasketDeliveryAddress(updatedBasket.id, deliveryWithoutId);
					if (setBasketDeliveryAddressError || !settedAddressToBasket) {
						return goToHome();
					} else {
						updatedBasket = settedAddressToBasket;
					}
				} else {
					const { settedHandoffToBasket, setHandoffBasketError } =
						await CheckoutManager.SetBasketHandoffMethod(updatedBasket.id, method);

					if (setHandoffBasketError || !settedHandoffToBasket) {
						return goToHome();
					} else {
						updatedBasket = settedHandoffToBasket;
					}
				}
			}
		}

		setBasket(updatedBasket);
		basketDone.current = true;
	};

	const handleBasketTransferring = async (
		basketId: string,
		restaurantId: number
	): Promise<TransferBasket | undefined> => {
		const { transferBasket, error } = await CheckoutManager.SetTransferBasket(basketId, restaurantId);
		if (!transferBasket || !transferBasket.basket || error) {
			return;
		}
		return transferBasket;
	};

	function addAppOnlyProductMetadata(product: Product, isAppOnly: boolean) {
		const appOnlyMetadata = { key: 'product-highlight-display', value: 'app-only' };
		if (isAppOnly) {
			if (product.metadata) {
				if (!product.metadata.find((metadata) => metadata.value === 'app-only'))
					product.metadata.push(appOnlyMetadata);
			} else product.metadata = [appOnlyMetadata];
		}
	}

	const initMenu = async (restaurant: Restaurant) => {
		let isAppOnly: boolean;
		if (!loadMenu) return;

		const { menu: _menu, error } = await MenuManager.GetRestaurantMenu(restaurant.id);
		if (error || !_menu || _menu.categories.length === 0) {
			return goToHome();
		}

		menuDone.current = true;
		_menu.categories.forEach((category: Category) => {
			category.products = category.products.filter((product) => {
				return !isProductDineInOnly(product);
			});

			isAppOnly = UtilsIsAppOnlyCategory(category);
			category.products.forEach((product) => addAppOnlyProductMetadata(product, isAppOnly));
		});
		const menuFiltered = _menu.categories.filter((category) => category.products.length > 0);
		setRestaurantMenu({ ..._menu, categories: menuFiltered });
		return;
	};

	const goToHome = () => {
		resetOrder();
		redirect('/');
	};

	if (isRestaurantMenuReady) {
		return <>{children}</>;
	}

	if (skeletonPages?.active) {
		return <SkeletonMenu fullMenu={fullMenu} />;
	}

	return null;
};
