import { Dispatch } from 'redux';
import { MenuStore, menuSlice } from './Menu.slice';

export const MenuOperations = {
	setSelectedProduct: (selectedProduct: MenuStore['selectedProduct']) => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.setSelectedProduct(selectedProduct));
	},
	setRestaurantMenu: (restaurantMenu: MenuStore['menu']) => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.setRestaurantMenu(restaurantMenu));
	},
	setOptionGroups: (optionGroups: MenuStore['optionGroups']) => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.setOptionGroups(optionGroups));
	},
	selectOption: (selectOption: MenuStore['selectOption']) => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.selectOption(selectOption));
	},
	resetCustomize: () => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.resetCustomize());
	},
	setEditedOptions: (editedOptions: MenuStore['selectedOptions']) => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.setEditedOptions(editedOptions));
	},
	setEditedProduct: (editedProduct: MenuStore['editedProduct']) => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.setEditedProduct(editedProduct));
	},
	setRedirectRouteMenu: (redirectRouteMenu: MenuStore['redirectRouteMenu']) => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.setRedirectRouteMenu(redirectRouteMenu));
	},

	setAddCartOrigin: (addCartOrigin: MenuStore['addCartOrigin']) => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.setAddCartOrigin (addCartOrigin));
	},

	resetMenu: () => (dispatch: Dispatch) => {
		dispatch(menuSlice.actions.resetMenu());
	},
};
