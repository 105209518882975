import styled from 'styled-components';

export const TransferTitle = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium};
	margin-bottom: 8px;
`;

export const TransferItem = styled.div`
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
`;
