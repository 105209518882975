import styled from 'styled-components';

export const Container = styled.div`
	min-height: 85vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Loading = styled.div``;
