import { Basket, BillingScheme, GiftCardItem, HandoffMethods } from '@maverick/entity';
import { PaymentType } from '@maverick/utils';
import { BillingMethods } from '../../enums/BillingMethod';
import { InputHandler } from '@maverick/hooks';
import { ValidationCreditCard } from '../../constants';
import { DigitalWalletsPaymentOptions } from '../../enums';
import { supportsDigitalWallets } from '../DigitalWalletsPayment';

export type PaymentOption = {
	type: PaymentType;
	label: string;
	id?: string;
	removable?: boolean;
};

export const doCVVRevalidation = (
	supportsOlopay: boolean,
	billingSchemeConst: BillingScheme[] | null,
	selectPaymentOption: PaymentOption
): boolean => {
	// TODO check this before going to PRD
	// if (!supportsOlopay) return false;
	const response = billingSchemeConst;

	if (!response || response.length === 0) {
		return false;
	}
	const billingMethodCard = response.find((r) => r.billingsettings.find((b) => b.name === 'DoCvvRevalidation'));
	const billingSettingsValue = billingMethodCard?.billingsettings.find((b) => b.name === 'DoCvvRevalidation');

	if (!billingSettingsValue) return false;

	return (
		[PaymentType.Amex, PaymentType.Mastercard, PaymentType.Discover, PaymentType.Visa].includes(
			selectPaymentOption.type
		) && billingSettingsValue?.value !== 'Never'
	);
};

export const canPayWithSelectedMethod = (paymentOptions: BillingScheme[], paymentType: BillingMethods) => {
	return paymentOptions.some((option) => {
		return option.type === paymentType;
	});
};

export const getPaymentMethodFromOption = (selectPaymentOption: PaymentOption) => {
	let method = BillingMethods.Cash;
	let billingAccountId = '';

	switch (selectPaymentOption.type) {
		case PaymentType.AddACard:
			method = BillingMethods.CreditCard;
			break;
		case PaymentType.PayAtRestaurant:
			method = BillingMethods.Cash;
			break;
		case PaymentType.Amex:
		case PaymentType.Discover:
		case PaymentType.Mastercard:
		case PaymentType.Visa:
			method = BillingMethods.BillingAccount;
			billingAccountId = selectPaymentOption.id!;
			break;
		case PaymentType.ApplePay:
		case PaymentType.GooglePay:
			method = BillingMethods.DigitalWallet;
			break;
	}
	return { method, billingAccountId };
};

export const validateCreditCardFields = (
	cardNumber: InputHandler<string>,
	expirationDate: InputHandler<string>,
	cvc: InputHandler<string>,
	zipCode: InputHandler<string>
): boolean => {
	let hasBlankField: boolean = false;
	if (!cardNumber.value) {
		cardNumber.setError(ValidationCreditCard.insertCardNumber);
		hasBlankField = true;
	}
	if (!expirationDate.value) {
		expirationDate.setError(ValidationCreditCard.insertExpirationDate);
		hasBlankField = true;
	}
	if (!cvc.value) {
		cvc.setError(ValidationCreditCard.insertCvc);
		hasBlankField = true;
	}
	if (!zipCode.value) {
		zipCode.setError(ValidationCreditCard.insertZipCode);
		hasBlankField = true;
	}
	return !hasBlankField;
};

export const handlePaymentOptions = async (
	response: BillingScheme[],
	basket: Basket | null,
	giftCardState: GiftCardItem[],
	paymentTypeDefault: {
		type: PaymentType;
		label: string;
	}[]
): Promise<{ paymentOptions: PaymentOption[]; supportsOlopay: boolean }> => {
	const isDelivery = basket?.deliverymode === HandoffMethods.Delivery;
	const paymentOptions: Array<PaymentOption> = [paymentTypeDefault[0]];
	const canPayWithCreditCard = canPayWithSelectedMethod(response, BillingMethods.CreditCard);
	const canPayInStore = canPayWithSelectedMethod(response, BillingMethods.PayInStore);
	let supportsOlopay = false;

	if (canPayWithCreditCard) {
		paymentOptions.push({ type: PaymentType.AddACard, label: 'Add a card' });
		supportsOlopay = response.find((r) => r.type === BillingMethods.CreditCard)?.supportsolopay ?? false;
	}

	const billingSchemeForApplePay = response.find(
		(b) => b.supportsolopay && b.name === DigitalWalletsPaymentOptions.ApplePay
	);

	const billingSchemeForGooglePay = response.find(
		(b) => b.supportsolopay && b.name === DigitalWalletsPaymentOptions.GooglePay
	);

	if (billingSchemeForApplePay || billingSchemeForGooglePay) {
		const { supportsApplePay, supportsGooglePay } = await supportsDigitalWallets();
		if (supportsApplePay) {
			paymentOptions.push({ type: PaymentType.ApplePay, label: DigitalWalletsPaymentOptions.ApplePay });
		}
		if (supportsGooglePay) {
			paymentOptions.push({ type: PaymentType.GooglePay, label: DigitalWalletsPaymentOptions.GooglePay });
		}
	}

	if (!isDelivery && !giftCardState.length && response && canPayInStore) {
		paymentOptions.push({ type: PaymentType.PayAtRestaurant, label: 'Pay at restaurant' });
	}

	const _billingAccounts = response.flatMap((scheme) => {
		if (scheme.accounts && scheme.accounts.length > 0) {
			return scheme.accounts.map((account) => {
				return account;
			});
		} else {
			return [];
		}
	});

	if (canPayWithCreditCard && _billingAccounts?.length > 0) {
		_billingAccounts?.forEach((b) => {
			if (b.accounttype === BillingMethods.CreditCard) {
				switch (b.cardtype) {
					case 'Amex': {
						const amex: PaymentOption = {
							type: PaymentType.Amex,
							label: b.cardsuffix,
							id: b.accountidstring,
							removable: true,
						};
						paymentOptions.push(amex);
						break;
					}
					case 'Mastercard': {
						const mastercard: PaymentOption = {
							type: PaymentType.Mastercard,
							label: b.cardsuffix,
							id: b.accountidstring,
							removable: true,
						};
						paymentOptions.push(mastercard);
						break;
					}
					case 'Visa': {
						const visa: PaymentOption = {
							type: PaymentType.Visa,
							label: b.cardsuffix,
							id: b.accountidstring,
							removable: true,
						};
						paymentOptions.push(visa);
						break;
					}
					case 'Discover': {
						const discover: PaymentOption = {
							type: PaymentType.Discover,
							label: b.cardsuffix,
							id: b.accountidstring,
							removable: true,
						};
						paymentOptions.push(discover);
						break;
					}
				}
			}
		});
	}

	return { paymentOptions, supportsOlopay };
};
