import styled, { css } from 'styled-components';

export const Icon = styled.div<{ isRight?: boolean }>`
	height: 24px;

	${({ isRight }) =>
		isRight &&
		css`
			rotate: 180deg;
		`}
`;

export const Container = styled.button<{ disabled: boolean; isRight: boolean }>`
	background: none;
	border: none;
	padding: 0;
	width: 40px;
	display: none;
	align-items: center;
	justify-content: flex-start;
	color: ${({ theme }) => theme.orderUi.colors.complementary.disabled};
	background: rgb(255, 255, 255);
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(255, 255, 255, 1) 50%,
		rgba(255, 255, 255, 0) 100%
	);

	${({ disabled }) =>
		!disabled &&
		css`
			cursor: pointer;
			color: ${({ theme }) => theme.orderUi.colors.primary.variant};

			:hover {
				color: ${({ theme }) => theme.orderUi.colors.primary.lightVariant};
			}

			:active {
				color: ${({ theme }) => theme.orderUi.colors.primary.lightVariant};
			}
		`}

	${({ isRight }) =>
		isRight &&
		css`
			justify-content: flex-end;
			background: rgb(255, 255, 255);
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 1) 50%,
				rgba(255, 255, 255, 1) 100%
			);
		`}
        
	
    
    @media screen and (min-width: 768px) {
		display: flex;
	}
`;
