import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Banner = styled.div`
	padding-top: 24px;
`;

export const Section = styled.div``;

export const SecondSection = styled.div`
	display: flex;
	margin: 16px 0px;
`;

export const ThirdSection = styled.div`
	display: flex;
	gap: 16px;
`;

export const ListContainer = styled.div``;

export const CardContainer = styled.div``;

export const CardItem = styled.div``;

export const Header = styled.div`
	display: flex;
	width: 35%;
	gap: 16px;
	align-items: center;
	@media screen and (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		width: 100%;
	}
`;

export const HeaderButtonContainer = styled.div`
	height: 40px;
	min-width: 150px;
	max-width: 250px;

	@media screen and (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		min-width: 120px;
		flex: 1;
	}
`;

export const ArrowIcon = styled.button<{ isOpen: boolean }>`
	background: none;
	border: none;
	padding: 0;
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	width: 16px;
	cursor: pointer;

	${({ isOpen }) =>
		isOpen &&
		css`
			transform: rotate(180deg);
		`}

	&:hover {
		color: ${({ theme }) => theme.orderUi.colors.primary.light};
	}
`;

export const ListWrapper = styled.div`
	position: relative;
	overflow: hidden;
`;

export const ScrollWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-behavior: smooth;
	gap: 16px;

	-ms-overflow-style: none;
	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}
`;

export const ListGradientNext = styled.div`
	position: absolute;
	background: linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0.5) 80.64%, rgba(255, 255, 255, 0) 100%);
	width: 60px;
	height: 100%;
	right: -2px;
`;

export const ListGradientPrevious = styled.div`
	position: absolute;
	background: linear-gradient(90deg, #ffffff 50%, rgba(255, 255, 255, 0.5) 80.64%, rgba(255, 255, 255, 0) 100%);
	width: 60px;
	height: 100%;
	left: 0px;
`;

export const NextButton = styled.div`
	position: absolute;
	height: min-content;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 20px;
	height: 20px;
`;

export const PreviousButton = styled.div`
	position: absolute;
	height: min-content;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 20px;
	height: 20px;
`;

export const Card = styled.div<{ selected: boolean }>`
	width: 410px;

	padding: 8px 16px;
	border: ${({ theme }) => theme.orderUi.specificStyle.cardBorderMedium};
	background-color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	border-radius: 4px;
	cursor: pointer;
	margin-top: 16px;
	line-height: 16px;
	min-width: max-content;

	@media screen and (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		width: 310px;
	}

	${({ selected }) =>
		selected &&
		css`
			border: 2px solid ${({ theme }) => theme.orderUi.colors.primary.medium};
		`}
`;

export const TextHeader = styled.div`
	display: flex;
	width: 100%;
	overflow: hidden;
	margin: 4px 0px;
`;

export const CardLabel = styled.p`
	max-width: 220px;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	word-break: break-all;
	margin: 0px;
	${({ theme }) => theme.orderUi.typography.title.subtitle};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	@media screen and (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		max-width: 120px;
	}
`;

export const CardQuantity = styled.div`
	overflow: hidden;
	${({ theme }) => theme.orderUi.typography.title.subtitle};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const DotSeparator = styled.div`
	margin: auto 8px;
`;

export const CardPrice = styled.div`
	overflow: hidden;
	${({ theme }) => theme.orderUi.typography.title.subtitle};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const Description = styled.div`
	max-width: 220px;
	flex: 1;
	flex-basis: 65%;
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	margin: auto;
	margin-top: 0px;

	@media screen and (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		max-width: 200px;
	}
`;

export const ImageContainer = styled.div`
	flex: 1;
	flex-basis: 35%;
	display: flex;
	justify-content: end;
	align-items: center;
	${({ theme }) => theme.orderUi.typography.headline.h5};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const Image = styled.section<{ imagePath: string }>`
	width: 60px;
	height: 60px;
	background: url('${({ imagePath }) => imagePath}') center no-repeat;
	background-size: cover;
	border-radius: 2px;

	&:nth-child(even) {
		margin-left: 16px;
	}
`;

export const ButtonContainer = styled.div`
	flex: 1;
`;

export const DineRewardsIcon = styled.div`
	background-image: url('https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/dine-rewards-logo.svg');
	width: 94px;
	height: 57px;
	background-repeat: no-repeat;

	@media screen and (max-width: 500px) {
		width: 80px;
		height: 49px;
	}
`;

export const GuestCardContainer = styled.div<{ selected: boolean }>`
	width: 410px;

	padding: 8px 16px;
	border: 1px solid ${({ theme }) => theme.orderUi.colors.secondary.dark};
	background-color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	border-radius: 4px;
	cursor: pointer;
	margin-top: 16px;
	line-height: 16px;

	@media screen and (max-width: 500px) {
		width: 100%;
	}

	${({ selected }) =>
		selected &&
		css`
			border: 2px solid ${({ theme }) => theme.orderUi.colors.primary.medium};
		`}
`;

export const GuestCardTitle = styled.p`
	margin: 0px;
	${({ theme }) => theme.orderUi.typography.title.subtitle};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const GuestDescription = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const GuestImageContainer = styled.div`
	align-items: center;
	${({ theme }) => theme.orderUi.typography.headline.h5};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const GuestSecondSection = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	justify-content: space-between;
	margin: 16px 0px;
`;
