import { Basket, BasketDeliveryAddress, HandoffMethods, RecentOrder, Restaurant } from '@maverick/entity';
import { CheckoutManager } from '../../../Checkout/Checkout.manager';
import {
	calendarError,
	createdBasketFromOrderError,
	resultRestaurantError,
	setBasketAddressError,
} from '../../../../shared/constants';
import { HandoffManager } from '../../../HandoffMethod/Handoff.manager';
import { GaEvent, Storage, handleRestaurantInfo } from '@maverick/utils';
import { ToastProps } from '@maverick/ui';

export const createNewBasketFromOrder = async (
	pageTitle: string,
	authToken: string,
	order: RecentOrder,
	setSelectedRestaurant: (selectedRestaurant: Restaurant | null) => void,
	setSelectedRestaurantAddress: (selectedRestaurantAddress: string) => void,
	setSelectedMethod: (method: HandoffMethods) => void,
	setDeliveryAddress: (deliveryAddress: BasketDeliveryAddress | null) => void,
	setToast: (toast: ToastProps) => void,
	setLoading: (loading: boolean) => void,
	setBasket: (basket: Basket) => void,
	setShowSummary: (showSummary: boolean) => void,
	redirect: (path: string) => void,
	gaErrorMessage: string = ''
) => {
	setLoading(true);

	if (!authToken) return;

	const createdBasketFromOrder = await CheckoutManager.CreateFromOrder(authToken, {
		id: order?.id,
		ignoreunavailableproducts: true,
	});

	if (createdBasketFromOrder.error || !createdBasketFromOrder.response) {
		const errorMessage = createdBasketFromOrder.error ?? createdBasketFromOrderError;
		setToast({
			variant: 'error',
			text: errorMessage,
		});
		GaEvent.ErrorMessage(gaErrorMessage + errorMessage, pageTitle);
		setLoading(false);
		return;
	}

	const resultRestaurant = await HandoffManager.GetRestaurant(createdBasketFromOrder.response.vendorid);
	if (!resultRestaurant) {
		setToast({
			variant: 'error',
			text: resultRestaurantError,
		});
		GaEvent.ErrorMessage(gaErrorMessage + resultRestaurantError, pageTitle);
		setLoading(false);
		return;
	}

	const { calendar, error } = await HandoffManager.GetRestaurantCalendarById(
		createdBasketFromOrder.response.vendorid
	);

	if (!calendar || calendar.length === 0 || error) {
		const errorMessage = error ?? calendarError;
		setToast({
			variant: 'error',
			text: errorMessage,
		});
		GaEvent.ErrorMessage(gaErrorMessage + errorMessage, pageTitle);
		setLoading(false);
		return;
	}

	setSelectedRestaurant({ ...resultRestaurant, calendar: calendar });
	GaEvent.Global.Set('store_id', resultRestaurant.id);

	if (resultRestaurant) {
		const { restaurantAddress } = handleRestaurantInfo(resultRestaurant);
		setSelectedRestaurantAddress(restaurantAddress);
	}

	setSelectedMethod(order.deliverymode);

	if (order?.deliverymode === HandoffMethods.Delivery) {
		setDeliveryAddress({
			...order.deliveryaddress,
			phonenumber: createdBasketFromOrder.response.contactnumber,
		});
		Storage.Session.Set('isDeliverySelected', true);

		const { settedAddressToBasket, setBasketDeliveryAddressError } = await CheckoutManager.SetBasketDeliveryAddress(
			createdBasketFromOrder.response.id,
			{
				...order.deliveryaddress,
				phonenumber: createdBasketFromOrder.response.contactnumber,
			}
		);

		if (setBasketDeliveryAddressError || !settedAddressToBasket) {
			const errorMessage = setBasketDeliveryAddressError ?? setBasketAddressError;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(gaErrorMessage + errorMessage, pageTitle);
			setLoading(false);
			return;
		}

		setBasket(settedAddressToBasket);
		setLoading(false);
		setShowSummary(true);
		redirect(`/menu/${resultRestaurant?.slug}`);
		return;
	}

	const { settedHandoffToBasket, setHandoffBasketError } = await CheckoutManager.SetBasketHandoffMethod(
		createdBasketFromOrder.response.id,
		order?.deliverymode
	);

	if (setHandoffBasketError || !settedHandoffToBasket) {
		setToast({
			variant: 'error',
			text: setHandoffBasketError!,
		});
		GaEvent.ErrorMessage(gaErrorMessage + setHandoffBasketError!, pageTitle);
		setLoading(false);

		return;
	}

	setBasket(settedHandoffToBasket);
	setLoading(false);
	setShowSummary(true);
	redirect(`/menu/${resultRestaurant?.slug}`);
	return;
};
