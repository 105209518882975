import styled, { css } from 'styled-components';

const ARROW_WIDTH = 11;
const ARROW_MARGIN = 16;

export const Container = styled.div`
	position: relative;
`;

export const AchorElement = styled.div`
	width: fit-content;
`;

export const Content = styled.div<{
	isTop: boolean;
	isLeft: boolean;
	maxWidth: number;
	variant?: 'small' | 'large';
}>`
	background-color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	padding: 16px;
	border-radius: 4px;
	box-sizing: border-box;
	width: max-content;
	max-width: ${({ maxWidth }) => Math.min(maxWidth, 600)}px;
	position: relative;

	::before {
		content: '';
		position: absolute;
		width: 11px;
		height: 6px;
		background-color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	}

	${({ variant }) =>
		variant === 'small' &&
		css`
			padding: 8px;
			${Text} {
				font-size: 12px;
			}
		`}

	${({ isLeft }) =>
		isLeft
			? css`
					::before {
						left: 16px;
					}
			  `
			: css`
					::before {
						right: 16px;
					}
			  `}

	${({ isTop }) =>
		isTop
			? css`
					::before {
						clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
						top: -6px;
					}
			  `
			: css`
					::before {
						clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
						bottom: -6px;
					}
			  `}
`;

export const Text = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
`;

export const TooltipContainer = styled.div<{
	isLeft: boolean;
	isTop: boolean;
	anchorHeight: number;
	anchorWidth: number;
}>`
	position: absolute;
	z-index: 1;
	display: flex;
	flex-direction: column;
	margin: 8px 0;

	${({ isLeft, anchorWidth }) =>
		isLeft
			? css`
					left: ${anchorWidth / 2 - ARROW_WIDTH / 2 - ARROW_MARGIN}px;
			  `
			: css`
					right: ${anchorWidth / 2 - ARROW_WIDTH / 2 - ARROW_MARGIN}px;
			  `}

	${({ isTop, anchorHeight }) =>
		isTop
			? css`
					top: ${anchorHeight}px;
			  `
			: css`
					bottom: ${anchorHeight}px;
			  `}
`;

export const TooltipContainerTransform = styled.div<{}>`
	position: absolute;
	z-index: 1;
	display: flex;
	flex-direction: column;
	left: -87px;
	top: 0px;
	bottom: 0px;
`;

export const ContentTransform = styled.div<{
	maxWidth: number;
}>`
	background-color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	padding: 16px;
	border-radius: 4px;
	box-sizing: border-box;
	width: max-content;
	max-width: ${({ maxWidth }) => Math.min(maxWidth, 600)}px;
	position: relative;

	::before {
		content: '';
		position: absolute;
		width: 11px;
		height: 6px;
		background-color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
		clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
		top: 13px;
		transform: rotate(90deg);
		right: -8px;
	}

	padding: 8px;
	${Text} {
		font-size: 12px;
	}
`;
