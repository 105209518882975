import { HomeLogo } from '@maverick/entity';

interface BrandsLogo {
	outback: HomeLogo;
	carrabbas: HomeLogo;
	bonefishgrill: HomeLogo;
}

export const BrandsLogo: BrandsLogo = {
	outback: {
		item: {
			Logo: {
				src: 'https://edge.sitecorecloud.io/osi-5dde00fe/media/Project/BBI/outback/logo-outback-white.svg',
				alt: "Outback's logo",
			},
			LogoMobile: {
				src: 'https://edge.sitecorecloud.io/osi-5dde00fe/media/Project/BBI/outback/logo.svg',
				alt: 'Outback Steakhouse',
			},
		},
	},
	carrabbas: {
		item: {
			Logo: {
				src: 'https://edge.sitecorecloud.io/osi-5dde00fe/media/Project/BBI/carrabbas/logo-carrabbas-colors.svg',
				alt: "Carrabbas's logo",
			},
			LogoMobile: {
				src: 'https://edge.sitecorecloud.io/osi-5dde00fe/media/Project/BBI/carrabbas/logo-carrabbas-colors.svg',
				alt: "Carrabbas's logo",
			},
		},
	},
	bonefishgrill: {
		item: {
			Logo: {
				src: 'https://edge.sitecorecloud.io/osi-5dde00fe/media/Project/BBI/bonefishgrill/Logos/white-bonefish-logo.webp',
				alt: 'Bonefish Grill Logo',
			},
			LogoMobile: {
				src: 'https://edge.sitecorecloud.io/osi-5dde00fe/media/Project/BBI/bonefishgrill/Logos/bonefish-logo.webp',
				alt: 'Bonefish Grill Logo',
			},
		},
	},
};
