import styled, { css } from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../utils/GeneralUtils';

export const Container = styled.div<{ variant: string }>`
	margin: 24px 0;
	display: flex;
	background: ${({ theme }) => theme.orderUi.colors.secondary.light};
	border-radius: 4px;
	min-height: 150px;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ variant }) =>
			variant === 'large' &&
			css`
				flex-direction: column;
				margin: unset;
				position: fixed;
				height: calc(100% - 106px);
				width: 50%;
			`}
	}
`;

export const HeyMate = styled.section<{ variant: string }>`
	padding: 16px;
	flex: 3;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ variant }) =>
			variant === 'large' &&
			css`
				flex: unset;
			`}
		padding: 16px;
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const Title = styled.div<{ variant: string }>`
	${({ theme }) => theme.orderUi.typography.headline.h4Variant};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	margin-right: 12px;
`;

export const Icon = styled.div`
	color: ${({ theme }) => theme.orderUi.colors.secondary.variant};
	margin: 1px 0px;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		height: 21px;
		width: auto;
	}
`;

export const DineDescription = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	margin-top: 8px;
`;

export const JoinDine = styled.div`
	margin-top: 16px;
	display: flex;
	align-items: flex-start;
	justify-content: left;
`;

export const Image = styled.section<{ imagePath: string; variant: string }>`
	flex: 2;
	background: url('${({ imagePath }) => imagePath}') center no-repeat;
	background-size: cover;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ variant }) =>
			variant === 'large' &&
			css`
				border-radius: unset;
			`}
		background-position: top;
	}
`;
