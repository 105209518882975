import styled from 'styled-components';

export const MobileBannerColumn = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	background-image: url(${({ theme }) => theme.orderUi.images.bgRewardsBanner});
	width: 100%;
	justify-content: space-between;
	transition: 'height 1.5s ease, opacity 1.5s ease';
	transition-duration: 300ms;
	z-index: 99;
`;

export const Container = styled.div`
	height: 60px;
`;
