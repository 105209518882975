import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../infrastructure';
import { RecentAndFaves } from './RecentAndFaves';
import { RecentAndFavesList } from './RecentAndFavesList';
import { RecentAndFavesCard } from './RecentAndFavesCard';
import { HandoffOperations } from '../../../HandoffMethod/redux/Handoff.operations';
import { AppOperations } from '../../../App/redux/App.operations';
import { CheckoutOperations } from '../../../Checkout/redux/Checkout.operations';
import { FeatureFlagKeys, getFeatureHighlight, FeatureFlagHighlight } from '@maverick/entity';
import { RecentAndFavesCardGuest } from './RecentAndFavesCardGuest';
import { UserOperations } from '../../../User/redux/User.operations';

const mapStateToProps = (state: RootState) => ({
	selectedRestaurant: state.handoff.selectedRestaurant,
	deliveryAddress: state.handoff.deliveryAddress,
	recentOrders: state.user.userRecentOrders?.orders,
	handoffMethod: state.handoff.selectedMethod,
	restaurantId: state.menu.menu?.restaurantId,
	imageBasePath: state.menu.menu?.imagepath,
	categories: state.menu.menu?.categories,
	faves: state.user.userFaves?.faves,
	userFaveProducts: state.user.userFaveProducts,
	authToken: state.app.userToken,
	basket: state.checkout.basket,
	highlightFeature: getFeatureHighlight(
		state.app.featureHighlight,
		FeatureFlagKeys.RecentOrdersAndFaves
	) as FeatureFlagHighlight,
});

const mapDispatchToProps = {
	setSelectedRestaurantAddress: HandoffOperations.setSelectedRestaurantAddress,
	setSelectedRestaurant: HandoffOperations.setSelectedRestaurant,
	setDeliveryAddress: HandoffOperations.setDeliveryAddress,
	setSelectedMethod: HandoffOperations.setSelectedMethod,
	setLoading: AppOperations.setLoading,
	setShowSummary: AppOperations.setShowSummary,
	setRedirectRoute: AppOperations.setRedirectRoute,
	setBasket: CheckoutOperations.setBasket,
	setUserFaveProducts: UserOperations.setUserFaveProducts,
	setUserAuthLastInteraction: UserOperations.setUserAuthLastInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;

const RecentAndFavesConnected = connector(RecentAndFaves);
export { RecentAndFavesConnected as RecentAndFaves };

export { RecentAndFavesList };

const RecentAndFavesCardConnected = connector(RecentAndFavesCard);
export { RecentAndFavesCardConnected as RecentAndFavesCard };

const RecentAndFavesCardGuestConnected = connector(RecentAndFavesCardGuest);
export { RecentAndFavesCardGuestConnected as RecentAndFavesCardGuest };
