export const deleteProductFromBasketError = 'An error occurred while deleting an item from the basket.';

export const deleteAllProductFromBasketError = 'An error occurred while cleaning the basket.';

export const getCurrentPositionError = 'Your location is blocked, please enable it in your browser settings.';

export const getRestaurantListError = 'An error occurred while searching for restaurants.';

export const getDeliveryRestaurantError = 'We were unable to locate any restaurants. Please, try another address.';

export const setBasketError = 'There was an error creating a basket.';

export const setBasketAddressError = 'There was an error setting the address in the basket.';

export const setTransferBasketError = 'There was an error transferring the basket.';

export const setBasketHandoffMethodError = 'There was an error setting basket handoff method.';

export const geolocationError = 'We were unable to get your location.';

export const addressError = 'Address not found. Please review the desired address information.';

export const getProductOptionsError = 'An error occurred while fetching product options.';

export const getRestaurantMenuError = 'There was an error fetching the restaurant menu.';

export const whatALuckMate = 'What a luck, mate! All orders come with our famous honey wheat bread and butter.';

export const errorRemovingPromoCode = 'An error occurred when removing promo code.';

export const promoCodeRemoved = 'Promo code removed!';

export const promoCodeAdded = 'Promo code added!';

export const giftCardAdded = 'Gift Card Added!';

export const errorAddingPromoCode =
	'Something went wrong adding your Promo code. Please, check if the info entered is correct.';

export const authenticationError = 'There was an error while trying to authenticate.';

export const DigitalWalletsError =
	'There was an error connecting to your digital wallet, please try again. No order has been placed or billed.';

export const getBillingAccountsError = 'There was an error while trying to get billing accounts.';

export const retrieveBasketError = 'An error occurred while getting the basket.';

export const getBillingSchemeError =
	'We had an error when trying to check available payment methods for this restaurant. Please reload the page.';

export const setBasketCustomFieldValueError = 'An error occurred when saving curbside info';

export const giftCardUsed = 'This gift card is already used';

export const postRetrieveGiftCardBalanceError = 'We had an error when trying to check gift card.';

export const balanceNotRetrieved = 'The card balance was not retrieved.';

export const addUpsellItemsError = 'An error occurred, the product was not added.';

export const addUpsellItems = 'Item added to your basket!';

export const deleteItem = 'Item removed from basket!';

export const deleteItems = 'Items removed from basket!';

export const duplicatedItem = 'Item duplicated!';

export const getCheckOrderStatusError = 'An error occurred while fetching the order information.';

export const favoriteAdded = 'Favorite order added to your basket!';

export const favoriteRemoved = 'Favorite removed!';

export const CreateUserFave = 'Favorite order added';

export const DeleteUserFave = 'Favorite order removed';

export const createdBasketFromOrderError = 'An error occurred when cloning the order.';

export const resultRestaurantError = 'Unable to recover the restaurant';

export const calendarError = "An error occurred while fetching the restaurant's calendar";

export const calendarGetTransactionsHistoryResponseError = 'There was a problem retrieving the rewards';

export const selectReward = 'Select a reward';

export const rewardAdded = 'Reward added!';

export const addRewardError = `A validation error occurred and the reward wasn't redeemed. Please check if restaurant is open and try again.`;

export const removeRewardsError = 'An error occurred when removing reward.';

export const removeRewards = 'Reward removed!';

export const selectGetQualifyingRewardsError = 'There was a problem retrieving the rewards';

export const selectRewardError = 'Is not possible to apply the selected reward.';

export const errorLogin =
	'Sorry, there was an error with your login request. Please check your account information and try again. If the problem continues, contact customer support.';

export const setTimeWantedError = 'An error occurred while setting time wanted.';

export const ErrorConstants = {
	errorGettingUpsells: 'An error occurred getting upsells.',
	errorAddingProduct: 'An error occurred, the product was not added.',
	errorLoadingMap: 'An error ocurred while loading restaurants locations',
};

export const noRestaurantFound = {
	curbside: 'We were unable to find any restaurants near this location. Please enter another address and try again.',
};

export const warningTransferBasket =
	'Your current basket will be transferred to another restaurant and menu options may change. Do you want to proceed?';

export const warningItemsNotTransferred =
	'Some items in your order are not currently available. You can choose a different option from the menu or proceed with only the available items.';

export const restaurantWillOpen =
	'This restaurant will be opening soon. Please choose one of the available times to receive your order.';

export const emptyHandoffMethodRewards = 'Please choose your hand-off method in order to redeem your rewards.';

export const emptyHandoffMethodFaves = 'Please choose your hand-off method in order to reorder your favorites.';

export const basketWithItemsReorderRecent =
	'There are already items in your basket. This action will remove these items and reorder your recent order. Do you wish to continue?';

export const basketWithItemsReorderFave =
	'Do you want to replace your basket with your favorite items? Some items may not be available depending on the selected restaurant.';

export const basketWithItemsReorderRecentOrder =
	'There are already items in you basket. This action will remove these items and reorder your recent order. Do you wish to continue?';

export const removeOrderFromFave = 'Do you want to remove this order from your favorites list?';

export const removeProductFromFave = 'Do you want to remove this product from your favorites list?';

export const curbsideOrPickupClosedRestaurant =
	'This restaurant is closed or unavailable. To continue, you can choose another or let us choose the closest restaurant for you.';

export const deliveryClosedRestaurant =
	'The restaurant you ordered from is currently closed for delivery. We can transfer your basket to another nearby restaurant, but menu options may differ. How you want to proceed?';

export const emptySuggestedRestaurants =
	'This restaurant is closed or unavailable and we could not find other open restaurants nearby. Please search for a new address or try again later.';

export const cannotChangeBasketErrorMessage =
	'Something went wrong. Please, place your order again. No order was charged.';

export const invalidCVVErrorMessage =
	'You entered a wrong CVV too many times.  For your security, your card information was removed. Try again or use another payment method.';

export const cannotSetTipError = 'An error occurred while setting the tip.';

export const messageDigitalWallet = `We've enhanced our payment security! Please re-enter your payment information for easy ordering.`;

export const messageTipAtRestaurant = `If you would like to add a tip, you may tip in-person at restaurant or use a different payment method.`;

export const onlyOneRewardMessage =
	'You can only redeem one Reward per order. To change the redeemed reward, remove the one applied in the Order Summary.';

export const emptyBasketInfoMessage = 'You must have items in your basket to redeem a reward.';

export const unavailablePaymentMethods = 'Error to load payment methods, please reload the page.';

export const searchThisAreaText = 'Search this area';

export const titleFavoriteGuest = 'Save your favorites with Dine Rewards!';

export const titleRecentGuest = 'Quickly reorder with Dine Rewards!';

export const descriptionFavoriteGuest =
	'Sign in or join Dine Rewards now to save your favorite orders and quickly reorder.';

export const descriptionRecentGuest =
	'Sign in or join Dine Rewards now to see your previous orders and quickly reorder.';

export const signUpSuccessfulMessage =
	'Check your email inbox to see if you received the email confirming your order and creating a password for your Dine Rewards account.';

export const signUpErrorMessage =
	"A problem occurred and your Dine Rewards account creation wasn't completed. Please try again";

export const signUpAlreadyCreatedMessage =
	'An account already exists with the same email or phone number from your order. You can sign into your account';
export const itemAppOnlyDownloadApp =
	'This item is only available for Outback App users. Download the App now and enjoy this and more exclusive items.';
export const faveProductNotAvailable =
	'This product is currently unavailable. Please select a restaurant that offers this item.';

export const redeemableRewardsTitle = 'Available Rewards for';

export const notRedeemableRewardsTitle = 'Rewards for other Dine Rewards brands';
