import { Basket, BasketProduct, Menu, OptionGroup, UserFaveProduct } from '@maverick/entity';
import { MenuService } from '../../services';

export const MenuManager = {
	GetRestaurantMenu: async (restaurantId: number): Promise<Partial<{ menu: Menu; error: string }>> => {
		const response = await MenuService.GetRestaurantMenu({ restaurantId });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const restaurantMenu = Menu.Make(response.body);
		restaurantMenu.restaurantId = restaurantId;
		restaurantMenu.categories = restaurantMenu.categories.filter((category) => category.products.length > 0);
		return { menu: restaurantMenu };
	},

	GetProductOptions: async (
		productId: number
	): Promise<Partial<{ optionGroup: Array<OptionGroup>; error: string }>> => {
		const response = await MenuService.GetProductOptions({ productId });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const optionsGroups: Array<OptionGroup> = response.body.optiongroups.map((o: any) => OptionGroup.Make(o));
		return { optionGroup: optionsGroups };
	},

	AddSingleProductToBasket: async (
		basketId: string,
		productid: number,
		quantity: number,
		options: Array<string>,
		specialinstructions: string,
		recipient: string
	): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await MenuService.AddSingleProductToBasket({
			basketId,
			productid,
			quantity,
			options,
			specialinstructions,
			recipient,
		});
		if (response.error) {
			return { error: response.body.message };
		}
		const basket: Basket = Basket.Make(response.body);
		return { response: basket };
	},

	UpdateSingleProductToBasket: async (
		basketId: string,
		basketProductId: number,
		productid: number,
		quantity: number,
		options: Array<string>,
		specialinstructions: string,
		recipient: string
	): Promise<Partial<{ response: Basket; error: string }>> => {
		const response = await MenuService.UpdateSingleProductToBasket({
			basketId,
			basketProductId,
			productid,
			quantity,
			options,
			specialinstructions,
			recipient,
		});
		if (response.error) {
			return { error: response.body.message };
		}
		const basket: Basket = Basket.Make(response.body);
		return { response: basket };
	},

	SaveFavoriteProduct: async (
		savedName: string,
		product: BasketProduct,
		authToken: string
	): Promise<Partial<{ response: Basket; error: string }>> => {
		const body = {
			name: savedName,
			product,
		};
		const response = await MenuService.SaveFavoriteProduct({
			body,
			authToken,
		});
		if (response.error) {
			return { error: response.body.message };
		}
		const basket: Basket = Basket.Make(response.body);
		return { response: basket };
	},

	DeleteFavoriteProduct: async (
		savedProductId: string,
		authToken: string
	): Promise<Partial<{ response: any; error: string }>> => {
		const response = await MenuService.DeleteFavoriteProduct({
			savedProductId,
			authToken,
		});
		if (response.error) {
			return { error: response.body.message };
		}
		return { response: response.body };
	},

	RetrieveFavoriteProduct: async (
		authToken: string
	): Promise<Partial<{ response: UserFaveProduct; error: string }>> => {
		const response = await MenuService.RetrieveFavoriteProduct({
			authToken,
		});

		if (response.error) {
			return { error: response.body?.message };
		}
		return {
			response: UserFaveProduct.Make(response.body),
		};
	},

	AddProductToBasketByChainId: async (
		basketId: string,
		productid: number,
		quantity: number,
		specialinstructions: string,
		recipient: string,
		chainproductid: string,
		choices: any
	): Promise<Partial<{ response: Basket; error: { code: number; message: string } }>> => {
		const response = await MenuService.AddProductToBasketByChainId({
			basketId,
			productid,
			quantity,
			specialinstructions,
			recipient,
			chainproductid,
			choices,
		});

		if (response.error) {
			return { error: { code: response.body.code, message: response.body.message } };
		}

		const basket: Basket = Basket.Make(response.body);

		return { response: basket };
	},
};
