import styled from 'styled-components';

export const Container = styled.div`
	display: inline-flex;
	width: 100%;
`;

export const OrdersCardContainer = styled.div`
	width: 100%;
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

export const TransferTitle = styled.div`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium};
	margin-bottom: 8px;
`;

export const TransferItem = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
`;
