import { FC, Suspense } from 'react';
import { lazily } from 'react-lazily';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { SelectHandoffMethod } from '../features/HandoffMethod/views/SelectHandoffMethod';
import { RootState } from './Store';
import {
	AppLoading,
	ScrollBlock,
	SkeletonCheckout,
	SkeletonMyActivity,
	SkeletonMyRewards,
	SkeletonRecentOrdersAndFaves,
	SkeletonOrderReceived,
	SkeletonProfile,
} from '@maverick/ui';
import { RestaurantMenu } from '../features/Menu/views/RestaurantMenu';
import { CustomizeProduct } from '../features/Menu/views/CustomizeProduct';
import { PreSelectRestaurant } from '../features/HandoffMethod/views/PreSelectRestaurant';
import { ScrollToTop } from './ScrollToTop';
import { PageView } from './ga-events/PageView';
import { ValidateBasketExpiration } from './ValidateBasketExpiration';
import { AuthenticationHandler } from './AuthenticationHandler';
import { RoutesGate } from './RoutesGate';
import { MenuGate } from '../features/Menu/views/MenuGate';
import { FeatureFlagKeys, getFeatureFlagItem } from '@maverick/entity';

const { Checkout } = lazily(() => import('../features/Checkout/views/Checkout'));
const { GiftCard } = lazily(() => import('../features/Checkout/views/GiftCard'));
const { PromoCode } = lazily(() => import('../features/Checkout/views/PromoCode'));
const { OrderReceived } = lazily(() => import('../features/OrderReceived/views/OrderReceived'));
const { Profile } = lazily(() => import('../features/User/views/Profile'));
const { Rewards } = lazily(() => import('../features/User/views/Rewards'));
const { RecentOrders } = lazily(() => import('../features/User/views/RecentOrders'));
const { Faves } = lazily(() => import('../features/User/views/Faves'));
const { MyActivity } = lazily(() => import('../features/User/views/MyActivity'));
const { Authentication } = lazily(() => import('./Authentication'));

export const Router: FC = () => {
	const loading = useSelector((state: RootState) => state.app.loading);
	const skeletonPage = useSelector((state: RootState) =>
		getFeatureFlagItem(state.app.featureFlagItems, FeatureFlagKeys.SkeletonPages)
	);
	return (
		<>
			{loading && <AppLoading />}
			<ScrollToTop />
			<ScrollBlock />
			<PageView />
			<ValidateBasketExpiration />
			<AuthenticationHandler />
			<RoutesGate>
				<Routes>
					<Route path='/' element={<SelectHandoffMethod />} />
					<Route path='/menu/:restaurantSlug/*'>
						<Route
							path=''
							element={
								<MenuGate loadMenu fullMenu>
									<RestaurantMenu fullMenu />
								</MenuGate>
							}
						/>
						<Route
							path='categories/:categoryId'
							element={
								<MenuGate loadMenu>
									<RestaurantMenu />
								</MenuGate>
							}
						/>
						<Route
							path='products/:productId'
							element={
								<MenuGate loadMenu>
									<CustomizeProduct />
								</MenuGate>
							}
						/>
						<Route
							path='products/:basketProductId/edit'
							element={
								<MenuGate loadMenu>
									<CustomizeProduct />
								</MenuGate>
							}
						/>
					</Route>
					<Route
						path='/checkout'
						element={
							<Suspense fallback={skeletonPage?.active ? <SkeletonCheckout /> : <AppLoading />}>
								<Checkout />
							</Suspense>
						}
					/>
					<Route
						path='/gift-card'
						element={
							<Suspense fallback={<AppLoading />}>
								<GiftCard />
							</Suspense>
						}
					/>
					<Route
						path='/promo-code'
						element={
							<Suspense fallback={<AppLoading />}>
								<PromoCode />
							</Suspense>
						}
					/>
					<Route
						path='/order-received'
						element={
							<Suspense fallback={skeletonPage?.active ? <SkeletonOrderReceived /> : <AppLoading />}>
								<OrderReceived />
							</Suspense>
						}
					/>
					<Route path='/callback/*'>
						<Route
							path='login'
							element={
								<Suspense fallback={<AppLoading />}>
									<Authentication />
								</Suspense>
							}
						/>
						<Route
							path='logout'
							element={
								<Suspense fallback={<AppLoading />}>
									<Authentication />
								</Suspense>
							}
						/>
					</Route>
					<Route
						path='/my-account'
						element={
							<Suspense fallback={skeletonPage?.active ? <SkeletonProfile /> : <AppLoading />}>
								<Profile />
							</Suspense>
						}
					/>
					<Route
						path='/my-rewards'
						element={
							<Suspense fallback={skeletonPage?.active ? <SkeletonMyRewards /> : <AppLoading />}>
								<Rewards />
							</Suspense>
						}
					/>
					<Route
						path='/recent-orders'
						element={
							<Suspense
								fallback={
									skeletonPage?.active ? (
										<SkeletonRecentOrdersAndFaves headerTitleWidth='80px' />
									) : (
										<AppLoading />
									)
								}
							>
								<RecentOrders />
							</Suspense>
						}
					/>
					<Route
						path='/faves'
						element={
							<Suspense
								fallback={
									skeletonPage?.active ? (
										<SkeletonRecentOrdersAndFaves headerTitleWidth='136px' />
									) : (
										<AppLoading />
									)
								}
							>
								<Faves />
							</Suspense>
						}
					/>
					<Route
						path='/my-activity'
						element={
							<Suspense fallback={skeletonPage?.active ? <SkeletonMyActivity /> : <AppLoading />}>
								<MyActivity />
							</Suspense>
						}
					/>
					<Route path='*' element={<PreSelectRestaurant />} />
				</Routes>
			</RoutesGate>
		</>
	);
};
