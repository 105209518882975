import { FC } from 'react';
import { ReduxProps } from '.';
import { UtilsMoneyFormatter } from '@maverick/utils';
import { paymentDetails } from '../../../../features/OrderReceived/views/OrderReceived/OrderReceived';
import * as Styled from '../OrderPaymentDetails.styled';
import { HandoffMethods } from '@maverick/entity';

export interface HandoffPaymentDetailsProps extends ReduxProps {
	paymentDetails?: paymentDetails;
}

export const HandoffPaymentDetails: FC<HandoffPaymentDetailsProps> = ({ basket, paymentDetails }) => {
	return (
		<>
			{basket?.deliverymode === HandoffMethods.Delivery && (
				<>
					{(!!basket.totalfees || !!paymentDetails?.totalfees) && (
						<>
							<Styled.Tax id='fee' data-testid='fee'>
								Fee
							</Styled.Tax>
							<Styled.Value id='fee-value' data-testid='fee-value'>
								{UtilsMoneyFormatter.format(paymentDetails?.totalfees ?? basket.totalfees)}
							</Styled.Value>
						</>
					)}
					<Styled.Tax id='delivery-fee' data-testid='delivery-fee'>
						Delivery Fee
					</Styled.Tax>
					<Styled.Value id='delivery-fee-value' data-testid='delivery-fee-value'>
						{UtilsMoneyFormatter.format(
							paymentDetails?.customerhandoffcharge ?? basket.customerhandoffcharge
						)}
					</Styled.Value>
				</>
			)}
			{(basket?.deliverymode === HandoffMethods.Curbside || basket?.deliverymode === HandoffMethods.Pickup) &&
				(basket.totalfees + basket.customerhandoffcharge > 0 ||
					(!!paymentDetails && paymentDetails.totalfees + paymentDetails.customerhandoffcharge > 0)) && (
					<>
						<Styled.Tax id='fee' data-testid='fee'>
							Fee
						</Styled.Tax>
						<Styled.Value id='fee-value' data-testid='fee-value'>
							{UtilsMoneyFormatter.format(
								!!paymentDetails
									? paymentDetails.totalfees + paymentDetails.customerhandoffcharge
									: basket.totalfees + basket.customerhandoffcharge
							)}
						</Styled.Value>
					</>
				)}
		</>
	);
};
