import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	background-color: ${({ theme }) => theme.orderUi.colors.tertiary.light};
	border-radius: 4px;
	margin-top: 24px;
	padding: 16px;
`;
export const CookiesMessage = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	a {
		color: ${({ theme }) => theme.orderUi.colors.primary.variant};
		${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
	}
`;
export const Icon = styled.div`
	width: 12px;
	height: 12px;
	margin: 4px 8px 0 0;
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		margin: 3px 16px 0 0;
		width: 14px;
		height: 14px;
	}
`;
