import { FC } from 'react';
import * as Styled from './RestaurantHeader.styled';
import { MenuMargins } from '../../../features/Menu/views/RestaurantMenu/RestaurantMenu';

export interface RestaurantHeaderProps {
	changeRestaurant: JSX.Element;
	margins: MenuMargins;
	navbar?: JSX.Element;
}

export const RestaurantHeader: FC<RestaurantHeaderProps> = ({ changeRestaurant, margins, navbar }) => {
	return (
		<Styled.Container id={'menu-header'} navbarHeight={margins.navbar}>
			<Styled.ChangeRestaurantContainer>{changeRestaurant}</Styled.ChangeRestaurantContainer>
			{!!navbar && (
				<Styled.NavbarContainer>
					<Styled.NavbarPosition>{navbar}</Styled.NavbarPosition>
				</Styled.NavbarContainer>
			)}
		</Styled.Container>
	);
};
