export enum ButtonProfile {
    RecentOrders = 'recent orders',
    MyFaves = 'my faves',
	MyRewards = 'my rewards',
    RedeemViaApp = 'redeem via app',
    ContactInfo = 'contact info',
    MyActivity = 'my activity',
    HowItWorks = 'how it works',
    MissingPoints = 'missing points'
}
