import { RootState } from '../../../../infrastructure';
import { connect, ConnectedProps } from 'react-redux';
import { GiftCardPaymentDetails } from './GiftCardPaymentDetails';
import { AppOperations } from '../../../../features/App/redux/App.operations';
import { CheckoutOperations } from '../../../../features/Checkout/redux/Checkout.operations';

const mapStateToProps = (state: RootState) => ({
	basket: state.checkout.basket,
	giftCardBasket: state.checkout.giftCard,
	giftCardLastOrder: state.checkout.lastOrder?.giftCardLastOrder ?? [],
});

const mapDispatchToProps = {
	setLoading: AppOperations.setLoading,
	setBasket: CheckoutOperations.setBasket,
	setGiftCard: CheckoutOperations.setGiftCard,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const GiftCardPaymentDetailsConnected = connector(GiftCardPaymentDetails);
export { GiftCardPaymentDetailsConnected as GiftCardPaymentDetails };
