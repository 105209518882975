export const ValidationConstants = {
	deliveryAddress: 'Enter a delivery address',
	name: 'Enter a name',
	firstName: 'Enter a first name',
	lastName: 'Enter a last name',
	phoneNumber: 'Enter a phone number',
	carModel: 'Enter a car model',
	carMake: 'Enter a car make',
	carColor: 'Enter a car color',
	promoCode: 'Enter a promo code',
	curbsideLocation: 'Please enter a location to start an order.',
	email: 'Invalid e-mail',
	favoriteName: 'Insert a favorite name',
	giftCardAlreadyUsed: 'This gift card is already used',
};

export const ValidationGiftCard = {
	pin: 'PIN is required',
	giftCard: 'Gift Card is required',
};

export const ValidationCheckoutForms = {
	invalid: 'Please, fill in the required fields to place your order.',
};

export const ValidationCreditCard = {
	insertCardNumber: 'Insert a card number',
	invalidCardNumber: 'Invalid card number',
	insertCvc: 'Insert a CVC',
	invalidCvc: 'Invalid CVC',
	insertExpirationDate: 'Insert an exp. date',
	invalidExpirationDate: 'Invalid exp. date',
	insertZipCode: 'Insert a ZIP',
	invalidZipCode: 'Invalid ZIP',
};
