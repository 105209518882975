import { HttpMethods, RestRequest, RestResponse } from '@maverick/http';
import { SitecoreClient } from './client';
import { Config } from '../../Config';

export const ContentService = {
	GetPromotionalBanners: async (): Promise<RestResponse> => {
		const query = `
			query GetPromotionalBanners($datasource: String!, $language: String!) {
				item(path: $datasource, language: $language) {
					fields {
						name,
						value
					}
				}
			}
		`;
		const datasource = `/sitecore/content/BBI/${Config.JssApp}/Order`;

		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/sitecore?query=${query}&datasource=${datasource}`,
		});

		return await SitecoreClient.ExecuteRequest(request);
	},

	GetPromotionalBannerData: async (dataSource: string): Promise<RestResponse> => {
		const query = `query GetPromotionalBannerData($datasource: String!, $language: String!) {
			item(path: $datasource, language: $language) {
			...on PromotionalBanner { 
			  button {
				text
			  },
			  title {
				value
			  },
			  text {
				value
			  },
			  mobileBackground {
				src,
				alt
			  },
			  desktopBackground {
				src,
				alt
			  },
			  button {
				text,
				target,
				url
			  },
			  buttonVariant {
				value
			  },
			  buttonAlignment {
				value
			  }
			  linkType {
				value
			  }
			  linkTypeValue {
				value
			  }
			}        
		  }
		}`;

		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `/sitecore?query=${query}&datasource=${dataSource}`,
		});

		return await SitecoreClient.ExecuteRequest(request);
	},
};
