import { RootState } from '../../../../infrastructure';
import { SelectHandoffMethod } from './SelectHandoffMethod';
import { connect, ConnectedProps } from 'react-redux';
import { HandoffOperations } from '../../redux/Handoff.operations';
import { FeatureFlagKeys, getFeatureFlagItem } from '@maverick/entity';
import { AppOperations } from '../../../App/redux/App.operations';
import { CheckoutOperations } from '../../../Checkout/redux/Checkout.operations';

const mapStateToProps = (state: RootState) => ({
	selectedMethod: state.handoff.selectedMethod,
	selectedRestaurant: state.handoff.selectedRestaurant,
	restaurantsList: state.handoff.restaurantList,
	basket: state.checkout.basket,
	searchedAddresses: state.handoff.searchedAddresses,
	selectedRestaurantAddress: state.handoff.selectedRestaurantAddress,
	processing: state.app.processing,
	skeletonPages: getFeatureFlagItem(state.app.featureFlagItems, FeatureFlagKeys.SkeletonPages),
	allowDelivery: state.app.allowDelivery,
});

const mapDispatchToProps = {
	setSelectedMethod: HandoffOperations.setSelectedMethod,
	setRestaurantList: HandoffOperations.setRestaurantList,
	setSelectedRestaurant: HandoffOperations.setSelectedRestaurant,
	setSelectedRestaurantAddress: HandoffOperations.setSelectedRestaurantAddress,
	setDeliveryAddress: HandoffOperations.setDeliveryAddress,
	setSearchedAddresses: HandoffOperations.setSearchedAddresses,
	setLoading: AppOperations.setLoading,
	setProcessing: AppOperations.setProcessing,
	setBasket: CheckoutOperations.setBasket,
	setItemsNotTransferred: CheckoutOperations.setItemsNotTransferred,
	setBasketCreationDate: CheckoutOperations.setBasketCreationDate,
	resetHandoff: HandoffOperations.resetHandoff,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const SelectHandoffMethodConnected = connector(SelectHandoffMethod);
export { SelectHandoffMethodConnected as SelectHandoffMethod };
