import styled from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../../../../utils/GeneralUtils';

export const BlankContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const BlankTitle = styled.div`
	margin-bottom: 20px;
	padding: 32px 32px 0;
`;

export const Title = styled.div`
	${({ theme }) => theme.orderUi.typography.title.title};
	margin-bottom: 16px;
`;

export const Subtitle = styled.div`
	${({ theme }) => theme.orderUi.typography.body.body};
`;

export const Close = styled.button`
	padding: 0;
	background: none;
	border: none;
	position: absolute;
	top: 29px;
	right: 24px;
	cursor: pointer;
	transition: all ease 0.1s;

	&:hover {
		opacity: 0.6;
	}

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		top: 37px;
		right: 32px;
	}
`;

export const Background = styled.div`
	position: relative;
	z-index: -1;
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-items: center;

	img {
		width: auto;
		height: 100%;
		object-fit: cover;
	}

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		img {
			width: auto;
			height: ${({ theme }) => (theme.orderUi.app.name === "Carrabba's Italian Grill" ? '100%' : '70%')};
			object-fit: cover;
		}
	}
`;

export const StartYourOrderButton = styled.div`
	position: absolute;
	padding: 24px 24px;
	background: ${({ theme }) => theme.orderUi.colors.neutral.light};
	bottom: 0;
	left: 0;
	width: 100%;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 32px 32px;
	}
`;
