import { FC } from 'react';
import { ReduxProps } from '.';
import { DiscountType } from '../../../enums';
import { Button, useToast } from '@maverick/ui';
import { GaEvent, UtilsMoneyFormatter } from '@maverick/utils';
import * as Styled from '../OrderPaymentDetails.styled';
import { CheckoutManager } from '../../../../features/Checkout/Checkout.manager';
import { errorRemovingPromoCode, promoCodeRemoved, removeRewards, removeRewardsError } from '../../../constants';
import { paymentDetails } from '../../../../features/OrderReceived/views/OrderReceived/OrderReceived';
import { Helmet } from 'react-helmet';

export interface DiscountsPaymentDetailsProps extends ReduxProps {
	hideRemoveButton?: boolean;
	paymentDetails?: paymentDetails;
	onRemove?: () => Promise<boolean | void>;
}
export const DiscountsPaymentDetails: FC<DiscountsPaymentDetailsProps> = ({
	basket,
	paymentDetails,
	setBasket,
	setProcessing,
	onRemove,
	hideRemoveButton = false,
}) => {
	const { setToast } = useToast();
	const helmetState = Helmet.peek();

	const removePromoCode = async () => {
		setProcessing(true);
		const { response, error } = await CheckoutManager.RemovePromoCode(basket!.id);

		if (error || !response) {
			const errorMessage = error ?? errorRemovingPromoCode;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage, helmetState.title);
			setProcessing(false);
			return;
		}

		setBasket(response);

		setToast({
			variant: 'success',
			text: promoCodeRemoved,
		});

		await onRemove?.();

		setProcessing(false);
	};

	const removeReward = async () => {
		setProcessing(true);
		const { response, error } = await CheckoutManager.RemoveRewards(
			basket!.id,
			basket?.appliedrewards[0].rewardid!
		);

		if (error || !response) {
			const errorMessage = error ?? removeRewardsError;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage, helmetState.title);
			setProcessing(false);
			return;
		}

		setBasket(response);

		setToast({
			variant: 'success',
			text: removeRewards,
		});

		await onRemove?.();

		setProcessing(false);
	};

	const renderDiscount = (discountType: DiscountType) => {
		const discounts = paymentDetails ? paymentDetails.discounts : basket?.discounts;
		const discount = discounts?.find((d) => d.type === discountType);

		if (!discount) {
			return null;
		}

		const id = discountType === DiscountType.Coupon ? 'promo-code' : 'reward';
		const description = discount.description;
		const amount = discount.amount;

		const removeHandler = discountType === DiscountType.Coupon ? removePromoCode : removeReward;

		return (
			<>
				<Styled.Tax id={id} data-testid={id}>
					<Styled.PromoCode>
						<div>
							{discountType === DiscountType.Coupon ? 'Promo Code - ' : ''}
							{description}
						</div>
						{!hideRemoveButton && (
							<div>
								<Button
									text='Remove'
									variant='terciary'
									onClick={removeHandler}
									id={`remove-${id}`}
								></Button>
							</div>
						)}
					</Styled.PromoCode>
				</Styled.Tax>
				<Styled.Value id={`value-${id}`} data-testid={`value-${id}`}>
					-{UtilsMoneyFormatter.format(amount)}
				</Styled.Value>
			</>
		);
	};

	return (
		<>
			{renderDiscount(DiscountType.Coupon)}
			{renderDiscount(DiscountType.Reward)}
		</>
	);
};
