import { DESKTOP_BREAKPOINT, ULTRAWIDE_BREAKPOINT } from '@maverick/utils';
import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
	${({ theme }) => theme.orderUi.specificStyle.profileHeaderBackground};
	background-size: cover;
	background-color: ${({ theme }) => theme.orderUi.colors.secondary.medium};
	@media (min-width: ${DESKTOP_BREAKPOINT + 1}px) {
		${({ theme }) => theme.orderUi.specificStyle.rewardsBannerMainContainer};
	}
`;

export const Container = styled.div<{ hideWhenMobile: boolean }>`
	flex: 1;
	display: flex;
	padding: 16px 32px;
	flex-direction: column;
	justify-content: space-around;

	@media (max-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 12px 24px 16px 24px;
		${({ hideWhenMobile }) =>
			hideWhenMobile &&
			css`
				padding: 0;
			`}
	}
	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		width: 100%;
		max-width: 1920px;
		margin: 0 auto;
	}
`;

export const Banner = styled.div`
	display: flex;
	width: 100%;
	@media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		flex-direction: column;
	}
`;

export const Divider = styled.div`
	width: 2px;
	height: 76px;
	margin: 0 12px;
	background-color: ${({ theme }) => theme.orderUi.specificStyle.rewardsBannerBorderColor};

	@media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		width: 100%;
		height: 2px;
		margin: 24px 0;
	}

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		width: 2px;
	}
`;

export const Columns = styled.div`
	flex-direction: row;
	display: flex;
	flex: 1;
	@media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		flex-direction: column;
		width: 100%;
	}
`;

export const ButtonReturn = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-bottom: 32px;
	margin-right: auto;
	@media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		width: 100%;
	}
`;

export const BannerColumn = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		justify-content: flex-start;
	}
`;
