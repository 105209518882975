import { RootState } from '../../../infrastructure';
import { connect, ConnectedProps } from 'react-redux';
import { OrderPaymentDetails } from './OrderPaymentDetails';
import { AppOperations } from '../../../features/App/redux/App.operations';
import { CheckoutOperations } from '../../../features/Checkout/redux/Checkout.operations';

const mapStateToProps = (state: RootState) => ({
	giftCardBasket: state.checkout.giftCard,
	giftCardLastOrder: state.checkout.lastOrder?.giftCardLastOrder ?? [],
	basket: state.checkout.basket,
});

const mapDispatchToProps = {
	setLoading: AppOperations.setLoading,
	setBasket: CheckoutOperations.setBasket,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const OrderPaymentDetailsConnected = connector(OrderPaymentDetails);
export { OrderPaymentDetailsConnected as OrderPaymentDetails };
