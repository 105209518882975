import { FC } from 'react';
import * as Styled from './RewardsProgressBar.styled';

export interface RewardsProgressBarProps {
	percentage: number;
}

export const RewardsProgressBar: FC<RewardsProgressBarProps> = ({ percentage }) => {
	return (
		<Styled.ProgressBar>
			<Styled.Progress data-testid='progress-bar' style={{ width: `${percentage}%` }}></Styled.Progress>
		</Styled.ProgressBar>
	);
};
