import styled from 'styled-components';

export const Container = styled.div``;

export const Main = styled.div<{ columns: number }>`
	display: grid;
	grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
	gap: 16px 24px;
`;

export const OptionGroupWrapper = styled.div``;
