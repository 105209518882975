import { configureStore, combineReducers, Action, Middleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore, createTransform, PERSIST, PURGE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import traverse from 'traverse';
import thunk, { ThunkAction } from 'redux-thunk';
import { createWrapper } from 'next-redux-wrapper';
import { createStateSyncMiddleware } from 'redux-state-sync';
import { CheckoutStore, CheckoutSlice } from '../features/Checkout/redux/Checkout.slice';
import { appSlice, AppState } from '../features/App/redux/App.slice';
import { HandoffStore, HandoffSlice } from '../features/HandoffMethod/redux/Handoff.slice';
import { UserStore, userSlice } from '../features/User/redux/User.slice';
import { MenuStore, menuSlice } from '../features/Menu/redux/Menu.slice';

export interface ReduxAction {
	type: string;
	payload?: any;
}

const dateTransform = createTransform(null, (outboundState) => {
	return traverse(outboundState).map((val) => {
		const d = new Date(val);
		if (d instanceof Date && !isNaN(d as unknown as number) && d.toISOString() === val) return d;
		return val;
	});
});

const getPersistConfig = (key: string, blacklist?: Array<string>) => ({
	key,
	storage,
	blacklist,
	transforms: [dateTransform],
});

const reducers = combineReducers({
	handoff: persistReducer<HandoffStore>(getPersistConfig(HandoffSlice.name), HandoffSlice.reducer),
	checkout: persistReducer<CheckoutStore>(getPersistConfig(CheckoutSlice.name), CheckoutSlice.reducer),
	menu: persistReducer<MenuStore>(getPersistConfig(menuSlice.name), menuSlice.reducer),
	user: persistReducer<UserStore>(getPersistConfig(userSlice.name), userSlice.reducer),
	app: persistReducer<AppState>(getPersistConfig(appSlice.name, ['loading', 'processing']), appSlice.reducer),
});

const middlewares: Array<Middleware> = [thunk];
const isServer = typeof window === 'undefined';
if (!isServer) {
	middlewares.push(createStateSyncMiddleware({ blacklist: [PERSIST, PURGE] }));
}

export const store = configureStore({
	reducer: reducers,
	devTools: true,
	middleware: middlewares,
});

export const makeStore = () => store;

export const persistor = persistStore(store);

export type RootStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<RootStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
export const StoreWrapper = createWrapper<RootStore>(makeStore);
