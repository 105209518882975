import { FC, useEffect } from 'react';
import * as Styled from './DeliveryHandoff.styled';

import { BasketDeliveryAddress, HandoffMethods, ItemAutocomplete, Restaurant, SearchedAddresses } from '@maverick/entity';
import { DeliveryContent } from './DeliveryContent';
import { AutocompleteSearch, Input, SkeletonDeliveryContent, SkeletonInput, useToast } from '@maverick/ui';
import { useInput } from '@maverick/hooks';
import { GaEvent, Storage } from '@maverick/utils';
import { fetchAddressSuggestions, validateDeliverySearch } from '../../../../../utils/GeneralUtils';

interface DeliveryHandoffProps {
	deliveryRestaurant: Restaurant | null;
	setDeliveryRestaurant: (restaurant: Restaurant | null) => void;
	setDeliveryBuilding: (building: string | null) => void;
	deliverySearchedAddress: BasketDeliveryAddress | undefined | null;
	setDeliverySearchedAddress: (deliverySearchedAddress: BasketDeliveryAddress | null) => void;
	setLoading: (loading: boolean) => void;
	setSearchedAddresses: (searchedAddresses: SearchedAddresses) => void;
	searchedAddresses: SearchedAddresses | null;
	loading: boolean;
	skeletonPagesActive: boolean;
}

export const DeliveryHandoff: FC<DeliveryHandoffProps> = ({
	deliveryRestaurant,
	deliverySearchedAddress,
	setDeliverySearchedAddress,
	setDeliveryRestaurant,
	setDeliveryBuilding,
	setLoading,
	setSearchedAddresses,
	searchedAddresses,
	loading,
	skeletonPagesActive,
}) => {
	const { setToast } = useToast();

	useEffect(() => {
		if (deliverySearchedAddress?.building) {
			buildingInput.setValue(deliverySearchedAddress.building);
		}
	}, [deliverySearchedAddress?.building]);

	const buildingInput = useInput('', {
		label: 'Suite / Apartment - optional',
		placeholder: 'Your apartment number',
		id: 'suite',
		maxlength: 128,
		charCounter: true,
		onChange: (value: string) => setDeliveryBuilding(value),
	});

	const clearDeliverySearch = () => {
		setDeliverySearchedAddress(null);
		setDeliveryRestaurant(null);
		setDeliveryBuilding(null);
		buildingInput.setValue('');
	};

	const handleDeliverySearch = async (searchedAddress: ItemAutocomplete) => {
		const error = await validateDeliverySearch(searchedAddress);

		if (error) {
			setToast({
				variant: 'error',
				text: error,
			});
			GaEvent.ErrorMessage(error);
			setLoading(false);
			return;
		}

		const addressDetails = {
			address: searchedAddress.label,
			lat: searchedAddress.value.latitude,
			long: searchedAddress.value.longitude,
			city: searchedAddress.value.city,
			postalCode: searchedAddress.value.postalCode,
			number: searchedAddress.value.number,
			street: searchedAddress.value.street,
			streetLabel: searchedAddress.value.addressLabel,
		};
		const basketDeliveryAddress = BasketDeliveryAddress.Make(searchedAddress.label, addressDetails);

		setDeliverySearchedAddress?.(basketDeliveryAddress);

		setSearchedAddresses({
			...searchedAddresses,
			delivery: basketDeliveryAddress,
			lastMethod: HandoffMethods.Delivery,
		});

		Storage.Session.Set('isUrlSlugFlow', false);

		setLoading(false);
	};

	const hasRestaurant = !!deliveryRestaurant;

	return (
		<Styled.Container>
			<Styled.Address>
				<AutocompleteSearch
					id='delivery-address-input'
					label='Delivery Address'
					placeholder='e.g. 123 Sample Street, Tampa, FL'
					onSearch={fetchAddressSuggestions}
					onChoose={handleDeliverySearch}
					onClear={clearDeliverySearch}
					initialValue={deliverySearchedAddress?.streetaddress}
				/>
				{loading && skeletonPagesActive ? (
					<SkeletonInput withIcon={false} />
				) : hasRestaurant ? (
					<Input handler={buildingInput} />
				) : null}
			</Styled.Address>
			{loading && skeletonPagesActive ? (
				<SkeletonDeliveryContent />
			) : (
				<DeliveryContent hasRestaurantAvailable={hasRestaurant} />
			)}
		</Styled.Container>
	);
};
