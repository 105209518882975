import { ContentManager } from '../features/App/Content.manager';

export const retrievePromotionalBanner = async (bannerName: string) => {
	const { promotionalBanners } = await ContentManager.GetPromotionalBanners();

	const menuBannerId = promotionalBanners?.find((banner) => banner.name === bannerName)?.value;

	if (!menuBannerId) return;

	const { bannerData } = await ContentManager.GetPromotionalBannerData(menuBannerId);

	if (!bannerData) return;

	return { bannerData };
};
