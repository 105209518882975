import { FC, useEffect, useState } from 'react';
import { ReduxProps } from '.';
import * as Styled from './BannerHandoffStatus.styled';
import { useLocation } from 'react-router-dom';
import { handleHours, mapHandoffMethodToCalendarType } from '../../../utils/RestaurantHoursUtils';
import { HandoffMethods } from '@maverick/entity';

interface BannerHandoffStatusProps extends ReduxProps {
	variant: 'menu' | 'product' | 'summary';
}

export const BannerHandoffStatus: FC<BannerHandoffStatusProps> = ({ selectedRestaurant, selectedMethod, variant }) => {
	const location = useLocation();
	const [typeBanner, setTypeBanner] = useState<string>();
	const [bannerContent, setBannerContent] = useState({ title: '', content: '' });

	useEffect(() => {
		if (!selectedRestaurant || !selectedRestaurant.calendar) return;
		const hoursRestaurant = handleHours(selectedRestaurant);

		if (hoursRestaurant !== 'Unavailable') {
			const calendarType = mapHandoffMethodToCalendarType(selectedMethod);
			const filteredHours = hoursRestaurant.find((handoffMethod) => handoffMethod.type === calendarType);

			const isAllHandoffMethodsClosed = hoursRestaurant.every((handoffMethod) => handoffMethod.tag === 'closed');
			if (isAllHandoffMethodsClosed) {
				setTypeBanner(selectedMethod);
				setBannerContent({
					title: `we're closed now`,
					content: `Please, try again soon. You can still browse our menu and fill your bag with your favorite dishes.`,
				});
				return;
			}

			if (filteredHours && filteredHours.tag === 'closed') {
				setTypeBanner(selectedMethod);
				getBannerContent(selectedMethod);
			}
		}
	}, [location.pathname, selectedMethod, selectedRestaurant]);

	const getBannerContent = (selectedMethod: HandoffMethods) => {
		switch (selectedMethod) {
			case HandoffMethods.Curbside:
			case HandoffMethods.Pickup:
				setBannerContent({
					title: 'Curbside is CLOSED',
					content: `Don't worry, though! You can still fill your bag with your favorite dishes for delivery.`,
				});
				break;
			case HandoffMethods.Delivery:
				setBannerContent({
					title: 'Delivery is CLOSED',
					content: `Don't worry, though! You can still fill your bag with your favorite dishes for curbside pickup.`,
				});
				break;
			default:
		}
	};

	if (!typeBanner) return null;

	return (
		<Styled.Container variant={variant}>
			<Styled.Banner>
				<Styled.Title tabIndex={0}>{bannerContent.title} </Styled.Title>
				<Styled.Content tabIndex={0}>{bannerContent.content}</Styled.Content>
			</Styled.Banner>
		</Styled.Container>
	);
};
