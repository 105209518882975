import { FC, useEffect, useState, ReactNode } from 'react';
import { ReduxProps } from '.';
import { useLocation, useNavigate } from 'react-router-dom';
import { rules } from './rules';

interface RoutesGateProps extends ReduxProps {
	children: ReactNode;
}
export const RoutesGate: FC<RoutesGateProps> = ({ state, children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [ready, setReady] = useState<boolean>(false);

	useEffect(() => {
		setReady(false);

		const rule = rules.get(location.pathname);
		if (rule) {
			const result = rule(state);
			if (result) {
				navigate(result);
			} else {
				setReady(true);
			}
		} else {
			setReady(true);
		}

		setReady(true);
	}, [location]);

	if (ready) {
		return <>{children}</>;
	}
	return null;
};
