import { DiscoverIcon } from '@maverick/icons/dist/DiscoverIcon';
import { MastercardIcon } from '@maverick/icons/dist/MastercardIcon';
import { VisaIcon } from '@maverick/icons/dist/VisaIcon';
import { AmexIcon } from '@maverick/icons/dist/AmexIcon';
import { DefaultPaymentIcon } from '@maverick/icons/dist/DefaultPaymentIcon';

export const FlagValidator = (cardNumber: string): JSX.Element => {
	let visaRegex = new RegExp('^4[0-9]{6,}([0-9]{3})?$');
	let masterCardRegex = new RegExp(
		'^5[1-5]([0-9]{1,14})?|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)([0-9]{1,12})?$'
	);
	let amexRegex = new RegExp('^3[47][0-9]{5,}$');
	let discoverRegex = new RegExp('^6(?:011|5[0-9]{2})[0-9]{3,}$');
	const cleanNumber = cardNumber.replace(/\s/g, '');

	if (cleanNumber.match(visaRegex)) {
		return VisaIcon;
	}

	if (cleanNumber.match(masterCardRegex)) {
		return MastercardIcon;
	}

	if (cleanNumber.match(amexRegex)) {
		return AmexIcon;
	}

	if (cleanNumber.match(discoverRegex)) {
		return DiscoverIcon;
	}

	return DefaultPaymentIcon;
};
