import { RootState } from '../../../../infrastructure';
import { connect, ConnectedProps } from 'react-redux';
import { MenuGate } from './MenuGate';
import { HandoffOperations } from '../../../HandoffMethod/redux/Handoff.operations';
import { MenuOperations } from '../../redux/Menu.operations';
import { FeatureFlagKeys, getFeatureFlagItem } from '@maverick/entity';
import { UserOperations } from '../../../User/redux/User.operations';
import { AppOperations } from '../../../../features/App/redux/App.operations';
import { CheckoutOperations } from '../../../Checkout/redux/Checkout.operations';

const mapState = (state: RootState) => ({
	selectedRestaurant: state.handoff.selectedRestaurant,
	method: state.handoff.selectedMethod,
	basket: state.checkout.basket,
	userToken: state.app.userToken,
	menu: state.menu.menu,
	deliveryAddress: state.handoff.deliveryAddress,
	auth0Token: state.app.auth0Token,
	skeletonPages: getFeatureFlagItem(state.app.featureFlagItems, FeatureFlagKeys.SkeletonPages),
	userRewards: state.user.userRewards,
});

const mapDispatch = {
	setSelectedRestaurantAddress: HandoffOperations.setSelectedRestaurantAddress,
	setSelectedRestaurant: HandoffOperations.setSelectedRestaurant,
	setSelectedMethod: HandoffOperations.setSelectedMethod,
	setUserRedeemableRewards: UserOperations.setUserRedeemableRewards,
	setUserToken: AppOperations.setUserToken,
	setAuth0Token: AppOperations.setAuth0Token,
	setRestaurantMenu: MenuOperations.setRestaurantMenu,
	setBasket: CheckoutOperations.setBasket,
	resetOrder: CheckoutOperations.resetCheckout,
	setItemsNotTransferred: CheckoutOperations.setItemsNotTransferred,
};

const connector = connect(mapState, mapDispatch);
const MenuGateConnected = connector(MenuGate);

export { MenuGateConnected as MenuGate };
export type MenuGateReduxProps = ConnectedProps<typeof connector>;
