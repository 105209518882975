import { RootState } from '../../../../infrastructure';
import { connect, ConnectedProps } from 'react-redux';
import { OrderSummary } from './OrderSummary';
import { FeatureFlagKeys, getFeatureFlagItem } from '@maverick/entity';
import { AppOperations } from '../../../App/redux/App.operations';
import { CheckoutOperations } from '../../redux/Checkout.operations';
import { MenuOperations } from '../../../Menu/redux/Menu.operations';
import { UserOperations } from '../../../User/redux/User.operations';

const mapStateToProps = (state: RootState) => ({
	basket: state.checkout.basket,
	menu: state.menu.menu,
	selectedRestaurant: state.handoff.selectedRestaurant,
	giftCardState: state.checkout.giftCard,
	loading: state.app.loading,
	processing: state.app.processing,
	skeletonPages: getFeatureFlagItem(state.app.featureFlagItems, FeatureFlagKeys.SkeletonPages),
	userRewards: state.user.userRewards,
	imagepath: state.menu.menu?.imagepath,
});

const mapDispatchToProps = {
	setBasket: CheckoutOperations.setBasket,
	setLoading: AppOperations.setLoading,
	setProcessing: AppOperations.setProcessing,
	setShowSummary: AppOperations.setShowSummary,
	setRedirectRoute: AppOperations.setRedirectRoute,
	setAddCartOrigin: MenuOperations.setAddCartOrigin,
	setUserAuthLastInteraction: UserOperations.setUserAuthLastInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const OrderSummaryConnected = connector(OrderSummary);
export { OrderSummaryConnected as OrderSummary };
