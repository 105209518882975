import { connect, ConnectedProps } from 'react-redux';
import { HowItWorks } from './HowItWorks';
import { RootState } from '../../../../infrastructure/Store';
import { AppOperations } from '../../../App/redux/App.operations';

const mapStateToProps = (state: RootState) => ({
	showHowItWorks: state.app.showHowItWorks,
	selectedRestaurant: state.handoff.selectedRestaurant,
});

const mapDispatchToProps = {
	setLoading: AppOperations.setLoading,
	setShowHowItWorks: AppOperations.setShowHowItWorks,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const HowItWorksConnected = connector(HowItWorks);
export { HowItWorksConnected as HowItWorks };
