import { HttpStatus } from '@maverick/http';
import { UserService } from '../../services';
import {
	UserFave,
	UserRecentOrders,
	OloApiError,
	Basket,
	TransactionsHistory,
	TokenProvider,
	Rewards,
} from '@maverick/entity';
import { LoyaltyService } from '../../services/bbi/Loyalty.service';

export const UserManager = {
	RetrieveUserRecentOrders: async (
		authtoken: string
	): Promise<Partial<{ response: UserRecentOrders; error: OloApiError }>> => {
		const response = await UserService.RetrieveUserRecentOrders({ authtoken });
		if (response.error) {
			if (response.status === HttpStatus.BadRequest) {
				const error = OloApiError.Make(response.body);
				return { error: error };
			}

			return {
				error: {
					num: response.body.num ?? 0,
					message: 'An error occurred while retrieving your recent orders. Please try again later.',
				},
			};
		}

		const recentOrders = UserRecentOrders.Make(response.body);

		return { response: recentOrders };
	},

	RetrieveUserFaves: async (authtoken: string): Promise<Partial<{ response: UserFave; error: OloApiError }>> => {
		const response = await UserService.RetrieveUserFaves({ authtoken });
		if (response.error) {
			if (response.status === HttpStatus.BadRequest) {
				const error = OloApiError.Make(response.body);
				return { error: error };
			}

			return {
				error: {
					num: response.body.num ?? 0,
					message: 'An error occurred while retrieving your faves. Please try again later.',
				},
			};
		}

		const favorites = UserFave.Make(response.body);
		favorites.faves = favorites.faves.reverse();
		return { response: favorites };
	},

	CreateBasketFromFave: async (
		authToken: string,
		faveid: number
	): Promise<Partial<{ response: Basket; error: OloApiError }>> => {
		const response = await UserService.CreateBasketFromFave({
			authToken: authToken,
			faveid: faveid,
			ignoreunavailableproducts: true,
		});
		if (response.error) {
			if (response.status === HttpStatus.BadRequest) {
				const error = OloApiError.Make(response.body);
				return { error: error };
			}

			return {
				error: {
					num: response.body.num ?? 0,
					message: 'An error occurred while creating your basket. Please try again later.',
				},
			};
		}
		return { response: response.body };
	},

	DeleteUserAccount: async (authtoken: string): Promise<Partial<{ response: string; error: string }>> => {
		const response = await UserService.DeleteUserAccount({ authtoken });
		if (response.error) {
			return { error: response.errorMessage };
		}

		return {};
	},

	RemoveUserBillingAccount: async (
		authtoken: string,
		billingAccountId: string
	): Promise<Partial<{ error: string }>> => {
		const response = await UserService.RemoveUserBillingAccount({ authtoken, billingAccountId });
		if (response.error) {
			return { error: response.errorMessage };
		}

		return {};
	},

	GetTransactionsHistoryByEmail: async (
		email: string
	): Promise<Partial<{ transactionsHistory: TransactionsHistory; error: string }>> => {
		const response = await LoyaltyService.GetTransactionsHistoryByEmail({ email });
		if (response.error) {
			return { error: response.errorMessage };
		}

		const rewards = TransactionsHistory.Make(response.body);
		return { transactionsHistory: rewards };
	},

	AuthorizeUser: async (
		provider: string,
		providertoken: string,
		provideruserid: string,
		contactnumber: string | null,
		basketid: string | null,
		firstname: string | null,
		lastname: string | null,
		emailaddress: string | null,
		newUser: string | null
	): Promise<Partial<{ tokenProvider: TokenProvider; error: string }>> => {
		const response = await UserService.AuthorizeUser({
			provider,
			providertoken,
			provideruserid,
			contactnumber,
			basketid,
			firstname,
			lastname,
			emailaddress,
		});
		if (response.error) {
			return { error: response.errorMessage };
		}

		const tokenProvider = TokenProvider.Make({ ...response.body, newUser });

		return { tokenProvider: tokenProvider };
	},

	GetQualifyingRewards: async (
		authtoken: string,
		vendorId: number
	): Promise<Partial<{ responseRewards: Array<Rewards>; error: string }>> => {
		const response = await UserService.GetQualifyingRewards({ authtoken, vendorId });
		if (response.error) {
			return { error: response.errorMessage };
		}
		const rewards: Array<Rewards> = response.body.rewards.map((u: Rewards) => Rewards.Make(u));
		return { responseRewards: rewards };
	},

	DeleteLoyaltyAccount: async (loyaltyId: string): Promise<Partial<{ isAccountDeleted: boolean }>> => {
		const response = await LoyaltyService.DeleteLoyaltyAccount({ loyaltyId });
		if (response.error) {
			return { isAccountDeleted: false };
		}

		if (!response.body) {
			return { isAccountDeleted: false };
		}

		return { isAccountDeleted: true };
	},
};
