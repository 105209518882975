import { FC } from 'react';
import * as Styled from './PromotionalBanner.styled';

import { PromotionalBanner, PromotionalBannerProps } from './PromotionalBanner';
import { useRedirect } from '../../hooks';
import { Button } from '@maverick/ui';
import { GaEvent } from '@maverick/utils';
import { Category, PromotionalBannerData } from '@maverick/entity';

export const PromotionalBannerRendering: FC<
	PromotionalBannerData & { bannerInfo: { name: string; id: number } } & { categoryList: Category[] | undefined } & {
		restaurantSlug: string | undefined;
	}
> = ({
	button,
	buttonAlignment,
	buttonVariant,
	desktopBackground,
	mobileBackground,
	text,
	title,
	bannerInfo,
	linkType,
	linkTypeValue,
	categoryList,
	restaurantSlug,
}) => {
	const redirect = useRedirect();

	const redirectClickBanner = () => {
		if (linkType.value === 'Category' && linkTypeValue.value) {
			return redirect(`/menu/${restaurantSlug}/categories/${linkTypeValue.value}`, {
				newTab: false,
				replace: true,
			});
		}

		if (linkType.value === 'Product' && linkTypeValue.value) {
			let productId: number | undefined;

			categoryList?.find((category: Category) => {
				const foundProduct = category.products.find((product) => {
					return product.chainproductid == linkTypeValue.value;
				});
				productId = productId || foundProduct?.id;
				return productId;
			});

			if (productId) {
				return redirect(`/menu/${restaurantSlug}/products/${productId}`, { newTab: false, replace: true });
			}
		}

		if (button.url) {
			return redirect(button.url, { newTab: button.target === '_blank' });
		}
	};

	const handleBannerClick = () => {
		const { text } = button;

		GaEvent.PromotionalBannerClick(text, bannerInfo.id || 0, bannerInfo.name);
	};

	const BannerButton = () => {
		const { url, text, target } = button;

		if (!url || !text) return null;

		const variant = buttonVariant.value === 'Secondary' ? 'secondary' : 'primary';
		const alignment =
			buttonAlignment.value === 'Center' ? 'center' : buttonAlignment.value === 'Right' ? 'right' : 'left';

		return (
			<Styled.ButtonContainer alignment={alignment} data-testid='button-container'>
				<Styled.Button>
					<Button
						text={text}
						variant={variant}
						onClick={() => {
							redirect(url, { newTab: target === '_blank' });
							handleBannerClick();
						}}
						id={`${bannerInfo.name.replaceAll(' ', '-')}`}
					/>
				</Styled.Button>
			</Styled.ButtonContainer>
		);
	};

	const promotionalBannerProps: PromotionalBannerProps = {
		title: title.value,
		text: text.value,
		button: <BannerButton />,
		hasLink: button.url !== '',
		background: { mobile: mobileBackground?.src, desktop: desktopBackground?.src },
		id: bannerInfo.name.replaceAll(' ', '-'),
		onClickBanner: () => redirectClickBanner(),
	};

	return <PromotionalBanner {...promotionalBannerProps} />;
};
