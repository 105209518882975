import { connect, ConnectedProps } from 'react-redux';
import { BannerHandoffStatus } from './BannerHandoffStatus';
import { RootState } from '../../../infrastructure';

const mapStateToProps = (state: RootState) => ({
	selectedMethod: state.handoff.selectedMethod,
	selectedRestaurant: state.handoff.selectedRestaurant,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const BannerHandoffStatusConnected = connector(BannerHandoffStatus);
export { BannerHandoffStatusConnected as BannerHandoffStatus };
