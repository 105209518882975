import { RootState } from '../../../../infrastructure';
import { connect, ConnectedProps } from 'react-redux';
import { HandoffPaymentDetails } from './HandoffPaymentDetails';
import { CheckoutOperations } from '../../../../features/Checkout/redux/Checkout.operations';

const mapStateToProps = (state: RootState) => ({
	basket: state.checkout.basket,
});

const mapDispatchToProps = {
	setBasket: CheckoutOperations.setBasket,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const HandoffPaymentDetailsConnected = connector(HandoffPaymentDetails);
export { HandoffPaymentDetailsConnected as HandoffPaymentDetails };
