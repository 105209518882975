import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { FeatureFlagItem, FeatureFlagHighlight } from '@maverick/entity';

export interface AppState {
	loading: boolean;
	processing: boolean;
	redirectRoute: string | null;
	redirectRouteRewards: string | null;
	redirectRouteCheckout: string | null;
	redirectRouteEdit: string | null;
	userToken: string | null;
	showSummary: boolean;
	showHowItWorks: boolean;
	auth0Token: string | null;
	allowDelivery: boolean;
	featureFlagItems: Array<FeatureFlagItem>;
	featureHighlight: Array<FeatureFlagHighlight>;
}

export const initialState: AppState = {
	loading: false,
	processing: false,
	redirectRoute: null,
	redirectRouteRewards: null,
	redirectRouteCheckout: null,
	redirectRouteEdit: null,
	userToken: null,
	showSummary: false,
	showHowItWorks: false,
	auth0Token: null,
	allowDelivery: false,
	featureFlagItems: [],
	featureHighlight: [],
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setLoading: (state, { payload }: PayloadAction<AppState['loading']>) => {
			state.loading = payload;
		},

		setProcessing: (state, { payload }: PayloadAction<AppState['processing']>) => {
			state.processing = payload;
		},

		setRedirectRoute: (state, { payload }: PayloadAction<AppState['redirectRoute']>) => {
			state.redirectRoute = payload;
		},

		clearRedirectRoute: (state) => {
			state.redirectRoute = initialState.redirectRoute;
		},

		setRedirectRouteRewards: (state, { payload }: PayloadAction<AppState['redirectRouteRewards']>) => {
			state.redirectRouteRewards = payload;
		},

		clearRedirectRouteRewards: (state) => {
			state.redirectRouteRewards = initialState.redirectRouteRewards;
		},

		setRedirectRouteCheckout: (state, { payload }: PayloadAction<AppState['redirectRouteCheckout']>) => {
			state.redirectRouteCheckout = payload;
		},

		clearRedirectRouteCheckout: (state) => {
			state.redirectRouteCheckout = initialState.redirectRouteCheckout;
		},

		setRedirectRouteEdit: (state, { payload }: PayloadAction<AppState['redirectRouteEdit']>) => {
			state.redirectRouteEdit = payload;
		},

		clearRedirectRouteEdit: (state) => {
			state.redirectRouteEdit = initialState.redirectRouteEdit;
		},

		setUserToken: (state, { payload }: PayloadAction<AppState['userToken']>) => {
			state.userToken = payload;
		},

		clearUserToken: (state) => {
			state.userToken = initialState.userToken;
		},

		setShowSummary: (state, { payload }: PayloadAction<AppState['showSummary']>) => {
			state.showSummary = payload;
		},

		setShowHowItWorks: (state, { payload }: PayloadAction<AppState['showHowItWorks']>) => {
			state.showHowItWorks = payload;
		},

		setAuth0Token: (state, { payload }: PayloadAction<AppState['auth0Token']>) => {
			state.auth0Token = payload;
		},

		clearAuth0Token: (state) => {
			state.auth0Token = initialState.auth0Token;
		},

		setAllowDelivery: (state, { payload }: PayloadAction<AppState['allowDelivery']>) => {
			state.allowDelivery = payload;
		},

		setFeatureFlagItems: (state, { payload }: PayloadAction<AppState['featureFlagItems']>) => {
			state.featureFlagItems = payload;
		},

		setFeatureFlagHighlight: (state, { payload }: PayloadAction<AppState['featureHighlight']>) => {
			state.featureHighlight = payload;
		},

		resetApp: (state) => {
			return {...initialState, allowDelivery : state.allowDelivery};
		}
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => ({
			...state,
			...action,
		}));
	},
});
