import { FC } from 'react';
import * as Styled from './CookiesInfo.styled';
import { Icon } from '@maverick/ui';
import { IconTooltip } from '@maverick/icons/dist/IconTooltip';

interface CookiesInfoProps {
	consentStatus: string;
}

export const CookiesInfo: FC<CookiesInfoProps> = ({ consentStatus }) => {
	return (
		<>
			{consentStatus === 'disabled' ? (
				<Styled.Container data-testid='cookies-info'>
					<Styled.Icon>
						<Icon icon={IconTooltip} />
					</Styled.Icon>
					<Styled.CookiesMessage>
						It seems that you haven't accepted all cookies on our site. We save these small files on your
						browser to remember your preferences and make your experience even better.{' '}
						<a id='one-trust-modal'>Enter here</a> to enable cookies.
					</Styled.CookiesMessage>
				</Styled.Container>
			) : null}
		</>
	);
};
