import styled, { css } from 'styled-components';

export const Field = styled.div<{ error: boolean }>`
	${({ error }) =>
		error &&
		css`
			${Top} {
				border-color: ${({ theme }) => theme.orderUi.colors.complementary.error};
			}

			${Label} {
				color: ${({ theme }) => theme.orderUi.colors.complementary.error};
			}
		`}
`;

export const InputContainer = styled.div`
	padding: 2px 8px;
`;

export const Bottom = styled.div`
	margin: 0px;
	display: flex;
	justify-content: space-between;
`;

export const Error = styled.div`
	color: ${({ theme }) => theme.orderUi.colors.complementary.error};
	padding: 0 0 0 16.5px;
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
`;

export const Top = styled.fieldset`
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.orderUi.colors.tertiary.medium};
	padding: 2px 8px 8px 8px;
	position: relative;
	margin: 0px;
`;

export const Label = styled.legend`
	${({ theme }) => theme.orderUi.typography.inputs.inputLabel};
	padding: 0 8px;
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const Input = styled.div`
	border: none;
	width: 100%;
	outline: none;
	background: transparent;

	${({ theme }) => theme.orderUi.typography.body.body};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	font-family: inherit;

	&::placeholder {
		color: ${({ theme }) => theme.orderUi.colors.tertiary.medium};
	}
`;
