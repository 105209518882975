import { FC, useState, useEffect, useLayoutEffect, Suspense } from 'react';
import * as Styled from './SelectHandoffMethod.styled';
import { useTheme } from 'styled-components';

import { Button, SkeletonProductCard, SkeletonSelectHandoffMethod, useModal, useToast } from '@maverick/ui';
import {
	BrowserPermissionLinks,
	checkIsCalendarOld,
	DateWithoutUTCFormat,
	GaEvent,
	GetCookie,
	handleRestaurantInfo,
	Storage,
} from '@maverick/utils';
import {
	Address,
	Basket,
	BasketDeliveryAddress,
	CalendarTypes,
	Cookies,
	HandoffMethods,
	Restaurant,
	TransferBasket,
} from '@maverick/entity';
import { CheckoutManager } from '../../../Checkout/Checkout.manager';
import { SelectItem } from '../../../../shared/components';
import { getUserCoordinates } from '../../../../utils/GeneralUtils';
import { HandoffManager } from '../../Handoff.manager';
import { useRedirect } from '../../../../shared/hooks';
import { DeliveryHandoff } from './DeliveryHandoff';
import { CurbsideHandoff } from './CurbsideHandoff';
import { CookiesInfo } from './CookiesInfo';
import { Helmet } from 'react-helmet';
import { ReduxProps } from '.';
import {
	getDeliveryRestaurantError,
	getCurrentPositionError,
	setTransferBasketError,
	getRestaurantListError,
	setBasketAddressError,
	warningTransferBasket,
	noRestaurantFound,
} from '../../../../shared/constants';
import { Browser, useBrowser, useOS } from '@maverick/hooks';
import { lazily } from 'react-lazily';
import { CalendarInfo, handleHours, mapHandoffMethodToCalendarType } from '../../../../utils/RestaurantHoursUtils';
import { RestaurantStatus } from '../../../../shared/enums/RestaurantStatus';
const { RadarMap } = lazily(() => import('@maverick/map'));

interface SelectHandoffMethodProps extends ReduxProps {}

export const SelectHandoffMethod: FC<SelectHandoffMethodProps> = ({
	basket,
	selectedMethod,
	restaurantsList,
	searchedAddresses,
	selectedRestaurant,
	selectedRestaurantAddress,
	skeletonPages,
	allowDelivery,
	processing,
	setSelectedRestaurantAddress,
	setBasketCreationDate,
	setItemsNotTransferred,
	setSelectedRestaurant,
	setSearchedAddresses,
	setDeliveryAddress,
	setSelectedMethod,
	setRestaurantList,
	setLoading,
	setBasket,
	setProcessing,
	resetHandoff,
}) => {
	const redirect = useRedirect();
	const os = useOS();
	const browser = useBrowser();
	const { setModal } = useModal();
	const { setToast } = useToast();
	const { orderUi } = useTheme();
	const baseUrl = String(process.env.ORDER_URL);

	const methods = [
		{ method: HandoffMethods.Curbside, icon: orderUi.icons.curbside, label: 'Curbside', id: 'main-content' },
		{ method: HandoffMethods.Delivery, icon: orderUi.icons.delivery, label: 'Delivery', id: 'Delivery' },
	];

	const [handoffOption, setHandoffOption] = useState<HandoffMethods>(HandoffMethods.None);

	const [isOrderNowDisabled, setIsOrderNowDisabled] = useState<boolean>(true);

	const [curbsideSearchedAddress, setCurbsideSearchedAddress] = useState<Address | null>();
	const [curbsideRestaurant, setCurbsideRestaurant] = useState<Restaurant | null>(null);

	const [deliverySearchedAddress, setDeliverySearchedAddress] = useState<BasketDeliveryAddress | null>(null);
	const [deliveryBuilding, setDeliveryBuilding] = useState<string | null>(deliverySearchedAddress?.building ?? '');
	const [deliveryRestaurant, setDeliveryRestaurant] = useState<Restaurant | null>(null);
	const [consentStatus, setConsentStatus] = useState<string>('unknown');
	const [isPageReady, setIsPageReady] = useState(false);

	const navBarHeight = document.getElementById('header')?.clientHeight || 0;

	useLayoutEffect(() => {
		initSelectHandoff();
	}, []);

	useLayoutEffect(() => {
		if (handoffOption === HandoffMethods.Delivery && deliveryRestaurant) {
			setIsOrderNowDisabled(false);
			return;
		}
		if (handoffOption === HandoffMethods.Curbside && curbsideRestaurant) {
			setIsOrderNowDisabled(false);
			return;
		}

		setIsOrderNowDisabled(true);
	}, [handoffOption, deliveryRestaurant, curbsideRestaurant]);

	useEffect(() => {
		if (selectedRestaurant) {
			const foundSelectedRestaurant = restaurantsList.find(
				(restaurant) => restaurant.id === selectedRestaurant.id
			);

			if (!!foundSelectedRestaurant) {
				const isCalendarOld = checkIsCalendarOld(foundSelectedRestaurant);
				if (isCalendarOld) {
					resetHandoff();
				} else {
					setSelectedRestaurant(foundSelectedRestaurant);
					setCurbsideRestaurant(selectedRestaurant);
					updateConsentStatus();
				}
				return;
			}
		}

		if (restaurantsList.length > 0) {
			setCurbsideRestaurant(restaurantsList[0]);
		}
	}, [restaurantsList, selectedRestaurant]);

	useEffect(() => {
		if (curbsideSearchedAddress) {
			getCurbsideRestaurantList();
			updateConsentStatus();
		}
	}, [curbsideSearchedAddress]);

	useEffect(() => {
		if (deliverySearchedAddress) {
			getDeliveryRestaurant();
			updateConsentStatus();
		}
	}, [deliverySearchedAddress]);

	const updateConsentStatus = () => {
		const optanonConsentCookie = document.cookie
			.split(';')
			.find((cookie) => cookie.trim().startsWith('OptanonConsent='));

		const extractedGroupsValue = optanonConsentCookie ? extractGroupsValue(optanonConsentCookie) : null;
		const newConsentStatus = getCookieConsentStatus(extractedGroupsValue);

		setConsentStatus(newConsentStatus);
	};

	useEffect(() => {
		updateConsentStatus();
	}, [consentStatus]);

	useEffect(() => {
		setTimeout(() => {
			setIsPageReady(true);
			if (!allowDelivery) setHandoffOption(HandoffMethods.Curbside);
		}, 500);
	}, []);

	const initSelectHandoff = async () => {
		setLoading(true);

		const currentPosition = await getUserCoordinates({ pageLoad: true });
		updateUserLocation(currentPosition);

		const cookie = GetCookie(Cookies.FavoriteStore);
		if (cookie) {
			await handleSearchFromCookie(cookie);
			setHandoffOption(
				selectedMethod === HandoffMethods.Delivery ? HandoffMethods.Delivery : HandoffMethods.Curbside
			);
			setLoading(false);
			return;
		}

		if (searchedAddresses?.delivery) {
			setDeliverySearchedAddress(searchedAddresses.delivery);
			setDeliveryBuilding(searchedAddresses.delivery.building ?? '');
		}

		if (Storage.Session.Get('isUrlSlugFlow') === true) {
			if (!selectedRestaurant) {
				setHandoffOption(HandoffMethods.Curbside);
				setLoading(false);
				return;
			}

			setCurbsideSearchedAddress({
				address: selectedRestaurantAddress,
				latitude: selectedRestaurant.latitude,
				longitude: selectedRestaurant.longitude,
			});

			setHandoffOption(HandoffMethods.Curbside);

			setLoading(false);
			return;
		}

		if (Storage.Session.Get('isUrlSlugFlow') === false) {
			setHandoffOption(
				selectedMethod === HandoffMethods.Delivery ? HandoffMethods.Delivery : HandoffMethods.Curbside
			);
		}

		if (Storage.Session.Get('isUrlSlugFlow') === undefined) {
			if (searchedAddresses?.lastMethod) {
				setHandoffOption(searchedAddresses.lastMethod as HandoffMethods);
			} else {
				setHandoffOption(HandoffMethods.Curbside);
			}
		}

		if (searchedAddresses?.curbside) {
			setCurbsideSearchedAddress(searchedAddresses.curbside);
		}

		setLoading(false);
	};

	const handleSearchFromCookie = async (cookie: string) => {
		const restaurant = await HandoffManager.GetLocationByRef(cookie);

		if (restaurant) {
			const { restaurantAddress } = handleRestaurantInfo(restaurant);
			const { latitude, longitude } = restaurant;

			setHandoffOption(HandoffMethods.Curbside);

			const { restaurantList, error } = await HandoffManager.GetCurbsideRestaurantList(latitude, longitude);
			if (error || !restaurantList) {
				GaEvent.SearchStore(restaurantAddress, 0, false, HandoffMethods.Curbside);
				GaEvent.ErrorMessage(error ?? getRestaurantListError);
				return;
			}
			if (restaurantList.length === 0) {
				GaEvent.SearchStore(restaurantAddress, restaurantList.length, false, HandoffMethods.Curbside);
				setRestaurantList([]);

				setCurbsideSearchedAddress({
					address: restaurantAddress,
					latitude: latitude,
					longitude: longitude,
				});
				setSearchedAddresses({
					curbside: { address: restaurantAddress, latitude: latitude, longitude: longitude },
				});

				setSelectedRestaurantAddress(restaurantAddress);
				return;
			}

			setCurbsideSearchedAddress({
				address: restaurantAddress,
				latitude: latitude,
				longitude: longitude,
			});
			setSearchedAddresses({
				curbside: { address: restaurantAddress, latitude: latitude, longitude: longitude },
			});

			GaEvent.SearchStore(restaurantAddress, restaurantList.length, true, HandoffMethods.Curbside);
			setRestaurantList(restaurantList);
		}
	};

	const handleHighlightedRestaurant = (restaurant: Restaurant) => {
		if (curbsideRestaurant?.id === restaurant.id) {
			setCurbsideRestaurant(null);
			return;
		}

		setCurbsideRestaurant(restaurant);
	};

	const updateUserLocation = async (currentPosition: GeolocationCoordinates | null) => {
		if (!currentPosition) {
			setSearchedAddresses({
				...searchedAddresses,
				userGeoLocation: undefined,
			});
			return;
		}

		setSearchedAddresses({
			...searchedAddresses,
			userGeoLocation: { latitude: currentPosition.latitude, longitude: currentPosition.longitude },
		});
	};

	const handleUseMyLocation = async () => {
		GaEvent.UseMyLocationClick();

		const currentPosition = await getUserCoordinates();

		updateUserLocation(currentPosition);

		if (!currentPosition) {
			handleGeolocationToast();
			return;
		}

		Storage.Session.Set('isUrlSlugFlow', false);
		setRestaurantList([]);
		setCurbsideRestaurant(null);
		setCurbsideSearchedAddress({
			latitude: currentPosition.latitude,
			longitude: currentPosition.longitude,
			address: 'Your Current Location',
		});
	};

	const handleGeolocationToast = () => {
		let key = '';

		if (browser === Browser.Safari) {
			if (os.isMac) key = 'Desktop';
			if (os.isiOS) key = 'Mobile';
		}

		key += browser;

		const linkPermission = BrowserPermissionLinks[key as keyof typeof BrowserPermissionLinks];

		if (browser === Browser.Default) {
			setToast({
				variant: 'error',
				text: getCurrentPositionError,
			});
			return;
		}

		setToast({
			variant: 'error',
			text: getCurrentPositionError,
			link: { link: linkPermission, label: 'Click here' },
			secondary: 'to learn how to enable location.',
		});
	};

	const getCurbsideRestaurantList = async () => {
		if (!curbsideSearchedAddress) return;

		setProcessing(true);

		const { latitude, longitude, address } = curbsideSearchedAddress;
		const { restaurantList, error } = await HandoffManager.GetCurbsideRestaurantList(latitude, longitude);

		if (error || !restaurantList) {
			setToast({
				variant: 'error',
				text: error ?? getRestaurantListError,
			});
			GaEvent.SearchStore(address, 0, false, HandoffMethods.Curbside);
			GaEvent.ErrorMessage(error ?? getRestaurantListError);
			setProcessing(false);
			return;
		}

		if (restaurantList.length === 0) {
			GaEvent.SearchStore(address, restaurantList.length, false, HandoffMethods.Curbside);
			if (handoffOption !== HandoffMethods.Delivery) setNoRestaurantFoundModal();
			setProcessing(false);
			setRestaurantList([]);
			return;
		}

		GaEvent.SearchStore(address, restaurantList.length, true, HandoffMethods.Curbside);
		setRestaurantList(restaurantList);
		setProcessing(false);
		return;
	};

	const getDeliveryRestaurant = async () => {
		if (!deliverySearchedAddress) return;

		const { latitude, longitude, streetaddress } = deliverySearchedAddress;

		if (!latitude || !longitude || !streetaddress) return;

		setProcessing(true);

		const { restaurantList, error } = await HandoffManager.GetDeliveryRestaurant(latitude, longitude);

		if (error) {
			setToast({
				variant: 'error',
				text: error ?? getRestaurantListError,
			});
			GaEvent.SearchStore(streetaddress, 0, false, HandoffMethods.Delivery);
			GaEvent.ErrorMessage(error ?? getRestaurantListError);
			setProcessing(false);
			setDeliveryRestaurant(null);
			return;
		}

		if (!restaurantList?.length) {
			GaEvent.SearchStore(streetaddress, 0, false, HandoffMethods.Delivery);
			GaEvent.ErrorMessage(getDeliveryRestaurantError);
			setProcessing(false);
			setDeliveryRestaurant(null);
			if (handoffOption === HandoffMethods.Delivery) setNoRestaurantFoundModal();
			return;
		}

		setDeliveryRestaurant(restaurantList[0]);

		GaEvent.SearchStore(streetaddress, restaurantList.length, true, HandoffMethods.Delivery);
		GaEvent.Global.Set('store_id', restaurantList[0].id);
		setProcessing(false);
		return;
	};

	const setNoRestaurantFoundModal = () => {
		setModal({
			text:
				handoffOption === HandoffMethods.Curbside
					? noRestaurantFound.curbside
					: orderUi.texts.NoRestaurantFoundDelivery,
			primaryButton: {
				label: handoffOption === HandoffMethods.Curbside ? 'Close' : 'Ok',
				onClick: () => {},
			},
			id: 'empty-restaurants-modal',
		});
	};

	const setTransferBasketModal = (handoffMethod: HandoffMethods, orderRestaurant: Restaurant) => {
		setModal({
			text: warningTransferBasket,
			primaryButton: {
				label: 'Transfer Basket',
				onClick: () => handleTransferBasket(handoffMethod, orderRestaurant),
			},
			secondaryButton: { label: 'Cancel' },
			id: 'warning-transfer-basket-modal',
		});
	};

	const handleErrorMessage = (errorMessage: string) => {
		setToast({
			variant: 'error',
			text: errorMessage,
		});
		GaEvent.ErrorMessage(errorMessage);
		setLoading(false);
	};

	const onClickOrderNow = async () => {
		const orderRestaurant = handoffOption === HandoffMethods.Delivery ? deliveryRestaurant : curbsideRestaurant;

		if (!orderRestaurant) return;

		let handoffMethod = HandoffMethods.None;

		if (handoffOption !== HandoffMethods.Delivery && curbsideRestaurant) {
			handoffMethod = orderRestaurant.supportscurbside
				? HandoffMethods.Curbside
				: orderRestaurant.canpickup
				? HandoffMethods.Pickup
				: HandoffMethods.None;
		}

		if (handoffOption === HandoffMethods.Delivery && deliveryRestaurant) {
			handoffMethod = HandoffMethods.Delivery;
		}

		if (!!basket) {
			if (orderRestaurant.id !== basket.vendorid) {
				if (basket.products.length > 0) {
					setTransferBasketModal(handoffMethod, orderRestaurant);
					return;
				} else {
					handleTransferBasket(handoffMethod, orderRestaurant);
					return;
				}
			}

			if (basket.deliverymode === HandoffMethods.Delivery && handoffMethod === HandoffMethods.Delivery) {
				if (basket.deliveryaddress?.building != deliveryBuilding) {
					const updatedBasket = await handleBasketDeliveryAddress(basket);
					if (!updatedBasket) return;
					setBasket(updatedBasket);
				}
			}
		}
		GaEvent.Global.Set('store_id', orderRestaurant.id);
		updateStoredData(handoffMethod, orderRestaurant);
		redirect(`/menu/${orderRestaurant.slug}`);
	};

	const handleTransferBasket = async (handoffMethod: HandoffMethods, orderRestaurant: Restaurant) => {
		if (!basket || !orderRestaurant) return;

		setLoading(true);
		let updatedBasket: Basket | null = basket;

		if (updatedBasket.deliverymode === HandoffMethods.Delivery) {
			const resetDeliveryAddress = await handleBasketHandoffMethod(updatedBasket, HandoffMethods.Delivery);
			if (!resetDeliveryAddress) return;
			updatedBasket = resetDeliveryAddress;
		}

		const transferredBasket = await handleBasketTransferring(updatedBasket.id, orderRestaurant.id);
		if (!transferredBasket || !transferredBasket.basket) return;

		updatedBasket = transferredBasket.basket.products.length ? transferredBasket.basket : null;

		if (transferredBasket.itemsnottransferred) setItemsNotTransferred(transferredBasket.itemsnottransferred);

		if (updatedBasket !== null) {
			if (handoffMethod === HandoffMethods.Delivery) {
				const settedAddressToBasket = await handleBasketDeliveryAddress(updatedBasket);
				if (!settedAddressToBasket) return;
				updatedBasket = settedAddressToBasket;
			}

			if (handoffMethod === HandoffMethods.Curbside || handoffMethod === HandoffMethods.Pickup) {
				const settedHandoffToBasket = await handleBasketHandoffMethod(updatedBasket, handoffMethod);
				if (!settedHandoffToBasket) return;
				updatedBasket = settedHandoffToBasket;
			}
		}

		setBasket(updatedBasket);
		setBasketCreationDate(DateWithoutUTCFormat(new Date()));

		updateStoredData(handoffMethod, orderRestaurant);
		redirect(`/menu/${orderRestaurant.slug}`);
		setLoading(false);
	};

	const clearBasketOnError = () => {
		setBasket(null);
	};

	const handleBasketTransferring = async (
		basketId: string,
		restaurantId: number
	): Promise<TransferBasket | undefined> => {
		const { transferBasket, error } = await CheckoutManager.SetTransferBasket(basketId, restaurantId);

		if (!transferBasket || !transferBasket.basket || error) {
			const couldNotLoadBasket = 'Could not load shopping basket';

			if (error == couldNotLoadBasket) {
				clearBasketOnError();
				setLoading(false);
				return;
			}
			handleErrorMessage(error ?? setTransferBasketError);
			return;
		}

		return transferBasket;
	};

	const handleBasketDeliveryAddress = async (basket: Basket): Promise<Basket | undefined> => {
		if (!deliverySearchedAddress) return;

		const { settedAddressToBasket, setBasketDeliveryAddressError } = await CheckoutManager.SetBasketDeliveryAddress(
			basket.id,
			{ ...deliverySearchedAddress, building: deliveryBuilding ?? '' }
		);

		if (!settedAddressToBasket || setBasketDeliveryAddressError) {
			const errorMessage = setBasketDeliveryAddressError ?? setBasketAddressError;
			handleErrorMessage(errorMessage);
			return;
		}

		return settedAddressToBasket;
	};

	const handleBasketHandoffMethod = async (
		basket: Basket,
		handoffMethod: HandoffMethods
	): Promise<Basket | undefined> => {
		const { settedHandoffToBasket, setHandoffBasketError } = await CheckoutManager.SetBasketHandoffMethod(
			basket.id,
			handoffMethod
		);

		if (!settedHandoffToBasket || setHandoffBasketError) {
			handleErrorMessage(setHandoffBasketError ?? setTransferBasketError);
			return;
		}

		return settedHandoffToBasket;
	};

	const searchThisArea = (searchAreaAddress: Address) => {
		setCurbsideSearchedAddress(searchAreaAddress);

		if (handoffOption !== HandoffMethods.Delivery) {
			setSearchedAddresses({
				...searchedAddresses,
				curbside: searchAreaAddress,
				lastMethod: HandoffMethods.Curbside,
			});
		}
	};

	const updateStoredData = (handoffMethod: HandoffMethods, orderRestaurant: Restaurant) => {
		setSelectedMethod(handoffMethod);
		getRestaurantAddress(orderRestaurant);
		setSelectedRestaurant(orderRestaurant);

		if (handoffMethod === HandoffMethods.Delivery) {
			if (deliverySearchedAddress) {
				const updatedDeliveryAddress = {
					...deliverySearchedAddress,
					streetLabel: deliverySearchedAddress.streetLabel,
					building: deliveryBuilding ?? '',
				};

				setDeliveryAddress(updatedDeliveryAddress);
				setSearchedAddresses({
					...searchedAddresses,
					delivery: updatedDeliveryAddress,
				});
			}
			Storage.Session.Set('isDeliverySelected', true);
		} else {
			Storage.Session.Set('isDeliverySelected', false);
		}

		if (Storage.Session.Get('isUrlSlugFlow') === undefined || handoffOption === HandoffMethods.Delivery) {
			Storage.Session.Set('isUrlSlugFlow', false);
		}
	};

	const getRestaurantAddress = (restaurant: Restaurant) => {
		const { restaurantAddress } = handleRestaurantInfo(restaurant);
		setSelectedRestaurantAddress(restaurantAddress);
	};

	const extractGroupsValue = (cookieValue: string): string | null => {
		const cookiePairs = cookieValue.split('&');
		for (const pair of cookiePairs) {
			const [key, value] = pair.split('=');
			if (key === 'groups') {
				return value;
			}
		}
		return null;
	};

	const getCookieConsentStatus = (groupsValue: string | null): string => {
		if (groupsValue !== null) {
			const groupItems = groupsValue.split('%2C');
			const consentStatusValues = groupItems.map((item) => item.split('%3A')[1]);

			if (consentStatusValues.includes('0')) {
				return 'disabled';
			} else {
				return 'enabled';
			}
		}
		return 'unknown';
	};

	const checkPickupAvailability = (hoursRestaurant: CalendarInfo[], calendarType: CalendarTypes) => {
		if (calendarType === CalendarTypes.Curbside)
			return hoursRestaurant.find((handoffMethod) => handoffMethod.type === CalendarTypes.Pickup);
		return undefined;
	};

	const showButton = () => {
		const handleHoursTag = (): RestaurantStatus | undefined => {
			const restaurant = curbsideRestaurant ? curbsideRestaurant : deliveryRestaurant;
			if (!restaurant) return undefined;
			const hoursRestaurant = handleHours(restaurant);
			if (hoursRestaurant === 'Unavailable') return undefined;
			if (Array.isArray(hoursRestaurant)) {
				const calendarType = mapHandoffMethodToCalendarType(handoffOption);
				let filteredHours = hoursRestaurant.find((handoffMethod) => handoffMethod.type === calendarType);

				if (filteredHours === undefined) filteredHours = checkPickupAvailability(hoursRestaurant, calendarType);

				return filteredHours?.tag;
			}
			return undefined;
		};

		const hoursTag = handleHoursTag();

		if (
			(handoffOption === HandoffMethods.Delivery && !deliverySearchedAddress) ||
			(handoffOption === HandoffMethods.Curbside && (restaurantsList.length === 0 || !curbsideRestaurant))
		) {
			return <></>;
		} else if (hoursTag === RestaurantStatus.Closed || hoursTag === undefined) {
			return (
				<Button
					onClick={onClickOrderNow}
					disabled={isOrderNowDisabled}
					text='View Menu'
					variant='secondary'
					id='order-now'
				/>
			);
		} else if (hoursTag === RestaurantStatus.OrderAhead) {
			return (
				<Button
					onClick={onClickOrderNow}
					disabled={isOrderNowDisabled}
					text='Order Ahead'
					variant='primary'
					id='order-now'
				/>
			);
		}
		return (
			<Button
				onClick={onClickOrderNow}
				disabled={isOrderNowDisabled}
				text={'Order Now'}
				ariaLabel={isOrderNowDisabled ? 'Order Now - Select a location to enable this button' : 'Order Now'}
				variant='primary'
				id='order-now'
			/>
		);
	};

	return (
		<>
			{!isPageReady && skeletonPages?.active && <SkeletonSelectHandoffMethod />}
			<Styled.Container displayNone={!isPageReady && !!skeletonPages?.active}>
				<Helmet>
					<link rel='canonical' href={`${baseUrl}`} />
					<meta property='og:title' content={`${orderUi.app.name} - Order Online`}></meta>
					<meta property='og:type' content='restaurant'></meta>
					<meta property='og:image' content={orderUi.links.logoMeta}></meta>
					<meta property='og:url' content={window.location.href}></meta>
					<meta
						property='og:description'
						content={`Order Online at ${orderUi.app.name}. Pay Ahead and Skip the Line.`}
					></meta>
					<meta property='og:locale:alternate' content='en_US'></meta>
					<meta property='og:site_name' content={orderUi.app.name}></meta>
				</Helmet>

				<Styled.LeftContainer>
					<Styled.Content>
						<Styled.HandoffTitle>
							<Styled.TitleResponsive>{orderUi.texts.SelectHandoffMethodTitle}</Styled.TitleResponsive>
							<Styled.SubtitleResponsive>Choose an order type:</Styled.SubtitleResponsive>
						</Styled.HandoffTitle>

						<Styled.Type>
							<Styled.TypeIcon role='listbox' aria-label='choose an order type'>
								{methods.map(({ method, icon, label, id }) => (
									<SelectItem
										key={method}
										label={label}
										icon={icon}
										selected={handoffOption === method}
										onClick={() => (allowDelivery ? setHandoffOption(method) : null)}
										id={id}
										allowDelivery={allowDelivery}
									/>
								))}
							</Styled.TypeIcon>
						</Styled.Type>

						<Styled.HandoffContent>
							{handoffOption === HandoffMethods.Delivery ? (
								<DeliveryHandoff
									deliveryRestaurant={deliveryRestaurant}
									setDeliveryRestaurant={setDeliveryRestaurant}
									setDeliveryBuilding={setDeliveryBuilding}
									setDeliverySearchedAddress={setDeliverySearchedAddress}
									deliverySearchedAddress={deliverySearchedAddress}
									setLoading={setProcessing}
									setSearchedAddresses={setSearchedAddresses}
									searchedAddresses={searchedAddresses}
									loading={processing}
									skeletonPagesActive={skeletonPages?.active ?? false}
								/>
							) : (
								<CurbsideHandoff
									curbsideRestaurant={curbsideRestaurant}
									handleHighlightedRestaurant={handleHighlightedRestaurant}
									getCurrentLocation={handleUseMyLocation}
									setCurbsideRestaurant={setCurbsideRestaurant}
									curbsideSearchedAddress={curbsideSearchedAddress}
									setCurbsideSearchedAddress={setCurbsideSearchedAddress}
									setRestaurantList={setRestaurantList}
									setLoading={setProcessing}
									setSearchedAddresses={setSearchedAddresses}
									searchedAddresses={searchedAddresses}
									restaurantsList={restaurantsList}
									loading={processing}
									skeletonPagesActive={skeletonPages?.active ?? false}
								/>
							)}
						</Styled.HandoffContent>

						<CookiesInfo consentStatus={consentStatus} />

						<Styled.ButtonContainer>
							<Styled.Button>{showButton()}</Styled.Button>
						</Styled.ButtonContainer>
					</Styled.Content>
				</Styled.LeftContainer>

				<Styled.RightContainer>
					{processing ? (
						<SkeletonProductCard />
					) : (
						<Suspense fallback={<></>}>
							<Styled.Map navBarHeight={navBarHeight}>
								<RadarMap
									handoffOption={handoffOption}
									getCurrentLocation={handleUseMyLocation}
									curbsideRestaurants={restaurantsList}
									selectedCurbsideRestaurant={curbsideRestaurant}
									onMarkerClick={handleHighlightedRestaurant}
									deliverySearchedAddress={deliverySearchedAddress}
									searchThisArea={searchThisArea}
									showSearchButton
								/>
							</Styled.Map>
						</Suspense>
					)}
				</Styled.RightContainer>
			</Styled.Container>
		</>
	);
};
