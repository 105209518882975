import { RootState } from '../Store';

const requiresUserTokenRedirect = (state: RootState, redirect: string): string | void => {
	if (!state.app.userToken) {
		return redirect;
	}
};

export const rules = new Map<string, (state: RootState) => string | void>([
	[
		'/checkout',
		(state) => {
			if (!state.handoff.selectedRestaurant || !state.handoff.selectedMethod) {
				return '/';
			}
			if (!state.checkout.basket || !state.checkout.basket.products?.length) {
				return `/menu/${state.handoff.selectedRestaurant?.slug}`;
			}
		},
	],
	[
		'/order-received',
		(state) => {
			if (!state.handoff.selectedRestaurant || !state.handoff.selectedMethod) {
				return '/';
			}
			if (!state.checkout.lastOrder) {
				return `/menu/${state.handoff.selectedRestaurant?.slug}`;
			}
		},
	],
	['/my-account', (state) => requiresUserTokenRedirect(state, '/')],
	['/recent-orders', (state) => requiresUserTokenRedirect(state, '/')],
	['/faves', (state) => requiresUserTokenRedirect(state, '/')],
	['/my-rewards', (state) => requiresUserTokenRedirect(state, '/')],
	['/my-activity', (state) => requiresUserTokenRedirect(state, '/')],
]);
