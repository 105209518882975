import React, { FC, useState, useEffect } from 'react';
import * as Styled from './RewardsRedeemColumn.styled';
import { Icon } from '@maverick/ui';
import { CalendarIcon } from '@maverick/icons/dist/CalendarIcon';
import { NotificationIcon } from '@maverick/icons/dist/NotificationIcon';
import { UserRewards } from '@maverick/entity';
import { useRedirect } from '../../../hooks';
import { useLocation } from 'react-router-dom';
import { getDateWithoutTimezone } from '@maverick/utils';

interface RewardsRedeemColumnProps {
	rewards: UserRewards;
	restaurantSlug?: string;
	handleMenuRedirect?: () => void;
}

type ColumnData = {
	text: JSX.Element;
	button: string;
	onClick: () => void;
	icon: JSX.Element;
};

export const RewardsRedeemColumn: FC<RewardsRedeemColumnProps> = ({ rewards, restaurantSlug, handleMenuRedirect }) => {
	const redirect = useRedirect();
	const location = useLocation();

	const isInMyAccount = location.pathname === '/my-account';

	const [data, setData] = useState<ColumnData>({
		text: <></>,
		button: '',
		onClick: () => {},
		icon: <></>,
	});

	useEffect(() => {
		getRewardData();
	}, [rewards]);

	const handleRedirect = (page: string) => {
		handleMenuRedirect?.();
		redirect(page);
	};

	const getRewardData = () => {
		const {
			maximumPointsLimit,
			pointsUntilReward,
			expirationDate,
			pointsRewardAmount,
			rewardsCounter,
			pointsCount,
		} = rewards;

		if (pointsCount > 0 && maximumPointsLimit > pointsUntilReward && rewardsCounter === 0) {
			setData({
				text: (
					<>
						{pointsUntilReward} points to get a{' '}
						<span data-testid='total-rewards'>${pointsRewardAmount} off </span>
						reward.
					</>
				),
				button: 'Show details',
				onClick: () => handleRedirect(isInMyAccount ? `/menu/${restaurantSlug ?? '/'}` : '/my-account'),
				icon: NotificationIcon,
			});

			return;
		}

		if (rewardsCounter === 1) {
			setData({
				text: <>Your rewards expires {getDateWithoutTimezone(expirationDate)}</>,
				button: 'Show details',
				onClick: () => handleRedirect('/my-rewards'),
				icon: CalendarIcon,
			});

			return;
		}

		if (rewardsCounter > 1) {
			setData({
				text: <>You have rewards!</>,
				button: 'Show details',
				onClick: () => handleRedirect('/my-rewards'),
				icon: NotificationIcon,
			});

			return;
		}

		setData({
			text: <>{isInMyAccount ? `Place a new order to earn points!` : 'Place your order to earn points!'}</>,
			button: isInMyAccount ? 'Order now' : 'Show details',
			onClick: () => handleRedirect(isInMyAccount ? `/menu/${restaurantSlug ?? '/'}` : '/my-account'),
			icon: NotificationIcon,
		});
	};

	const { text, onClick, button, icon } = data;

	return (
		<Styled.RedeemColumn>
			<Styled.RedeemContent>
				<Styled.RedeemItems tabIndex={0}>
					<Styled.Icon>
						<Icon icon={icon} customSize />
					</Styled.Icon>
					<Styled.RewardsInfo data-testid='text'>{text}</Styled.RewardsInfo>
				</Styled.RedeemItems>
				<Styled.Redeem onClick={onClick}>{button}</Styled.Redeem>
			</Styled.RedeemContent>
		</Styled.RedeemColumn>
	);
};
