import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../infrastructure';
import { TemperatureSlider } from './TemperatureSlider';
import { MenuOperations } from '../../../features/Menu/redux/Menu.operations';

const mapStateToProps = ({ menu }: RootState) => ({
	selectedOptions: menu.selectedOptions,
});

const mapDispatchToProps = {
	selectOption: MenuOperations.selectOption,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const TemperatureSliderConnected = connector(TemperatureSlider);
export { TemperatureSliderConnected as TemperatureSlider };
