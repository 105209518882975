import { FC } from 'react';
import * as Styled from './RewardsCheckColumn.styled';
import { handleKeyDown } from '../../../../utils/GeneralUtils';

interface RewardsCheckColumnProps {
	rewardsCounter: number;
	handleClick: () => void;
}

export const RewardsCheckColumn: FC<RewardsCheckColumnProps> = ({ rewardsCounter, handleClick }) => {
	const brand = String(process.env.BRAND);
	const displayBrand = brand.charAt(0).toUpperCase() + brand.slice(1);

	return (
		<Styled.CheckRewardsColumn
			tabIndex={0}
			role='button'
			onKeyDown={(ev) => handleKeyDown(ev, handleClick)}
			onClick={handleClick}
		>
			<Styled.CheckRewards>{`My ${displayBrand} Rewards`}</Styled.CheckRewards>
			{rewardsCounter > 0 && (
				<Styled.RewardsCounter data-testid='rewards-counter'>{rewardsCounter}</Styled.RewardsCounter>
			)}
		</Styled.CheckRewardsColumn>
	);
};
