import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Icons = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;

	@media (min-width: 768px) {
		gap: 16px;
	}
`;

export const Icon = styled.div<{ isLoading?: boolean }>`
	height: 24px;
	max-width: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all ease 0.1s;
	position: relative;

	& > *:first-child {
		color: ${({ theme }) => theme.orderUi.icons.iconHeaderColor};
	}

	${({ isLoading }) =>
		isLoading &&
		css`
			cursor: default;

			&:hover {
				opacity: 1;
			}
		`}
`;

export const AnimatedIconContainer = styled(Icon)`
	top: -1px;
	height: 26.5px;
	max-width: 50px;
`;

export const Button = styled.button`
	background: none;
	border: none;
	padding: 5px 8px;
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	cursor: pointer;

	@media (max-width: 767px) {
		padding: 5px 10px;
	}

	&:hover {
		${Icon || AnimatedIconContainer} {
			opacity: 0.6;
		}
	}
`;

export const UserAccount = styled.a`
	background: none;
	border: none;
	padding: 5px 8px;
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	cursor: pointer;
	appearance: auto;
	display: inline-block;

	@media (max-width: 767px) {
		padding: 5px 10px;
	}

	&:hover {
		${Icon || AnimatedIconContainer} {
			opacity: 0.6;
		}
	}
`;

export const BasketBadge = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1.8px solid;
	background: ${({ theme }) => theme.orderUi.colors.primary.light};
	${({ theme }) => theme.orderUi.typography.caption.caption};
	font-weight: 700;
	position: absolute;
	top: -4px;
	right: -6px;
`;
