import { InputHandler } from '@maverick/hooks';
import { GaEvent } from '@maverick/utils';
import { Helmet } from 'react-helmet';
import { ValidationCreditCard } from '../../../constants';

export const CardNumberValidator = (cardNumber: InputHandler<string>): boolean => {
	const { title } = Helmet.peek();

	if (!cardNumber.value) {
		cardNumber.setError?.(ValidationCreditCard.insertCardNumber);
		GaEvent.ErrorMessage(ValidationCreditCard.insertCardNumber, title);
		return false;
	}

	if (/[^0-9-\s]+/.test(cardNumber.value)) {
		cardNumber.setError(ValidationCreditCard.invalidCardNumber);
		GaEvent.ErrorMessage(ValidationCreditCard.invalidCardNumber, title);
		return false;
	}
	const value = cardNumber.value.replace(/\D/g, '');
	
	if (/^(\d)\1*$/.test(value)) {
		cardNumber.setError(ValidationCreditCard.insertCardNumber);
		GaEvent.ErrorMessage(ValidationCreditCard.insertCardNumber, title);
		return false;
	}

	let nCheck = 0, nDigit = 0, bEven = false;

	for (let n = value.length - 1; n >= 0; n--) {
		let cDigit = value.charAt(n);
		nDigit = parseInt(cDigit, 10);

		if (bEven) {
			if ((nDigit *= 2) > 9) nDigit -= 9;
		}

		nCheck += nDigit;
		bEven = !bEven;
	}

	if (nCheck % 10 !== 0) {
		cardNumber.setError(ValidationCreditCard.invalidCardNumber);
		GaEvent.ErrorMessage(ValidationCreditCard.invalidCardNumber, title);
		return false;
	}

	return true;
};
