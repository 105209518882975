import { connect, ConnectedProps } from 'react-redux';
import { HandoffOperations } from '../../redux/Handoff.operations';
import { PreSelectRestaurant } from './PreSelectRestaurant';

const mapDispatchToProps = {
	setSelectedRestaurant: HandoffOperations.setSelectedRestaurant,
};

const connector = connect(null, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const PreSelectRestaurantConnected = connector(PreSelectRestaurant);
export { PreSelectRestaurantConnected as PreSelectRestaurant };
