import { FC, useRef } from 'react';
import { Button, Icon } from '@maverick/ui';
import * as Styled from './BlankOrderSummary.styled';
import { useRedirect } from '../../../../../shared/hooks';
import { Restaurant } from '@maverick/entity';
import { useTheme } from 'styled-components';
import { BlankOrderSummaryBackground } from '../../../../../shared/components/BlankOrderSummaryBackground';
import { IconClose } from '@maverick/icons/dist/IconClose';

export interface BlankOrderSummaryProps {
	closable?: boolean;
	onClose?: () => void;
	selectedRestaurant: Restaurant | null;
}

export const BlankOrderSummary: FC<BlankOrderSummaryProps> = ({ closable = false, onClose, selectedRestaurant }) => {
	const redirect = useRedirect();
	const orderSummary = useRef(null);
	const { orderUi } = useTheme();

	const startYourOrder = () => {
		redirect(`/menu/${selectedRestaurant?.slug}`);
		onClose?.();
	};

	return (
		<Styled.BlankContainer ref={orderSummary}>
			<Styled.BlankTitle>
				<Styled.Title id='blank-title' data-testid='blank-title'>
					{orderUi.texts.BlankOrderSummaryTitle}
				</Styled.Title>
				<Styled.Subtitle>{orderUi.texts.BlankOrderSummarySubtitle}</Styled.Subtitle>
				{closable && (
					<Styled.Close id='close-basket-button' aria-label='Close basket' onClick={onClose}>
						<Icon icon={IconClose} ariaLabel='close' testId='close-icon' />
					</Styled.Close>
				)}
			</Styled.BlankTitle>
			<Styled.Background>
				<BlankOrderSummaryBackground
					data-testid='blank-order-summary-background'
					src={orderUi.images.blankOrderSummaryBackground}
				/>
			</Styled.Background>
			<Styled.StartYourOrderButton>
				<Button id='start-order' text='Start Your Order' variant='primary' onClick={startYourOrder} />
			</Styled.StartYourOrderButton>
		</Styled.BlankContainer>
	);
};
