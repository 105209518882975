import styled, { css } from 'styled-components';
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from '@maverick/utils';

export const Container = styled.div<{ background: { mobile: string; desktop: string }; hasLink: boolean }>`
	cursor: default;
	${({ hasLink }) =>
		hasLink &&
		css`
			cursor: pointer;
		`}
	position: relative;
	/* @TODO */
	/* cursor: pointer;
	min-height: 200px;
	width: 100%;
	padding: 18px;
	border-radius: 16px;
	background: url(${({ background }) => background.mobile}) #ababab no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	align-items: center;
	overflow: hidden;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 24px 36px;
		background: url(${({ background }) => background.desktop}) #ababab no-repeat;
		background-position: center;
		background-size: cover;
	} */
`;

export const Main = styled.div`
	user-select: none;
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 16px;

	/* @TODO */
	/* position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		gap: 24px;
	}
`;

export const ButtonContainer = styled.div<{ alignment: 'left' | 'center' | 'right' }>`
	width: 100%;
	display: flex;
	justify-content: ${({ alignment }) => alignment};
`;

export const Button = styled.div`
	width: 100%;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		width: auto;
		min-width: 342px;
	}
`;

export const ImageDesktop = styled.img`
	border-radius: 16px;
	display: flex;
	align-items: center;
	display: none;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		display: block;
	}
`;

export const ImageMobile = styled.img`
	border-radius: 16px;
	display: flex;
	align-items: center;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		display: none;
	}
`;
