import { Icon } from '@maverick/ui';
import React, { FC } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './ChangeRestaurant.styled';
import { useSessionStorage } from '@maverick/hooks';
import { BasketDeliveryAddress, DeliveryInstructions, HandoffMethods } from '@maverick/entity';
import { IconLocationDelivery } from '@maverick/icons/dist/IconLocationDelivery';

interface ChangeRestaurantProps {
	method?: HandoffMethods;
	address?: string;
	label?: string;
	displayOnly?: boolean | false;
	displayLabel?: boolean;
	newLabelDelivery?: boolean | false;
	iconOptional?: JSX.Element;
	iconOptionalColor?: string;
	restaurantName?: string;
	deliveryAddress?: BasketDeliveryAddress | null;
	hasHandoffMethodAlert?: boolean;
	redirect?: () => void;
}

export const ChangeRestaurant: FC<ChangeRestaurantProps> = ({
	method,
	address,
	label = '',
	displayOnly,
	displayLabel = false,
	newLabelDelivery,
	iconOptionalColor,
	iconOptional,
	restaurantName,
	deliveryAddress,
	hasHandoffMethodAlert: handoffMethodAlert,
	redirect,
}) => {
	const { orderUi } = useTheme();
	const [deliveryInfo] = useSessionStorage('stored-delivery-info', new DeliveryInstructions());

	const getHandoffMethodIcon = () => {
		if (method === HandoffMethods.Curbside) {
			return orderUi.icons.curbside;
		}

		if (method === HandoffMethods.Pickup) {
			return orderUi.icons.pickup;
		}

		return orderUi.icons.delivery;
	};

	let icon: JSX.Element = <></>;

	let labelRestaurant: JSX.Element = <>{label}</>;
	if (label === '') {
		icon = getHandoffMethodIcon();
		if (!displayOnly || displayLabel) {
			switch (method) {
				case HandoffMethods.Curbside:
					label = 'Curbside at';
					labelRestaurant = (
						<>
							{label} <Styled.RestaurantName id='restaurantName'>{restaurantName}</Styled.RestaurantName>
						</>
					);
					break;

				case HandoffMethods.Pickup:
					label = 'Pickup at';
					labelRestaurant = (
						<>
							{label} <Styled.RestaurantName id='restaurantName'>{restaurantName}</Styled.RestaurantName>
						</>
					);
					break;

				case HandoffMethods.Delivery:
					label = 'Delivery from';
					const endLabel = `${!newLabelDelivery ? ' to' : ''}`;
					labelRestaurant = (
						<>
							{label} <Styled.RestaurantName id='restaurantName'>{restaurantName}</Styled.RestaurantName>
							{endLabel}
						</>
					);
					break;
				default:
					break;
			}
		}
	}

	let adrsContainer: JSX.Element;
	if (!newLabelDelivery || method !== HandoffMethods.Delivery) {
		adrsContainer = (
			<Styled.AddressContainer>
				<Styled.MethodIcon iconColor={iconOptionalColor ?? orderUi.colors.tertiary.dark}>
					<Icon icon={iconOptional ?? icon} customSize />
				</Styled.MethodIcon>
				<Styled.AddressContainerItem data-testid='addressContainerItem'>
					<Styled.Label id='method'>{labelRestaurant}</Styled.Label>
					<Styled.Address id='address'>{address}</Styled.Address>
				</Styled.AddressContainerItem>
				{!displayOnly && (
					<Styled.EditIcon onClick={redirect} aria-label='Edit address' data-testid='edit-address-button'>
						<Icon icon={orderUi.icons.iconEdit} ariaLabel='edit location' customSize />
					</Styled.EditIcon>
				)}
			</Styled.AddressContainer>
		);
	} else {
		let addressCity = deliveryAddress?.streetLabel;
		if (parseInt(deliveryAddress?.building!)) {
			addressCity += ` #${deliveryAddress?.building}`;
		} else {
			addressCity += ` ${deliveryAddress?.building ? ` - ${deliveryAddress?.building}` : ''}`;
		}

		adrsContainer = (
			<Styled.ContainerAddressIcon>
				<Styled.AddressContainer>
					<Styled.MethodIcon iconColor={orderUi.colors.tertiary.dark}>
						<Icon icon={iconOptional ?? icon} customSize />
					</Styled.MethodIcon>
					<Styled.Container data-testid='instructionContainer'>
						<Styled.Label id='method'>{labelRestaurant}</Styled.Label>
						<Styled.Address>
							Instructions: {deliveryInfo.instructions ? deliveryInfo.instructions : 'None'}
						</Styled.Address>
					</Styled.Container>
				</Styled.AddressContainer>
				<Styled.AddressContainer>
					<Styled.MethodIcon iconColor={iconOptionalColor ?? orderUi.colors.tertiary.dark} iconPadding='6px'>
						<Icon icon={IconLocationDelivery} customSize />
					</Styled.MethodIcon>
					<Styled.Container data-testid='addressContainer'>
						<Styled.Address data-testid='address'>{addressCity}</Styled.Address>
						<Styled.Address data-testid='zipCode'>{`${deliveryAddress?.city} ${deliveryAddress?.zipcode}`}</Styled.Address>
					</Styled.Container>
				</Styled.AddressContainer>
			</Styled.ContainerAddressIcon>
		);
	}

	return (
		<Styled.Container data-testid='changeRestaurant'>
			{adrsContainer}

			{handoffMethodAlert && method && (
				<Styled.HandoffMethodAlert data-testid='handoff-method-alert'>
					<Styled.HandoffMethodAlertIcon>
						<Icon icon={getHandoffMethodIcon()} customSize />
					</Styled.HandoffMethodAlertIcon>
					<Styled.HandoffMethodAlertText>
						Only {method} handoff method is available.
					</Styled.HandoffMethodAlertText>
				</Styled.HandoffMethodAlert>
			)}
		</Styled.Container>
	);
};
