import { FC } from 'react';
import * as Styled from './Tag.styled';
import { Icon } from '@maverick/ui';
import { IconAppTag } from '@maverick/icons/dist/IconAppTag';

import { ProductHighlightDisplay } from '../../enums';

type Tag = FeatureTag | ProductTag;

interface FeatureTag {
	variant: 'feature';
	text: string;
}

interface ProductTag {
	variant: 'product';
	type: string;
}

const ProductTag: FC<{ text: string; icon?: JSX.Element; variant: string }> = ({ text, icon, variant }) => {
	return (
		<Styled.TagProduct data-testid={`product-tag-${variant}`}>
			<Styled.Content>
				{icon && (
					<Styled.IconContent>
						<Icon icon={icon} />
					</Styled.IconContent>
				)}
				{text}
			</Styled.Content>
		</Styled.TagProduct>
	);
};

export const Tag: FC<Tag> = (props) => {
	if (props.variant === 'feature') {
		return <Styled.TagFeature data-testid='feature-tag'>{props.text}</Styled.TagFeature>;
	}

	const hasPropType = (displayTag: string): string => {
		if (props.type.includes(displayTag)) {
			return props.type;
		}
		return displayTag;
	};

	switch (props.type) {
		case hasPropType(ProductHighlightDisplay.New):
			return <ProductTag text='New!' variant={props.type} />;

		case hasPropType(ProductHighlightDisplay.LimitedTimeOffer):
			return <ProductTag text='Limited Time Offer' variant={props.type} />;

		case hasPropType(ProductHighlightDisplay.SeasonalSpecial):
			return <ProductTag text='Seasonal Special' variant={props.type} />;

		case hasPropType(ProductHighlightDisplay.AppOnly):
			return <ProductTag text='App Only' icon={IconAppTag} variant={props.type} />;

		default:
			return null;
	}
};
