import { FC, useEffect, useState, useRef } from 'react';
import * as Styled from './OrderSummary.styled';
import { ReduxProps } from './index';
import { useRedirect } from '../../../../shared/hooks';
import { CheckoutManager } from '../../../Checkout/Checkout.manager';
import { Button, Icon, SkeletonOrderSummary, useToast } from '@maverick/ui';
import { InfoIcon } from '@maverick/icons/dist/InfoIcon';
import { IconUnion } from '@maverick/icons/dist/IconUnion';
import { IconClearBasket } from '@maverick/icons/dist/IconClearBasket';
import { IconClose } from '@maverick/icons/dist/IconClose';
import { useForceRender } from '@maverick/hooks';
import {
	Basket,
	BasketProduct,
	Upsell,
	Item,
	UpsellItem,
	Product,
	UpsellGroup,
	Menu,
	AppliedReward,
} from '@maverick/entity';
import { Title } from '../../../../shared/components';
import { ProductSummary, SummaryFunctionsProps } from '../../../../shared/components/ProductSummary';
import { MenuManager } from '../../../Menu/Menu.manager';
import { OptionsCard } from '../../../../shared/components/OptionsCard';
import {
	addUpsellItems,
	addUpsellItemsError,
	deleteItem,
	deleteProductFromBasketError,
	ErrorConstants,
	duplicatedItem,
	retrieveBasketError,
	deleteAllProductFromBasketError,
	deleteItems,
} from '../../../../shared/constants';
import { BlankOrderSummary } from './BlankOrderSummary';
import { GaEvent, UtilsGetProductTag, UtilsIsAppOnly, UtilsMoneyFormatter } from '@maverick/utils';
import { Helmet } from 'react-helmet';
import { RewardsList } from '../../../../shared/components/RewardsList';
import { useAuth0 } from '@auth0/auth0-react';
import { OrderPaymentDetails } from '../../../../shared/components/OrderPaymentDetails';
import { calculateBasketValue, calculatePoints, validBasketDollarsPoints } from '../../../../utils/GeneralUtils';
import { useTheme } from 'styled-components';
import { WarningMessage } from '../../../../shared/components/WarningMessage';
import { Config } from '../../../../Config';
import { DRBanner } from '../../../../shared/components/DRBanner';
import { ProductSuggestion } from '../../../../shared/components/ProductSuggestion';
import { BannerHandoffStatus } from '../../../../shared/components/BannerHandoffStatus';

export interface OrderSummaryProps extends ReduxProps {
	onClose: () => void;
}

export const OrderSummary: FC<OrderSummaryProps> = ({
	basket,
	menu,
	userRewards,
	selectedRestaurant,
	giftCardState,
	loading,
	processing,
	skeletonPages,
	imagepath,
	setLoading,
	setProcessing,
	setBasket,
	onClose,
	setShowSummary,
	setAddCartOrigin,
	setRedirectRoute,
	setUserAuthLastInteraction,
}) => {
	const pageTitle = 'Order Summary';
	const redirect = useRedirect();
	const { setToast } = useToast();
	const forceRender = useForceRender();
	const { isAuthenticated, user, loginWithRedirect } = useAuth0();
	const { orderUi } = useTheme();
	const [points, setPoints] = useState<number>(0);
	const [addsOns, setAddsOns] = useState<Array<Upsell>>([]);
	const [free, setFree] = useState<Array<Upsell>>([]);
	const [upsellGroups, setUpsellGroups] = useState<Array<UpsellGroup>>([]);
	const [productList, setProductList] = useState<
		Array<{ product: Upsell; productTag: string | null; category: string; brand?: string }>
	>([]);
	const [updatedBasketValue, setUpdatedBasketValue] = useState<number>(() => {
		if (basket && basket.tip) {
			const calculatedBasketValue = calculateBasketValue(basket, giftCardState) - basket.tip;
			return calculatedBasketValue >= 0 ? calculatedBasketValue : 0;
		}
		return 0;
	});
	const [showClearBasketContainer, setShowClearBasketContainer] = useState<boolean>(false);

	const helmetState = Helmet.peek();

	const orderSummary = useRef(null);
	const closeButtonRef = useRef<HTMLButtonElement>(null);
	const authUrl = process.env.AUTH0_DOMAIN ?? '';

	useEffect(() => {
		if (basket && menu) {
			setPoints(calculatePoints(basket, menu));
		}
	}, [basket, menu, giftCardState, basket?.discounts]);

	useEffect(() => {
		closeButtonRef.current?.focus();
		if (basket && menu) {
			GaEvent.ViewCart(basket, menu.categories, selectedRestaurant?.brand ?? 'not-set');
		}
	}, []);

	useEffect(() => {
		if (basket?.products.length && menu) {
			retrieveUpsells();
			setPoints(calculatePoints(basket, menu));
			if (isAuthenticated) retrieveProductsSuggestion(remainValueToReward());
		}
	}, [basket]);

	function updateImages(items: Upsell[], menu: Menu | null) {
		items?.forEach((i) => {
			menu?.categories.forEach((c) => {
				const image = c.products
					.find((p) => p.id === i.id)
					?.images?.find((i) => i.groupname === 'mobile-webapp-menu')?.filename;
				if (!!image) {
					i.image = `${menu.imagepath}${image}`;
				}
			});
		});
	}

	useEffect(() => {
		updateImages(addsOns, menu);
		forceRender();
	}, [addsOns]);

	useEffect(() => {
		updateImages(free, menu);
		forceRender();
	}, [free]);

	useEffect(() => {
		if (basket) {
			const calculatedBasketValue = calculateBasketValue(basket, giftCardState) - basket.tip;
			setUpdatedBasketValue(calculatedBasketValue >= 0 ? calculatedBasketValue : 0);
		}
	}, [basket, giftCardState, basket?.tip]);

	const editProduct = (_: Basket, basketProduct: BasketProduct) => {
		redirect(`menu/${selectedRestaurant?.slug}/products/${basketProduct.id}/edit`);
		onClose();
	};

	const retrieveUpsells = async () => {
		if (!basket) {
			return;
		}
		setProcessing(true);
		const { groups, error } = await CheckoutManager.GetUpsells(basket.id);
		setProcessing(false);
		setLoading(false);
		if (!isAuthenticated || !user) {
			setProcessing(false);
			setLoading(false);
		}
		if (error || !groups) {
			const errorMessage = error ?? ErrorConstants.errorGettingUpsells;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage, helmetState.title);
			return;
		}

		const freeList: Array<Upsell> = [];
		const addsOnList: Array<Upsell> = [];

		groups?.forEach((g) => {
			g.items.forEach((i) => {
				if (i.cost !== '$0.00') {
					addsOnList.push(i);
				} else {
					freeList.push(i);
				}
			});
		});

		setUpsellGroups(groups);
		setFree(freeList);
		setAddsOns(addsOnList);
	};

	const addUpsells = async (upsell: Upsell) => {
		if (!basket) {
			return;
		}
		setProcessing(true);
		const item = new Item();
		item.items = [];
		let upsellItem = new UpsellItem();
		upsellItem.id = upsell.id;
		upsellItem.quantity = 1;
		item.items.push(upsellItem);

		const { response, error } = await CheckoutManager.AddUpsellItems(basket.id, item);
		setProcessing(false);
		setLoading(false);
		if (error || !response) {
			const errorMessage = error ?? addUpsellItemsError;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage, helmetState.title);
			return;
		}

		setToast({
			variant: 'success',
			text: addUpsellItems,
		});

		const product = new Product({
			name: upsell.name,
			id: upsell.id,
			cost: +upsell.cost.replace('$', ''),
		});

		const productCategory = upsellGroups.find((ug) => ug.items.find((i) => i.id === upsell.id))?.title;

		GaEvent.AddToCart(
			product,
			1,
			selectedRestaurant!.id,
			selectedRestaurant!.brand,
			productCategory!,
			+upsell.cost.replace('$', ''),
			'upsell',
			false
		);

		setBasket(response);
	};

	const isCloseToReward = (): boolean => {
		if (basket && menu && userRewards) {
			return userRewards.pointsUntilReward - (calculatePoints(basket, menu) % 350) <= 50;
		}
		return false;
	};

	const remainValueToReward = (): number => {
		if (isCloseToReward() && basket && menu && userRewards) {
			const result = userRewards.pointsUntilReward / 5 - (validBasketDollarsPoints(basket, menu) % 70);
			return Number(result.toFixed(2));
		}
		return 0;
	};

	const rewardCard = (): number => {
		return userRewards ? (Math.floor((points + (userRewards.pointsCount ?? 0)) / 350) + 1) * 5 : 0;
	};

	const retrieveProductsSuggestion = async (value: number) => {
		const prodList = Array<{ product: Upsell; category: string; productTag: string | null; brand?: string }>();
		menu?.categories.map((category) =>
			category.products.map((product) => {
				if (
					prodList.length < 3 &&
					product.cost >= value &&
					product.cost <= value + 3 &&
					product.cost != 0 &&
					!UtilsIsAppOnly(menu, product.id)
				) {
					const image = product.images?.find((i) => i.groupname === 'mobile-webapp-menu')?.filename;
					const imagePath = image ? `${menu.imagepath}${image}` : `${orderUi.images.productPlaceholder}`;

					const upsell: Upsell = {
						cost: `$${product.cost.toFixed(2)}`,
						id: product.id,
						image: imagePath,
						name: product.name,
						maxquantity: 0,
						minquantity: 0,
						shortdescription: product.description,
					};
					prodList.push({
						product: upsell,
						productTag: UtilsGetProductTag(product),
						category: category.name,
						brand: selectedRestaurant?.brand,
					});
				}
			})
		);
		setProductList(prodList);
	};

	const onSelectProduct = (productId: number) => {
		setAddCartOrigin('order summary');
		redirect(`/menu/${selectedRestaurant!.slug}/products/${productId}`);
		onClose();
	};

	const removeProduct = async (basket: Basket, product: BasketProduct) => {
		const productId = product.id;
		const basketId = basket.id;
		setProcessing(true);
		const { response, error } = await CheckoutManager.DeleteProductFromBasket(basketId, productId);

		if (error || !response) {
			const errorMessage = error ?? deleteProductFromBasketError;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage, helmetState.title);
			setProcessing(false);
			return;
		}
		setBasket(response);

		setToast({
			variant: 'success',
			text: error ?? deleteItem,
		});

		const category = menu?.categories.find((c) => c.products.find((p) => p.id === product.productId));
		const categoryName = category?.name;
		const productTag = UtilsGetProductTag(category?.products.find((p) => p.id === product.productId));

		GaEvent.RemoveFromCart(
			product,
			productTag,
			1,
			selectedRestaurant!.id,
			selectedRestaurant!.brand,
			categoryName!
		);
		if (basket.products.length === 1) {
			setBasket(null);
			setShowSummary(false);
		}
		setProcessing(false);
		setLoading(false);
	};

	const cleanBasket = async (basket: Basket) => {
		setProcessing(true);
		const basketId = basket.id;
		const products = basket.products;
		const productsId = basket.products.map((product) => product.id);

		const { response, error } = await CheckoutManager.DeleteAllProductFromBasket(basketId, productsId);

		if (response) {
			setBasket(response);
		}

		if (error) {
			const errorMessage = deleteAllProductFromBasketError;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage, helmetState.title);
		} else {
			setToast({
				variant: 'success',
				text: deleteItems,
			});
		}

		products
			.filter((product) => !response?.products.includes(product))
			.forEach((product) => {
				const category = menu?.categories.find((c) => c.products.find((p) => p.id === product.productId));

				const categoryName = category?.name;
				const productTag = UtilsGetProductTag(category?.products.find((p) => p.id === product.productId));

				GaEvent.RemoveFromCart(
					product,
					productTag,
					1,
					selectedRestaurant!.id,
					selectedRestaurant!.brand,
					categoryName!
				);
			});

		setShowClearBasketContainer(false);
		setBasket(null);
		setShowSummary(false);
		setProcessing(false);
		setLoading(false);
	};

	const duplicateProduct = async (basket: Basket, product: BasketProduct) => {
		const optionsList: Array<string> = [];
		product.choices.forEach((choice) => {
			!!choice.optionid && optionsList.push(choice.optionid.toString());
		});
		setProcessing(true);

		const { response, error } = await MenuManager.AddSingleProductToBasket(
			basket.id,
			product.productId,
			product.quantity,
			optionsList,
			product.specialinstructions,
			product.recipient
		);

		if (error || !response) {
			const errorMessage = error ?? ErrorConstants.errorAddingProduct;
			setToast({
				variant: 'error',
				text: errorMessage,
			});
			GaEvent.ErrorMessage(errorMessage, helmetState.title);
			setProcessing(false);
			setLoading(false);
			return;
		}

		const basketProduct = new Product({
			name: product.name,
			id: product.productId,
			cost: product.basecost,
		});

		const productCategory = menu?.categories.find((c) => c.products.find((p) => p.id === product.productId))?.name;

		GaEvent.AddToCart(
			basketProduct,
			1,
			selectedRestaurant!.id,
			selectedRestaurant!.brand,
			productCategory!,
			product.totalcost,
			'cart page',
			product.choices.some((choice) => {
				return choice.cost > 0;
			})
		);

		setBasket(response);

		setToast({
			variant: 'success',
			text: error ?? duplicatedItem,
		});

		setProcessing(false);
		setLoading(false);
	};

	const redirectToMenu = () => {
		redirect(`/menu/${selectedRestaurant?.slug}`);
		onClose();
	};

	const handleOnClick = async () => {
		redirect('/checkout');
		onClose();
	};

	const handleOnClickSignIn = async () => {
		GaEvent.InternalLinkClick(authUrl + '/login', 'proceed_checkout_sign_in');
		setUserAuthLastInteraction('order-summary-button');
		setRedirectRoute('/checkout');
		setShowSummary(false);
		loginWithRedirect({ source: Config.Source, prompt: 'login' });
	};

	const removeReward = async (appliedReward: AppliedReward) => {
		if (!basket || !appliedReward) return;
		await CheckoutManager.RemoveRewards(basket.id, appliedReward.rewardid);
	};

	const validateAndUpdateBasket = async (appliedReward?: AppliedReward) => {
		if (!basket) return false;

		const validationResponse = await CheckoutManager.ValidateBasket(basket.id);

		if (validationResponse.error) {
			if (appliedReward) {
				removeReward(appliedReward);
			}

			setToast({
				variant: 'error',
				text: validationResponse.error.message,
			});

			GaEvent.ErrorMessage(validationResponse.error.message, pageTitle);
			setProcessing(false);
			setLoading(false);
			return false;
		}

		const { retrieveBasket, error } = await CheckoutManager.GetBasket(basket.id);
		if (!retrieveBasket || error) {
			setToast({
				variant: 'error',
				text: retrieveBasketError,
			});

			GaEvent.ErrorMessage(retrieveBasketError, pageTitle);
			setProcessing(false);
			setLoading(false);
			return false;
		}
		retrieveBasket.customfields = basket.customfields;
		setBasket(retrieveBasket);
		return true;
	};

	const summaryFunctions: Array<SummaryFunctionsProps> = [
		{ label: 'Edit', function: editProduct },
		{ label: 'Remove', function: removeProduct },
		{ label: 'Duplicate', function: duplicateProduct },
	];

	if (loading && skeletonPages?.active) {
		return <SkeletonOrderSummary />;
	}

	if (!!basket && basket.products.length > 0) {
		return (
			<Styled.Container ref={orderSummary} role='dialog' aria-modal='true'>
				<Styled.Body>
					<Styled.ComponentTitle>
						<Title main='Order summary' />
						<Styled.Close
							ref={closeButtonRef}
							id='close-basket-button'
							aria-label='Close basket'
							onClick={onClose}
						>
							<Icon icon={IconClose} ariaLabel='close' testId='close-icon' />
						</Styled.Close>
					</Styled.ComponentTitle>
					<Styled.Summary>
						<BannerHandoffStatus variant='summary' />
						<Styled.SummaryTitle>Items</Styled.SummaryTitle>
						<Styled.SummaryProducts aria-label='Items' role='list'>
							{basket.products.map((product, index) => (
								<ProductSummary
									product={product}
									basket={basket}
									key={product.id}
									summaryFunctions={summaryFunctions}
									basketIndex={index}
								/>
							))}
						</Styled.SummaryProducts>
						<Styled.BasketOptions>
							<hr />
							<Styled.Options>
								<Button
									icon={IconClearBasket}
									text='Clear Basket'
									variant='terciary'
									onClick={() => setShowClearBasketContainer(true)}
									id={'clear-basket'}
								/>
								<Button
									icon={IconUnion}
									text='Add more items'
									variant='terciary'
									onClick={redirectToMenu}
									id={'add-more-items'}
								/>
							</Styled.Options>
							{showClearBasketContainer && (
								<Styled.ClearBasketContainer>
									Are you sure you want to remove all items?
									<Styled.ClearBasketOptions>
										<Button
											text='Remove'
											variant='terciary'
											onClick={() => cleanBasket(basket)}
											id={'remove-all-itens'}
										/>
										<Button
											text='Cancel'
											variant='terciary'
											onClick={() => setShowClearBasketContainer(false)}
											id={'cancel-clear-basket'}
										/>
									</Styled.ClearBasketOptions>
								</Styled.ClearBasketContainer>
							)}
							<hr />
						</Styled.BasketOptions>
					</Styled.Summary>

					{isAuthenticated ? <RewardsList onRedeemReward={validateAndUpdateBasket} /> : <DRBanner />}

					{(!!addsOns.length || !!free.length) && (
						<>
							<Styled.Section>
								<Styled.Header>
									<Styled.SubtitleSection
										data-testid='complete-your-meal'
										id='complete-your-meal-title'
									>
										{orderUi.texts.OrderSummarySuggestion}
									</Styled.SubtitleSection>
									<Styled.Optional>optional</Styled.Optional>
								</Styled.Header>

								{isAuthenticated && productList.length > 0 && isCloseToReward() && (
									<ProductSuggestion
										remainingValue={remainValueToReward() ?? 0}
										rewardsValue={rewardCard()}
										productList={productList}
										onClick={onSelectProduct}
									/>
								)}

								{!!addsOns.length && (
									<>
										<Styled.Subtitle>{orderUi.texts.AddOnsSubtitle}</Styled.Subtitle>
										<Styled.OptionCard role='list' aria-label='add ons'>
											{addsOns.map((i) => {
												return (
													<OptionsCard
														upsell={i}
														selected={false}
														key={i.id}
														onClick={() => addUpsells(i)}
														testId={i.name.replace(/\s/g, '-') + '_' + i.id}
														imageBasePath={imagepath}
													/>
												);
											})}
										</Styled.OptionCard>
									</>
								)}

								{!!free.length && (
									<>
										<Styled.Subtitle>{orderUi.texts.FreeSubtitle}</Styled.Subtitle>
										<Styled.OptionCard role='list' aria-label='free'>
											{free.map((i) => {
												return (
													<OptionsCard
														upsell={i}
														selected={false}
														key={i.id}
														onClick={() => addUpsells(i)}
														testId={i.id.toString()}
														imageBasePath={imagepath}
													/>
												);
											})}
										</Styled.OptionCard>
									</>
								)}
							</Styled.Section>

							{!!orderUi.texts.AddOnsDescription ? (
								<Styled.UtensilsInfo>
									<Styled.Icon>
										<Icon icon={InfoIcon} customSize />
									</Styled.Icon>
									<div>{orderUi.texts.AddOnsDescription}</div>
								</Styled.UtensilsInfo>
							) : (
								<Styled.EmptyUtensilsInfo />
							)}
						</>
					)}
					<OrderPaymentDetails onRemove={validateAndUpdateBasket} title={'Details'} hideTip isEstimated />
					{!isAuthenticated && points > 0 && (
						<WarningMessage message={points.toString()} variant='points' displayStyle='pointsEarn' />
					)}
				</Styled.Body>

				{isAuthenticated ? (
					<Styled.Button id='continue-button-wrapper'>
						<Button
							text={'Continue - ' + UtilsMoneyFormatter.format(updatedBasketValue)}
							variant='primary'
							id='continue'
							onClick={handleOnClick}
							loading={processing}
						/>
					</Styled.Button>
				) : (
					<Styled.NotLoggedUserContainer id='guest-continue-buttons-wrapper'>
						<Button
							text={'Continue as guest'}
							variant='secondary'
							id='continue-as-guest'
							padding='8px 18px'
							onClick={handleOnClick}
							loading={processing}
						/>
						<Button
							text={'Sign in & Continue'}
							variant='primary'
							id='sign-in-and-continue'
							onClick={handleOnClickSignIn}
							padding='8px 18px'
							loading={processing}
						/>
					</Styled.NotLoggedUserContainer>
				)}
			</Styled.Container>
		);
	}

	return (
		<BlankOrderSummary
			data-testid='blank-order-summary'
			onClose={onClose}
			selectedRestaurant={selectedRestaurant}
			closable
		/>
	);
};
