import { ContentService } from '../../services/sitecore/Content.service';
import { PromotionalBannerData } from '@maverick/entity';

export const ContentManager = {
	GetPromotionalBanners: async (): Promise<
		Partial<{ promotionalBanners: Array<{ name: string; value: string }>; error: string }>
	> => {
		const response = await ContentService.GetPromotionalBanners();

		if (response.error) {
			return { error: response.errorMessage };
		}

		return { promotionalBanners: response?.body?.item?.fields };
	},

	GetPromotionalBannerData: async (
		bannerId: string
	): Promise<Partial<{ bannerData: PromotionalBannerData; error: string }>> => {
		const response = await ContentService.GetPromotionalBannerData(bannerId);

		if (response.error) {
			return { error: response.errorMessage };
		}

		return { bannerData: response?.body?.item };
	},
} as const;
