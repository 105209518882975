import { FC, useEffect, createRef, useRef } from 'react';
import * as Styled from './RestaurantNavbar.styled';
import { Pill } from '../CategoryPill';
import { Category } from '@maverick/entity';
import { ArrowButton } from '../ArrowButton';
import { useIsOnScreen } from '@maverick/hooks';

export interface RestaurantNavbarProps {
	categories: Array<Category>;
	onClickPill: (id: number) => void;
	highlightedPill: string;
}

export const RestaurantNavbar: FC<RestaurantNavbarProps> = ({ categories, onClickPill, highlightedPill }) => {
	const pillsContainerRef = useRef<HTMLDivElement>(null);

	const scrollPillsContainer = (scrollBy: number) => {
		pillsContainerRef.current?.scrollBy({ left: scrollBy, behavior: 'smooth' });
	};

	useEffect(() => {
		centerHighlightedPill(highlightedPill);
	}, [highlightedPill]);

	const initialRef = categories.map(() => createRef<HTMLAnchorElement>());
	const pillsRef = useRef(initialRef);

	const centerHighlightedPill = (id: string) => {
		const pill = pillsRef.current.find((pill) => pill.current?.id.split('-')[1] === id);

		if (pill?.current && pillsContainerRef.current) {
			pillsContainerRef.current.scrollTo({
				left: pill.current.offsetLeft - window.innerWidth / 2 + pill.current.getBoundingClientRect().width / 2,
				behavior: 'smooth',
			});
		}
	};

	const isFirstPillOnScreen = useIsOnScreen(pillsRef.current[0]);
	const isLastPillOnScreen = useIsOnScreen(pillsRef.current[pillsRef.current.length - 1]);

	const hideArrows = isFirstPillOnScreen && isLastPillOnScreen;

	return (
		<>
			<Styled.Container hideArrows={hideArrows}>
				{hideArrows ? null : (
					<Styled.ArrowContainer>
						<ArrowButton
							onClick={() => scrollPillsContainer(-400)}
							id='left-arrow-button'
							disabled={isFirstPillOnScreen}
						/>
					</Styled.ArrowContainer>
				)}
				<Styled.PillsContainer
					role='menubar'
					ref={pillsContainerRef}
					id='pills-container'
					data-testid='pills-container'
				>
					{categories.map(({ id, name, metadata }, i) => (
						<Pill
							text={name}
							key={id}
							id={i===0?'main-content': `pill-${id}`}
							onClick={() => onClickPill(id)}
							selected={id === +highlightedPill}
							pillRef={pillsRef?.current[i]}
							metadata={metadata}
						/>
					))}
				</Styled.PillsContainer>
				{hideArrows ? null : (
					<Styled.ArrowContainer isRight>
						<ArrowButton
							isRight
							onClick={() => scrollPillsContainer(400)}
							id='right-arrow-button'
							disabled={isLastPillOnScreen}
						/>
					</Styled.ArrowContainer>
				)}
			</Styled.Container>
		</>
	);
};
