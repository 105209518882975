import { CalendarTypes, Restaurant } from '@maverick/entity';
import { handleHours } from '../../../utils/RestaurantHoursUtils';
import HourDisplay from './component/HourDisplay';

interface HandoffHoursProps {
	restaurant: Restaurant;
}

export const HandoffHours: React.FC<HandoffHoursProps> = ({ restaurant }) => {
	const restaurantHours = handleHours(restaurant);

	if (restaurantHours === 'Unavailable') {
		return (
			<>
				<HourDisplay
					calendar={{ type: CalendarTypes.Curbside, range: 'Unavailable', tag: 'Unavailable' }}
					index={0}
				/>
				<HourDisplay
					calendar={{ type: CalendarTypes.Delivery, range: 'Unavailable', tag: 'Unavailable' }}
					index={1}
				/>
				<HourDisplay
					calendar={{ type: CalendarTypes.Business, range: 'Unavailable', tag: 'Unavailable' }}
					index={2}
				/>
			</>
		);
	}

	return (
		<>
			{restaurantHours.map((calendar, index) => (
				<HourDisplay key={`${calendar.type}-${index}`} calendar={calendar} index={index} />
			))}
		</>
	);
};
