export enum BillingMethods {
	CreditCard = 'creditcard',
	CreditCardOnFile = 'creditcardonfile',
	CreditCardToken = 'creditcardtoken',
	BillingAccount = 'billingaccount',
	Cash = 'cash',
	PayInStore = 'payinstore',
	StoredValue = 'storedvalue',
	PrePaid = 'prepaid',
	DigitalWallet = 'digitalwallet',
}
