import { RootState } from '../../../infrastructure';
import { connect, ConnectedProps } from 'react-redux';
import { DineRewardsBanner } from './DineRewardsBanner';
import { AppOperations } from '../../../features/App/redux/App.operations';

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = { setRedirectRoute: AppOperations.setRedirectRoute };

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const DineRewardsBannerConnected = connector(DineRewardsBanner);
export { DineRewardsBannerConnected as DineRewardsBanner };
