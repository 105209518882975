import { FC, MutableRefObject, RefObject } from 'react';
import * as Styled from './RestaurantProducts.styled';
import { Category, Product } from '@maverick/entity';
import { CategoryHeader } from '../CategoryHeader';
import { ProductCard } from '../ProductCard';

export interface RestaurantProductsProps {
	categories: Array<Category>;
	imagepath: string;
	onSelectProduct: (product: Product, name: string) => void;
	categoriesRef?: MutableRefObject<RefObject<HTMLDivElement>[]>;
}

export const RestaurantProducts: FC<RestaurantProductsProps> = ({
	categories,
	imagepath,
	onSelectProduct,
	categoriesRef,
}) => {
	return (
		<Styled.Container>
			{categories.map((category, i) => (
				<Styled.CategorySection
					id={category.id.toString()}
					key={category.id}
					role='region'
					aria-label={category.name}
					data-testid={`category-section-${category.name}`}
					ref={categoriesRef?.current[i]}
				>
					<CategoryHeader id={category.id} text={category.name} />
					<Styled.ProductsCardContainer>
						{category.products.map((product) => (
							<ProductCard
								key={product.id}
								product={product}
								onClick={() => onSelectProduct(product, category.name)}
								imageBasePath={imagepath}
							/>
						))}
					</Styled.ProductsCardContainer>
				</Styled.CategorySection>
			))}
		</Styled.Container>
	);
};
