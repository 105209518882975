import { FC, useEffect, useState } from 'react';
import * as Styled from './RecentAndFaves.styled';
import { FaveCustomProduct, FaveOrder, RecentOrder } from '@maverick/entity';
import { Button, useModal, useToast } from '@maverick/ui';
import { PlusIconOutlined } from '@maverick/icons/dist/PlusIconOutlined';
import { CategoryHistoryItem, CategoryHistoryOptions } from './RecentAndFaves';
import { useRedirect } from '../../../../shared/hooks';
import { basketWithItemsReorderFave, basketWithItemsReorderRecentOrder } from '../../../../shared/constants';
import { handleTagNew, isDesktop } from '../../../../utils/GeneralUtils';
import { ReduxProps } from '.';
import { createNewBasketFromOrder } from '../../../User/views/RecentOrders/RecentOrderUtil';
import { createNewBasketFromFave, createNewBasketFromFaveProduct } from '../../../User/views/Faves/FavesUtil';
import { GaEvent, formatDate } from '@maverick/utils';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface ItemInfo {
	id: number;
	label: string;
	quantity: number;
	price?: number;
	description: string;
	image1: string;
	image2: string;
}

const gaErrorMessage = '[RecentOrdersAndFaves]';

export interface RecentAndFavesCardProps extends ReduxProps {
	item?: FaveOrder | RecentOrder | FaveCustomProduct;
	categorySelected: CategoryHistoryItem | null;
}

export const RecentAndFavesCard: FC<RecentAndFavesCardProps> = ({
	item,
	categorySelected,
	categories,
	restaurantId,
	imageBasePath,
	authToken,
	basket,
	selectedRestaurant,
	highlightFeature,
	setSelectedRestaurant,
	setSelectedRestaurantAddress,
	setSelectedMethod,
	setDeliveryAddress,
	setLoading,
	setBasket,
	setShowSummary,
	setRedirectRoute,
}) => {
	const pageTitle = 'RecentAndFaves';
	const location = useLocation();
	const redirect = useRedirect();
	const { setToast } = useToast();
	const { setModal } = useModal();
	const { orderUi } = useTheme();
	const imagePlaceholder = orderUi.images.productPlaceholder;

	const [displayInfo, setDisplayInfo] = useState<ItemInfo | null>(null);
	const shouldShowSecondImage =
		(displayInfo?.image1 !== imagePlaceholder && displayInfo?.image2 !== imagePlaceholder) ||
		(displayInfo?.image1 === imagePlaceholder && displayInfo?.image2 === imagePlaceholder);

	const getFavoriteCost = (item: FaveOrder) => {
		let orderPrice = 0;
		if (categorySelected?.key === CategoryHistoryOptions.Favorites && item.vendorid === restaurantId) {
			item?.products.forEach((product) => {
				categories?.forEach((category) => {
					return category.products.find((element) => {
						orderPrice += element.id === product.productid ? element.cost : 0;
					});
				});
			});
		}
		return orderPrice;
	};

	const getFavoriteProductCost = (item: FaveCustomProduct) => {
		let orderPrice = 0;
		if (categorySelected?.key === CategoryHistoryOptions.Favorites) {
			categories?.some((category) => {
				const foundProduct = category.products.find((product) => {
					return product.chainproductid == item.product.chainproductid;
				});
				if (foundProduct) {
					orderPrice += foundProduct?.cost ? foundProduct.cost : 0;
					return true;
				}
				return false;
			});
		}
		return orderPrice;
	};

	const getRecentOrderCost = (item: RecentOrder) => {
		let orderPrice = 0;
		if (categorySelected?.key === CategoryHistoryOptions.RecentOrders && item.vendorid === restaurantId) {
			item?.products.forEach((product) => {
				let productCosts = new Array<number>();
				categories?.forEach((category) => {
					return category.products.find((element) => {
						if (element.name === product.name) productCosts.push(element.cost);
					});
				});
				if (productCosts.length > 1) {
					orderPrice += getNearCostValue(productCosts, product.totalcost);
				} else {
					orderPrice += productCosts.length === 1 ? productCosts[0] : 0;
				}
			});
		}
		return orderPrice;
	};

	const getNearCostValue = (costs: Array<number>, goal: number) => {
		var closest = costs.reduce(function (prev, curr) {
			return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
		});

		return closest;
	};

	const getFavoriteProductsImages = (item: FaveOrder) => {
		let images: Array<string> = [imagePlaceholder, imagePlaceholder];
		let higherProductPrice = 0;
		let secondHigherProductPrice = 0;

		if (categorySelected?.key === CategoryHistoryOptions.Favorites && item.vendorid === restaurantId) {
			item?.products.forEach((product) => {
				categories?.forEach((category) => {
					category.products.forEach((element) => {
						if (element.id === product.productid) {
							if (element.cost > higherProductPrice) {
								higherProductPrice = element.cost;
								const image = element.images?.find(
									(image) => image.groupname === 'mobile-webapp-customize'
								)?.filename;
								images[0] = imageBasePath! + image;
							} else if (element.cost > secondHigherProductPrice) {
								secondHigherProductPrice = element.cost;
								const image = element.images?.find(
									(image) => image.groupname === 'mobile-webapp-customize'
								)?.filename;
								images[1] = imageBasePath! + image;
							}
						}
					});
				});
			});
		}
		return images;
	};

	const getImageFavoriteProduct = (item: FaveCustomProduct) => {
		let images: Array<string> = [imagePlaceholder, imagePlaceholder];

		if (categorySelected?.key === CategoryHistoryOptions.Favorites && item) {
			categories?.some((category) => {
				const foundProduct = category.products.find((product) => {
					return product.chainproductid == item.product.chainproductid;
				});
				if (foundProduct) {
					foundProduct?.images?.forEach((image) => {
						if (image.groupname === 'mobile-webapp-customize') {
							images[0] = imageBasePath! + image.filename;
						}
					});
					return true;
				}
				return false;
			});
		}

		return images;
	};

	const getRecentProductsImages = (item: RecentOrder) => {
		let images: Array<string> = [imagePlaceholder, imagePlaceholder];
		let higherProductPrice = 0;
		let secondHigherProductPrice = 0;

		if (categorySelected?.key === CategoryHistoryOptions.RecentOrders && item.vendorid === restaurantId) {
			item?.products.forEach((product) => {
				categories?.forEach((category) => {
					category.products.forEach((element) => {
						if (element.name === product.name) {
							if (element.cost > higherProductPrice) {
								higherProductPrice = element.cost;
								const image = element.images?.find(
									(image) => image.groupname === 'mobile-webapp-customize'
								)?.filename;
								images[0] = imageBasePath! + image;
							} else if (element.cost > secondHigherProductPrice) {
								secondHigherProductPrice = element.cost;
								const image = element.images?.find(
									(image) => image.groupname === 'mobile-webapp-customize'
								)?.filename;
								images[1] = imageBasePath! + image;
							}
						}
					});
				});
			});
		}
		return images;
	};

	useEffect(() => {
		if (categorySelected?.key === CategoryHistoryOptions.Favorites && item) {
			if ('products' in item) {
				const favorite = item as FaveOrder;
				const images = getFavoriteProductsImages(favorite);
				setDisplayInfo({
					id: favorite.id,
					label: favorite.name,
					quantity: favorite.products.length,
					price: getFavoriteCost(favorite),
					description: favorite.products.map((product) => product.name).join(', '),
					image1: images[0],
					image2: images[1],
				});
			} else {
				const images = getImageFavoriteProduct(item);
				setDisplayInfo({
					id: item.id,
					label: item.name,
					quantity: item.product.quantity,
					price: getFavoriteProductCost(item),
					description: item.product.choices?.map((choice) => choice.name).join(', '),
					image1: images[0],
					image2: images[1],
				});
			}
		}

		if (categorySelected?.key === CategoryHistoryOptions.RecentOrders) {
			const recentOrder = item as RecentOrder;
			const images = getRecentProductsImages(recentOrder);

			setDisplayInfo({
				id: Number(recentOrder.id),
				label: formatDate(recentOrder.timeplaced || '', 'M/d/yy'),
				quantity: recentOrder.products.length,
				price: getRecentOrderCost(recentOrder),
				description: recentOrder.products
					.map((product) => {
						return product.name;
					})
					.join(', '),
				image1: images[0],
				image2: images[1],
			});
		}
	}, [item]);

	const onClickShowDetails = () => {
		setRedirectRoute(location.pathname);
		if (categorySelected?.key === CategoryHistoryOptions.Favorites) {
			GaEvent.FavoritesShowDetailsButtonClick();
			redirect('/faves');
		} else if (categorySelected?.key === CategoryHistoryOptions.RecentOrders) {
			GaEvent.RecentOrdersShowDetailsButtonClick();
			redirect('/recent-orders');
		}
	};

	const showCreateOrderModal = () => {
		const modalText =
			categorySelected?.key === CategoryHistoryOptions.Favorites
				? basketWithItemsReorderFave
				: basketWithItemsReorderRecentOrder;
		setModal({
			text: modalText,
			primaryButton: { label: 'Continue', onClick: () => orderAgain() },
			secondaryButton: { label: 'Cancel' },
			id: 'create-order-modal',
		});
	};

	const onClickOrderAgain = () => {
		if (basket?.products && basket?.products.length > 0 && item) {
			if ('products' in item) {
				showCreateOrderModal();
			} else {
				orderAgain();
			}
		} else {
			orderAgain();
		}
	};

	const orderAgain = () => {
		const newFeature = handleTagNew(highlightFeature);
		if (categorySelected?.key === CategoryHistoryOptions.Favorites) {
			GaEvent.FavoritesOrderAgainButtonClick();
			if (newFeature) {
				GaEvent.OrderAgainHighlightButtonClick('favorites');
			}

			if (categorySelected?.key === CategoryHistoryOptions.Favorites && item) {
				if (item && 'products' in item) {
					createNewBasketFromFave(
						pageTitle,
						authToken!,
						selectedRestaurant,
						item as FaveOrder,
						basket,
						setToast,
						setModal,
						setLoading,
						setBasket,
						setShowSummary,
						redirect,
						gaErrorMessage
					);
				} else {
					createNewBasketFromFaveProduct(
						authToken,
						item,
						basket,
						selectedRestaurant,
						setModal,
						setToast,
						setLoading,
						setBasket,
						setShowSummary,
						redirect
					);
				}
			}
		}
		if (categorySelected?.key === CategoryHistoryOptions.RecentOrders) {
			GaEvent.RecentOrdersOrderAgainButtonClick();
			if (newFeature) {
				GaEvent.OrderAgainHighlightButtonClick('recent orders');
			}
			createNewBasketFromOrder(
				pageTitle,
				authToken!,
				item as RecentOrder,
				setSelectedRestaurant,
				setSelectedRestaurantAddress,
				setSelectedMethod,
				setDeliveryAddress,
				setToast,
				setLoading,
				setBasket,
				setShowSummary,
				redirect,
				gaErrorMessage
			);
		}
	};

	if (!item || !displayInfo) {
		return null;
	}

	return (
		<Styled.CardContainer>
			<div>
				<Styled.Container>
					<Styled.Card
						tabIndex={0}
						selected={false}
						id={`recent-and-faves-${item.id}`}
						data-testid={`recent-and-faves-${item.id}`}
					>
						<Styled.Section>
							<Styled.TextHeader>
								<Styled.CardLabel title={displayInfo?.label} tabIndex={0}>
									{displayInfo?.label}
								</Styled.CardLabel>
								<Styled.DotSeparator tabIndex={-1} aria-hidden>
									&#x2022;
								</Styled.DotSeparator>
								<Styled.CardQuantity title={displayInfo?.label} tabIndex={0}>
									{displayInfo?.quantity === 1 ? `1 Item` : `${displayInfo?.quantity} Items`}
								</Styled.CardQuantity>
								{!!displayInfo?.price && (
									<Styled.DotSeparator tabIndex={-1} aria-hidden>
										&#x2022;
									</Styled.DotSeparator>
								)}
								<Styled.CardPrice title={displayInfo?.label} tabIndex={0}>
									{displayInfo?.price ? `$${displayInfo?.price.toFixed(2)}` : ''}
								</Styled.CardPrice>
							</Styled.TextHeader>
						</Styled.Section>
						<Styled.SecondSection>
							<Styled.Description tabIndex={0}>{displayInfo?.description}</Styled.Description>
							<Styled.ImageContainer>
								<Styled.Image
									imagePath={displayInfo?.image1 || ''}
									id='faves-recent-image-1'
									data-testid='faves-recent-image-1'
								/>
								{isDesktop() && shouldShowSecondImage && (
									<Styled.Image
										imagePath={displayInfo?.image2 || ''}
										id='faves-recent-image-2'
										data-testid='faves-recent-image-2'
									/>
								)}
							</Styled.ImageContainer>
						</Styled.SecondSection>
						<Styled.ThirdSection>
							<Styled.ButtonContainer>
								<Button
									data-testid={`show-details`}
									text='Show details'
									variant='secondary'
									onClick={onClickShowDetails}
									id={`show-details`}
								/>
							</Styled.ButtonContainer>
							<Styled.ButtonContainer>
								<Button
									data-testid={`order-again`}
									text='Order again'
									variant='primary'
									onClick={onClickOrderAgain}
									id={`order-again`}
									icon={PlusIconOutlined}
								/>
							</Styled.ButtonContainer>
						</Styled.ThirdSection>
					</Styled.Card>
				</Styled.Container>
			</div>
		</Styled.CardContainer>
	);
};
