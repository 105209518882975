import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Subject = styled.div`
	flex-direction: column;
	padding-bottom: 24px;
`;

export const CardTitle = styled.div`
	${({ theme }) => theme.orderUi.typography.title.subtitle};
	margin-bottom: 8px;
`;

export const Icon = styled.div`
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	margin-right: 16px;

	div {
		width: 32px;
		height: 32px;
	}
`;

export const CardContent = styled.div`
	flex: 1;
	${({ theme }) => theme.orderUi.typography.body.bodySmall}
`;
