import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../Store';
import { AuthenticationHandler } from './AuthenticationHandler';
import { AppOperations } from '../../features/App/redux/App.operations';

const mapStateToProps = (state: RootState) => ({
	userRewards: state.user.userRewards,
});

const mapDispatchToProps = {
	setAuth0Token: AppOperations.setAuth0Token,
	clearAuth0Token: AppOperations.clearAuth0Token,
	clearUserToken: AppOperations.clearUserToken,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const AuthenticationHandlerConnected = connector(AuthenticationHandler);
export { AuthenticationHandlerConnected as AuthenticationHandler };
