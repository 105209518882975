import { FC, useState, useEffect } from 'react';
import { CallToAction } from '../CallToAction';
import { OptionsCard } from '../OptionsCard/OptionsCard';
import { ReduxProps } from '.';
import { Option, OptionGroup } from '@maverick/entity';
import { handleKeyDown } from '../../../utils/GeneralUtils';
import { OptionGroupSection } from '../OptionGroupSection';
import { useModal } from '@maverick/ui';

import * as Styled from './OptionsCardList.styled';
import { getOptionContext } from '../../../features/Menu/redux/Menu.slice';

interface OptionsCardListProps extends ReduxProps {
	options: Array<Option>;
	cardsGrid: number;
	isUpdate: boolean;
	optionGroup: string;
	isAppOnly?: boolean;
	setTransferBasketModal?: () => void;
}
export const OptionsCardList: FC<OptionsCardListProps> = ({
	options,
	cardsGrid,
	isUpdate,
	selectedOptions,
	selectOption,
	rawOptionGroups,
	optionGroup,
	isAppOnly = false,
	setTransferBasketModal,
	imagepath,
}) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [list, setList] = useState<Array<Option>>([]);
	const { setModal } = useModal();

	const sideCategoryAll = 'All';

	const pills: Array<string> = [];
	const [selectedPill, setSelectedPill] = useState<string>(sideCategoryAll);

	const optionsSelected = options.filter((o) => selectedOptions.includes(o.id));

	options.forEach((o) => {
		const sideCategory = o.metadata?.find((m) => m.key === 'sideCategory');

		if (sideCategory && !pills.includes(sideCategory.value)) {
			if (!pills.includes(sideCategoryAll)) {
				pills.push(sideCategoryAll);
			}
			pills.push(sideCategory.value);
		}
	});

	useEffect(() => {
		const reorderedOptions = [...options];
		const defaultOptionIndex = options.findIndex((o) => o.isdefault);
		if (defaultOptionIndex !== -1) {
			const defaultOption = reorderedOptions.splice(defaultOptionIndex, 1);
			reorderedOptions.unshift(defaultOption[0]);
		}
		if (selectedPill != sideCategoryAll)
			setList(
				reorderedOptions.filter(
					(o) => o.metadata?.find((m) => m.key === 'sideCategory')?.value === selectedPill
				)
			);
		else setList(reorderedOptions);
	}, [selectedPill]);

	useEffect(() => {
		const hasSelectedOption = options.some((o) => selectedOptions.includes(o.id));
		if (!hasSelectedOption) {
			const defaultOption = options.find((o) => o.isdefault);
			if (defaultOption) {
				selectOption(defaultOption.id);
			}
		}
	}, []);

	const handleSelectOption = (id: number) => {
		if (isUpdate) {
			const context = getOptionContext(rawOptionGroups, id);
			if (context && OptionGroup.IsMasterOptionGroup(context.optionGroup)) {
				setModal({
					text: `Changing this item will reset other customizations for your item. Do you want to continue?`,
					primaryButton: {
						label: 'Change option',
						onClick: () => selectOption(id),
					},
					secondaryButton: {
						label: 'Cancel',
						onClick: () => {},
					},
					id: '',
				});
				return;
			}
		}
		if (isAppOnly && setTransferBasketModal) {
			setTransferBasketModal();
		}
		selectOption(id);
	};

	const formattedOptionGroup = optionGroup.replace(/[^a-zA-Z0-9]/g, '-');

	return (
		<Styled.Container isExpanded={isExpanded}>
			{!!pills.length && (
				<Styled.PillsNavbar>
					<Styled.PillsContainer
						role='menu'
						id={`pills-container-${formattedOptionGroup}`}
						data-testid={`pills-container-${formattedOptionGroup}`}
					>
						{pills.map((p, i) => {
							const isSelected = p === selectedPill;
							return (
								<Styled.Pill
									data-testid={`pill-${formattedOptionGroup}-${p}`}
									id={`pill-${formattedOptionGroup}-${i}`}
									key={i}
									selected={isSelected}
									onClick={() => {
										setSelectedPill(p);
									}}
									onKeyDown={(ev) => handleKeyDown(ev, () => setSelectedPill(p))}
									tabIndex={0}
									aria-label={`Option ${p}`}
									role='menuitem'
									aria-current={isSelected}
								>
									{p}
								</Styled.Pill>
							);
						})}
					</Styled.PillsContainer>
				</Styled.PillsNavbar>
			)}

			<Styled.Options
				role='listbox'
				aria-label={`${formattedOptionGroup}-${selectedPill}`}
				data-testid={selectedPill ? `options-${formattedOptionGroup}-${selectedPill}` : `options-container`}
				id={selectedPill ? `options-${formattedOptionGroup}-${selectedPill}` : `options-container`}
				columns={cardsGrid}
			>
				{list.map((o) => {
					const isSelected = selectedOptions.includes(o.id);
					return (
						<OptionsCard
							key={o.id}
							option={o}
							onClick={() => handleSelectOption(o.id)}
							selected={isSelected}
							testId={o.name.replace(/\s/g, '-') + '_' + o.id}
							imageBasePath={imagepath}
							isSteakOption={optionGroup == 'Steak Options'}
						/>
					);
				})}
			</Styled.Options>

			{list.length > cardsGrid && (
				<Styled.CallToAction>
					<CallToAction
						label={isExpanded ? 'Close' : 'View more'}
						onClick={() => setIsExpanded(!isExpanded)}
					/>
				</Styled.CallToAction>
			)}

			{optionsSelected.map((o) => (
				<Styled.OptionGroupWrapper key={o.id}>
					{o.modifiers?.map((m) => (
						<OptionGroupSection
							optionGroup={m}
							isUpdate={isUpdate}
							key={m.id}
							isNested
							aria-labelledby={`option-group-label-${m.description}`}
						/>
					))}
				</Styled.OptionGroupWrapper>
			))}
		</Styled.Container>
	);
};
