import React, { FC, MouseEvent } from 'react';
import * as Styled from './CallToAction.styled';

export interface CallToActionProps {
	label: string;
	onClick?: () => void;
	expanded?: boolean;
	id?: string;
}

export const CallToAction: FC<CallToActionProps> = ({ label, onClick, expanded, id }) => {
	const handleOnClick = (ev: MouseEvent) => {
		onClick?.();
		ev.stopPropagation();
		ev.preventDefault();
	};

	const handleKeyDown = (ev: React.KeyboardEvent) => {
		if (ev.key === 'Enter' || ev.key === ' ') {
			onClick?.();
			ev.stopPropagation();
			ev.preventDefault();
		}
	};

	return (
		<Styled.Container
			role='button'
			tabIndex={0}
			aria-expanded={expanded}
			onKeyDown={(ev) => handleKeyDown(ev)}
			onClick={handleOnClick}
			data-testid={!!id ? id : 'callToAction'}
		>
			{label}
		</Styled.Container>
	);
};
