import { Icon } from '@maverick/ui';
import { FC } from 'react';
import { handleKeyDown } from '../../../utils/GeneralUtils';

import * as Styled from './SelectItem.styled';

export interface SelectItemProps {
	label: string;
	icon: JSX.Element;
	onClick: () => void;
	selected: boolean;
	id?: string;
	allowDelivery: boolean;
}
export const SelectItem: FC<SelectItemProps> = ({ label, icon, onClick, selected, id, allowDelivery }) => {
	return (
		<Styled.Container
			onClick={onClick}
			selected={selected}
			data-testid={id ? `selectItem-${id}` : 'selectItem'}
			id={id ? `${id}` : 'selectItem'}
			tabIndex={0}
			onKeyDown={(ev) => handleKeyDown(ev, onClick)}
			aria-label={`Select ${label}`}
			role='option'
			aria-selected={selected}
			allowDelivery={allowDelivery}
			label={label}
		>
			<Styled.Icon>
				<Icon icon={icon} customSize data-testid='icon' aria-hidden={true} />
			</Styled.Icon>

			<Styled.Title selected={selected} data-testid='label' allowDelivery={allowDelivery}>
				{label}
			</Styled.Title>
		</Styled.Container>
	);
};
