import { Icon } from '@maverick/ui';
import React, { FC } from 'react';
import * as Styled from './OptionsButtonProfile.styled';
import { IconArrowRight } from '@maverick/icons/dist/IconArrowRight';
import { Tooltip } from '../Tooltip';
import { Tag } from '../Tag';
import { FeatureFlagHighlight } from '@maverick/entity';

export type ButtonProfileProps = {
	icon: JSX.Element;
	title: string;
	redirect: () => void;
	showTagNew?: FeatureFlagHighlight | null;
};

export interface OptionsButtonProfileProps {
	button: ButtonProfileProps;
}

export const OptionsButtonProfile: FC<OptionsButtonProfileProps> = ({ button }) => {
	const renderTag = button.showTagNew && button.title === 'Redeem via app';

	return (
		<Styled.ButtonContainer
			onClick={button.redirect}
			id={button.title.toLowerCase().replace(/[^a-z0-9]/gi, '-') + '-option'}
		>
			<Styled.ButtonItems>
				<Styled.Icon>
					<Icon
						icon={button.icon}
						testId={button.title.toLowerCase().replace(' ', '-') + '-icon'}
						customSize
					/>
				</Styled.Icon>

				<Styled.Button>{button.title}</Styled.Button>
			</Styled.ButtonItems>

			{renderTag && (
				<Tooltip
					description='Click here to open or install the app and easily redeem your rewards in restaurant.'
					id='redeem-in-person-tooltip'
					tabIndex={0}
				>
					<Tag variant='feature' text='New!' />
				</Tooltip>
			)}

			<Styled.IconArrow id='icon-arrow' data-testid='icon-arrow'>
				<Icon icon={IconArrowRight} customSize />
			</Styled.IconArrow>
		</Styled.ButtonContainer>
	);
};
