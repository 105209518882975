import { HttpStatus, RestApi, RestResponse } from '@maverick/http';

const RadarResponseMiddleware = (response: RestResponse) => {
	if (response.status === HttpStatus.BadRequest) {
		const newResponse: RestResponse = {
			...response,
			errorMessage: response.body.message,
		};

		return newResponse;
	}

	if (response.status === HttpStatus.Ok) {
		if (response.body.meta.code !== HttpStatus.Ok) {
			const newResponse: RestResponse = {
				...response,
				error: true,
				errorMessage: 'An error occurred while fetching the location',
			};

			return newResponse;
		}

		return response;
	}

	const newResponse: RestResponse = {
		...response,
		errorMessage: 'An error occurred while fetching the location',
	};

	return newResponse;
};

export const RadarClient = new RestApi('/order/api/radar', undefined, RadarResponseMiddleware);
