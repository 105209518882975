import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { GaEvent, Storage } from '@maverick/utils';

const getPageType = (pathname: string): string => {
	if (pathname === '/order') return 'Order Page';
	if (pathname === '/order/restaurants') return 'Restaurants Page';
	if (pathname === '/order/menu') return 'Menu Page';
	if (pathname.includes('/products/')) return 'Product Page';
	if (pathname === '/order/checkout') return 'Checkout Page';
	if (pathname === '/order/order-received') return 'Receipt Page';
	return 'Content Page';
};

export const PageView: FC = () => {
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			const { pathname } = window.location;
			const pageType = getPageType(pathname);

			const helmetState = Helmet.peek();

			const prevPageTitle = Storage.Session.Get<string | undefined>('previous-page-title');
			const pageTitle = helmetState?.title || document.title;
			GaEvent.PageView(pageTitle, prevPageTitle, pageType, 'order');
			GaEvent.Global.Set('page_type', pageType);
			GaEvent.Global.Set('previous_page_title', prevPageTitle ?? 'entry_page');
			GaEvent.Global.Set('site_section', 'order');
			GaEvent.GlobalEvent();
			Storage.Session.Set('previous-page-title', pageTitle);
		}, 500);
	}, [location]);

	return null;
};
