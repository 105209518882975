import { Dispatch } from 'redux';
import { CheckoutStore, CheckoutSlice } from './Checkout.slice';

export const CheckoutOperations = {
	setBasket: (basket: CheckoutStore['basket']) => (dispatch: Dispatch) => {
		const action = CheckoutSlice.actions.setBasket(basket);
		dispatch(action);
	},

	setGiftCard: (giftCard: CheckoutStore['giftCard']) => (dispatch: Dispatch) => {
		dispatch(CheckoutSlice.actions.setGiftCard(giftCard));
	},

	setItemsNotTransferred: (itemsNotTransferred: CheckoutStore['itemsNotTransferred']) => (dispatch: Dispatch) => {
		dispatch(CheckoutSlice.actions.setItemsNotTransferred(itemsNotTransferred));
	},

	clearItemsNotTransferred: () => (dispatch: Dispatch) => {
		dispatch(CheckoutSlice.actions.clearItemsNotTransferred());
	},

	setBillingScheme: (billingScheme: CheckoutStore['billingScheme']) => (dispatch: Dispatch) => {
		dispatch(CheckoutSlice.actions.setBillingScheme(billingScheme));
	},

	setBasketCreationDate: (basketCreationDate: CheckoutStore['basketCreationDate']) => (dispatch: Dispatch) => {
		dispatch(CheckoutSlice.actions.setBasketCreationDate(basketCreationDate));
	},

	setLastOrder: (lastOrder: CheckoutStore['lastOrder']) => (dispatch: Dispatch) => {
		dispatch(CheckoutSlice.actions.setLastOrder(lastOrder));
	},

	setCurbsideInfo: (curbsideInfo: CheckoutStore['curbsideInfo']) => (dispatch: Dispatch) => {
		dispatch(CheckoutSlice.actions.setCurbsideInfo(curbsideInfo));
	},

	resetCheckout: () => (dispatch: Dispatch) => {
		dispatch(CheckoutSlice.actions.resetCheckout());
	},

	setSignUpOption: (loading: CheckoutStore['signUpOption']) => (dispatch: Dispatch) => {
		dispatch(CheckoutSlice.actions.setSignUpOption(loading));
	},

} as const;
