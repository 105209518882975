import { useAuth0 } from '@auth0/auth0-react';
import { GaEvent } from '@maverick/utils';
import { FC, useEffect } from 'react';
import { UserInfo } from '../../shared/enums';
import { ReduxProps } from './index';

export interface AuthenticationHandlerProps extends ReduxProps {}

export const AuthenticationHandler: FC<AuthenticationHandlerProps> = ({
	setAuth0Token,
	clearAuth0Token,
	clearUserToken,
	userRewards,
}) => {
	const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0();
	const userInfoPrefix = 'https://dine-Rewards/';

	useEffect(() => {
		if (isLoading) {
			return;
		}

		checkAuthentication();
	}, [isAuthenticated, isLoading]);

	const checkAuthentication = async () => {
		if (isAuthenticated) {
			const token = await getAccessTokenSilently();
			GaEvent.Global.Set('logged_in_status', 'logged in');
			GaEvent.Global.Set('dine_rewards_user_id', user ? user[`${userInfoPrefix}${UserInfo.LoyaltyId}`] : null);
			GaEvent.Global.Set('dine_rewards_status', 'link');
			GaEvent.Global.Set('email_address_code', user ? user[`${userInfoPrefix}${UserInfo.Email}`] : null);
			setAuth0Token(token);
			return;
		}

		clearAuth0Token();
		clearUserToken();
		GaEvent.Global.Set('logged_in_status', 'not logged in');
		GaEvent.Global.Set('dine_rewards_user_id', null);
		GaEvent.Global.Set('dine_rewards_status', 'unlinked');
		GaEvent.Global.Set('email_address_code', null);

		GaEvent.Global.Set('event_category', 'rewards updated');
		GaEvent.Global.Set(
			'event_action',
			userRewards?.rewardsCounter ? userRewards.rewardsCounter.toString() : 'no rewards'
		);

		GaEvent.Global.Set(
			'dollar_rewards_available',
			userRewards?.totalRewardAmount ? userRewards.totalRewardAmount.toString() : 'zero'
		);
	};

	return null;
};
