import { GraphQLResponse } from '@maverick/http';
import { Config } from '../../Config';
import { GraphQLApi } from '@maverick/http';

const SitecoreClient = new GraphQLApi(Config.SitecoreGraphQLEndpoint, { sc_apikey: Config.SitecoreApiKey });

export const NavigationService = {
	GetRouteItem: async <T>(dataSource: string): Promise<GraphQLResponse<T>> => {
		const request = `
        query GetRouteItem($datasource: String!, $language: String!) {
            item(path: $datasource, language: $language) {
              ... on _RedirectPage {
                link {
                  jsonValue
                }
              }
              url: url {
                path
              }
              label: displayName
            }
          }          
        `;

		return SitecoreClient.ExecuteRequest<T>(request, {
			datasource: dataSource,
			language: 'en',
		});
	},

	GetHomeData: async <T>(): Promise<GraphQLResponse<T>> => {
		const request = `query GetHomeData($datasource: String!, $language: String!) {
                item(path: $datasource, language: $language) {
                    ... on _Home {
                        mainItems {
                            value
                        }
                        hamburgerItems {
                            value
                        }
                        featuredItems {
                          value
                        }
                        unfeaturedItems {
                          value
                        }
                    }
                }
            }`;

		return SitecoreClient.ExecuteRequest<T>(request, {
			datasource: `/sitecore/content/BBI/${Config.JssApp}/Home`,
			language: 'en',
		});
	},

	GetHomeLogo: async <T>(): Promise<GraphQLResponse<T>> => {
		const request = `
        query GetLogoHome($datasource: String!, $language: String!) {
            item(path: $datasource, language: $language) {
              Logo: field(name:"Logo"){
                ... on ImageField{
                  src
                  alt
                }
              },
              LogoMobile:field(name:"Logo Mobile"){
                ... on ImageField{
                  src
                  alt
                }
              }
            }
          }                 
        `;

		return SitecoreClient.ExecuteRequest<T>(request, {
			datasource: `/sitecore/content/BBI/${Config.JssApp}/Home`,
			language: 'en',
		});
	},
};
