import styled, { css } from 'styled-components';
import { TABLET_BREAKPOINT, ULTRAWIDE_BREAKPOINT } from '@maverick/utils';

export const Container = styled.div<{ variant: 'menu' | 'product' | 'summary' }>`
	width: 100%;
	margin: 0 auto;

	${({ variant }) =>
		variant === 'menu' &&
		css`
			max-width: ${ULTRAWIDE_BREAKPOINT}px;
			padding: 24px 24px 0;

			@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
				padding: 32px 32px 0;
			}
		`}
	${({ variant }) =>
		variant === 'product' &&
		css`
			margin-bottom: 24px;
		`} 
	${({ variant }) =>
		variant === 'summary' &&
		css`
			margin-top: 16px;
			margin-bottom: 16px;
		`}
`;

export const Banner = styled.div`
	display: flex;
	width: 100%;
	border-radius: 4px;
	padding: 16px;
	flex-direction: column;
	gap: 8px;
	background-color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	margin: 0 auto;
`;

export const Title = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h5};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.light};
`;

export const Content = styled.div`
	${({ theme }) => theme.orderUi.typography.body.body};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.light};
`;
