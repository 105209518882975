import { FC, useState } from 'react';
import * as Styled from './RecentAndFaves.styled';
import { Button } from '@maverick/ui';
import { FaveCustomProduct, FaveOrder, RecentOrder } from '@maverick/entity';
import { isDesktop } from '../../../../utils/GeneralUtils';
import { CategoryHistoryItem } from './RecentAndFaves';
import { RecentAndFavesCard } from '.';
import { MenuArrowLeft } from '@maverick/icons/dist/MenuArrowLeft';
import { MenuArrowRight } from '@maverick/icons/dist/MenuArrowRight';

export interface RecentAndFavesListProps {
	items: Array<FaveOrder | RecentOrder | FaveCustomProduct>;
	categorySelected: CategoryHistoryItem | null;
}

export const RecentAndFavesList: FC<RecentAndFavesListProps> = ({ items, categorySelected }) => {
	const [focused, setFocused] = useState<number>(1);
	const isLastItemFocused = items && focused >= items.length - 2;
	const isFirstItemFocused = focused <= 1;

	const scrollToNextCard = () => {
		if (items && focused >= items?.length - 1) {
			return;
		}
		const nextCard = document.getElementById(`${focused + 1}-order-records`);
		nextCard?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		setTimeout(() => {
			setFocused(focused + 1);
		}, 300);
	};

	const scrollToPreviousCard = () => {
		const previousCard = document.getElementById(`${focused - 1}-order-records`);
		previousCard?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		if (focused == 0) {
			return;
		}
		setTimeout(() => {
			setFocused(focused - 1);
		}, 300);
	};

	return (
		<Styled.Container>
			<div>
				<Styled.ListWrapper>
					{isDesktop() && !isFirstItemFocused && (
						<Styled.ListGradientPrevious>
							<Styled.PreviousButton>
								<Button
									icon={MenuArrowLeft}
									text=''
									disabled={isFirstItemFocused}
									variant='terciary'
									onClick={scrollToPreviousCard}
									id={'previous-reward'}
								/>
							</Styled.PreviousButton>
						</Styled.ListGradientPrevious>
					)}
					{isDesktop() && !isLastItemFocused && (
						<Styled.ListGradientNext>
							<Styled.NextButton>
								<Button
									icon={MenuArrowRight}
									disabled={isLastItemFocused}
									text=''
									variant='terciary'
									onClick={scrollToNextCard}
									id={'next-reward'}
								/>
							</Styled.NextButton>
						</Styled.ListGradientNext>
					)}
					<Styled.ScrollWrapper>
						{items?.map((item, index) => {
							return (
								<Styled.CardItem
									key={`${index}-order-records`}
									id={`${index}-order-records`}
									data-testid={`${index}-order-records`}
								>
									<RecentAndFavesCard item={item} categorySelected={categorySelected} />
								</Styled.CardItem>
							);
						})}
					</Styled.ScrollWrapper>
				</Styled.ListWrapper>
			</div>
		</Styled.Container>
	);
};
