import React, { FC, useState, useEffect } from 'react';
import * as Styled from './RecentAndFaves.styled';
import { FaveCustomProduct, FaveOrder, RecentOrder } from '@maverick/entity';
import { Button, Icon } from '@maverick/ui';
import { ArrowIcon } from '@maverick/icons/dist/ArrowIcon';
import { RecentAndFavesList, ReduxProps } from '.';
import { GaEvent } from '@maverick/utils';
import { Tag } from '../../../../shared/components';
import { Tooltip } from '../../../../shared/components';
import { handleTagNew } from '../../../../utils/GeneralUtils';
import { RecentAndFavesCardGuest } from '.';
import { MenuManager } from '../../Menu.manager';

export interface CategoryHistoryItem {
	label: string;
	key: string;
	id: number;
}

export enum CategoryHistoryOptions {
	Favorites = 'favorites',
	RecentOrders = 'recent-orders',
}

const pageTitle = 'menu';

export interface RecentAndFavesProps extends ReduxProps {
	isAuthenticated: boolean;
}

export const RecentAndFaves: FC<RecentAndFavesProps> = ({
	faves,
	recentOrders,
	highlightFeature,
	isAuthenticated,
	userFaveProducts,
	setUserFaveProducts,
	authToken,
}) => {
	const [listCategories, setListCategories] = useState<Array<CategoryHistoryItem>>([]);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [categorySelected, setCategorySelected] = useState<CategoryHistoryItem | null>(null);
	const [shouldDisplay, setShouldDisplay] = useState<boolean>(false);
	const [listItems, setListItems] = useState<Array<FaveOrder | RecentOrder | FaveCustomProduct>>([]);

	useEffect(() => {
		retrieveFavoritesProducts();
	}, []);

	useEffect(() => {
		let newValue = [];
		if (isAuthenticated) {
			if ((faves && faves.length) || userFaveProducts?.savedproducts.length) {
				newValue.push({ label: 'Favorites', key: CategoryHistoryOptions.Favorites, id: 0 });
			}

			if (recentOrders && recentOrders.length) {
				newValue.push({
					label: 'Recent orders',
					key: CategoryHistoryOptions.RecentOrders,
					id: 1,
				});
			}
			if (newValue.length > 0) {
				setShouldDisplay(true);
			}
		} else {
			newValue.push({ label: 'Favorites', key: CategoryHistoryOptions.Favorites, id: 0 });
			newValue.push({ label: 'Recent orders', key: CategoryHistoryOptions.RecentOrders, id: 1 });
			setShouldDisplay(true);
		}

		setListCategories(newValue);
	}, [faves, isAuthenticated, recentOrders, userFaveProducts]);

	useEffect(() => {
		if (categorySelected?.key === CategoryHistoryOptions.Favorites) {
			if (faves && userFaveProducts?.savedproducts) {
				setListItems(
					(faves as unknown as Array<FaveOrder | FaveCustomProduct>).concat(
						userFaveProducts?.savedproducts as unknown as Array<FaveOrder | FaveCustomProduct>
					)
				);
			}
		} else if (categorySelected?.key === CategoryHistoryOptions.RecentOrders) {
			setListItems(recentOrders as unknown as Array<RecentOrder>);
		}
	}, [categorySelected, faves, recentOrders, userFaveProducts?.savedproducts]);

	const retrieveFavoritesProducts = async () => {
		if (!isAuthenticated || !authToken) {
			return;
		}

		const { response, error } = await MenuManager.RetrieveFavoriteProduct(authToken);

		if (!response || error || !response.savedproducts) {
			return;
		}

		setUserFaveProducts(response);
	};

	const onSelectCategory = (category: CategoryHistoryItem) => {
		setIsOpen(true);
		setCategorySelected(category);
		if (category.key === CategoryHistoryOptions.Favorites) {
			GaEvent.FavoritesButtonClick(
				faves?.length || 0,
				isAuthenticated ? pageTitle : 'guest_order',
				!isAuthenticated
			);
		} else if (category.key === CategoryHistoryOptions.RecentOrders) {
			GaEvent.RecentOrdersButtonClick(
				recentOrders?.length || 0,
				isAuthenticated ? pageTitle : 'guest_order',
				!isAuthenticated
			);
		}
	};

	const onClickExpandArrow = () => {
		if (!isAuthenticated) {
			GaEvent.FavoritesButtonClick(0, 'arrow', true);
		}
		if (isOpen) {
			setCategorySelected(null);
		} else {
			setCategorySelected(listCategories[0]);
		}
		setIsOpen(!isOpen);
	};

	if (!shouldDisplay) {
		return <></>;
	}
	return (
		<Styled.Container data-testid={'recent-and-faves'}>
			<Styled.Banner>
				<Styled.Header role='listbox'>
					{listCategories.map((category) => {
						return (
							<Styled.HeaderButtonContainer key={category.key} role='option'>
								<Button
									borderRadius={99}
									padding={'12px'}
									fitParent={true}
									text={category.label}
									variant={categorySelected?.id === category.id ? 'primary' : 'secondary'}
									onClick={() => onSelectCategory(category)}
									id={category.key}
								/>
							</Styled.HeaderButtonContainer>
						);
					})}
					<Styled.ArrowIcon
						aria-expanded={isOpen}
						aria-label={`${isOpen ? 'Close' : 'Open'} ${'favorites and recent orders'}`}
						data-testid='arrow-button'
						onClick={() => onClickExpandArrow()}
						isOpen={isOpen}
					>
						<Icon data-testid='arrow-icon' icon={ArrowIcon} />
					</Styled.ArrowIcon>
					{handleTagNew(highlightFeature) && (
						<Tooltip
							description='Now you can access your Favorite and Recent orders directly from the menu page.'
							id='recent-and-faves-tooltip'
							tabIndex={0}
						>
							<Tag variant='feature' text='New!' />
						</Tooltip>
					)}
				</Styled.Header>
			</Styled.Banner>
			<div>
				{isOpen && isAuthenticated && (
					<RecentAndFavesList items={listItems} categorySelected={categorySelected} />
				)}
			</div>
			{isOpen && !isAuthenticated && <RecentAndFavesCardGuest categorySelected={categorySelected!} />}
		</Styled.Container>
	);
};
