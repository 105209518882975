import { FC } from 'react';
import * as Styled from './ArrowButton.styled';
import { Icon } from '@maverick/ui';
import { MenuArrowLeft } from '@maverick/icons/dist/MenuArrowLeft';

export interface ArrowButtonProps {
	onClick: () => void;
	id?: string;
	disabled?: boolean;
	isRight?: boolean;
}

export const ArrowButton: FC<ArrowButtonProps> = ({ onClick, id, isRight = false, disabled = false }) => {
	return (
		<Styled.Container
			id={id ?? 'arrow-button'}
			data-testid={id ?? 'arrow-button'}
			disabled={disabled}
			isRight={isRight}
			onClick={onClick}
			aria-label='Scroll categories menu'
		>
			<Styled.Icon isRight={isRight}>
				<Icon icon={MenuArrowLeft} ariaLabel={isRight ? 'next' : 'previous'} customSize />
			</Styled.Icon>
		</Styled.Container>
	);
};
