export enum OloCodeErrors {
	'MisunderstoodRequest' = 1,
	'NoLongerAvailable' = 2,
	'InsufficientAccess' = 3,
	'VelocityThresholdExceeded' = 4,
	'BrandError' = 5,
	'InvalidOperationForAPIKey' = 6,
	'InvalidIPAddress' = 7,
	'InvalidOperationForVendor' = 8,
	'InvalidOperation' = 9,
	'OloInternalError' = 10,
	'NotSupportedByBrand' = 11,
	'LegacyEndpoint' = 12,
	'InvalidAuthtoken' = 101,
	'MessageForUser' = 200,
	'InvalidCoupon' = 201,
	'AuthDeclined' = 203,
	'InvalidAVS' = 204,
	'InvalidCVV' = 205,
	'InvalidAdvanceTime' = 206,
	'UnsupportedVersion' = 207,
	'InvalidReward' = 208,
	'AlreadySubmitted' = 209,
	'CapacityThrottled' = 210,
	'MenuItemEightySixed' = 211,
	'MenuItemOutOfHours' = 212,
	'TransmissionFailure' = 213,
	'InsufficientPaymentBalance' = 215,
	'LoyaltyRedemptionDeclined' = 216,
	'RestaurantUnreachable' = 217,
	'RestaurantOutOfHours' = 218,
	'RestaurantTemporarilyUnavailable' = 219,
	'InvalidPOSConfiguration' = 220,
	'InvalidPOSCouponConfiguration' = 221,
	'InvalidMenuSetupMapping:' = 222,
	'POSContention' = 223,
	'MenuItemNotOffered' = 224,
	'POSResponseTimeout' = 225,
	'LoyaltyAccountNotFound' = 226,
	'RequiresPasswordReset' = 227,
	'DispatchError' = 228,
	'MenuItemNoLongerAvailable' = 250,
	'MenuItemMissingRequiredSelections' = 251,
	'MenuItemRequirementsNotMet' = 252,
	'InvalidPromotionCode' = 253,
	'TooManyInvalidCVV' = 254,
}
