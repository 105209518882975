import { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { CreditCardElements as CardElements } from '@olo/pay';
import * as Styled from './CreditCardPayment.styled';
import type { PaymentMethodResult, CardOptions } from '@olo/pay';
import { Input } from '@maverick/ui';
import { InputModes } from '@maverick/utils';
import { InputHandler, useInput } from '@maverick/hooks';
import { ValidationCreditCard } from '../../constants/ValidationConstants';
import { Config } from '../../../Config';
import { useTheme } from 'styled-components';
export interface CreditCardSubmitBody {
	cardtype: string;
	cardlastfour: string;
	expiryyear: number;
	expirymonth: number;
	zip: string;
	token: string;
	saveOnFile: boolean;
}
export interface CreditCardPaymentHandle {
	createPaymentMethod: () => Promise<PaymentMethodResult | undefined>;
	validateCardFields: () => boolean;
	setInvalidCreditCardNumber: (message: string) => void;
	getZipCode: () => string;
	focusFirstErrorField: () => void;
}

const paymentEnvironment = Config.Env.IsPrd ? 'production' : 'development';

export const CreditCardPayment = forwardRef<CreditCardPaymentHandle>(function CreditCardPayment(_, ref) {
	const [cardElements] = useState<CardElements>(() => new CardElements(paymentEnvironment));
	const [cardNumberError, setCardNumberError] = useState('');
	const [cardExpiryError, setCardExpiryError] = useState('');
	const [cardCvcError, setCardCvcError] = useState('');
	const { orderUi } = useTheme();

	const ZipCodeValidator = (zipCode: InputHandler<string>): boolean => {
		let valid = true;

		if (zipCode.value?.length > 0 && zipCode.value?.length < 5) {
			zipCode.setError?.(ValidationCreditCard.invalidZipCode);
			valid = false;
		}

		return valid;
	};

	const zipCode: InputHandler<string> = useInput('', {
		id: 'zip',
		label: 'ZIP',
		placeholder: '72105',
		maxlength: 5,
		onBlur: () => ZipCodeValidator(zipCode),
	});

	useEffect(() => {
		const createCardElements = async () => {
			const options: CardOptions = {
				elementsOptions: {
					fonts: [
						{
							src: orderUi.fonts.src,
							family: orderUi.fonts.family,
						},
					],
				},
				cardElementOptions: {
					style: {
						base: {
							fontSize: '16px',
							fontFamily: orderUi.fonts.family,
							'::placeholder': {
								color: orderUi.colors.tertiary.medium,
							},
						},
						invalid: {
							color: '#2C2C2B',
						},
					},
				},
				placeholders: {
					cvc: '123',
					expiry: 'MM / YY',
					number: '1234 5678 9101 1121',
				},
			};

			await cardElements.create(options);
			const numberInput = cardElements.cardNumber;
			const expiryInput = cardElements.cardExpiry;
			const cvcInput = cardElements.cardCvc;

			numberInput?.on('change', (e) => {
				setCardNumberError('');
				if (e.error) {
					setCardNumberError(e.error.message);
				}
			});

			expiryInput?.on('change', (e) => {
				setCardExpiryError('');
				if (e.error) {
					setCardExpiryError(e.error.message);
				}
			});

			cvcInput?.on('change', (e) => {
				setCardCvcError('');
				if (e.error) {
					setCardCvcError(e.error.message);
				}
			});
		};
		createCardElements();
	}, [cardElements]);

	useImperativeHandle(ref, () => ({
		async createPaymentMethod() {
			const response = (await cardElements.createPaymentMethod()) as PaymentMethodResult;
			return response;
		},
		validateCardFields() {
			if (!zipCode.value.length) {
				zipCode.setError?.(ValidationCreditCard.insertZipCode);
			}
			return !(cardNumberError || cardCvcError || cardExpiryError || zipCode.error || !zipCode.value.length);
		},
		setInvalidCreditCardNumber: (message: string) =>{
			setCardNumberError(message);
		},
		getZipCode() {
			return zipCode.value;
		}, 
		focusFirstErrorField: () => {
			if (cardNumberError) {
				cardElements.cardNumber?.focus();
			} else if (cardCvcError) {
				cardElements.cardCvc?.focus();
			} else if (cardExpiryError) {
				cardElements.cardExpiry?.focus();
			} else if (zipCode.error) {
				document.getElementById('zip-input')?.focus();
			}
		},
		hasErrors: () => !!(cardNumberError || cardCvcError || cardExpiryError || zipCode.error),
	}));

	return (
		<Styled.Container>
			<Styled.Field error={cardNumberError}>
				<Styled.Top>
					<Styled.Label>Card Number</Styled.Label>
					<Styled.InputContainer data-testid='card-number-input-container'>
						<Styled.Input data-testid='card-number-input' data-olo-pay-card-number />
					</Styled.InputContainer>
				</Styled.Top>

				{cardNumberError && (
					<Styled.Bottom>
						<Styled.Error>{cardNumberError}</Styled.Error>
					</Styled.Bottom>
				)}
			</Styled.Field>
			<Styled.Field error={cardCvcError}>
				<Styled.Top>
					<Styled.Label>CVC</Styled.Label>
					<Styled.InputContainer data-testid='cvc-input-container'>
						<Styled.Input data-testid='cvc-input' data-olo-pay-card-cvc />
					</Styled.InputContainer>
				</Styled.Top>

				{cardCvcError && (
					<Styled.Bottom>
						<Styled.Error>{cardCvcError}</Styled.Error>
					</Styled.Bottom>
				)}
			</Styled.Field>
			<Styled.Field error={cardExpiryError}>
				<Styled.Top>
					<Styled.Label>Exp. Date</Styled.Label>
					<Styled.InputContainer data-testid='expiration-date-input-container'>
						<Styled.Input data-testid='expiration-date-input' data-olo-pay-card-expiry />
					</Styled.InputContainer>
				</Styled.Top>

				{cardExpiryError && (
					<Styled.Bottom>
						<Styled.Error>{cardExpiryError}</Styled.Error>
					</Styled.Bottom>
				)}
			</Styled.Field>
			<Input handler={zipCode} mode={InputModes.Numeric} />
		</Styled.Container>
	);
});
