import { DESKTOP_BREAKPOINT, ULTRAWIDE_BREAKPOINT } from '@maverick/utils';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ displayNone: boolean }>`
	display: inline-flex;
	width: 100%;
	margin-bottom: 80px;
	@media screen and (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		flex-direction: column-reverse;
	}
	${({ displayNone }) =>
		displayNone &&
		css`
			display: none;
		`}
`;

export const LeftContainer = styled.div`
	width: 100%;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		flex: 1;
	}
`;

export const RightContainer = styled.div`
	height: 100%;
	@media screen and (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		height: 220px;
		width: 100%;
	}
	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		width: 60%;
	}
	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		width: 60%;
	}
`;

export const Map = styled.div<{ navBarHeight: number }>`
	width: 100%;
	height: 100%;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		position: fixed;
		width: 60%;
		height: calc(100% - ${(props) => props.navBarHeight}px);
	}

	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		width: 60%;
	}

	.maplibregl-ctrl-group button {
		width: 48px;
		height: 48px;
	}

	.maplibregl-ctrl-bottom-right {
		display: none;
	}
`;

export const Content = styled.div`
	padding: 24px;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 32px;
	}

	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		margin: 0 auto;
	}
`;

export const HandoffTitle = styled.div`
	${({ theme }) => theme.orderUi.specificStyle.handoffOrderStyle}
`;

export const TitleResponsive = styled.h1`
	${({ theme }) => theme.orderUi.typography.headline.h3};
	margin-bottom: 4px;
	font-weight: 400;
	color: ${({ theme }) => theme.orderUi.specificStyle.titleColor};
	text-transform: ${({ theme }) => theme.orderUi.specificStyle.titleLetterCase};
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		justify-content: center;
		margin-bottom: 0;
	}
`;

export const SubtitleResponsive = styled.h2`
	${({ theme }) => theme.orderUi.typography.headline.h3};
	color: ${({ theme }) => theme.orderUi.specificStyle.titleColor};
	text-transform: ${({ theme }) => theme.orderUi.specificStyle.titleLetterCase};
	font-weight: 400;
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		color: ${({ theme }) => theme.orderUi.specificStyle.titleColor};
		${({ theme }) => theme.orderUi.typography.headline.h3};
	}
`;

export const Type = styled.section`
	margin-top: 24px;

	& > div {
		margin-top: 16px;
		display: flex;
		gap: 16px;
	}
`;

export const TypeIcon = styled.div`
	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		justify-content: center;
	}
`;

export const HandoffContent = styled.div`
	margin-top: 24px;

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		margin-top: 32px;
	}
`;

export const ButtonContainer = styled.div`
	position: fixed;
	width: 100%;
	bottom: 0px;
	left: 0;
	right: 0;
	padding: 16px 24px 16px 24px;
	z-index: 10;
	background: #ffffff;
	box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		right: unset;
		width: 40%;
	}

	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		width: 40%;
	}
`;

export const Button = styled.div`
	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		width: 50%;
		margin: auto;
		max-width: 448px;
	}
`;
