import { Restaurant } from '@maverick/entity';
import React, { FC } from 'react';
import * as Styled from './RestaurantInfo.styled';
import { handleRestaurantInfo } from '@maverick/utils';

export interface RestaurantInfoProps {
	restaurant: Restaurant;
	listIndex?: number;
	selected: boolean;
}

export const RestaurantInfo: FC<RestaurantInfoProps> = ({ restaurant, listIndex = 0, selected }) => {
	const { restaurantName, restaurantAddress } = handleRestaurantInfo(restaurant);

	return (
		<Styled.Container data-testid='restaurant-info'>
			<Styled.HeaderContainer>
				<Styled.Title selected={selected} data-testid={`restaurant-name-${listIndex}`}>
					{restaurantName}
				</Styled.Title>
				<Styled.DotContainer>•</Styled.DotContainer>
				<Styled.Distance>{`${restaurant.distance} mi`}</Styled.Distance>
			</Styled.HeaderContainer>
			<Styled.Address data-testid={`restaurant-address-${listIndex}`}>{restaurantAddress}</Styled.Address>
		</Styled.Container>
	);
};
