import { FC, useEffect } from 'react';
import { AppManager } from '../../../features/App/App.manager';
import { useInterval } from '@maverick/hooks';
import { Config } from '../../../Config';

export const WhitelabelSwitch: FC = () => {
	const verify = () => {
		AppManager.GetWhitelabelSwitch()
			.then((whitelabelSwitch) => {
				if (!whitelabelSwitch) return;
				window.location.replace(Config.WhitelabelUrl);
			})
			.catch((err) => console.log({ err }));
	};

	useEffect(() => {
		verify();
	}, []);

	useInterval(verify, 300_000);

	return null;
};
