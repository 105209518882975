import React, { FC } from 'react';

import * as Styled from './Title.styled';

interface TitleProps {
	main: string;
	secondary?: string;
	id?: string;
	responsive?: boolean;
}
export const Title: FC<TitleProps> = ({ main, secondary, id, responsive = false }) => {
	if (responsive) {
		return (
			<Styled.ContainerResponsive data-testid={id ? `${id}-title` : 'title'} id={id ? `${id}-title` : 'title'}>
				<Styled.TitleResponsive tabIndex={0}>{main}</Styled.TitleResponsive>
				{!!secondary && (
					<Styled.SubtitleResponsive
						id={id ? `${id}-subtitle` : 'subtitle'}
						data-testid={id ? `${id}-subtitle` : 'subtitle'}
						tabIndex={0}
					>
						{!!secondary && secondary}
					</Styled.SubtitleResponsive>
				)}
			</Styled.ContainerResponsive>
		);
	}

	return (
		<Styled.Container data-testid={id ? `${id}-title` : 'title'} id={id ? `${id}-title` : 'title'}>
			<Styled.Title tabIndex={0}>{main}</Styled.Title>
			{!!secondary && (
				<Styled.Subtitle
					id={id ? `${id}-subtitle` : 'subtitle'}
					data-testid={id ? `${id}-subtitle` : 'subtitle'}
					tabIndex={0}
				>
					{!!secondary && secondary}
				</Styled.Subtitle>
			)}
		</Styled.Container>
	);
};
