import { FC, ReactNode } from 'react';
import * as Styled from './PromotionalBanner.styled';
import { handleKeyDown } from '../../../utils/GeneralUtils';

export interface PromotionalBannerProps {
	title: ReactNode;
	text: ReactNode;
	button: ReactNode;
	hasLink: boolean;
	background: { mobile: string; desktop: string };
	id: string;
	onClickBanner: () => void;
}

export const PromotionalBanner: FC<PromotionalBannerProps> = ({
	title,
	text,
	button,
	background,
	hasLink,
	id,
	onClickBanner,
}) => {
	return (
		<Styled.Container
			background={background}
			hasLink={hasLink}
			data-testid='promotional-banner'
			id={id}
			onClick={onClickBanner}
			tabIndex={0}
			role='banner'
			onKeyDown={(ev) => handleKeyDown(ev, onClickBanner)}
		>
			<Styled.ImageDesktop
				src={background.desktop}
				width={'100%'}
				height={'auto'}
				alt='promotional-banner-desktop'
			></Styled.ImageDesktop>
			<Styled.ImageMobile
				src={background.mobile}
				width={'100%'}
				height={'auto'}
				alt='promotional-banner-mobile'
			></Styled.ImageMobile>
			<Styled.Main>
				{/* @TODO: We are temporarly hiding title, text and button (and using its props to redirect the user when they click on banner) */}
				{/* {title}
			{text}
			{button} */}
			</Styled.Main>
		</Styled.Container>
	);
};
