import { InputHandler } from '@maverick/hooks';
import { GaEvent } from '@maverick/utils';
import { Helmet } from 'react-helmet';
import { ValidationCreditCard } from '../../../constants';

export const ZipCodeValidator = (zipCode: InputHandler<string>): boolean => {
	let valid = true;
	const { title } = Helmet.peek();

	if (!zipCode.value) {
		zipCode.setError?.(ValidationCreditCard.insertZipCode);
		GaEvent.ErrorMessage(ValidationCreditCard.insertZipCode, title);
		valid = false;
	}

	if (zipCode.value?.length < 5) {
		zipCode.setError?.(ValidationCreditCard.invalidZipCode);
		GaEvent.ErrorMessage(ValidationCreditCard.invalidZipCode, title);
		valid = false;
	}

	return valid;
};
