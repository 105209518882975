import { FC, useEffect } from 'react';
import * as Styled from './TemperatureSlider.styled';
import { Icon } from '@maverick/ui';
import { Metadata, Option } from '@maverick/entity';
import { ReduxProps } from '.';
import { Slider as UISlider } from '@maverick/ui';
import { handleKeyDown } from '../../../utils/GeneralUtils';
import { useTheme } from 'styled-components';
import { IconTooltip } from '@maverick/icons/dist/IconTooltip';

interface TemperatureSliderProps extends ReduxProps {
	options: Array<Option>;
	isUpdate: boolean;
}

export const TemperatureSlider: FC<TemperatureSliderProps> = ({ options, selectedOptions, selectOption }) => {
	const { orderUi } = useTheme();

	const selectedOption = options.find((o) => selectedOptions.includes(o.id));

	useEffect(() => {
		const hasSelectedOption = options.some((o) => selectedOptions.includes(o.id));
		if (!hasSelectedOption) {
			const defaultOption = options.find((o) => o.isdefault);
			if (defaultOption) {
				selectOption(defaultOption.id);
			}
		}
	}, []);

	const findTemperatureOptionMetadata = (metadata: Array<Metadata>) => {
		return metadata.find((meta) => meta.key === 'temp-option');
	};

	const getAbbreviation = (metadata: Array<Metadata>) => {
		const temperatureMetadata = findTemperatureOptionMetadata(metadata);
		switch (temperatureMetadata?.value) {
			case 'rare':
				return 'R';
			case 'medrare':
				return 'MR';
			case 'med':
				return 'M';
			case 'medwell':
				return 'MW';
			case 'welldone':
				return 'W';
			default:
				return;
		}
	};

	const getTemperatureMetadataValue = (metadata: Array<Metadata> | null) => {
		if (!metadata) {
			return;
		}

		const temperatureMetadata = findTemperatureOptionMetadata(metadata);
		switch (temperatureMetadata?.value) {
			case 'rare':
				return 'rare';
			case 'medrare':
				return 'medium rare';
			case 'med':
				return 'medium';
			case 'medwell':
				return 'medium well';
			case 'welldone':
				return 'well done';
			default:
				return;
		}
	};

	const handleChange = (index: any) => {
		const isIndexValid = index <= options.length && index >= 1;
		if (isIndexValid)
			selectOption(options[index - 1].id);
		else
			selectOption(options[options.length - 1].id);
	};

	return (
		<Styled.Container>
			<Styled.Subtitle data-testid='temperature-subtitle'>
				You can tap the letter of your prefered temp
			</Styled.Subtitle>

			<Styled.SliderContainer>
				<Styled.Slider>
					<UISlider
						data-testid={'temperature-slider'}
						min={1}
						max={options.length}
						value={options.findIndex((o) => o.id === selectedOption?.id) + 1}
						ariaValuetext={getTemperatureMetadataValue(selectedOption ? selectedOption.metadata : null)}
						onChange={(index: any) => handleChange(index)}
						renderThumb={(props: any) => (
							<Styled.Thumb {...props} aria-hidden='true'>
								<Styled.ThumbIcon>
									<Icon icon={orderUi.icons.iconTemperature} customSize testId='temperature' />
								</Styled.ThumbIcon>
							</Styled.Thumb>
						)}
					/>
				</Styled.Slider>
				<Styled.TemperatureOptions role='listbox' aria-label='temperature-options'>
					{options.map((option) => (
						<Styled.TemperatureOption
							selected={selectedOption?.id === option.id}
							data-testid='temperature-option'
							role='option'
							aria-selected={selectedOption?.id === option.id}
							key={option.id}
							onClick={() => selectOption(option.id)}
							onKeyDown={(ev) => handleKeyDown(ev, () => selectOption(option.id))}
						>
							<Styled.Circle aria-hidden={true} />
							<div tabIndex={0} aria-label={getTemperatureMetadataValue(option.metadata)}>
								{getAbbreviation(option.metadata!)}
							</div>
						</Styled.TemperatureOption>
					))}
				</Styled.TemperatureOptions>
				<Styled.CustomTrack />
			</Styled.SliderContainer>
			<Styled.Description data-testid='temperature-description'>
				<Styled.DescriptionIcon>
					{' '}
					<Icon icon={IconTooltip}></Icon>
				</Styled.DescriptionIcon>
				{selectedOption?.name}
			</Styled.Description>
		</Styled.Container>
	);
};
