import { FC } from 'react';
import * as Styled from './RewardsProgressColumn.styled';
import { RewardsProgressBar } from '../RewardsProgressBar';
import { UserRewards } from '@maverick/entity';

interface RewardsProgressColumnProps {
	rewards: UserRewards;
}

export const RewardsProgressColumn: FC<RewardsProgressColumnProps> = ({ rewards }) => {
	const { pointsCount, maximumPointsLimit, pointsRewardAmount, pointsUntilReward } = rewards;
	const percentage = (pointsCount / maximumPointsLimit) * 100;

	return (
		<Styled.ProgressItems>
			<Styled.Columns>
				<Styled.ProgressContainer>
					<Styled.RewardsInfo tabIndex={0}>
						{pointsUntilReward} points to get a{' '}
						<span data-testid='total-rewards'>${pointsRewardAmount} off </span>
						reward.
					</Styled.RewardsInfo>
					<RewardsProgressBar percentage={percentage} />
					<Styled.Points tabIndex={0} data-testid='current-total-points'>
						<span>{pointsCount}</span> / {maximumPointsLimit}
					</Styled.Points>
				</Styled.ProgressContainer>
			</Styled.Columns>
		</Styled.ProgressItems>
	);
};
