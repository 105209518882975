import styled, { css } from 'styled-components';

export const Section = styled.section`
	margin-top: 16px;
`;

export const Container = styled.div`
	border-radius: 4px;
	padding: 16px;
	border: 1px solid ${({ theme }) => theme.orderUi.colors.secondary.dark};
	background-color: ${({ theme }) => theme.orderUi.colors.secondary.light};
	background-image: url(${({ theme }) => theme.orderUi.images.bgRewardsBanner});
	background-size: cover;
`;

export const DescriptionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const IconContainer = styled.div`
	width: 8px;
	height: 14px;
	transform: rotate(-0.25turn);
	color: #3e7a7b;
`;

export const Description = styled.div`
	font-size: 14px;
	line-height: 18px;
	color: #232222;
	font-weight: 500;
`;

export const RemainingValue = styled.span`
	font-weight: bold;
`;

export const RewardsValue = styled.span`
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
`;

export const OptionCard = styled.div`
	margin: 16px 0 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 12px;
`;

export const ArrowIcon = styled.button<{ isOpen: boolean }>`
	background: none;
	border: none;
	padding: 0;
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	width: 16px;
	cursor: pointer;

	${({ isOpen }) =>
		isOpen &&
		css`
			transform: rotate(180deg);
		`}

	&:hover {
		color: ${({ theme }) => theme.orderUi.colors.primary.light};
	}
`;
