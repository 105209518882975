import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.div``;

export const Section = styled.section``;

export const SectionTitle = styled.div`
	margin-bottom: 8px;
	${({ theme }) => theme.orderUi.typography.headline.h5};

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.headline.h4Variant};
	}
`;

export const SaveCard = styled.div`
	margin: 24px 0px 0px 0px;
`;

export const AddCreditCard = styled.div`
	margin-top: 12px;
	display: grid;
	gap: 20px 20px;
	grid-template-columns: repeat(6, 1fr);

	div:not(:nth-child(1)) {
		grid-column: span 2;
	}

	div:nth-child(1) {
		grid-column: 1 / 7;
	}

	@media screen and (max-width: 380px) {
		div:not(:nth-child(1)) {
			grid-column: span 3;
		}

		div:nth-child(4) {
			grid-column: 1 / 7;
		}
	}
`;

export const Line = styled.hr`
	background-color: ${({ theme }) => theme.orderUi.colors.neutral.medium};
	border: none;
	height: 1.5px;
	margin: 24px 0;
`;

export const Icon = styled.div`
	width: 14px;
	height: 14px;
`;

export const RevalidateCVCSection = styled.section`
	margin: 24px 0;
`;
