import { Product } from '@maverick/entity';
import { isAfter } from 'date-fns';

const HIGHLIGHT_DISPLAY = 'product-highlight-display';
const HIGHLITH_DATE_END = 'product-highlight-date-end';

interface TagExhibition {
	active: boolean;
	type: string | undefined;
}

const TagResponse: TagExhibition = {
	active: false,
	type: '',
};

export const handleTagExhibition = (product: Product | undefined): TagExhibition => {
	if (!product) return TagResponse;

	const tagType = product.metadata?.filter((metadata) => metadata.key.includes(HIGHLIGHT_DISPLAY)) ?? undefined;
	const tagExpDate = product.metadata?.find((metadata) => metadata.key === HIGHLITH_DATE_END)?.value ?? undefined;

	if (tagType?.find((e) => e.value === 'app-only')) {
		return {
			active: true,
			type: 'app-only',
		};
	}

	if (!tagExpDate || !tagType || tagType.length === 0) return TagResponse;
	const newDate = new Date();
	const today = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
	const expDate = new Date(tagExpDate);
	if (isAfter(today, expDate)) return TagResponse;

	return {
		active: true,
		type: tagType[0].value,
	};
};
