import { Dispatch } from 'redux';
import { AppState, appSlice } from './App.slice';

export const AppOperations = {
	setLoading: (loading: AppState['loading']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setLoading(loading));
	},

	setProcessing: (processing: AppState['processing']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setProcessing(processing));
	},

	setRedirectRoute: (route: AppState['redirectRoute']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setRedirectRoute(route));
	},

	clearRedirectRoute: () => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.clearRedirectRoute());
	},

	setRedirectRouteRewards: (route: AppState['redirectRouteRewards']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setRedirectRouteRewards(route));
	},

	clearRedirectRouteRewards: () => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.clearRedirectRouteRewards());
	},

	setRedirectRouteCheckout: (route: AppState['redirectRouteCheckout']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setRedirectRouteCheckout(route));
	},

	clearRedirectRouteCheckout: () => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.clearRedirectRouteCheckout());
	},

	setRedirectRouteEdit: (route: AppState['redirectRouteEdit']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setRedirectRouteEdit(route));
	},

	clearRedirectRouteEdit: () => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.clearRedirectRouteEdit());
	},

	setUserToken: (userToken: AppState['userToken']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setUserToken(userToken));
	},

	clearUserToken: () => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.clearUserToken());
	},

	setShowSummary: (showSummary: AppState['showSummary']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setShowSummary(showSummary));
	},

	setShowHowItWorks: (showHosetShowHowItWorks: AppState['showHowItWorks']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setShowHowItWorks(showHosetShowHowItWorks));
	},

	setAuth0Token: (token: AppState['auth0Token']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setAuth0Token(token));
	},

	clearAuth0Token: () => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.clearAuth0Token());
	},

	setAllowDelivery: (allowDelivery: AppState['allowDelivery']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setAllowDelivery(allowDelivery));
	},

	setFeatureFlagItems: (items: AppState['featureFlagItems']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setFeatureFlagItems(items));
	},

	setFeatureHighlight: (highlightItems: AppState['featureHighlight']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setFeatureFlagHighlight(highlightItems));
	},

	resetApp: () => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.resetApp());
	},
};
