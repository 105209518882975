import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from '@maverick/utils';
import styled, { css } from 'styled-components';

export const TagFeature = styled.div`
	${({ theme }) => theme.orderUi.typography.tagFeature}
	background-color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	border-radius: 4px;
	width: fit-content;
	cursor: default;
`;

export const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
	clip-path: ${({ theme }) => theme.orderUi.productTag.clipPathRight};
	${({ theme }) => theme.orderUi.typography.tagProduct}
	background-color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	padding: 4px 18px 4px 6px;
	font-weight: ${({ theme }) => theme.orderUi.productTag.fontWeight};
	border-top-left-radius: ${({ theme }) => theme.orderUi.productTag.borderTopLeft};
`;

export const IconContent = styled.div`
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
`;

const SMALL_TAG = css`
	font-size: 12px;
	left: ${({ theme }) => theme.orderUi.productTag.smallTag.left};

	::before {
		bottom: -9px;
		width: 9px;
		height: 9px;
	}

	${Content} {
		padding: 4px 18px 4px 6px;
	}
`;

const LARGE_TAG = css`
	font-size: 16px;
	left: ${({ theme }) => theme.orderUi.productTag.largeTag.left};

	::before {
		bottom: -14px;
		width: 13px;
		height: 14px;
	}

	${Content} {
		padding: 4px 20px 4px 8px;
	}
`;

export const TagProduct = styled.div`
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	filter: drop-shadow(0px 2px 2.2px rgba(0, 0, 0, 0.25));
	position: absolute;
	left: ${({ theme }) => theme.orderUi.productTag.left};
	top: ${({ theme }) => theme.orderUi.productTag.top};
	z-index: 1;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 10px;

	${({ theme }) =>
		theme.orderUi.productTag.showClipPathLeft &&
		css`
			::before {
				content: '';
				clip-path: polygon(100% 0, 100% 100%, 0 0);
				background-color: ${({ theme }) => theme.orderUi.colors.primary.medium};
				box-shadow: 0px 2px 2.5px 0px rgba(0, 0, 0, 0.55) inset;
				position: absolute;
				left: 0;
				bottom: -9px;
				width: 9px;
				height: 9px;
			}
		`}

	@media (min-width: 375px) {
		${SMALL_TAG}
	}

	@media (min-width: 500px) {
		${LARGE_TAG}
	}

	@media (min-width: ${TABLET_BREAKPOINT}px) {
		${SMALL_TAG}
	}

	@media (min-width: 1024px) {
		${LARGE_TAG}
	}

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${SMALL_TAG}
	}

	@media (min-width: 1440px) {
		${LARGE_TAG}
	}
`;
