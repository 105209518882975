import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 8px;
`;

export const Product = styled.div`
	${({ theme }) => theme.orderUi.typography.title.subtitle};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const Cost = styled.div`
	${({ theme }) => theme.orderUi.typography.title.subtitle};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	margin-left: 8px;
`;

export const CustomOptions = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	margin-bottom: 8px;
`;

export const Item = styled.span`
	display: inline-block;
`;

export const Modifier = styled.span``;

export const Option = styled.span`
	display: block;
`;

export const Buttons = styled.div`
	display: flex;
	justify-content: left;
	gap: 16px;
`;

export const Section = styled.div``;

export const SpecialInstructionsTitle = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const SpecialInstructions = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	margin-bottom: 8px;
`;
