import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
	RewardWallet,
	UserContactInfo,
	UserFave,
	UserFaveProduct,
	UserRecentOrders,
	UserRewards,
} from '@maverick/entity';

export interface UserStore {
	userRewards: UserRewards | undefined;
	userRedeemableRewards: RewardWallet[] | undefined;
	userRecentOrders: UserRecentOrders | null;
	userFaves: UserFave | null;
	userFaveProducts: UserFaveProduct | null;
	userInfo: UserContactInfo | null;
	userLogged: boolean;
	userAuthLastInteraction: string | null;
}

const initialState: UserStore = {
	userRewards: undefined,
	userRedeemableRewards: undefined,
	userRecentOrders: null,
	userFaves: null,
	userFaveProducts: null,
	userInfo: null,
	userLogged: false,
	userAuthLastInteraction: null
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserRewards: (state, { payload }: PayloadAction<UserStore['userRewards']>) => {
			state.userRewards = payload;
		},

		setUserRedeemableRewards: (state, { payload }: PayloadAction<UserStore['userRedeemableRewards']>) => {
			state.userRedeemableRewards = payload;
		},

		setUserRecentOrders: (state, { payload }: PayloadAction<UserStore['userRecentOrders']>) => {
			state.userRecentOrders = payload;
		},

		setUserFaves: (state, { payload }: PayloadAction<UserStore['userFaves']>) => {
			state.userFaves = payload;
		},

		setUserFaveProducts: (state, { payload }: PayloadAction<UserStore['userFaveProducts']>) => {
			state.userFaveProducts = payload;
		},

		setUserInfo: (state, { payload }: PayloadAction<UserStore['userInfo']>) => {
			state.userInfo = payload;
		},

		setUserLogged: (state, { payload }: PayloadAction<UserStore['userLogged']>) => {
			state.userLogged = payload;
		},

		setUserAuthLastInteraction: (state, { payload }: PayloadAction<UserStore['userAuthLastInteraction']>) => {
			state.userAuthLastInteraction = payload;
		},

		resetUser: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => ({
			...state,
			...action,
		}));
	},
});
