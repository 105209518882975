import { HttpMethods, RestRequest, RestResponse } from '@maverick/http';
import { OloClient } from './client';

const apiVersion = 'v1.1';
interface GetCheckOrderStatusParams {
	orderGuid: string;
}

interface CreateUserFaveParams {
	authToken: string;
	basketId: string;
	description: string;
}

interface DeleteUserFaveParams {
	authToken: string;
	faveId: number;
}

export const OrderReceivedService = {
	GetCheckOrderStatus: async (params: GetCheckOrderStatusParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Get,
			endpoint: `${apiVersion}/orders/${params.orderGuid}`,
		});

		return await OloClient.ExecuteRequest(request);
	},

	CreateUserFave: async (params: CreateUserFaveParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Post,
			endpoint: `${apiVersion}/users/${params.authToken}/faves`,
			body: {
				basketid: params.basketId,
				description: params.description,
				isdefault: false,
			},
		});

		return await OloClient.ExecuteRequest(request);
	},

	DeleteUserFave: async (params: DeleteUserFaveParams): Promise<RestResponse> => {
		const request = new RestRequest({
			method: HttpMethods.Delete,
			endpoint: `${apiVersion}/users/${params.authToken}/faves/${params.faveId}`,
		});

		return await OloClient.ExecuteRequest(request);
	},
} as const;
