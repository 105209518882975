import styled from 'styled-components';

export const Container = styled.div<{}>`
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	position: relative;
	padding-bottom: 12px;
`;

export const Welcome = styled.div<{}>`
	text-transform: ${({ theme }) => theme.orderUi.specificStyle.titleLetterCase};
`;

export const CheckRewards = styled.div<{}>``;

export const Title = styled.div`
	all: unset;
	${({ theme }) => theme.orderUi.typography.headline.h4}
	white-space: nowrap;
	color: ${({ theme }) => theme.orderUi.specificStyle.titleColor};
`;
