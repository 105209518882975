import styled, { css } from 'styled-components';
import { PillProps } from './CategoryPill';
import { TABLET_BREAKPOINT } from '@maverick/utils';
import { Icon } from '@maverick/ui';

export const Pill = styled.div`
	width: max-content;
	white-space: nowrap;
	display: flex;
	text-align: center;
	align-items: center;
	gap: 8px;

	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	padding: 0 16px;

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.body};
	}
`;

export const Container = styled.a<Pick<PillProps, 'selected'>>`
	padding: 16px 0;
	cursor: pointer;


	:not(:last-child) {
		${Pill} {
			border-right: solid 2px ${({ theme }) => theme.orderUi.colors.neutral.medium};
		}
	}

	${({ selected }) =>
		!selected &&
		css`
			@media (hover: hover) and (pointer: fine) {
				&:hover {
					${Pill} {
						color: ${({ theme }) => theme.orderUi.colors.primary.lightVariant};
					}
				}
			}
		`}

	${({ selected }) =>
		selected &&
		css`
			${Pill} {
				color: ${({ theme }) => theme.orderUi.colors.primary.variant};
				${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};

				@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
					${({ theme }) => theme.orderUi.typography.inputs.button};
				}
			}
		`}
`;

export const AppOnlyIcon = styled(Icon)`
	width: 24px;
	height: 24px;
`;
