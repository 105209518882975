import { connect, ConnectedProps } from 'react-redux';
import { RewardsList } from './RewardsList';
import { RootState } from '../../../infrastructure';
import { FeatureFlagKeys, getFeatureFlagItem } from '@maverick/entity';
import { AppOperations } from '../../../features/App/redux/App.operations';
import { CheckoutOperations } from '../../../features/Checkout/redux/Checkout.operations';

const mapStateToProps = (state: RootState) => ({
	selectedRestaurant: state.handoff.selectedRestaurant,
	basket: state.checkout.basket,
	userToken: state.app.userToken,
	userRewards: state.user.userRewards,
	userRedeemableRewards: state.user.userRedeemableRewards,
	processing: state.app.processing,
	skeletonPages: getFeatureFlagItem(state.app.featureFlagItems, FeatureFlagKeys.SkeletonPages),
});

const mapDispatchToProps = {
	setProcessing: AppOperations.setProcessing,
	setBasket: CheckoutOperations.setBasket,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const RewardsListConnected = connector(RewardsList);
export { RewardsListConnected as RewardsList };
