import { FC, createRef, useRef, useEffect, useState } from 'react';
import { Menu, Product, Restaurant } from '@maverick/entity';
import { MenuMargins } from '../RestaurantMenu';
import { useIsMobile, useSpyScrollArea } from '@maverick/hooks';
import * as Styled from './MenuPage.styled';
import { MenuContent } from '../MenuContent';
import { RestaurantHeader, RestaurantNavbar, RestaurantProducts } from '../../../../../shared/components';
import { GaEvent } from '@maverick/utils';

interface MenuPageProps {
	menu: Menu;
	onSelectProduct: (product: Product, name: string) => void;
	changeRestaurant: JSX.Element;
	margins: MenuMargins;
	selectedRestaurant: Restaurant;
}

export const MenuPage: FC<MenuPageProps> = ({
	menu,
	changeRestaurant,
	onSelectProduct,
	margins,
	selectedRestaurant,
}) => {
	const initialRef = menu.categories.map(() => createRef<HTMLDivElement>());
	const categoriesRef = useRef(initialRef);

	const isMobile = useIsMobile(768);
	const margin = margins.navbar + margins.header;
	const padding = isMobile ? 24 : 32;

	const scrollToCategory = (id: number) => {
		const category = categoriesRef?.current.find((category) => category.current?.id === id.toString());

		if (category?.current) {
			setScrollDisabled(true);
			window.scrollTo({ top: category.current.offsetTop - margin - padding, behavior: 'smooth' });
			setHighlightedCategory(id.toString());
			stopListenerOnAutomaticallyScroll();
			const firstProduct = category.current.children[1]?.children[0] as HTMLElement | undefined;
			firstProduct?.focus({ preventScroll: true });
		}
	};

	const [activeCategoryOnScroll, setActiveCategoryOnScroll] = useSpyScrollArea(categoriesRef, {
		start: margin,
		end: margin + 100,
	});

	const [highlightedCategory, setHighlightedCategory] = useState<string>(menu.categories[0].id.toString());
	const [scrollDisabled, setScrollDisabled] = useState<boolean>(false);

	useEffect(() => {
		if (!scrollDisabled && activeCategoryOnScroll) {
			setHighlightedCategory(activeCategoryOnScroll);
		}
	}, [activeCategoryOnScroll]);

	useEffect(() => {
		const categorySelected = menu.categories.find((category) => category.id.toString() === highlightedCategory);
		GaEvent.ViewItemList(categorySelected!, selectedRestaurant.brand);
	}, [highlightedCategory]);

	const stopListenerOnAutomaticallyScroll = () => {
		let isScrolling: any;
		window.addEventListener('scroll', () => {
			window.clearTimeout(isScrolling);
			isScrolling = setTimeout(() => {
				setScrollDisabled(false);
				setActiveCategoryOnScroll('');
			}, 50);
		});
	};

	return (
		<Styled.Container>
			<RestaurantHeader
				changeRestaurant={changeRestaurant}
				navbar={
					<RestaurantNavbar
						categories={menu.categories}
						onClickPill={scrollToCategory}
						highlightedPill={highlightedCategory}
					/>
				}
				margins={margins}
			/>
			<MenuContent margins={margins} fullMenu>
				<RestaurantProducts
					categories={menu.categories}
					imagepath={menu.imagepath}
					onSelectProduct={onSelectProduct}
					categoriesRef={categoriesRef}
				/>
			</MenuContent>
		</Styled.Container>
	);
};
