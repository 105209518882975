import { InputHandler } from '@maverick/hooks';
import { GaEvent } from '@maverick/utils';
import { Helmet } from 'react-helmet';
import { ValidationCreditCard } from '../../../constants';

export const CvcValidator = (cvc: InputHandler<string>): boolean => {
	const { title } = Helmet.peek();
	if (!cvc.value) {
		cvc.setError?.(ValidationCreditCard.insertCvc);
		GaEvent.ErrorMessage(ValidationCreditCard.insertCvc, title);
		return false;
	}
	if (cvc.value?.length < 3 || cvc.value?.length > 4) {
		cvc.setError?.(ValidationCreditCard.invalidCvc);
		GaEvent.ErrorMessage(ValidationCreditCard.invalidCvc, title);
		return false;
	}
	return true;
};
