import { FC, useEffect, useState, useRef, ReactNode } from 'react';
import * as Styled from './RewardsMobile.styled';

import { useIsOnScreen, useScrollDirection } from '@maverick/hooks';

interface RewardsMobileProps {
	children: ReactNode;
}

export const RewardsMobile: FC<RewardsMobileProps> = ({ children }) => {
	const hasScrolled = useScrollDirection();
	const [height, setHeight] = useState<number>(0);
	const bannerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const _height = hasScrolled === 'up' ? 60 : 0;

		setHeight(_height);
	}, [hasScrolled]);

	const isVisible = useIsOnScreen(bannerRef, { threshold: 0.1 });

	useEffect(() => {
		if (isVisible) {
			setHeight(60);
		} else if (hasScrolled !== 'up') {
			setHeight(0);
		}
	}, [isVisible]);

	return (
		<Styled.Container ref={bannerRef}>
			<Styled.MobileBannerColumn
				tabIndex={0}
				id='mobile-banner'
				data-testid='mobile-banner'
				style={{ height: height }}
			>
				{children}
			</Styled.MobileBannerColumn>
		</Styled.Container>
	);
};
