import { Basket, FaveCustomProduct, FaveOrder, HandoffMethods, Restaurant } from '@maverick/entity';
import { CheckoutManager } from '../../../Checkout/Checkout.manager';
import { faveProductNotAvailable, favoriteAdded, warningItemsNotTransferred } from '../../../../shared/constants';
import { GaEvent } from '@maverick/utils';
import { ModalButton, ToastProps } from '@maverick/ui';
import { UserManager } from '../../User.manager';
import * as Styled from './Faves.styled';
import { MenuManager } from '../../../Menu/Menu.manager';
import { OloCodeErrors } from '../../../../shared/constants/OloCodeErrors';

export const createNewBasketFromFave = async (
	pageTitle: string,
	authToken: string | null,
	selectedRestaurant: Restaurant | null,
	fave: FaveOrder,
	basket: Basket | null,
	setToast: (toast: ToastProps) => void,
	setModal: (modal: {
		title?: string | undefined;
		text: string;
		id: string;
		primaryButton: ModalButton;
		secondaryButton?: ModalButton | undefined;
		element?: JSX.Element | undefined;
		outsideClick?: (() => void) | undefined;
	}) => void,
	setLoading: (loading: boolean) => void,
	setBasket: (basket: Basket) => void,
	setShowSummary: (showSummary: boolean) => void,
	redirect: (path: string, options?: any) => void,
	gaErrorMessage: string = ''
) => {
	const handleErrorReorderingFave = () => {
		setToast({
			variant: 'error',
			text: `We're experiencing some problems to reorder your fave. Please try again.`,
		});
		setLoading(false);
		return;
	};
	const updateDeliveryAddress = async (transferredBasketId: string, currentBasket: Basket) => {
		const { city, streetaddress, zipcode, latitude, longitude } = currentBasket.deliveryaddress;
		const deliveryaddress = {
			city: city,
			streetaddress: streetaddress,
			zipcode: zipcode,
			latitude: latitude,
			longitude: longitude,
		};
		const updatedBasket = await CheckoutManager.SetBasketDeliveryAddress(transferredBasketId, deliveryaddress);
		if (updatedBasket.setBasketDeliveryAddressError) {
			GaEvent.ErrorMessage(gaErrorMessage + updatedBasket.setBasketDeliveryAddressError, pageTitle);
		}
		return updatedBasket?.settedAddressToBasket ?? undefined;
	};
	const updateHandoffMethod = async (transferredBasketId: string, currentHandoffMethod: HandoffMethods) => {
		const updatedBasket = await CheckoutManager.SetBasketHandoffMethod(transferredBasketId, currentHandoffMethod);
		if (updatedBasket.setHandoffBasketError) {
			GaEvent.ErrorMessage(gaErrorMessage + updatedBasket.setHandoffBasketError, pageTitle);
		}
		return updatedBasket?.settedHandoffToBasket ?? undefined;
	};
	const transferToCurrentRestaurant = async (faveBasketId: string, currentRestaurantId: number) => {
		const transferredBasket = await CheckoutManager.SetTransferBasket(faveBasketId, currentRestaurantId);
		if (transferredBasket.error) {
			GaEvent.ErrorMessage(gaErrorMessage + transferredBasket.error, pageTitle);
		}
		return {
			transferredBasket: transferredBasket?.transferBasket?.basket ?? undefined,
			itemsNotTransferred: transferredBasket?.transferBasket?.itemsnottransferred,
		};
	};
	const createOrderFromFave = async (authToken: string, faveId: number) => {
		let createdBasketFromFave = await UserManager.CreateBasketFromFave(authToken, faveId);
		if (createdBasketFromFave.error) {
			GaEvent.ErrorMessage(gaErrorMessage + createdBasketFromFave.error.message, pageTitle);
		}
		return createdBasketFromFave?.response ?? undefined;
	};
	const openOrderSummary = () => {
		const event = new CustomEvent('summaryOpen', { detail: true });
		setShowSummary(true);
		document.dispatchEvent(event);
	};

	const createNewBasket = async (currentBasket: Basket | null, createdFaveBasket: Basket | null) => {
		if (!createdFaveBasket) {
			return { newBasket: null, itemsNotTransferred: [] };
		}
		if (currentBasket) {
			const { transferredBasket, itemsNotTransferred } = await transferToCurrentRestaurant(
				createdFaveBasket.id,
				currentBasket.vendorid
			);

			if (!transferredBasket) {
				handleErrorReorderingFave();
				return { newBasket: null, itemsNotTransferred: [] };
			}

			if (currentBasket.deliverymode === 'delivery') {
				const updatedBasket = await updateDeliveryAddress(transferredBasket.id, currentBasket);
				if (!updatedBasket) {
					handleErrorReorderingFave();
					return { newBasket: null, itemsNotTransferred: [] };
				}
				setBasket(updatedBasket);
				return { newBasket: updatedBasket, itemsNotTransferred: itemsNotTransferred };
			} else {
				let handoffMethod = currentBasket.deliverymode as HandoffMethods;
				const updatedBasket = await updateHandoffMethod(transferredBasket.id, handoffMethod);
				if (!updatedBasket) {
					handleErrorReorderingFave();
					return { newBasket: null, itemsNotTransferred: [] };
				}
				setBasket(updatedBasket);
				return { newBasket: updatedBasket, itemsNotTransferred: itemsNotTransferred };
			}
		} else {
			setBasket(createdFaveBasket);
			return { newBasket: createdFaveBasket, itemsNotTransferred: [] };
		}
	};

	setLoading(true);

	if (!authToken || !fave) {
		setLoading(false);
		return;
	}

	const createdFaveBasket = await createOrderFromFave(authToken, fave.id);
	if (!createdFaveBasket) {
		handleErrorReorderingFave();
		return;
	}

	const { newBasket, itemsNotTransferred } = await createNewBasket(basket, createdFaveBasket);
	setLoading(false);
	if (!newBasket) {
		return;
	}

	if (itemsNotTransferred?.length) {
		setToast({
			variant: 'success',
			text: favoriteAdded,
		});

		setModal({
			text: warningItemsNotTransferred,
			primaryButton: {
				label: 'Choose Alternative',
				onClick: () => redirect(`/menu/${selectedRestaurant?.slug}`),
			},
			secondaryButton: {
				label: 'Proceed with Available Items',
				onClick: openOrderSummary,
			},
			element: (
				<div>
					<Styled.TransferTitle>Items unavailable:</Styled.TransferTitle>
					{itemsNotTransferred?.map((item, i) => (
						<Styled.TransferItem key={i}>{item}</Styled.TransferItem>
					))}
				</div>
			),
			id: 'items-not-transfered-modal',
		});
		return;
	} else {
		setToast({
			variant: 'success',
			text: favoriteAdded,
		});
		openOrderSummary();
	}
};

export const createNewBasketFromFaveProduct = async (
	authToken: string | null,
	faveProduct: FaveCustomProduct,
	basket: Basket | null,
	selectedRestaurant: Restaurant | null,
	setModal: (modal: {
		title?: string | undefined;
		text: string;
		id: string;
		primaryButton: ModalButton;
		secondaryButton?: ModalButton | undefined;
		element?: JSX.Element | undefined;
		outsideClick?: (() => void) | undefined;
	}) => void,
	setToast: (toast: ToastProps) => void,
	setLoading: (loading: boolean) => void,
	setBasket: (basket: Basket) => void,
	setShowSummary: (showSummary: boolean) => void,
	redirect: (path: string, options?: any) => void
) => {
	const createNewBasket = async () => {
		if (selectedRestaurant) {
			const { basket, error } = await CheckoutManager.SetBasket(selectedRestaurant.id, authToken);
			if (basket) {
				handleProductOnBasket(basket);
			}

			if (error || !basket) {
				setLoading(false);
				setToast({
					variant: 'error',
					text: 'An error occurred. ' + error,
				});
				return;
			}
		}
	};

	const handleProductOnBasket = async (currentBasket: Basket) => {
		const { response, error } = await MenuManager.AddProductToBasketByChainId(
			currentBasket.id,
			faveProduct.id,
			faveProduct.product.quantity,
			faveProduct.product.specialinstructions,
			faveProduct.product.recipient,
			faveProduct.product.chainproductid ? faveProduct.product.chainproductid.toString() : '',
			faveProduct.product.choices
		);

		if (error?.code === OloCodeErrors.OloInternalError) {
			return setModal({
				text: faveProductNotAvailable,
				primaryButton: {
					label: 'Choose Alternative',
					onClick: () => redirect(`/`),
				},
				secondaryButton: {
					label: 'Proceed with Available Items',
					onClick: () => {},
				},
				element: (
					<div>
						<Styled.TransferTitle>Item unavailable:</Styled.TransferTitle>
						{<Styled.TransferItem key={0}>{faveProduct.product.name}</Styled.TransferItem>}
					</div>
				),
				id: 'not-available-product-modal',
			});
		}

		if (error || !response) {
			setToast({
				variant: 'error',
				text: 'An error occurred. ' + error?.message,
			});

			setLoading(false);
			return error;
		}

		setBasket(response);
		setShowSummary(true);
		document.dispatchEvent(new CustomEvent('summaryOpen', { detail: true }));
	};

	if (!authToken || !faveProduct) {
		setLoading(false);
		return;
	}

	if (basket) {
		handleProductOnBasket(basket);
	} else {
		createNewBasket();
	}
};
