import { Basket, GiftCardItem } from '@maverick/entity';
import { FC } from 'react';
import { ReduxProps } from '.';
import { UtilsMoneyFormatter } from '@maverick/utils';
import * as Styled from './OrderPaymentDetails.styled';
import { paymentDetails } from '../../../features/OrderReceived/views/OrderReceived/OrderReceived';
import { calculateBasketValue } from '../../../utils/GeneralUtils';
import { HandoffPaymentDetails } from './HandoffPaymentDetails';
import { DiscountsPaymentDetails } from './DiscountsPaymentDetails';
import { GiftCardPaymentDetails } from './GiftCardPaymentDetails';

export interface OrderPaymentDetailsProps extends ReduxProps {
	hideRemoveButton?: boolean;
	title?: string;
	titleVariant?: 'primary' | 'secondary';
	paymentDetails?: paymentDetails;
	hideTip?: boolean;
	isEstimated?: boolean;
	onRemove?: () => Promise<boolean | void>;
}
export const OrderPaymentDetails: FC<OrderPaymentDetailsProps> = ({
	basket,
	giftCardBasket,
	giftCardLastOrder,
	title = 'Order summary',
	titleVariant = 'primary',
	paymentDetails,
	hideRemoveButton = false,
	hideTip = false,
	isEstimated = false,
	onRemove,
}) => {
	const calculatePaidValue = (paymentDetails: paymentDetails | undefined, giftCardLastOrder: Array<GiftCardItem>) => {
		if (!paymentDetails) return 0;
		const deducePaidValue =
			paymentDetails.total - giftCardLastOrder.reduce((previous, current) => current.balance + previous, 0);
		return deducePaidValue < 0 ? 0 : deducePaidValue;
	};

	const totalValue = (
		basket: Basket | null,
		paymentDetails: paymentDetails | undefined,
		giftCardBasket: Array<GiftCardItem>,
		giftCardLastOrder: Array<GiftCardItem>,
		hideTip: boolean
	) => {
		if (!paymentDetails) {
			const calculatedBasketValue = calculateBasketValue(basket, giftCardBasket) - (hideTip ? basket!.tip : 0);
			return UtilsMoneyFormatter.format(Math.max(calculatedBasketValue, 0));
		} else {
			const calculatedPaidValue =
				calculatePaidValue(paymentDetails, giftCardLastOrder) -
				(hideTip ? paymentDetails?.tip ?? basket!.tip : 0);
			return UtilsMoneyFormatter.format(Math.max(calculatedPaidValue, 0));
		}
	};

	if (!basket && !paymentDetails) {
		return null;
	}

	return (
		<Styled.Container id='order-payment-details' data-testid='order-payment-details'>
			{titleVariant === 'secondary' && (
				<Styled.Title id='title-optionally' data-testid='title-optionally'>
					{title}
				</Styled.Title>
			)}
			{titleVariant === 'primary' && (
				<Styled.SectionTitle id='title' data-testid='title'>
					{title}
				</Styled.SectionTitle>
			)}
			<Styled.OrderSummary>
				<Styled.Tax id='subtotal' data-testid='subtotal'>
					Subtotal
				</Styled.Tax>
				<Styled.Value id='subtotal-value' data-testid='subtotal-value'>
					{UtilsMoneyFormatter.format(paymentDetails?.subTotal || basket?.subtotal || 0)}
				</Styled.Value>
				<Styled.Tax id='tax' data-testid='tax'>
					{isEstimated ? 'Tax (est.)' : 'Tax'}
				</Styled.Tax>
				<Styled.Value id='tax-value' data-testid='tax-value'>
					{UtilsMoneyFormatter.format(paymentDetails?.salestax || basket?.salestax || 0)}
				</Styled.Value>
				<HandoffPaymentDetails data-testid='handoff-payment-details' paymentDetails={paymentDetails} />
				{!hideTip && (
					<>
						<Styled.Tax id='tip' data-testid='tip'>
							Tip
						</Styled.Tax>
						<Styled.Value id='tip-value' data-testid='tip-value'>
							{UtilsMoneyFormatter.format(paymentDetails?.tip || basket?.tip || 0)}
						</Styled.Value>
					</>
				)}
				<DiscountsPaymentDetails
					data-testid='discounts-payment-details'
					paymentDetails={paymentDetails}
					hideRemoveButton={hideRemoveButton}
					onRemove={onRemove}
				/>
				<GiftCardPaymentDetails
					data-testid='gift-card-payment-details'
					paymentDetails={paymentDetails}
					hideRemoveButton={hideRemoveButton}
				/>
				<Styled.Subtitle id='total' data-testid='total'>
					Total
				</Styled.Subtitle>
				<Styled.TotalValue id='total-value' data-testid='total-value'>
					{totalValue(basket, paymentDetails, giftCardBasket, giftCardLastOrder, hideTip)}
				</Styled.TotalValue>
			</Styled.OrderSummary>
		</Styled.Container>
	);
};
