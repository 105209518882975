import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const ButtonItems = styled.div`
	display: flex;
	align-items: center;
`;

export const Icon = styled.div`
	color: ${({ theme }) => theme.orderUi.specificStyle.profileButtonTextColor};
	height: 30px;
	width: 30px;
	margin-right: 24px;
`;

export const Button = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.h4}
	color: ${({ theme }) => theme.orderUi.specificStyle.profileButtonTextColor};
	text-transform: ${({ theme }) => theme.orderUi.specificStyle.titleLetterCase};
`;

export const IconArrow = styled.div`
	display: flex;
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	width: 12px;
	height: 20px;
	margin-left: 8px;
`;

export const ButtonContainer = styled.button`
	background: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: solid 1px;
	border-color: ${({ theme }) => theme.orderUi.colors.secondary.dark};
	border-radius: 4px;
	padding: 24px;
	cursor: pointer;
	${({ theme }) => theme.orderUi.specificStyle.profileButton};

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		width: 380px;
		height: ${({ theme }) => theme.orderUi.specificStyle.profileButtonHeight};
	}

	:hover {
		${Icon} {
			color: ${({ theme }) => theme.orderUi.colors.primary.light};
		}
		${Button} {
			color: ${({ theme }) => theme.orderUi.colors.primary.light};
		}
		${IconArrow} {
			color: ${({ theme }) => theme.orderUi.colors.primary.light};
		}
	}
`;
