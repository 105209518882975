import styled from 'styled-components';
import { DESKTOP_BREAKPOINT } from '@maverick/utils';

export const Container = styled.div`
	position: relative;
	cursor: pointer;

	&:focus-visible {
		outline: -webkit-focus-ring-color auto 1px;
	}
`;

export const ProductContainer = styled.div`
	aspect-ratio: 1/1;
`;

export const ProductImageContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

export const ProductName = styled.h3`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium};
	padding: 8px;
	width: 100%;
	position: absolute;
	bottom: 0;
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	background: rgba(0, 0, 0, 0.75);
	border-radius: 0 0 2px 2px;

	@media screen and (min-width: 500px) {
		${({ theme }) => theme.typography.caption.captionLarge};
	}

	@media screen and (min-width: 768px) {
		${({ theme }) => theme.typography.caption.captionMedium};
	}

	@media screen and (min-width: 1024px) {
		${({ theme }) => theme.typography.caption.captionLarge};
	}

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.typography.caption.captionMedium};
	}

	@media screen and (min-width: 1440px) {
		${({ theme }) => theme.typography.caption.captionLarge};
	}
`;

export const ProductInfoContainer = styled.span`
	margin-top: 8px;
	display: flex;
	align-items: baseline;
`;

export const ProductCalories = styled.span<{ fullWidth?: boolean }>`
	width: ${(props) => (props.fullWidth ? '100%' : '50%')};
	display: flex;
	justify-content: baseline;
	align-items: center;
	${({ theme }) => theme.orderUi.typography.body.bodySmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;

export const ProductCost = styled.span<{ fullWidth?: boolean }>`
	/*TODO: Decidir se as calorias serão exibidas ou não*/
	/* width: ${(props) => (props.fullWidth ? '100%' : '50%')}; */
	width: 100%;
	display: flex;
	justify-content: end;
	align-items: center;
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};
`;
