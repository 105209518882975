import { FC } from 'react';
import * as Styled from './ProductSummary.styled';
import { Button } from '@maverick/ui';
import {
	Basket,
	BasketProduct,
	Choice,
	Choices,
	FaveChoice,
	FaveProduct,
	OrderStatusProduct,
	RecentOrderProduct,
} from '@maverick/entity';
import { UtilsMoneyFormatter } from '@maverick/utils';

export interface SummaryFunctionsProps {
	label: string;
	function: Function;
}
interface ProductSummaryProps {
	product?: BasketProduct | OrderStatusProduct | RecentOrderProduct;
	fave?: FaveProduct;
	basket?: Basket;
	summaryFunctions?: Array<SummaryFunctionsProps>;
	hiddenCost?: boolean;
	basketIndex?: number;
}

export const ProductSummary: FC<ProductSummaryProps> = ({
	product,
	fave,
	basket,
	summaryFunctions,
	hiddenCost,
	basketIndex = 0,
}) => {
	const customOptions: FC<Choice | Choices | FaveChoice> = (choice) => {
		const existsChoice = choice as Choice;

		if (existsChoice.indent !== undefined) {
			if (existsChoice.indent === 0) {
				return <Styled.Item>{choice.name}</Styled.Item>;
			} else {
				return <Styled.Modifier>- {choice.name}</Styled.Modifier>;
			}
		}
		return <Styled.Modifier>{choice.name}</Styled.Modifier>;
	};

	if (product) {
		return (
			<Styled.Container role='listitem' tabIndex={0}>
				<Styled.Header>
					<Styled.Product data-testid={`product-name-${basketIndex}`}>{product.name}</Styled.Product>
					{!hiddenCost && (
						<Styled.Cost data-testid={`product-cost-${basketIndex}`}>
							{UtilsMoneyFormatter.format(product.totalcost)}
						</Styled.Cost>
					)}
				</Styled.Header>
				<Styled.CustomOptions>
					{product.choices.map((choice, index) => (
						<Styled.Option key={choice.id} data-testid={`product-choice-${basketIndex}-${index}`}>
							{customOptions(choice)}
						</Styled.Option>
					))}
				</Styled.CustomOptions>
				{product.specialinstructions && (
					<Styled.Section data-testid={`special-instructions-${basketIndex}`}>
						<Styled.SpecialInstructionsTitle>{`Special Instructions`}</Styled.SpecialInstructionsTitle>
						<Styled.SpecialInstructions
							data-testid={`special-instructions-text-${basketIndex}`}
						>{`- ${product.specialinstructions}`}</Styled.SpecialInstructions>
					</Styled.Section>
				)}
				{!!summaryFunctions && (
					<Styled.Buttons data-testid='summary-buttons'>
						{summaryFunctions.map((func) => (
							<Button
								text={func.label}
								variant='terciary'
								onClick={() => func.function(basket, product)}
								key={func.label}
								id={`${basketIndex}-${func.label.toLowerCase()}`}
							/>
						))}
					</Styled.Buttons>
				)}
			</Styled.Container>
		);
	}
	if (fave) {
		return (
			<Styled.Container>
				<Styled.Header>
					<Styled.Product data-testid={`product-name-${basketIndex}`}>{fave.name}</Styled.Product>
				</Styled.Header>
				<Styled.CustomOptions>
					{fave.choices.map((choice, index) => (
						<Styled.Option key={choice.optionid} data-testid={`product-choice-${basketIndex}-${index}`}>
							{customOptions(choice)}
						</Styled.Option>
					))}
				</Styled.CustomOptions>
				{!!summaryFunctions && (
					<Styled.Buttons data-testid='summary-buttons'>
						{summaryFunctions.map((func) => (
							<Button
								text={func.label}
								variant='terciary'
								onClick={() => func.function(basket, fave)}
								key={func.label}
								id={`${basketIndex}-${func.label.toLowerCase()}`}
							/>
						))}
					</Styled.Buttons>
				)}
			</Styled.Container>
		);
	}
	return <></>;
};
