import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../infrastructure';
import { HeaderInteraction } from './HeaderInteraction';
import { AppOperations } from '../../../features/App/redux/App.operations';
import { CheckoutOperations } from '../../../features/Checkout/redux/Checkout.operations';
import { UserOperations } from '../../../features/User/redux/User.operations';

const mapStateToProps = (state: RootState) => ({
	basket: state.checkout.basket,
	showSummary: state.app.showSummary,
	userToken: state.app.userToken,
	userLogged: state.user.userLogged,
});

const mapDispatchToProps = {
	setRedirectRoute: AppOperations.setRedirectRoute,
	setShowSummary: AppOperations.setShowSummary,
	setLoading: AppOperations.setLoading,
	setBasket: CheckoutOperations.setBasket,
	setUserLogged: UserOperations.setUserLogged,
	setUserAuthLastInteraction: UserOperations.setUserAuthLastInteraction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
const HeaderInteractionConnected = connector(HeaderInteraction);
export { HeaderInteractionConnected as HeaderInteraction };
