import { Icon } from '@maverick/ui';
import { FC } from 'react';
import * as Styled from './NutritionalInformation.styled';
import { useTheme } from 'styled-components';
import { IconArrowRight } from '@maverick/icons/dist/IconArrowRight';
import { Link } from 'react-router-dom';
interface NutritionalInformationProps {
	productName: string;
}

export const NutritionalInformation: FC<NutritionalInformationProps> = ({ productName }) => {
	const theme = useTheme();

	const nutritionalInformationIndex = productName.split('*').length - 1;

	return (
		<Styled.Container data-testid='info'>
			<Styled.NutritionalInformation>
				<Styled.Information>
					<Styled.InfoSubtitle data-testid='info-subtitle'>Nutritional information</Styled.InfoSubtitle>

					{nutritionalInformationIndex === 1 && (
						<Styled.InfoDescription data-testid='info-description'>
							*These items are cooked to order. Consuming raw or undercooked meats, poultry, seafood,
							shellfish, or eggs which may contain harmful bacteria may increase your risk of foodborne
							illness, especially if you have certain medical conditions.
						</Styled.InfoDescription>
					)}
					{nutritionalInformationIndex === 2 && (
						<Styled.InfoDescription data-testid='info-second-description'>
							**Item contains or may contain nuts.
						</Styled.InfoDescription>
					)}

					<Styled.InfoDescription data-testid='info-third-description'>
						2,000 calories a day is used for general nutrition advice, but calorie needs vary.
					</Styled.InfoDescription>
				</Styled.Information>

				<Styled.Line />
				<Styled.LinkAdditional>
					<Link
						to={theme.orderUi.links.nutritionAdditionalInfo}
						data-testid='additional-nutrition-button'
						title='Open additional nutrition information in a new tab'
						target='_blank'
					>
						<Styled.LinkAdditionalText>
							<strong>Additional nutrition information</strong>
						</Styled.LinkAdditionalText>
						<Styled.IconArrow>
							<Icon icon={IconArrowRight} customSize />
						</Styled.IconArrow>
					</Link>
				</Styled.LinkAdditional>
			</Styled.NutritionalInformation>
		</Styled.Container>
	);
};
