import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.div``;

export const CategorySection = styled.div`
	margin-bottom: 24px;

	@media screen and (min-width: 768px) {
		margin-bottom: 32px;
	}
`;

export const ProductsCardContainer = styled.div`
	margin-top: 16px;
	display: grid;
	gap: 24px;
	grid-template-columns: 1fr 1fr;

	@media screen and (min-width: 768px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		grid-template-columns: repeat(6, 1fr);
	}
`;
