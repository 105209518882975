import { FC, ReactNode, useState, useRef, useLayoutEffect } from 'react';
import * as Styled from './Tooltip.styled';

import { UtilsCalculateDisplayTime, UtilsElementPosition } from '@maverick/utils';
import { useOS } from '@maverick/hooks';

interface TooltipProps {
	variant?: 'small' | 'large';
	description: string;
	children: ReactNode;
	id: string;
	transformTooltip?: boolean;
	tabIndex?: number;
}

export const Tooltip: FC<TooltipProps> = ({ variant, description, children, id, transformTooltip, tabIndex }) => {
	const userOS = useOS();
	const isMobile = userOS.isAndroid || userOS.isiOS;
	const screenPadding = isMobile ? 24 : 32;
	const anchorRef = useRef<HTMLDivElement>(null);
	const timeoutRef = useRef<number | null>(null);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const tooltipTimeout = UtilsCalculateDisplayTime(description);

	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const [isLeft, setIsLeft] = useState<boolean>(false);
	const [isTop, setIsTop] = useState<boolean>(false);
	const [tooltipMaxWidth, setTooltipMaxWidth] = useState<number>(0);
	const [anchorHeight, setAnchorHeight] = useState<number>(0);
	const [anchorWidth, setAnchorWidth] = useState<number>(0);

	useLayoutEffect(() => {
		const onResize = () => {
			setShowTooltip(false);

			if (!anchorRef.current) return;

			const windowWidth = window.innerWidth;
			const windowHeight = window.innerHeight;
			const anchorX = UtilsElementPosition(anchorRef).x;
			const anchorY = anchorRef.current.getBoundingClientRect().top;
			const _anchorWidth = anchorRef.current.clientWidth;
			const _anchorHeight = anchorRef.current.clientHeight;

			const isOnLeft = anchorX + _anchorWidth / 2 <= windowWidth / 2;
			const isOnTop = anchorY <= windowHeight / 2;

			let _tooltipMaxWidth = 0;

			if (isOnLeft) {
				_tooltipMaxWidth = windowWidth - anchorX - screenPadding;
			} else {
				_tooltipMaxWidth = windowWidth - (windowWidth - anchorX - _anchorWidth) - screenPadding;
			}

			setTooltipMaxWidth(_tooltipMaxWidth);
			setIsLeft(isOnLeft);
			setIsTop(isOnTop);
			setAnchorWidth(_anchorWidth ?? 0);
			setAnchorHeight(_anchorHeight ?? 0);
		};

		setTimeout(() => {
			onResize();
		}, 2000);

		window.addEventListener('resize', onResize);
		window.addEventListener('scroll', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
			window.removeEventListener('scroll', onResize);
		};
	}, [anchorRef?.current]);

	const handleClick = () => {
		if (!isMobile) return;

		if (showTooltip && timeoutRef.current) {
			setShowTooltip(false);
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
			return;
		}

		setShowTooltip(true);
		timeoutRef.current = window.setTimeout(() => {
			setShowTooltip(false);
		}, tooltipTimeout);
	};

	const handleHover = (visible: boolean) => {
		if (!isMobile) setShowTooltip(visible);
	};

	return (
		<Styled.Container>
			<Styled.AchorElement
				tabIndex={tabIndex}
				aria-describedby={id}
				aria-label={isMobile ? 'Click for more details' : undefined}
				ref={anchorRef}
				onClick={handleClick}
				onFocus={() => handleHover(true)}
				onBlur={() => handleHover(false)}
				onMouseEnter={() => handleHover(true)}
				onMouseLeave={() => handleHover(false)}
			>
				{children}
			</Styled.AchorElement>
			{!transformTooltip && showTooltip ? (
				<Styled.TooltipContainer
					isLeft={isLeft}
					isTop={isTop}
					anchorHeight={anchorHeight}
					anchorWidth={anchorWidth}
					ref={tooltipRef}
					role='tooltip'
					id={id}
					data-testid='tooltip'
				>
					<Styled.Content isLeft={isLeft} isTop={isTop} maxWidth={tooltipMaxWidth} variant={variant}>
						<Styled.Text>{description}</Styled.Text>
					</Styled.Content>
				</Styled.TooltipContainer>
			) : null}
			{transformTooltip && showTooltip ? (
				<Styled.TooltipContainerTransform ref={tooltipRef} role='tooltip' id={id} data-testid='tooltip'>
					<Styled.ContentTransform maxWidth={tooltipMaxWidth}>
						<Styled.Text>{description}</Styled.Text>
					</Styled.ContentTransform>
				</Styled.TooltipContainerTransform>
			) : null}
		</Styled.Container>
	);
};
