import React from 'react';
import { FC } from 'react';
import { ReduxProps } from '.';
import { UtilsMoneyFormatter } from '@maverick/utils';
import { Button } from '@maverick/ui';
import * as Styled from '../OrderPaymentDetails.styled';
import { GiftCardItem } from '@maverick/entity';
import { paymentDetails } from '../../../../features/OrderReceived/views/OrderReceived/OrderReceived';

export interface GiftCardPaymentDetailsProps extends ReduxProps {
	paymentDetails?: paymentDetails;
	hideRemoveButton?: boolean;
}

export const GiftCardPaymentDetails: FC<GiftCardPaymentDetailsProps> = ({
	basket,
	paymentDetails,
	giftCardBasket,
	giftCardLastOrder,
	setGiftCard,
	hideRemoveButton = false,
}) => {
	const getGiftCardBalance = (currentGiftCard: GiftCardItem, index: number) => {
		const giftCardTotalWithoutCurrent = giftCardBasket
			.slice(0, index)
			.reduce((previous, current) => current.balance + previous, 0);

		let totalWithoutCurrentCard = 0;

		if (paymentDetails) {
			totalWithoutCurrentCard = paymentDetails.total - giftCardTotalWithoutCurrent;
		} else {
			totalWithoutCurrentCard = basket!.total - giftCardTotalWithoutCurrent;
		}

		if (totalWithoutCurrentCard < 0) {
			return 0;
		}
		const basketValue = totalWithoutCurrentCard - currentGiftCard.balance;
		return basketValue < 0 ? totalWithoutCurrentCard : currentGiftCard.balance;
	};

	const handleRemoveGiftCard = (index: number) => {
		const giftCardStateCopy = [...giftCardBasket];
		giftCardStateCopy.splice(index, 1);
		setGiftCard(giftCardStateCopy);
	};

	const isOrderReceived = window.location.href.includes('/order-received');
	const showGiftCardLastOrder = giftCardBasket.length === 0 && !!giftCardLastOrder.length && isOrderReceived;
	return (
		<>
			{!!giftCardBasket &&
				giftCardBasket.map((giftCard, index) => (
					<React.Fragment key={index}>
						<Styled.Tax id='gift-card' data-testid='gift-card'>
							<Styled.Remove>
								<div>Gift Card - {giftCard.cardnumber.substring(15)}</div>
								{hideRemoveButton ? null : (
									<div>
										<Button
											text='Remove'
											variant='terciary'
											onClick={() => handleRemoveGiftCard(index)}
											id={'remove-gift-card'}
										/>
									</div>
								)}
							</Styled.Remove>
						</Styled.Tax>
						<Styled.Value id='gift-card-value' data-testid='gift-card-value'>
							-{UtilsMoneyFormatter.format(getGiftCardBalance(giftCard, index))}
						</Styled.Value>
					</React.Fragment>
				))}
			{showGiftCardLastOrder &&
				giftCardLastOrder.map((giftCard, index) => (
					<React.Fragment key={index}>
						<Styled.Tax id='gift-card-last-order' data-testid='gift-card-last-order'>
							<Styled.GiftCard>Gift Card - {giftCard.cardnumber.substring(15)}</Styled.GiftCard>
						</Styled.Tax>
						<Styled.Value id='gift-card-last-order-value' data-testid='gift-card-last-order-value'>
							-{UtilsMoneyFormatter.format(getGiftCardBalance(giftCard, index))}
						</Styled.Value>
					</React.Fragment>
				))}
		</>
	);
};
