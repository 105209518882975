import styled, { css } from 'styled-components';

export const Container = styled.div<{ hideArrows: boolean }>`
	width: max-content;
	max-width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;

	@media screen and (min-width: 768px) {
		padding: 0 ${({ hideArrows }) => (hideArrows ? '0' : '32px')};
	}
`;

export const PillsContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: row;
	overflow-x: scroll;
	max-width: max-content;
	scroll-behavior: smooth;
	padding: 0 8px;

	-ms-overflow-style: none;
	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: 768px) {
		padding: 0 16px;
	}
`;

export const ArrowContainer = styled.div<{ isRight?: boolean }>`
	position: absolute;

	${({ isRight }) =>
		isRight &&
		css`
			right: 30px;
		`}
	${({ isRight }) =>
		!isRight &&
		css`
			left: 30px;
		`}
`;
