import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../Store';
import { RoutesGate } from './RoutesGate';

const mapStateToProps = (state: RootState) => ({
	state,
});

const connector = connect(mapStateToProps);
const RoutesGateConnected = connector(RoutesGate);
export { RoutesGateConnected as RoutesGate };
export type ReduxProps = ConnectedProps<typeof connector>;
