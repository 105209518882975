import { FC, useEffect, useState, useLayoutEffect } from 'react';
import * as Styled from './RestaurantMenu.styled';
import { ReduxProps } from '.';
import { GaEvent, handleRestaurantInfo } from '@maverick/utils';
import { HandoffMethods, Product } from '@maverick/entity';
import { useRedirect } from '../../../../shared/hooks';
import { ChangeRestaurant } from '../../../../shared/components';
import { useLocation, useParams } from 'react-router-dom';
import { useModal } from '@maverick/ui';
import { warningItemsNotTransferred } from '../../../../shared/constants';
import { CategoryPage } from './CategoryPage';
import { MenuPage } from './MenuPage';
import { Helmet } from 'react-helmet';
import { useTheme } from 'styled-components';
import { handleTagExhibition } from '../../../../utils/MenuUtils';

interface RestaurantMenuProps extends ReduxProps {
	fullMenu?: boolean;
}

export type MenuMargins = {
	header: number;
	navbar: number;
};

export const RestaurantMenu: FC<RestaurantMenuProps> = ({
	fullMenu,
	menu,
	deliveryAddress,
	method,
	selectedRestaurantAddress,
	selectedRestaurant,
	itemsNotTransferred,
	redirectRouteCheckout,
	redirectRouteEdit,
	setLoading,
	setRedirectRouteMenu,
	clearRedirectRouteCheckout,
	clearRedirectRouteEdit,
	clearItemsNotTransferred,
	setShowSummary,
	setAddCartOrigin,
}) => {
	const redirect = useRedirect();
	const location = useLocation();
	const { setModal } = useModal();
	const { orderUi } = useTheme();
	const baseUrl = String(process.env.ORDER_URL);
	const { categoryId } = useParams();
	const { restaurantName } = handleRestaurantInfo(selectedRestaurant!);

	const handleProceedWithAvailableItems = () => {
		clearItemsNotTransferred();
		setShowSummary(true);
	};

	const setItemsNotTransferredModal = () => {
		setModal({
			text: warningItemsNotTransferred,
			primaryButton: {
				label: 'Choose Alternative',
				onClick: clearItemsNotTransferred,
			},
			secondaryButton: {
				label: 'Proceed with Available Items',
				onClick: handleProceedWithAvailableItems,
			},
			outsideClick: handleProceedWithAvailableItems,
			element: (
				<div>
					<Styled.TransferTitle>Items unavailable:</Styled.TransferTitle>
					{itemsNotTransferred?.map((item, i) => (
						<Styled.TransferItem key={i}>{item}</Styled.TransferItem>
					))}
				</div>
			),
			id: 'items-not-transfered-modal',
		});
	};

	const initMenu = async () => {
		if (itemsNotTransferred && itemsNotTransferred.length) {
			setItemsNotTransferredModal();
		} else if (redirectRouteCheckout || redirectRouteEdit) {
			setShowSummary(true);
		}

		if (!!redirectRouteCheckout) {
			clearRedirectRouteCheckout();
		} else if (!!redirectRouteEdit) {
			clearRedirectRouteEdit();
		}
	};

	useEffect(() => {
		initMenu();
	}, []);

	useEffect(() => {
		setRedirectRouteMenu(location.pathname);
	}, [fullMenu]);

	const [menuHeaderHeight, setMenuHeaderHeight] = useState<number>(0);
	const [navbarHeight, setNavbarHeight] = useState<number>(0);
	const [margins, setMargins] = useState<MenuMargins>({ navbar: 0, header: 0 });

	useLayoutEffect(() => {
		const navbar = document.getElementById('header');
		const menuHeader = document.getElementById('menu-header');

		const updateMarginOnResize = () => {
			if (menuHeader) {
				setMenuHeaderHeight(menuHeader.clientHeight);
			}

			if (navbar) {
				setNavbarHeight(navbar.clientHeight);
			}
		};

		updateMarginOnResize();

		window.addEventListener('resize', updateMarginOnResize);

		return () => {
			window.removeEventListener('resize', updateMarginOnResize);
		};
	}, [menu, fullMenu]);

	useEffect(() => {
		setMargins({ ...margins, header: menuHeaderHeight, navbar: navbarHeight });
	}, [menuHeaderHeight, navbarHeight]);

	const onSelectProduct = (product: Product, categoryName: string) => {
		setLoading(true);

		GaEvent.SelectItem(product, selectedRestaurant!.id, selectedRestaurant!.brand, categoryName, 'select_item');
		const displayTag = handleTagExhibition(product);
		if (!!displayTag.type && displayTag.active) {
			GaEvent.SelectItem(
				product,
				selectedRestaurant!.id,
				selectedRestaurant!.brand,
				categoryName,
				'product_highlight'
			);
		}

		setAddCartOrigin('menu page');

		redirect(`/menu/${selectedRestaurant!.slug}/products/${product.id}`);
	};

	const retrieveMetaTag = (url: string) => (
		<Helmet>
			<title>{`${orderUi.app.name} - ${restaurantName} - Order Online`}</title>

			<link rel='canonical' href={url} />

			<meta property='og:title' content={`${restaurantName} - Order Online`}></meta>
			<meta property='og:type' content='restaurant'></meta>
			<meta property='og:image' content={orderUi.links.logoMeta}></meta>
			<meta property='og:url' content={window.location.href}></meta>
			<meta
				property='og:description'
				content={`Order Online at ${restaurantName}, ${selectedRestaurant!.city}. Pay Ahead and Skip the Line.`}
			></meta>
			<meta property='og:locale:alternate' content='en_US'></meta>
			<meta property='og:site_name' content={orderUi.app.name}></meta>
		</Helmet>
	);

	const changeRestaurant = (
		<ChangeRestaurant
			method={method}
			address={method === HandoffMethods.Delivery ? deliveryAddress?.streetaddress : selectedRestaurantAddress}
			displayOnly={false}
			redirect={() => {
				redirect('/');
			}}
			restaurantName={restaurantName}
		/>
	);

	if (!menu) return null;

	if (fullMenu) {
		const metaTag = retrieveMetaTag(`${baseUrl}/menu/${selectedRestaurant?.slug}`);
		return (
			<>
				{metaTag}
				<MenuPage
					menu={menu}
					changeRestaurant={changeRestaurant}
					onSelectProduct={onSelectProduct}
					margins={margins}
					selectedRestaurant={selectedRestaurant!}
				/>
			</>
		);
	}

	const metaTag = retrieveMetaTag(`${baseUrl}/menu/${selectedRestaurant?.slug}/categories/${categoryId}`);

	return (
		<>
			{metaTag}
			<CategoryPage
				menu={menu}
				changeRestaurant={changeRestaurant}
				onSelectProduct={onSelectProduct}
				margins={margins}
			/>
		</>
	);
};
