import { LoggerFrontend, LoggerFrontendApps } from '@maverick/logger/dist/Logger.frontend';
import { LogData } from '@maverick/logger/dist/Logger';
import { store } from '../Store';

const getBaseInfo = () => {
	const state = store.getState();
	return {
		isAuthenticated: !!state.app.auth0Token,
		firstName: state.user.userInfo?.firstName,
		lastName: state.user.userInfo?.lastName,
		phone: state.user.userInfo?.phone,
		email: state.user.userInfo?.email,
	};
};

class OrderLog {
	private readonly logger: LoggerFrontend;

	constructor() {
		this.logger = new LoggerFrontend(LoggerFrontendApps.Order, '/order');
	}

	Info(context: string, data: LogData): void {
		this.logger.Info(context, { ...data, baseInfo: getBaseInfo() });
	}

	Warn(context: string, data: LogData): void {
		this.logger.Warn(context, { ...data, baseInfo: getBaseInfo() });
	}

	Error(context: string, data: LogData): void {
		this.logger.Error(context, { ...data, baseInfo: getBaseInfo() });
	}
}

export const Log = new OrderLog();
