import { FC, useRef } from 'react';
import * as Styled from './HowItWorks.styled';
import { useTheme } from 'styled-components';
import { ReduxProps } from './index';
import { useRedirect } from '../../../../shared/hooks';
import { Button, Icon } from '@maverick/ui';
import { Title } from '../../../../shared/components';
import { ContentCard } from '../../../../shared/components/ContentCard/ContentCard';
import { IconClose } from '@maverick/icons/dist/IconClose';

export interface HowItWorksProps extends ReduxProps {
	onClose: () => void;
	setShowHowItWorks: (value: boolean) => void;
}

export const HowItWorks: FC<HowItWorksProps> = ({ onClose, setShowHowItWorks, selectedRestaurant }) => {
	const { orderUi } = useTheme();
	const redirect = useRedirect();

	const howItWorks = useRef<HTMLDivElement>(null);

	const cardContents = [
		{
			icon: orderUi.icons.iconRecentOrders,
			title: 'Eat',
			content:
				'Experience the flavors and taste the rewards at your favorite restaurants! Get 5 points for every qualified dollar you spend on food and non-alcoholic beverages at Outback Steakhouse, Carrabba’s Italian Grill, Bonefish Grill and Fleming’s Prime Steakhouse & Wine Bar.',
		},
		{
			icon: orderUi.icons.iconEarn,
			title: 'Earn',
			content:
				'Earn 350 points for dining in, taking out or ordering delivery and they’ll automatically convert to a sweet $5 off reward. There are no limits to how many rewards you can earn either!',
		},
		{
			icon: orderUi.icons.iconDollarSign,
			title: 'Redeem',
			content: 'Your $5 off rewards are valid for 90 days. Time to savor the sweet taste of savings!',
		},
	];

	const redirectToOrder = () => {
		setShowHowItWorks(false);
		window.dispatchEvent(new CustomEvent('scroll-unblock'));
		if (selectedRestaurant) {
			redirect(`/menu/${selectedRestaurant.slug}`);
		} else {
			redirect('/');
		}
	};

	const brands = ['outback', 'carrabbas', 'bonefishgrill', 'flemings'];

	return (
		<Styled.Container ref={howItWorks}>
			<Styled.Body>
				<Styled.HowItWorksSection>
					<Styled.ComponentTitle>
						<Title main='How it works' id='how-it-works' aria-label='How it works' />
						<Styled.Close
							data-testid='close-how-it-works-button'
							aria-label='Close How it works'
							onClick={onClose}
						>
							<Icon icon={IconClose} ariaLabel='close' testId='close-icon' />
						</Styled.Close>
					</Styled.ComponentTitle>
					<Styled.Cards>
						{cardContents.map(({ icon, title, content }) => {
							return <ContentCard key={title} icon={icon} title={title} content={content} />;
						})}
					</Styled.Cards>
				</Styled.HowItWorksSection>

				<Styled.BeyondSection>
					<Styled.BeyondTitle data-testid='beyond-outback-title'>
						Beyond {orderUi.app.shortName}
					</Styled.BeyondTitle>
					<Styled.BeyondSubtitle>Enjoy dining at one of our many other locations.</Styled.BeyondSubtitle>
					<Styled.Logo>
						<Styled.Brand
							alt={orderUi.app.brand}
							src={`https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/${orderUi.app.brand}-logo.svg`}
						/>
						{brands
							.filter((brand) => brand !== orderUi.app.brand)
							.map((brand, index) => (
								<Styled.Brand
									key={index}
									alt={brand}
									src={`https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/${brand}-logo.svg`}
								/>
							))}
					</Styled.Logo>
				</Styled.BeyondSection>

				<Button text='Order now' variant='primary' id='order-now' onClick={redirectToOrder} />

				<Styled.ContactUsSection id='contact-us-section' tabIndex={0}>
					<Styled.ContactUs aria-hidden='false' href='/contact-us' data-testid='contact-us' tabIndex={0}>
						Contact us
					</Styled.ContactUs>
					<Styled.Link
						tabIndex={0}
						href='https://www.dine-rewards.com/'
						aria-label='Visit Dine Rewards website'
						data-testid='dine-rewards'
					>
						dine-rewards.com
					</Styled.Link>
					<Styled.Phone
						href='tel:18775467404'
						aria-label='1. 8 7 7. 5 4 6. 7 4 0 4.'
						data-testid='contact-phone'
						tabIndex={0}
					>
						1-877-546-7404
					</Styled.Phone>
				</Styled.ContactUsSection>
			</Styled.Body>
		</Styled.Container>
	);
};
