import { InputHandler } from '@maverick/hooks';
import { GaEvent } from '@maverick/utils';
import { Helmet } from 'react-helmet';
import { ValidationCreditCard } from '../../../constants';

export const ExpirationDateValidator = (expirationDate: InputHandler<string>): boolean => {
	const { title } = Helmet.peek();

	if (!expirationDate.value) {
		expirationDate.setError?.(ValidationCreditCard.insertExpirationDate);
		GaEvent.ErrorMessage(ValidationCreditCard.insertExpirationDate, title);

		return false;
	}

	if (expirationDate.value.length < 4) {
		expirationDate.setError?.(ValidationCreditCard.invalidExpirationDate);
		GaEvent.ErrorMessage(ValidationCreditCard.invalidExpirationDate, title);

		return false;
	}

	const d = new Date();
	const currentYear = d.getFullYear();
	const currentMonth = d.getMonth() + 1;
	const year = parseInt(expirationDate.value.substring(2, 4), 10) + 2000;
	const month = parseInt(expirationDate.value.substring(0, 2), 10);

	if (month < 1 || month > 12) {
		expirationDate.setError?.(ValidationCreditCard.invalidExpirationDate);
		GaEvent.ErrorMessage(ValidationCreditCard.invalidExpirationDate, title);

		return false;
	}

	if (year < currentYear || (year === currentYear && month < currentMonth)) {
		expirationDate.setError?.(ValidationCreditCard.invalidExpirationDate);
		GaEvent.ErrorMessage(ValidationCreditCard.invalidExpirationDate, title);

		return false;
	}

	return true;
};
