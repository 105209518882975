import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

import { BasketDeliveryAddress, HandoffMethods, Restaurant, SearchedAddresses } from '@maverick/entity';

export interface HandoffStore {
	selectedMethod: HandoffMethods;
	restaurantList: Array<Restaurant>;
	selectedRestaurant: Restaurant | null;
	selectedRestaurantAddress: string;
	deliveryAddress: BasketDeliveryAddress | null;
	searchedAddresses: SearchedAddresses | null;
}

const initialState: HandoffStore = {
	selectedMethod: HandoffMethods.Curbside,
	restaurantList: [],
	selectedRestaurant: null,
	selectedRestaurantAddress: '',
	deliveryAddress: null,
	searchedAddresses: null,
};

export const HandoffSlice = createSlice({
	name: 'handoff',
	initialState,
	reducers: {
		setSelectedMethod: (state, { payload }: PayloadAction<HandoffStore['selectedMethod']>) => {
			state.selectedMethod = payload;
		},

		setRestaurantList: (state, { payload }: PayloadAction<HandoffStore['restaurantList']>) => {
			state.restaurantList = payload;
		},

		setSelectedRestaurant: (state, { payload }: PayloadAction<HandoffStore['selectedRestaurant']>) => {
			state.selectedRestaurant = payload;
		},

		setSelectedRestaurantAddress: (
			state,
			{ payload }: PayloadAction<HandoffStore['selectedRestaurantAddress']>
		) => {
			state.selectedRestaurantAddress = payload;
		},

		setDeliveryAddress: (state, { payload }: PayloadAction<HandoffStore['deliveryAddress']>) => {
			state.deliveryAddress = payload;
		},

		setSearchedAddress: (state, { payload }: PayloadAction<HandoffStore['searchedAddresses']>) => {
			state.searchedAddresses = payload;
		},

		resetHandoff: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => ({
			...state,
			...action,
		}));
	},
});
