import { ItemAutocomplete, Restaurant, RestaurantCanDeliver } from '@maverick/entity';
import { RestaurantsService } from '../../services';
import { Calendar } from '@maverick/entity';
import { DateWithoutUTCFormat } from '@maverick/utils';
import { RadarService } from '../../services/radar';

const getCalendarDates = () => {
	const today = DateWithoutUTCFormat(new Date());
	const plus6days: Date = new Date();
	plus6days.setDate(today.getDate() + 6);

	const convertDateToString = (date: Date) => {
		return date.toISOString().slice(0, 10).replaceAll('-', '');
	};

	return { fromDate: convertDateToString(today), toDate: convertDateToString(plus6days) };
};

const forbiddenRestaurants = [225934];

export const HandoffManager = {
	GetCurbsideRestaurantList: async (
		latitude?: number,
		longitude?: number
	): Promise<Partial<{ restaurantList: Array<Restaurant>; error: string }>> => {
		const calendarDates = getCalendarDates();
		const response = await RestaurantsService.GetNearRestaurants({
			latitude: latitude ?? 0,
			longitude: longitude ?? 0,
			calendarstart: calendarDates.fromDate,
			calendarend: calendarDates.toDate,
		});

		if (response.error) {
			return { error: response.errorMessage };
		}

		const restaurants: Array<Restaurant> = response.body.restaurants.map((r: any) => Restaurant.Make(r));

		restaurants.forEach((restaurant) => {
			restaurant.calendar = [];
			(restaurant.calendars || []).forEach((calendar) => {
				restaurant.calendar.push(Calendar.Make(calendar));
			});
			restaurant.calendars = undefined;
		});
		const validRestaurants = restaurants.filter((r) => !forbiddenRestaurants.includes(r.id));
		return { restaurantList: validRestaurants.filter((r) => !!r.calendar) };
	},

	GetDeliveryRestaurant: async (
		latitude: number,
		longitude: number
	): Promise<Partial<{ restaurantList: Array<Restaurant>; error: string }>> => {
		const calendarDates = getCalendarDates();
		const response = await RestaurantsService.GetDeliveryRestaurant({
			latitude: latitude,
			longitude: longitude,
			calendarstart: calendarDates.fromDate,
			calendarend: calendarDates.toDate,
		});

		if (response.error) {
			return { error: response.errorMessage };
		}

		const restaurants: Array<Restaurant> = response.body.restaurants.map((r: any) => Restaurant.Make(r));
		restaurants.map((r) => HandoffManager.GetRestaurantCalendar(r));

		return { restaurantList: restaurants.filter((r) => !!r.calendar) };
	},

	GetRestaurant: async (restaurantId: number): Promise<Restaurant | null> => {
		const response = await RestaurantsService.GetRestaurant({ restaurantId });

		if (response.error) return null;

		return Restaurant.Make(response.body);
	},

	GetRestaurantCalendar: async (restaurant: Restaurant): Promise<void> => {
		if (!restaurant.calendars) {
			return;
		}

		restaurant.calendar = restaurant.calendars.map((c: any) => {
			return Calendar.Make(c);
		});
	},

	GetRestaurantCalendarById: async (
		restaurantId: number
	): Promise<Partial<{ calendar: Array<Calendar>; error: string }>> => {
		const response = await RestaurantsService.GetRestaurantCalendar({ restaurantId: restaurantId });

		if (response.error) {
			return { error: response.errorMessage };
		}

		const calendar = response.body.calendar.map((c: any) => {
			return Calendar.Make(c);
		});

		return { calendar: calendar };
	},

	GetRestaurantBySlug: async (slug: string): Promise<Restaurant | null> => {
		const response = await RestaurantsService.GetRestaurantBySlug(slug);

		if (response.error) {
			return null;
		}

		return Restaurant.Make(response.body);
	},

	GetDeliveryAddressAutocomplete: async (value: string): Promise<Array<ItemAutocomplete> | string | null> => {
		const response = await RadarService.GetAddressAutocomplete(value);
		if (!response) {
			return null;
		}
		const result = response.map((item: any) => ({ value: item, label: item.formattedAddress }));
		return result;
	},

	CheckIfRestaurantCanDeliver: async (
		id: string,
		handoffmode: string,
		timewantedmode: string,
		timewantedutc: string,
		street: string,
		city: string,
		zipcode: string
	): Promise<Partial<{ restaurantCanDeliver: RestaurantCanDeliver; error: string }>> => {
		const response = await RestaurantsService.CheckIfRestaurantCanDeliver({
			id,
			handoffmode,
			timewantedmode,
			timewantedutc,
			street,
			city,
			zipcode,
		});

		if (response.error) {
			return { error: response.errorMessage };
		}

		const restaurantCanDeliver = RestaurantCanDeliver.Make(response.body);
		return { restaurantCanDeliver };
	},

	GetLocationByRef: async (ref: string): Promise<Restaurant | null> => {
		const result = await RestaurantsService.GetLocationByRef(ref);

		if (result.error || !result.body?.restaurants?.length) {
			return null;
		}

		return Restaurant.Make(result.body.restaurants[0]);
	},
};
