import { useNavigate } from 'react-router-dom';

interface RedirectOptions {
	newTab?: boolean;
	scrollToTop?: boolean;
	state?: any;
	replace?: boolean;
}

export const useRedirect = () => {
	const navigate = useNavigate();

	const redirect = (
		path: string,
		options: RedirectOptions = { newTab: false, scrollToTop: true, state: null, replace: false }
	) => {
		if (path.includes('http') || path.includes('tel:') || path.includes('mailto:') || options.newTab) {
			const a = document.createElement('a');
			a.setAttribute('href', path);
			a.setAttribute('rel', 'noopener noreferrer');
			a.setAttribute('target', '_blank');
			a.click();
			return;
		}

		navigate(path, { state: options.state, replace: options.replace });
	};

	return redirect;
};
