import { EdgeConfig } from '@maverick/entity';
import { get } from '@vercel/edge-config';
import { HttpMethods, RestRequest } from '@maverick/http';
import { VercelClient } from './client';
import { Config } from '../../Config';

export const EdgeConfigService = {
	Get: async (): Promise<EdgeConfig | null> => {
		const isServer = typeof window === 'undefined';

		try {
			if (isServer) {
				const brand = await get<EdgeConfig>(Config.Brand);
				if (!brand) return null;
				return EdgeConfig.Make(brand);
			} else {
				const request = new RestRequest({
					method: HttpMethods.Get,
					endpoint: `/edge-config?key=${Config.Brand}`,
				});
				const response = await VercelClient.ExecuteRequest(request);
				if (response.error || !response.body) return null;
				return EdgeConfig.Make(response.body);
			}
		} catch {
			return null;
		}
	},
};
