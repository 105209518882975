import { FC, useEffect, useState } from 'react';
import * as Styled from './ProductSuggestion.styled';
import { ArrowIcon } from '@maverick/icons/dist/ArrowIcon';
import { Icon } from '@maverick/ui';
import { OptionsCard } from '../OptionsCard';
import { Upsell } from '@maverick/entity';
import { GaEvent } from '@maverick/utils';

export interface ProductSuggestionProps {
	remainingValue: number;
	rewardsValue: number;
	productList: Array<{product: Upsell, category: string, brand?: string, productTag: string | null}>;
	onClick: (id: number) => void;
}

export const ProductSuggestion: FC<ProductSuggestionProps> = ({
	remainingValue,
	rewardsValue,
	productList,
	onClick,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const onClickExpandArrow = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		GaEvent.ViewItemListProductSuggestion(productList);
	}, []);

	return (
		<Styled.Section data-testid='product-suggestion'>
			<Styled.Container>
				<Styled.DescriptionContainer>
					<Styled.Description>
						{'Add '}
						<Styled.RemainingValue>{`$${remainingValue}`}</Styled.RemainingValue>
						{' to order for a '}
						<Styled.RewardsValue>{`$${rewardsValue} off`} </Styled.RewardsValue>
						{' reward'}
					</Styled.Description>
					<Styled.ArrowIcon
						aria-expanded={isOpen}
						aria-label={`${isOpen ? 'Close' : 'Open'} ${'product suggestion'}`}
						data-testid='arrow-button'
						onClick={() => onClickExpandArrow()}
						isOpen={isOpen}
					>
						<Icon data-testid='arrow-icon' icon={ArrowIcon} />
					</Styled.ArrowIcon>
				</Styled.DescriptionContainer>
				{isOpen && (
					<Styled.OptionCard role='list' aria-label='add ons'>
						{productList?.map((i) => {
							return (
								<OptionsCard
									upsell={i.product}
									selected={false}
									key={i.product.id}
									onClick={() => onClick(i.product.id)}
									testId={i.product.name.replace(/\s/g, '-') + '_' + i.product.id}
								/>
							);
						})}
					</Styled.OptionCard>
				)}
			</Styled.Container>
		</Styled.Section>
	);
};
