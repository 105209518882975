import React, { FC } from 'react';
import { Button, Icon } from '@maverick/ui';
import * as Styled from './DRBanner.styled';
import { IconStarRewards } from '@maverick/icons/dist/IconStarRewards';
import { IconCellphone } from '@maverick/icons/dist/IconCellphone';
import { IconQuicklyReorder } from '@maverick/icons/dist/IconQuicklyReorder';
import { useAuth0 } from '@auth0/auth0-react';
import { Config } from '../../../Config';
import { useTheme } from 'styled-components';
import { GaEvent } from '@maverick/utils';
import { ReduxProps } from '.';

export interface DRBannerProps extends ReduxProps {}

export const DRBanner: FC<DRBannerProps> = ({ setRedirectRoute, setUserAuthLastInteraction }) => {
	const { loginWithRedirect } = useAuth0();
	const { orderUi } = useTheme();
	const labelOnClickEvent = 'banner_order_summary';
	const authUrl = process.env.AUTH0_DOMAIN ?? '';

	const handleOnClickSignIn = () => {
		GaEvent.InternalLinkClick(authUrl + '/login', labelOnClickEvent);
		setRedirectRoute(location.pathname.split(`/order`)[1]);
		setUserAuthLastInteraction('order-summary-banner');
		loginWithRedirect({ source: Config.Source, prompt: 'login' });
	};

	const handleOnClickJoinNow = () => {
		GaEvent.InternalLinkClick(authUrl, labelOnClickEvent);
		setRedirectRoute(location.pathname.split(`/order`)[1]);
		setUserAuthLastInteraction('order-summary-banner');
		loginWithRedirect({ source: Config.Source });
	};

	return (
		<>
			<Styled.Container>
				<Styled.Title data-testid={'DRBanner-Title'}>
					<label tabIndex={0}>{orderUi.texts.DRBannerTitle}</label>
				</Styled.Title>
				<Styled.DescriptionContainer>
					<Styled.DescriptionItem>
						<Icon icon={IconStarRewards} />
						<Styled.DescriptionText>
							<label tabIndex={0}>{orderUi.texts.SignUpRewardText}</label>
						</Styled.DescriptionText>
					</Styled.DescriptionItem>
					<Styled.DescriptionItem>
						<Icon icon={IconCellphone} />
						<Styled.DescriptionText>
							<label tabIndex={0}>{orderUi.texts.SignUpPhoneText}</label>
						</Styled.DescriptionText>
					</Styled.DescriptionItem>
					<Styled.DescriptionItem>
						<Icon icon={IconQuicklyReorder} />
						<Styled.DescriptionText>
							<label tabIndex={0}> {orderUi.texts.SignUpReorderText}</label>
						</Styled.DescriptionText>
					</Styled.DescriptionItem>
				</Styled.DescriptionContainer>

				<Styled.ButtonContainer>
					<Button
						text={'Sign in'}
						variant='quinary'
						id='Sign-in'
						padding='8px 0px'
						onClick={handleOnClickSignIn}
					/>
					<Button
						text={'Join now'}
						variant='primary'
						id='Join-Now'
						onClick={handleOnClickJoinNow}
						padding='8px 0px'
					/>
				</Styled.ButtonContainer>
			</Styled.Container>
			<Styled.Line></Styled.Line>
		</>
	);
};
