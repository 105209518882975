import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../Store';
import { ValidateBasketExpiration } from './ValidateBasketExpiration';
import { CheckoutOperations } from '../../features/Checkout/redux/Checkout.operations';
import { AppOperations } from '../../features/App/redux/App.operations';

const mapStateToProps = (state: RootState) => ({
	basketCreationDate: state.checkout.basketCreationDate,
	showSummary: state.app.showSummary,
});

const mapDispatchToProps = {
	setBasket: CheckoutOperations.setBasket,
	setLoading: AppOperations.setLoading,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;

const ValidateBasketExpirationConnected = connector(ValidateBasketExpiration);
export { ValidateBasketExpirationConnected as ValidateBasketExpiration };
