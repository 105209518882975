import { Dispatch } from 'redux';
import { HandoffStore, HandoffSlice } from './Handoff.slice';
import { setCookie } from '@maverick/ui';
import { Cookies } from '@maverick/entity';

export const HandoffOperations = {
	setSelectedMethod: (method: HandoffStore['selectedMethod']) => (dispatch: Dispatch) => {
		const action = HandoffSlice.actions.setSelectedMethod(method);
		dispatch(action);
	},

	setRestaurantList: (restaurantList: HandoffStore['restaurantList']) => (dispatch: Dispatch) => {
		dispatch(HandoffSlice.actions.setRestaurantList(restaurantList));
	},

	setSelectedRestaurant: (selectedRestaurant: HandoffStore['selectedRestaurant']) => (dispatch: Dispatch) => {
		const extrefParts = selectedRestaurant?.extref?.split?.('_');
		const yextId = extrefParts && extrefParts.length >= 2 ? extrefParts[1] : selectedRestaurant?.extref;
		setCookie(Cookies.FavoriteStore, yextId);
		dispatch(HandoffSlice.actions.setSelectedRestaurant(selectedRestaurant));
	},

	setSelectedRestaurantAddress:
		(selectedRestaurantAddress: HandoffStore['selectedRestaurantAddress']) => (dispatch: Dispatch) => {
			dispatch(HandoffSlice.actions.setSelectedRestaurantAddress(selectedRestaurantAddress));
		},

	setDeliveryAddress: (deliveryAddress: HandoffStore['deliveryAddress']) => (dispatch: Dispatch) => {
		dispatch(HandoffSlice.actions.setDeliveryAddress(deliveryAddress));
	},

	setSearchedAddresses: (searchedAddresses: HandoffStore['searchedAddresses']) => (dispatch: Dispatch) => {
		dispatch(HandoffSlice.actions.setSearchedAddress(searchedAddresses));
	},

	resetHandoff: () => (dispatch: Dispatch) => {
		dispatch(HandoffSlice.actions.resetHandoff());
	},
} as const;
