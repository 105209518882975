import { NavigationProps } from '@maverick/entity';

export const NavigationOutback: NavigationProps = {
	mainItems: [
		{
			id: '1',
			url: '/menu',
			label: 'Menu',
			newTab: false,
		},
		{
			id: '2',
			url: '/order',
			label: 'Order',
			newTab: false,
		},
		{
			id: '3',
			url: '/careers',
			label: 'Careers',
			newTab: false,
		},
		{
			id: '4',
			url: '/rewards',
			label: 'Rewards',
			newTab: false,
		},
		{
			id: '5',
			url: '/specials',
			label: 'Specials',
			newTab: false,
		},
		{
			id: '6',
			url: '/gift-cards',
			label: 'Gift cards',
			newTab: false,
		},
		{
			id: '7',
			url: '/catering',
			label: 'Catering',
			newTab: false,
		},
	],
	hamburgerItems: [],
	featuredItems: [
		{
			id: '8',
			url: '/nutrition/smart-dining',
			label: 'Smart dining',
			newTab: false,
		},
		{
			id: '9',
			url: '/contact',
			label: 'Contact',
			newTab: false,
		},
		{
			id: '10',
			url: '/about',
			label: 'About',
			newTab: false,
		},
		{
			id: '11',
			url: '/careers',
			label: 'Careers',
			newTab: false,
		},
		{
			id: '12',
			url: '/press',
			label: 'Press',
			newTab: false,
		},
		{
			id: '12',
			url: '/international',
			label: 'International',
			newTab: false,
		},
	],
	unfeaturedItems: [
		{
			id: '13',
			url: '/specials',
			label: 'Specials',
			newTab: false,
		},
		{
			id: '14',
			url: '/steak',
			label: 'Steak',
			newTab: false,
		},
		{
			id: '15',
			url: '/bloomin-onion',
			label: 'Bloomin onion',
			newTab: false,
		},
		{
			id: '16',
			url: '/beverages',
			label: 'Bar',
			newTab: false,
		},
		{
			id: '17',
			url: '/privacy-rights',
			label: 'Privacy policy',
			newTab: false,
		},
		{
			id: '18',
			url: '/privacy-rights/ccpa',
			label: 'California privacy notice',
			newTab: false,
		},
		{
			id: '19',
			url: '/privacy-rights/accessibility',
			label: 'Accessibility statement',
			newTab: false,
		},
		{
			id: '20',
			url: '/privacy-rights/dine-rewards-terms',
			label: 'Dine Rewards terms & conditions',
			newTab: false,
		},
		{
			id: '21',
			url: '/privacy-rights/terms-conditions',
			label: 'Terms & conditions',
			newTab: false,
		},
		{
			id: '22',
			url: '/privacy-rights/sms-terms-conditions',
			label: 'SMS terms & conditions',
			newTab: false,
		},
		{
			id: '23',
			url: '/privacy-rights/gift-card-terms-conditions',
			label: 'Gift Card terms & conditions',
			newTab: false,
		},
	],
};

export const NavigationCarrabbas: NavigationProps = {
	mainItems: [
		{
			id: '1',
			url: '/menu',
			label: 'Menu',
			newTab: false,
		},
		{
			id: '2',
			url: '/order',
			label: 'Order Now',
			newTab: false,
		},
		{
			id: '3',
			url: '/catering',
			label: 'Catering',
			newTab: false,
		},
		{
			id: '4',
			url: '/gift-cards',
			label: 'Gift cards',
			newTab: false,
		},
		{
			id: '5',
			url: '/offers',
			label: 'Offers',
			newTab: false,
		},
		{
			id: '6',
			url: '/specials',
			label: 'Specials',
			newTab: false,
		},
		{
			id: '7',
			url: '/careers',
			label: 'Careers',
			newTab: false,
		},
	],
	hamburgerItems: [],
	featuredItems: [
		{
			id: '8',
			url: '/nutrition/smart-dining',
			label: 'Smart dining',
			newTab: false,
		},
		{
			id: '9',
			url: '/contact',
			label: 'Contact',
			newTab: false,
		},
		{
			id: '10',
			url: '/about',
			label: 'About',
			newTab: false,
		},
		{
			id: '11',
			url: '/careers',
			label: 'Careers',
			newTab: false,
		},
		{
			id: '12',
			url: '/press',
			label: 'Press',
			newTab: false,
		},
		{
			id: '12',
			url: '/international',
			label: 'International',
			newTab: false,
		},
	],
	unfeaturedItems: [
		{
			id: '13',
			url: '/specials',
			label: 'Specials',
			newTab: false,
		},
		{
			id: '14',
			url: '/steak',
			label: 'Steak',
			newTab: false,
		},
		{
			id: '15',
			url: '/bloomin-onion',
			label: 'Bloomin onion',
			newTab: false,
		},
		{
			id: '16',
			url: '/beverages',
			label: 'Bar',
			newTab: false,
		},
		{
			id: '17',
			url: '/privacy-rights',
			label: 'Privacy policy',
			newTab: false,
		},
		{
			id: '18',
			url: '/privacy-rights/ccpa',
			label: 'California privacy notice',
			newTab: false,
		},
		{
			id: '19',
			url: '/privacy-rights/accessibility',
			label: 'Accessibility statement',
			newTab: false,
		},
		{
			id: '20',
			url: '/privacy-rights/dine-rewards-terms',
			label: 'Dine Rewards terms & conditions',
			newTab: false,
		},
		{
			id: '21',
			url: '/privacy-rights/terms-conditions',
			label: 'Terms & conditions',
			newTab: false,
		},
		{
			id: '22',
			url: '/privacy-rights/sms-terms-conditions',
			label: 'SMS terms & conditions',
			newTab: false,
		},
		{
			id: '23',
			url: '/privacy-rights/gift-card-terms-conditions',
			label: 'Gift Card terms & conditions',
			newTab: false,
		},
	],
};

export const NavigationBonefishGrill: NavigationProps = {
	mainItems: [
		{
			id: '1',
			url: '/order',
			label: 'Order Now',
			newTab: false,
		},
		{
			id: '2',
			url: '/menu',
			label: 'Menus',
			newTab: false,
		},
		{
			id: '3',
			url: '/specials',
			label: 'Specials',
			newTab: false,
		},
		{
			id: '4',
			url: '/catering',
			label: 'Catering',
			newTab: false,
		},
		{
			id: '5',
			url: '/gift-cards',
			label: 'Gift cards',
			newTab: false,
		},
		{
			id: '6',
			url: '/rewards',
			label: 'Rewards',
			newTab: false,
		},
		{
			id: '7',
			url: '/careers',
			label: 'Careers',
			newTab: false,
		},
	],
	hamburgerItems: [],
	featuredItems: [
		{
			id: '8',
			url: '/nutrition/smart-dining',
			label: 'Smart dining',
			newTab: false,
		},
		{
			id: '9',
			url: '/contact',
			label: 'Contact',
			newTab: false,
		},
		{
			id: '10',
			url: '/about',
			label: 'About',
			newTab: false,
		},
		{
			id: '11',
			url: '/careers',
			label: 'Careers',
			newTab: false,
		},
		{
			id: '12',
			url: '/press',
			label: 'Press',
			newTab: false,
		},
		{
			id: '12',
			url: '/international',
			label: 'International',
			newTab: false,
		},
	],
	unfeaturedItems: [
		{
			id: '13',
			url: '/specials',
			label: 'Specials',
			newTab: false,
		},
		{
			id: '14',
			url: '/steak',
			label: 'Steak',
			newTab: false,
		},
		{
			id: '15',
			url: '/bloomin-onion',
			label: 'Bloomin onion',
			newTab: false,
		},
		{
			id: '16',
			url: '/beverages',
			label: 'Bar',
			newTab: false,
		},
		{
			id: '17',
			url: '/privacy-rights',
			label: 'Privacy policy',
			newTab: false,
		},
		{
			id: '18',
			url: '/privacy-rights/ccpa',
			label: 'California privacy notice',
			newTab: false,
		},
		{
			id: '19',
			url: '/privacy-rights/accessibility',
			label: 'Accessibility statement',
			newTab: false,
		},
		{
			id: '20',
			url: '/privacy-rights/dine-rewards-terms',
			label: 'Dine Rewards terms & conditions',
			newTab: false,
		},
		{
			id: '21',
			url: '/privacy-rights/terms-conditions',
			label: 'Terms & conditions',
			newTab: false,
		},
		{
			id: '22',
			url: '/privacy-rights/sms-terms-conditions',
			label: 'SMS terms & conditions',
			newTab: false,
		},
		{
			id: '23',
			url: '/privacy-rights/gift-card-terms-conditions',
			label: 'Gift Card terms & conditions',
			newTab: false,
		},
	],
};
