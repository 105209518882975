import { DESKTOP_BREAKPOINT, ULTRAWIDE_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.div``;

export const NotFound = styled.div`
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: flex;

	@media screen and (min-width: 1366px) {
		padding-top: 8px;
	}

	@media screen and (min-width: 1440px) {
		padding-top: 40px;
	}

	@media screen and (min-width: ${ULTRAWIDE_BREAKPOINT}px) {
		padding-top: 72px;
	}
`;

export const SROnlyTitle = styled.h2`
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
`;

export const RestaurantContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin: auto;
`;

export const FoundImage = styled.div`
	${({ theme }) => theme.orderUi.icons.iconNoRestaurantsFoundSize}
`;

export const MainText = styled.h2`
	all: unset;
	${({ theme }) => theme.orderUi.typography.title.subtitle}
	color: ${({ theme }) => theme.orderUi.colors.neutral.dark};
	margin-top: 32px;
	margin-bottom: 16px;
	text-align: center;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.buttonLarge}
	}
`;

export const SubText = styled.h3`
	all: unset;
	${({ theme }) => theme.orderUi.typography.body.bodySmall}
	color: ${({ theme }) => theme.orderUi.colors.neutral.dark};
	text-align: center;

	@media (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.body}
	}
`;

export const LocationContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const Location = styled.button`
	${({ theme }) => theme.orderUi.typography.caption.captionMedium};
	font-family: unset;
	background: none;
	border: none;
	padding: 0;
	display: inline-flex;
	align-items: center;
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	margin-top: 8px;
	cursor: pointer;
	font-weight: ${({ theme }) => theme.orderUi.specificStyle.specificFontWeight};
	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.inputs.buttonSmall};
		margin-top: 16px;
		font-weight: ${({ theme }) => theme.orderUi.specificStyle.specificFontWeight};
		
	}

	& > div {
		width: 16px;
		height: 16px;
		margin: 0 0 0 8px;
	}
`;

export const Address = styled.div`
	margin-bottom: 24px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
