import { DESKTOP_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const CheckRewardsColumn = styled.div`
	flex-direction: row;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	align-self: center;
	flex: 1;
	cursor: pointer;
	@media screen and (max-width: ${DESKTOP_BREAKPOINT - 1}px) {
		justify-content: space-around;
	}
`;

export const CheckRewards = styled.div`
	${({ theme }) => theme.orderUi.typography.inputs.button}
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	margin: 0 16px 0 0;
`;

export const RewardsCounter = styled.div`
	width: 24px;
	height: 24px;
	border-radius: 12px;
	${({ theme }) => theme.orderUi.typography.inputs.buttonSmall}
	background-color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	justify-content: center;
	text-align: center;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.orderUi.colors.neutral.light};
	${({ theme }) => theme.orderUi.specificStyle.rewardsCounter};
`;
